// @flow strict
/* eslint react/no-array-index-key: off */
/* eslint react/jsx-closing-tag-location: off */

import * as React from 'react';

import {
  sidebarMenu,
  SidebarMenu,
  SidebarMenuGroup,
  SidebarMenuItem,
  Button,
  icon,
  Icon,
  FormattedContentBlock,
} from '../components';

import type { DisplayType } from '../components/SidebarMenu';

type StateType = {
  menu1: DisplayType,
  menu2: DisplayType,
  menu2HasBadges: boolean,
  appMenuData: any, // TODO: Workout how to support tuples in flow
};

class SidebarMenuTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      menu1: sidebarMenu.init({ active: 'DOCUMENTS' }),
      menu2: sidebarMenu.init({ active: 2 }),
      menu2HasBadges: true,
      appMenuData: [
        {
          group: {
            header: 'Manage',
            items: [
              {
                icon: icon.Glyphs.ObjectsSubject,
                colour: '#1f9c73',
                text: 'Documents',
                id: 'DOCUMENTS',
                badgeTitle: 'new documents',
                badge: 3,
              },
              {
                icon: icon.Glyphs.DocumentAcrobat,
                colour: '#1f9c73',
                text: 'Q&A',
                id: 'Q_AND_A',
                badgeTitle: 'unread questions',
                badge: 15,
              },
            ],
          },
        },
        {
          group: {
            header: '',
            items: [
              {
                icon: icon.Glyphs.ObjectsSettings,
                colour: '#774dca',
                text: 'Settings',
                id: undefined,
              },
            ],
          },
        },
      ],
    };
  }

  update(address: string, value: sidebarMenu.DisplayType | boolean) {
    this.setState({
      ...this.state,
      [address]: value,
    });
  }

  render() {
    const { menu1, menu2, menu2HasBadges, appMenuData } = this.state;

    const {
      Messages: { HideMobile, ShowMobile, HideText, ShowText },
      Message,
    } = sidebarMenu;

    const renderGroup = (group: any, groupIndex: number) => (
      <SidebarMenuGroup header={group.group.header} key={`app-group-${groupIndex}`}>
        {group.group.items.map((item: any, itemIndex: number) => (
          <SidebarMenuItem
            icon={<Icon glyph={item.icon} text="ace.ansarada.com" colour={item.colour} />}
            text={item.text}
            id={item.id}
            badgeTitle={item.badgeTitle}
            badge={item.badge}
            key={`app-group-${groupIndex}-${itemIndex}`}
          />
        ))}
      </SidebarMenuGroup>
    );

    return (
      <div>
        <div className="app" id="page">
          <main id="content" role="main">
            <div className="ace-group ace-page-content">
              <div className="ace-item ace-page-content-sidebar">
                <FormattedContentBlock>
                  <h2>Sidebar Menu</h2>
                </FormattedContentBlock>

                <SidebarMenu
                  display={menu1}
                  mobileHeader={
                    <Button
                      variant="Ghost"
                      icon={<Icon glyph={icon.Glyphs.ControlMenu} />}
                      onClick={() => {
                        const msg = Message(menu1.mobileVisible ? HideMobile : ShowMobile)();
                        this.update('menu1', sidebarMenu.transition(menu1, msg));
                      }}
                    >
                      Menu
                    </Button>
                  }
                  onUpdate={value => {
                    this.update('menu1', value);
                  }}
                >
                  {appMenuData.map(renderGroup)}

                  <SidebarMenuGroup>
                    <SidebarMenuItem
                      icon={
                        <Icon
                          glyph={menu1.showText ? 'control-arrowleft' : 'control-arrowright'}
                          text={menu1.showText ? 'hide' : 'show'}
                          colour="purple"
                        />
                      }
                      text={menu1.showText ? 'Hide menu' : 'Show menu'}
                      id="text_toggle"
                      key="text_toggle"
                      onClick={() => {
                        const msg = Message(menu1.showText ? HideText : ShowText)();
                        this.update('menu1', sidebarMenu.transition(menu1, msg));
                      }}
                    />
                  </SidebarMenuGroup>
                </SidebarMenu>

                <FormattedContentBlock>
                  <h2>Fanicer Sidebar Menu</h2>
                  <p>Includes badges and custom mobile header.</p>
                </FormattedContentBlock>

                <SidebarMenu
                  display={menu2}
                  mobileHeader={
                    <div>
                      <Button
                        variant="Ghost"
                        icon={<Icon glyph={icon.Glyphs.ControlMenu} />}
                        onClick={() => {
                          const msg = Message(menu2.mobileVisible ? HideMobile : ShowMobile)();
                          this.update('menu2', sidebarMenu.transition(menu2, msg));
                        }}
                      >
                        Menu
                      </Button>
                      <Button variant="Ghost" icon={<Icon glyph={icon.Glyphs.ObjectsUsergroup} />}>
                        Account
                      </Button>
                    </div>
                  }
                  onUpdate={value => {
                    this.update('menu2', value);
                  }}
                  badges={menu2HasBadges}
                >
                  {appMenuData.map(renderGroup)}

                  <SidebarMenuGroup>
                    <SidebarMenuItem
                      icon={
                        <Icon
                          glyph={menu1.showText ? 'control-arrowleft' : 'control-arrowright'}
                          text={menu1.showText ? 'hide' : 'show'}
                          colour="purple"
                        />
                      }
                      text={menu1.showText ? 'Hide menu' : 'Show menu'}
                      id="text_toggle"
                      key="text_toggle"
                      onClick={() => {
                        const msg = Message(menu2.showText ? HideText : ShowText)();
                        this.update('menu2', sidebarMenu.transition(menu2, msg));
                      }}
                    />
                    <SidebarMenuItem
                      icon={
                        <Icon
                          glyph={menu2HasBadges ? icon.Glyphs.ControlOff : icon.Glyphs.ControlOn}
                          text={menu2HasBadges ? 'disable' : 'enable'}
                          colour="grey"
                        />
                      }
                      text={menu2HasBadges ? 'Disable badges' : 'Enable badges'}
                      id="menu2_badges_toggle"
                      key="menu2_badges_toggle"
                      onClick={() => {
                        this.update('menu2HasBadges', !menu2HasBadges);
                      }}
                    />
                  </SidebarMenuGroup>
                </SidebarMenu>
              </div>
              <div className="ace-item ace-page-content-content" />
            </div>
          </main>
          <footer id="footer" role="contentinfo" />
        </div>
      </div>
    );
  }
}

export default SidebarMenuTest;
