// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const LockSecurityPolicy = (): React.Element<*> => (
  <>
    <path d="M16.506 0.99h-13c-1.379 0-2.5 1.122-2.5 2.5v13c0 1.378 1.121 2.5 2.5 2.5h13c1.378 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.122-2.5-2.5-2.5zM18.006 16.49c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M11.506 12.006h-3c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h3c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M11.506 10.006h-3c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h3c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M13.006 8.096v-1.114c0-1.649-1.342-2.99-2.992-2.99s-2.992 1.342-2.992 2.99v1.091c-0.59 0.169-1.015 0.703-1.016 1.337v4.092c0.001 0.769 0.625 1.392 1.395 1.392 0 0 0 0 0 0v0l5.209 0.018c0.77 0 1.396-0.619 1.396-1.389v-4.092c-0.001-0.629-0.418-1.16-0.99-1.333l-0.010-0.003zM8.022 6.982c0-1.098 0.894-1.99 1.992-1.99s1.992 0.893 1.992 1.99v1.055l-3.984-0.014zM13.006 13.523c-0 0.218-0.177 0.395-0.395 0.395h-0l-5.209-0.022c-0 0-0 0-0.001 0-0.218 0-0.395-0.177-0.395-0.395v-4.092c0-0.218 0.177-0.395 0.395-0.395h0l5.209 0.022c0 0 0 0 0.001 0 0.218 0 0.395 0.177 0.395 0.395v0z"></path>
  </>
);
