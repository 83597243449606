// @flow strict
import * as React from 'react';
import { useUncontrolled } from 'uncontrollable';

// importing individual modules, so that ace-react does not pull in the entire
// date-fn library to minimise bundle size
import isValid from 'date-fns/isValid';
import type { ansaradaCCDPropType } from '../../ace-internal/types/general';

import { DatepickerContainer } from './internal/DatepickerContainer';
import { DatepickerProvider } from './internal/DatepickerContext';

export type BaseProps = {|
  ...ansaradaCCDPropType,
  /** an optional id prop */
  id?: string,
  /** the size of the input (matches other text input sizes) */
  size: 'Small' | 'Medium' | 'Large',
  /** the max date in a date range the user is allowed to pick from */
  maxDate?: Date,
  /** the min date in a date range the user is allowed to pick from */
  minDate?: Date,
  /** an optional classname to adjust width or margins */
  className?: string,
  /** one of three localized formats */
  format: 'D/M/Y' | 'M/D/Y' | 'Y/M/D',
  /** an optional placeholder value. Defaults to "Select a date" */
  placeholder: string,
|};

type ControlledSelectedDate<T> = {|
  selectedDate?: ?T,
  onSelectDate: (selectedDate: T) => void,
|};
type UncontrolledSelectedDate<T> = {|
  defaultSelectedDate: ?T,
  onSelectDate?: (selectedDate: T) => void,
|};
type SelectedDate<T> = ControlledSelectedDate<T> | UncontrolledSelectedDate<T>;

type ControlledCalendarVisible<T> = {|
  calendarVisible?: T,
  onCalendarVisible: (calendarVisible: T) => void,
|};
type UncontrolledCalendarVisible<T> = {|
  defaultCalendarVisible: T,
  onSelectDate?: (calendarVisible: T) => void,
|};
type CalendarVisible<T> = ControlledCalendarVisible<T> | UncontrolledCalendarVisible<T>;

type ControlledProps = {|
  ...ControlledSelectedDate<Date>,
  ...ControlledCalendarVisible<boolean>,
|};

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledProps,
|};

type DatepickerPropType = {|
  ...BaseProps,
  ...SelectedDate<Date>,
  ...CalendarVisible<boolean>,
|};

const ControlledDatepicker = ({
  className,
  selectedDate,
  calendarVisible,
  format,
  id,
  placeholder,
  size,
  minDate,
  maxDate,
  onCalendarVisible,
  onSelectDate,
  'data-ansarada-ccd': ansaradaCCD,
}: ControlledPropType) => {
  const _minDate = minDate && isValid(minDate) ? minDate : new Date(-8640000000000000);
  const _maxDate = maxDate && isValid(maxDate) ? maxDate : new Date(8640000000000000);

  return (
    <DatepickerProvider
      value={{
        minDate: _minDate,
        maxDate: _maxDate,
        calendarVisible,
        onCalendarVisible,
        selectedDate,
        onSelectDate,
      }}
    >
      <DatepickerContainer
        id={id}
        className={className}
        placeholder={placeholder}
        format={format}
        size={size}
        data-ansarada-ccd={ansaradaCCD || undefined}
      />
    </DatepickerProvider>
  );
};

/**
 * Allow users to select a date.
 *
 *
 * Use to select a single date from the calendar dialog or by typing in the date as indicated by the placeholder text.
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Autocomplete, Timepicker, TextInput, Dropdown
 * @category Form
 */
const Datepicker = (props: DatepickerPropType): React$Element<typeof ControlledDatepicker> => {
  const allProps = useUncontrolled(props, {
    selectedDate: 'onSelectDate',
    calendarVisible: 'onCalendarVisible',
  });
  return <ControlledDatepicker {...allProps} />;
};

Datepicker.defaultProps = {
  size: 'Medium',
  placeholder: 'Select a date',
};

export type DatepickerType = 'Datepicker';
export { Datepicker };
