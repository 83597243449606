// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Caution = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M10.006 4.99c-0.276 0-0.5 0.224-0.5 0.5v0 6c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M10.006 13.49c-0.276 0-0.5 0.224-0.5 0.5v0 0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-0.5c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M10.006 1.99c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM10.006 16.99c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"
    ></path>
  </>
);
