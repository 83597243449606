// @flow strict
import * as React from 'react';
import { FormattedContentBlock } from '../components';

const FormattedContentBlockTest = () => (
  <div>
    <FormattedContentBlock>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices, risus eu fermentum
        laoreet, nulla neque ornare dui, ut aliquam lorem nibh ut ante. Class aptent taciti sociosqu
        ad litora torquent per conubia nostra, per inceptos himenaeos. Cras ultrices arcu tempor
        neque viverra, eu dapibus nunc viverra. In efficitur, mi vel tempor tempus, urna diam
        feugiat ante, at auctor libero leo et enim. Cras est diam, vulputate quis metus a, rutrum
        laoreet orci. Ut eros velit, eleifend nec vulputate ut, molestie eget felis. Vivamus
        fermentum risus non sem ullamcorper pulvinar. Aliquam tincidunt feugiat lectus, eget blandit
        metus fringilla a. Pellentesque accumsan sit amet enim at congue. Aliquam bibendum, ipsum id
        aliquet interdum, turpis est consequat libero, blandit blandit ex risus imperdiet magna.
      </p>
      <p>
        In cursus varius urna, <strong>non congue</strong> lectus rhoncus id.{' '}
        <em>Nulla vel diam</em>eu lorem sagittis ultricies non pulvinar nisl. Aliquam vestibulum
        sapien velit. In faucibus, libero mollis consequat tincidunt, risus justo finibus nunc, sed
        tincidunt diam neque ut magna. Vivamus libero arcu, efficitur a dui a, cursus pharetra eros.
        In odio sem, porta at nisi nec, vulputate laoreet metus. Cras interdum ac metus quis mollis.
        Nulla orci arcu, dictum sit amet elit sit amet, laoreet gravida augue. Ut ultrices finibus
        ligula, ac auctor elit accumsan et.
      </p>
      <p>
        Duis aliquam porttitor neque, in gravida lacus tempor id. Fusce luctus erat vitae risus
        sodales mollis. Sed non lobortis nisi. Praesent nec arcu eu lacus sodales fermentum
        venenatis rhoncus lacus. Suspendisse potenti. Aenean et luctus enim. Ut ut odio ut enim
        volutpat lobortis. Quisque nec quam non leo ultricies venenatis nec eget neque.{' '}
      </p>
      <small>Small</small>
      <ul>
        <li>item</li>
        <li>item</li>
        <li>item</li>
      </ul>
      <ol>
        <li>item</li>
        <li>item</li>
        <li>item</li>
      </ol>
    </FormattedContentBlock>
  </div>
);

export default FormattedContentBlockTest;
