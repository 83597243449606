// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Information = (): React.Element<*> => (
  <>
    <path d="M10.002 8.99c-0.276 0-0.5 0.224-0.5 0.5v0 4c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-4c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M10.002 5.99c-0.276 0-0.5 0.224-0.5 0.5v0 0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-0.5c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M10.002 1.99c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM10.002 16.99c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"></path>
  </>
);
