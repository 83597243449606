// @flow strict

import * as React from 'react';
import {
  Button,
  asset,
  Asset,
  icon,
  Icon,
  Tree,
  TreeHeader,
  TreeHeaderGroup,
  TreeBody,
  TreeGroup,
  TreeItem,
  FormattedContentBlock,
} from '../components';

export const tree = (
  <Tree>
    <TreeHeader>
      <TreeHeaderGroup>
        <TreeItem>Asset</TreeItem>
        <TreeItem>Button</TreeItem>
      </TreeHeaderGroup>
    </TreeHeader>
    <TreeBody>
      <TreeGroup level={0} expanded>
        <TreeItem>
          <Icon glyph={icon.Glyphs.ControlExpanded} text="collapse" />
          <Asset variant={asset.Variants.Directory} name="Apples" number="1" />
        </TreeItem>
        <TreeItem>
          <Button>Button</Button>
        </TreeItem>
      </TreeGroup>
      <TreeGroup level={1} expanded>
        <TreeItem>
          <Icon glyph={icon.Glyphs.ControlExpanded} text="collapse" />
          <Asset variant={asset.Variants.Directory} name="Tasty" number="1.1" />
        </TreeItem>
        <TreeItem>
          <Button>Button</Button>
        </TreeItem>
      </TreeGroup>
      <TreeGroup level={2} disabled expanded>
        <TreeItem>
          <Asset
            variant={asset.Variants.File}
            fileType="acrobat"
            name="Red Delicious"
            number="1.1.1"
            path="/usr/Documents/red-delicious.pdf"
            size="1.9 mb"
            timestamp="Jun 8 2016"
          />
        </TreeItem>
        <TreeItem>
          <Button>Button</Button>
        </TreeItem>
      </TreeGroup>
      <TreeGroup level={2} expanded>
        <TreeItem>
          <Asset
            variant={asset.Variants.File}
            fileType="word"
            name="Royal Gala"
            number="1.1.1"
            path="/usr/Documents/royal-gala.DOCX"
            size="1.9 mb"
            href="http://www.example.com/"
            timestamp="Jun 8 2016"
          />
        </TreeItem>
        <TreeItem>
          <Button>Button</Button>
        </TreeItem>
      </TreeGroup>
      <TreeGroup level={1} disabled={false} expanded={false}>
        <TreeItem>
          <Icon glyph={icon.Glyphs.ControlCollapsed} text="expand" />
          <Asset variant={asset.Variants.Directory} name="Bland" number="1.2" />
        </TreeItem>
        <TreeItem>
          <Button>Button</Button>
        </TreeItem>
      </TreeGroup>
      <TreeGroup level={0} disabled expanded={false}>
        <TreeItem>
          <Icon glyph={icon.Glyphs.ControlCollapsed} text="expand" />
          <Asset variant={asset.Variants.Directory} name="Oranges" number="2" />
        </TreeItem>
        <TreeItem>
          <Button>Button</Button>
        </TreeItem>
      </TreeGroup>
      <TreeGroup level={0} expanded={false}>
        <TreeItem>
          <Icon glyph={icon.Glyphs.ControlCollapsed} text="expand" />
          <Asset variant={asset.Variants.Directory} name="Grapes" number="3" />
        </TreeItem>
        <TreeItem>
          <Button>Button</Button>
        </TreeItem>
      </TreeGroup>
    </TreeBody>
  </Tree>
);

const TreeTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>Tree</h2>
    </FormattedContentBlock>
    {tree}
  </div>
);

export default TreeTest;
