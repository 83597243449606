// @flow strict
import React, { useState } from 'react';
import marked from 'marked';
import { Tabs, Tab, FormattedContentBlock } from '@ansarada/ace-react';

import styles from './Changelog.scss';
import changelog from '../../../changelog-content.json';

const paths = {
  jiraFullDetails:
    'https://jira.ansarada.com/projects/ACEREACT?selectedItem=com.atlassian.jira.jira-projects-plugin:release-page&status=all',
  jiraFixVersion: version =>
    `https://jira.ansarada.com/browse/ACEREACT-606?jql=project%20%3D%20ACEREACT%20AND%20fixVersion%20%3D%20${version}`,
};

type ParsedEntry = {
  [string]: Array<string>,
};

type Entry = {
  version: string,
  date: string,
  title: string,
  parsed: ParsedEntry,
};

// eslint-disable-next-line prefer-spread
const flatten = arr => [].concat.apply([], arr);

const clean = (entry: ParsedEntry) => {
  const copy = Object.assign({}, entry);
  delete copy._;

  const noSubtitles = flatten(Object.values(copy)).filter(item => !entry._.includes(item));

  if (noSubtitles.length > 0) {
    copy.Other = noSubtitles;
  }

  return copy;
};

const render = (entry: ParsedEntry, version: string) => {
  const _entry = clean(entry);
  if (Object.keys(_entry).length === 0) {
    return (
      <ul key={`${version}-ul`}>
        <li>No changes found</li>
      </ul>
    );
  }

  return Object.entries(_entry).map<React$Node>(([subtitle: string, changes: string[]]) => {
    const _changes = Array.isArray(changes) ? changes.join('\n') : [];
    return [
      <h3 key={`${version}-${subtitle}`}>
        <small className={styles.subTitle}>{subtitle}</small>
      </h3>,
      <div key={`${version}-container`}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: marked(_changes) }} />
      </div>,
    ];
  });
};

const parse = (entry: Entry): any => {
  const { version, title, date, parsed } = entry;
  const url = version ? paths.jiraFixVersion(version) : window.ORBIT.repository.url;
  const text = version ? `${version}` : title;
  return [
    <h2 key={`${version}-title`} className={styles.title}>
      <a className="ace-text" href={url}>
        {text}
      </a>{' '}
      <span className="ace-tag">{date}</span>
    </h2>,
    render(parsed, version),
  ];
};

export const Changelog = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <FormattedContentBlock>
        <h2>Changelog</h2>
        <a href={paths.jiraFullDetails}>See JIRA for full details</a>.
      </FormattedContentBlock>

      <div className={styles.container}>
        <Tabs id="changelog" active={activeTab} onUpdate={tab => setActiveTab(tab)}>
          <Tab id="latest" header="Latest">
            <FormattedContentBlock className={styles.container}>
              {parse(changelog.versions[0])}
              {parse(changelog.versions[1])}
            </FormattedContentBlock>
          </Tab>
          <Tab id="archive" header="Archive">
            <FormattedContentBlock className={styles.container}>
              {changelog.versions.slice(2).map(version => parse(version))}
            </FormattedContentBlock>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
