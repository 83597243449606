// @flow strict
import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FormattedContentBlock, reactRouterWithAceLink, Link } from '@ansarada/ace-react';
import styles from './Tests.scss';

const RouterLink = reactRouterWithAceLink(ReactRouterLink);

const tests = [
  { label: 'Ace Text', path: '/tests/AceText' },
  { label: 'Asset', path: '/tests/Asset' },
  { label: 'AssetV2', path: '/tests/AssetV2' },
  { label: 'AssetPickerFolderItem', path: '/tests/AssetPickerFolderItem' },
  { label: 'AssetPickerFileItem', path: '/tests/AssetPickerFileItem' },
  { label: 'AssetPicker', path: '/tests/AssetPicker' },
  { label: 'Autocomplete', path: '/tests/Autocomplete' },
  { label: 'Avatar', path: '/tests/Avatar' },
  { label: 'Button', path: '/tests/Button' },
  { label: 'Breadcrumb', path: '/tests/Breadcrumb' },
  { label: 'Card', path: '/tests/Card' },
  { label: 'Datepicker', path: '/tests/Datepicker' },
  { label: 'Dropdown', path: '/tests/Dropdown' },
  { label: 'DropdownComplex', path: '/tests/DropdownComplex' },
  { label: 'Editor', path: '/tests/Editor' },
  { label: 'EmptyState', path: '/tests/EmptyState' },
  { label: 'ExpanderPanel', path: '/tests/ExpanderPanel' },
  { label: 'FormGrid', path: '/tests/FormGrid' },
  { label: 'FormLayout', path: '/tests/FormLayout' },
  { label: 'FormNonTextInput', path: '/tests/FormNonTextInput' },
  { label: 'FormSelect', path: '/tests/FormSelect' },
  { label: 'FormTextInput', path: '/tests/FormTextInput' },
  { label: 'FormattedContentBlock', path: '/tests/FormattedContentBlock' },
  { label: 'HelpLink', path: '/tests/HelpLink' },
  { label: 'Highlight', path: '/tests/Highlight' },
  { label: 'Icon', path: '/tests/Icon' },
  { label: 'Layout', path: '/tests/Layout' },
  { label: 'Lozenge', path: '/tests/Lozenge' },
  { label: 'LozengeGroup', path: '/tests/LozengeGroup' },
  { label: 'Message', path: '/tests/Message' },
  { label: 'Nav', path: '/tests/Nav' },
  { label: 'Panel', path: '/tests/Panel' },
  { label: 'Page', path: '/tests/Page' },
  { label: 'PageHidden', path: '/tests/PageHidden' },
  { label: 'Pagination', path: '/tests/Pagination' },
  { label: 'Progressbar', path: '/tests/Progressbar' },
  { label: 'SidebarMenu', path: '/tests/SidebarMenu' },
  { label: 'Table', path: '/tests/Table' },
  { label: 'Tab', path: '/tests/Tab' },
  { label: 'TextEditable', path: '/tests/TextEditable' },
  { label: 'Toast', path: '/tests/Toast' },
  { label: 'Toggle', path: '/tests/Toggle' },
  { label: 'Tooltip', path: '/tests/Tooltip' },
  { label: 'Tree', path: '/tests/Tree' },
  { label: 'TruncateText', path: '/tests/TruncateText' },
  { label: 'XSS', path: '/tests/XSS' },
  { label: 'Header', path: '/tests/Header' },
];

export const Tests = () => (
  <div className={styles.container}>
    <FormattedContentBlock>
      <h2>Tests</h2>
      <p>
        These pages are incomplete. When adding a new component, ensure it has a separate test page
        which exercises all the options in the API. Earlier components have JIRAs open to go back
        and set up proper test pages.
      </p>
      <p>
        <Link href="https://jira.ansarada.com/secure/RapidBoard.jspa?rapidView=309">
          Refer to the ACE React backlog
        </Link>{' '}
        before working on these pages.
      </p>
      <ul>
        {tests.map(item => (
          <li key={item.path}>
            <RouterLink to={item.path}>{item.label}</RouterLink>
          </li>
        ))}
      </ul>
    </FormattedContentBlock>
  </div>
);
