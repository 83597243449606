// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileHtml = (): React.Element<*> => (
  <>
    <path d="M7.142 14.835c0.090 0.090 0.216 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354l-2.646-2.646 2.646-2.646c0.090-0.090 0.146-0.216 0.146-0.354 0-0.276-0.224-0.5-0.5-0.5-0.138 0-0.263 0.056-0.354 0.146l-3 3c-0.091 0.090-0.146 0.216-0.146 0.354s0.056 0.263 0.146 0.354v0z"></path>
    <path d="M12.142 14.835c0.090 0.091 0.216 0.146 0.354 0.146s0.263-0.056 0.354-0.146l3-3c0.091-0.090 0.146-0.216 0.146-0.354s-0.056-0.263-0.146-0.354l-3-3c-0.090-0.090-0.216-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l2.647 2.646-2.647 2.646c-0.091 0.090-0.146 0.216-0.146 0.354s0.056 0.263 0.146 0.354v0z"></path>
    <path d="M16.496 0.981h-13c-1.381 0-2.5 1.119-2.5 2.5v0 13c0 1.381 1.119 2.5 2.5 2.5v0h13c1.381 0 2.5-1.119 2.5-2.5v0-13c0-1.381-1.119-2.5-2.5-2.5v0zM1.996 3.481c0-0.828 0.672-1.5 1.5-1.5v0h13c0.828 0 1.5 0.672 1.5 1.5v0 1.5h-16zM17.996 16.481c0 0.828-0.672 1.5-1.5 1.5v0h-13c-0.828 0-1.5-0.672-1.5-1.5v0-10.5h16z"></path>
  </>
);
