// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { Stage } from '../Stage';

import styles from './DesignTokenStage.scss';

type Props = {
  children: React.Node | string,
  fullWidth?: boolean,
};

export const DesignTokenStage = ({ children, fullWidth }: Props) => {
  const items = React.Children.map(children, child => {
    const className = classnames(child.props.className, styles.item, fullWidth && styles.fullWidth);
    const props = {
      className,
    };
    return React.cloneElement(child, props);
  });

  return (
    <Stage clean className={styles.container}>
      {items}
    </Stage>
  );
};
