// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FilePowerpoint = (): React.Element<*> => (
  <>
    <path
      fill="#d14626"
      d="M16.502 18.981h-13c-1.378 0-2.5-1.122-2.5-2.5v-13c0-1.379 1.122-2.5 2.5-2.5h13c1.379 0 2.5 1.121 2.5 2.5v13c0 1.378-1.121 2.5-2.5 2.5zM3.502 1.981c-0.827 0.003-1.497 0.673-1.5 1.5v13c0.003 0.827 0.673 1.497 1.5 1.5h13c0.827-0.003 1.497-0.673 1.5-1.5v-13c-0.003-0.827-0.673-1.497-1.5-1.5h-0z"
    ></path>
    <path
      fill="#d14626"
      d="M12.575 5.434q0.833 0.818 0.833 2.003t-0.833 2.018-2.018 0.832h-1.845v4.215q0 0.27-0.165 0.413t-0.405 0.143-0.405-0.143-0.165-0.413v-9.315q0-0.225 0.172-0.397t0.397-0.172h2.416q1.185-0.001 2.018 0.817zM12.267 7.436q0-0.69-0.502-1.185t-1.207-0.495h-1.845v3.391h1.845q0.705 0 1.207-0.502t0.502-1.208z"
    ></path>
  </>
);
