// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnAQuestion = (): React.Element<*> => (
  <>
    <path d="M10.089 3.547h-0.15q-1.185 0-2.020 0.832t-0.835 2.002v4.859q0 1.185 0.835 2.012t2.020 0.827h0.15c0.006 0 0.012 0 0.019 0 0.565 0 1.093-0.163 1.538-0.444l-0.012 0.007 0.27 0.269c0.1 0.102 0.239 0.165 0.393 0.165 0.004 0 0.009-0 0.013-0l-0.001 0q0.255 0 0.42-0.173t0.165-0.397q0-0.21-0.18-0.391l-0.268-0.285c0.32-0.47 0.482-1 0.482-1.59v-4.859q0-1.17-0.835-2.002t-2.005-0.832zM11.787 11.24c0 0.29-0.057 0.54-0.167 0.75l-0.241-0.24q-0.18-0.18-0.405-0.18t-0.405 0.18-0.18 0.405q0 0.195 0.18 0.405l0.24 0.227c-0.25 0.109-0.49 0.167-0.72 0.167h-0.15c-0.47 0-0.875-0.169-1.209-0.504s-0.504-0.739-0.504-1.209v-4.859c0-0.46 0.169-0.856 0.504-1.191s0.739-0.502 1.209-0.502h0.15c0.47 0 0.872 0.166 1.202 0.501s0.497 0.736 0.497 1.206z"></path>
    <path d="M15.506 1h-11c-1.379 0-2.5 1.122-2.5 2.5v11c0 1.378 1.121 2.5 2.5 2.5h4.017l1.055 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.428-0.241l0.001-0.002 1.054-1.757h4.017c1.378 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.122-2.5-2.5-2.5zM17.006 14.5c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.428 0.241l-0.001 0.002-0.771 1.285-0.772-1.285c-0.089-0.146-0.247-0.243-0.428-0.243h-4.3c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
  </>
);
