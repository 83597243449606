// @flow strict
import * as React from 'react';
import type { testIdPropType } from '../../ace-internal/types/general';

import { Icon } from '..';

export type SidebarMenuItemPropTypes = {
  ...testIdPropType,
  icon?: React.Element<typeof Icon>,
  text: string,
  link?: string,
  id?: string,
  badgeTitle?: string,
  badge?: number,
  onClick?: Function,
};

// SidebarMenuItem is a data object holder for JSX, hence returns null.
// Item is rendered by SidebarMenu
// eslint-disable-next-line no-unused-vars
export const SidebarMenuItem = (props: SidebarMenuItemPropTypes) => null;
