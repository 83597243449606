// @flow strict

import * as React from 'react';
import { icon, Icon, FormattedContentBlock } from '../components';

const IconTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>Icons</h2>

      {Object.keys(icon.Glyphs).map(key => (
        <div key={`${key}-div`}>
          <p>{key}</p>
          {['Small', 'Medium', 'Large', 'XLarge', 'XXLarge'].map(size => (
            <Icon glyph={icon.Glyphs[key]} text={key} key={`${key}${size}`} size={size} />
          ))}
        </div>
      ))}
    </FormattedContentBlock>
  </div>
);

export default IconTest;
