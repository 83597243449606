// @flow strict
import * as React from 'react';

import { Toaster, Toast, toast, Button, icon, Icon, FormattedContentBlock } from '../components';

import type { ToastType } from '../components/Toast';

type StateType = {
  nextId: number,
  toasts: Array<ToastType>,
};

class ToastTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      nextId: 1,
      toasts: [],
    };
  }

  addToast(timeoutDuration?: number): void {
    const t: ToastType = {
      id: `${this.state.nextId}`,
      text: `
        Test ${this.state.nextId}.
        Amet veniam tenetur accusantium provident corporis? Molestiaeatquevoluptatemaliquamadipisicingquasconsequaturquamvoluptates repudiandae quis Eius cum fugiat?
      `,
      actionText: 'Dismiss',
      timeoutDuration,
    };

    this.setState({
      nextId: this.state.nextId + 1,
      toasts: [...this.state.toasts, t],
    });
  }

  onClose(toastId: string): void {
    const toasts = this.state.toasts.filter(t => t.id !== toastId);
    this.setState({
      toasts: [...toasts],
    });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Toast</h2>
        </FormattedContentBlock>
        <Button onClick={() => this.addToast()}>Show toast autotimeout</Button>
        <Button onClick={() => this.addToast(0)}>Show toast</Button>
        <Toaster position={toast.Position.TOP} offset={40} data-test-id="toaster">
          {this.state.toasts.map(t => (
            <Toast
              key={t.id}
              icon={<Icon text="Loading" glyph={icon.Glyphs.StatusLoading} />}
              text={t.text}
              actionText={t.actionText}
              data-test-id="toast"
              data-ansarada-ccd
              onAction={() => {
                this.onClose(t.id);
              }}
              timeoutDuration={t.timeoutDuration}
              onTimeout={() => {
                this.onClose(t.id);
              }}
            />
          ))}
        </Toaster>
      </div>
    );
  }
}

export default ToastTest;
