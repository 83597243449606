// @flow strict
import * as React from 'react';
import type { LinkPropType } from './Link';
import { LinkVariantMap } from '../Button/Button.types';
import { BaseButton } from '../Button/Internal/BaseButton';

export type PropType = {|
  ...LinkPropType,
  to:
    | string
    | {
        pathname?: string,
        search?: string,
        hash?: string,
        state?: any,
      },
  replace?: boolean,
|};

export const reactRouterWithAceLink = (component: React.ComponentType<*>) => ({
  to,
  replace,
  variant = 'Link',
  ...props
}: PropType) => (
  // $FlowFixMe 0.112.0 - inexact rest of object pattern
  <BaseButton
    variant={LinkVariantMap[variant]}
    {...props}
    type="customLink"
    customLink={{ component, props: { to, replace } }}
  />
);
