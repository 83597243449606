// @flow strict
/* eslint jsx-a11y/no-noninteractive-element-to-interactive-role: off */
import * as React from 'react';
import classNames from 'classnames';
import { Asset } from '../Asset';
import { TruncateText } from '../TruncateText';
import * as icon from '../Icon';
import { KeyEvent } from '../../ace-internal/types/keys';

import styles from './AssetPickerFolderItem.scss';

const { Icon } = icon;

export type AssetPickerFolderItemCallBack = {
  id: string,
  type: 'Folder',
};

export type AssetPickerFolderItemPropType = {
  alt: string,
  id: string,
  className?: string,
  disabled?: boolean,
  name: string,
  openControlAlt: string,
  selected?: boolean,
  onSelect?: Function,
};

/** @ignore */
const AssetPickerFolderItem = (props: AssetPickerFolderItemPropType) => {
  const {
    alt,
    className,
    disabled = false,
    openControlAlt,
    name,
    onSelect,
    selected = false,
    id,
    ...rest
  } = props;

  const classes = classNames(
    styles.assetPickerFolderItem,
    { [styles.isDisabled]: disabled },
    { [styles.isActive]: !disabled && !selected },
    { [styles.isSelected]: selected },
    className,
  );

  const onClick = () => {
    if (onSelect) {
      onSelect({ id, type: 'Folder' });
    }
  };

  const onKeyDown = e => {
    if (KeyEvent.isEnter(e) || KeyEvent.isSpace(e)) {
      e.preventDefault();

      if (onSelect) {
        onSelect({ id, type: 'Folder' });
      }
    }
  };

  return (
    // $FlowFixMe 0.125.0
    <li
      aria-label={alt}
      aria-pressed={selected}
      className={classes}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={onSelect ? 0 : undefined}
      {...rest}
    >
      <div className={styles.assetColumn}>
        <Asset
          name={<TruncateText truncatePosition="Middle">{name}</TruncateText>}
          disabled={disabled}
          variant="Directory"
        />
      </div>
      <div className={styles.endControlColumn}>
        <Icon glyph={icon.Glyphs.ControlCollapsed} text={openControlAlt} />
      </div>
    </li>
  );
};

export { AssetPickerFolderItem };
