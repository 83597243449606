// @flow strict
import * as React from 'react';
import styles from './Box.scss';

type Props = {
  children: React.Node,
  style: Object,
};

export const Box = ({ children, style }: Props) => (
  <div className={styles.container} style={style}>
    {children}
  </div>
);
