// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';

import { Icon } from '../Icon';
import { IconBeta } from '../IconBeta';

import styles from './DropdownAction.scss';

type BaseProps = {|
  children: string,
  annotation?: string,
  disabled?: boolean,
  icon?: React.Element<typeof Icon>,
  ...testIdPropType,
  ...ansaradaCCDPropType,
  className?: string,
  selected?: boolean,
  id?: string,
|};

type DropdownActionUrlProps = {|
  href: ?string,
  target?: string,
  onClick?: (e: SyntheticMouseEvent<>) => void,
|};

type DropdownActionClickProps = {|
  onClick: (e: SyntheticMouseEvent<>) => void,
|};

type DropdownActionType = DropdownActionUrlProps | DropdownActionClickProps;

export type DropdownActionProps = {|
  ...BaseProps,
  ...DropdownActionType,
|};

/** @ignore */
const DropdownAction = (props: DropdownActionProps) => {
  const {
    id = uuid(),
    annotation,
    icon,
    disabled,
    'data-ansarada-ccd': ansaradaCCD,
    'data-test-id': testId,
    children,
    selected,
    className,
    onClick,
  } = props;

  const textContentClasses = classnames(styles.actionText, className, {
    [styles.isIconText]: !!(icon || annotation),
  });
  const linkContentClasses = classnames([styles.link], {
    [styles.isSelected]: selected,
  });
  const sharedProps = {
    id,
    'data-test-id': testId,
    className: linkContentClasses,
  };
  const content = (
    <React.Fragment>
      {icon}
      <span className={textContentClasses}>{children}</span>
      {annotation && <span className={styles.actionAnnotation}>{annotation}</span>}
    </React.Fragment>
  );

  return (
    <li className={styles.dropdownAction} data-ansarada-ccd={ansaradaCCD || undefined}>
      {disabled || !props.href ? (
        <button
          style={{ marginLeft: '-6px' }}
          onClick={(!disabled && onClick) || undefined}
          disabled={disabled}
          onMouseDown={e => e.preventDefault()}
          {...sharedProps}
        >
          {content}
        </button>
      ) : (
        <a
          href={props.href}
          target={props.target || undefined}
          rel={props.target ? 'noopener noreferrer' : undefined}
          onClick={(!disabled && onClick) || undefined}
          onMouseDown={e => e.preventDefault()}
          {...sharedProps}
        >
          {content}
          {props.target === '_blank' && <IconBeta glyph="External-link" size="Small" />}
        </a>
      )}
    </li>
  );
};

export { DropdownAction };
