// @flow strict
import * as React from 'react';
import cx from 'classnames';
import styles from './DesignTokenColor.scss';

type Contrast = 'Dark' | 'Light';

type Color = {|
  name: string,
  hex: string,
  variable: string,
|};

type Props = {|
  ...Color,
  contrast: Contrast,
  tint?: Color,
|};

const getColor = (contrast: Contrast) => {
  switch (contrast) {
    case 'Dark':
      return '#fff';
    case 'Light':
      return '#222';
    default:
      return '#222';
  }
};

const getContent = (color: Color) => (
  <>
    <span className={styles.title}>{color.name}</span>
    <span className={styles.title}>{color.hex}</span>
    <span className={styles.text}>{color.variable}</span>
  </>
);

export const DesignTokenColor = ({ name, hex, variable, contrast, tint }: Props) => {
  const color = getColor(contrast);
  const colorContent = (
    <div
      className={cx([styles.container, tint && styles.hasTint])}
      style={{ backgroundColor: hex, color }}
    >
      {getContent({ name, hex, variable })}
    </div>
  );

  if (tint) {
    return (
      <div>
        {colorContent}
        <div
          className={cx([styles.container, styles.tint])}
          style={{ backgroundColor: tint.hex, color }}
        >
          {getContent({ ...tint })}
        </div>
      </div>
    );
  }

  return colorContent;
};
