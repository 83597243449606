// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ChevronAllCollapse = (): React.Element<*> => (
  <>
    <path d="M14.859 9.638l-4.5-4.5c-0.090-0.090-0.215-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-4.5 4.5c-0.091 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146l4.147-4.146 4.146 4.146c0.091 0.095 0.219 0.154 0.361 0.154 0.276 0 0.5-0.224 0.5-0.5 0-0.142-0.059-0.27-0.154-0.361l-0-0z"></path>
    <path d="M10.359 11.632c-0.090-0.090-0.215-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-2.506 2.506c-0.090 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146l2.153-2.153 2.152 2.153c0.090 0.090 0.216 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354v0z"></path>
  </>
);
