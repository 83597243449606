// @flow strict
import * as React from 'react';
import useEventListener from '@use-it/event-listener';
import { v4 as uuid } from 'uuid';
import { Dropdown } from '../Dropdown';
import { DropdownGroup } from '../Dropdown/DropdownGroup';
import { DropdownAction } from '../Dropdown/DropdownAction';
import type { Props as BaseProps, Option, OnCreateOption } from './Select2.types';
import {
  search,
  valueExist,
  reducer,
  Trigger,
  NativeSelect,
  handleBodyEscape,
  handleBodyUpDown,
  handleInputKeydown,
  Options,
  flatValueExist,
} from './Select2.util';

type Props = {|
  ...BaseProps,
  onCreateOption: OnCreateOption,
|};

/** @ignore */
const ControlledCreateableSelect = ({
  id,
  name,
  placeholder,
  'data-test-id': testId,
  options,
  value = '',
  size,
  status,
  onCreateOption,
  onChange,
  disable = false,
}: Props): React.Element<'div'> => {
  const internalId = uuid();
  const initialState = {
    open: false,
    filter: '',
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const open = () => dispatch({ type: 'TOGGLE', payload: { open: true } });
  const close = () => dispatch({ type: 'TOGGLE', payload: { open: false, filter: '' } });
  const cleanFilter = () => dispatch({ type: 'CLEAR_FILTER' });

  const triggerRef = React.useRef(null);
  const containerRef = React.useRef(null);

  useEventListener('keydown', e => handleBodyEscape(e, state, close, cleanFilter), document.body);
  useEventListener('keydown', e => handleBodyUpDown(e, state, containerRef), document.body);
  useEventListener(
    'keydown',
    e => handleInputKeydown(e, state, open, containerRef),
    triggerRef.current,
  );

  // $FlowFixMe
  const optionsData = options.flatMap(group => group.items);
  const filteredOptions = search(optionsData, state.filter);

  const trigger = (
    <Trigger
      placeholder={placeholder}
      size={size}
      ariaControls={[internalId]}
      status={status}
      state={state}
      triggerRef={triggerRef}
      optionsData={optionsData}
      options={options}
      dispatch={dispatch}
      open={state.open}
      value={value}
      onCreateOption={onCreateOption}
      disable={disable}
    />
  );

  return (
    <div data-test-id={testId}>
      {!disable && (
        <Dropdown
          fullWidthTrigger
          id={internalId}
          open={state.open}
          onToggle={isOpen => {
            if (isOpen) {
              return open();
            }
            return close();
          }}
          trigger={trigger}
        >
          {(!state.filter || state.filter === '') && (!value || value === '') && (
            <DropdownGroup>
              <DropdownAction onClick={() => {}}>Start typing...</DropdownAction>
            </DropdownGroup>
          )}
          {state.filter.trim() !== '' && !valueExist(flatValueExist(options), state.filter) && (
            <DropdownGroup>
              <DropdownAction
                onClick={() => {
                  onCreateOption(state.filter);
                  cleanFilter();
                }}
              >
                {`Add "${state.filter}"`}
              </DropdownAction>
            </DropdownGroup>
          )}
          {(filteredOptions.length > 0 || state.filter.length === 0) && (
            <Options
              onChange={onChange}
              state={state}
              options={options}
              dispatch={dispatch}
              filteredOptions={filteredOptions}
              value={value}
            />
          )}
        </Dropdown>
      )}
      {disable && trigger}
      <NativeSelect
        id={id}
        name={name}
        size={size}
        status={status}
        state={state}
        optionsData={optionsData}
        onChange={onChange}
        dispatch={dispatch}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

/** @ignore */
const CreateableSelect = (props: Props): React$Element<typeof ControlledCreateableSelect> => {
  const [value, setValue] = React.useState(props.defaultValue || '');

  const onChange = (newValue: Option) => {
    if (typeof props.onChange !== 'undefined') {
      props.onChange(newValue);
    }

    if (typeof props.defaultValue !== 'undefined') {
      setValue(newValue.value);
    }
  };

  const allProps = {
    ...props,
    onChange,
    value: props.value || value,
  };

  return <ControlledCreateableSelect {...allProps} />;
};

CreateableSelect.defaultProps = {
  status: 'Good',
};

export { CreateableSelect };
