// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';

import { DropdownAction } from './DropdownAction';

import styles from './DropdownGroup.scss';

export type DropdownGroupPropsType = {|
  /** Used for adding dropdown actions to a dropdown group */
  children: React.ChildrenArray<React.Element<typeof DropdownAction> | void | false>,
  ...testIdPropType,
  ...ansaradaCCDPropType,
  /** Sets the dropdown group header text */
  header?: string,
  /** Allow new items to be added to the group */
  insertMode?: boolean,
  /** Add CSS classes to the element */
  className?: string,
|};

/** @ignore */
const DropdownGroup = (props: DropdownGroupPropsType) => {
  const {
    header,
    children,
    'data-test-id': testId,
    'data-ansarada-ccd': ansaradaCCD,
    insertMode,
    className,
  } = props;

  return (
    <div
      className={classnames([
        styles.dropdownContentGroup,
        insertMode && styles.insertMode,
        className,
      ])}
      data-test-id={testId}
    >
      {header && (
        <div className={styles.dropdownHeader}>
          <h3 className={styles.dropdownHeaderText} data-ansarada-ccd={ansaradaCCD || undefined}>
            {header}
          </h3>
        </div>
      )}
      <ul className={styles.dropdownMenu}>{children}</ul>
    </div>
  );
};

export { DropdownGroup };
