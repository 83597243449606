// @flow strict
import * as React from 'react';

import type { NavigationGroup } from '../types';
import { Dropdown } from '../../Dropdown';
import { DropdownAction } from '../../Dropdown/DropdownAction';
import { DropdownGroup } from '../../Dropdown/DropdownGroup';
import { Icon } from '../../Icon';

import styles from './SecondaryNavigation.scss';

type Props = {|
  items: $ReadOnlyArray<NavigationGroup>,
  heading: string,
  triggerLabel: string,
  testId: string,
  userName: string,
|};

/** @ignore */
export const SecondaryNavigation = ({ items, heading, triggerLabel, testId, userName }: Props) => {
  const [isProductNavigatorOpen, setIsProductNavigatorOpen] = React.useState(false);

  return (
    <nav className={styles.container} aria-label={heading}>
      <Dropdown
        data-test-id={testId}
        trigger={
          <button
            className={styles.trigger}
            aria-label={triggerLabel}
            data-test-id="nav-dropdown-trigger"
          >
            <Icon
              className={styles.triggerIconSmallScreen}
              glyph="ControlMenuNavigation"
              size="Large"
            />
            <span className={styles.userName}>{userName}</span>
            <span className={styles.triggerIconLargeScreen} />
          </button>
        }
        open={isProductNavigatorOpen}
        onToggle={open => {
          setIsProductNavigatorOpen(open);
        }}
      >
        {items.map(group => (
          <DropdownGroup key={group.items[0].name}>
            {group.items.map(landmark => (
              <DropdownAction
                key={landmark.name}
                {...{
                  href: landmark.link || undefined,
                  target: landmark.target || undefined,
                  onClick: landmark.onClick || undefined,
                }}
                data-test-id={landmark.name}
              >
                {landmark.name}
              </DropdownAction>
            ))}
          </DropdownGroup>
        ))}
      </Dropdown>
    </nav>
  );
};
