// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const RedactText = (): React.Element<*> => (
  <>
    <path d="M6.996 5.981h-4.487c-0.276 0-0.5 0.224-0.5 0.5v0 2c0 0.276 0.224 0.5 0.5 0.5v0h4.487z"></path>
    <path d="M3.447 3.481h12.188c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-12.188c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
    <path d="M17.85 14.128l-5.606-5.606c-0.002-0.002-0.003-0.003-0.005-0.004-0.044-0.044-0.096-0.080-0.153-0.105l-0.003-0.001-1.031-0.43h5.445c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0l-7.003 0.001-1.004-0.855c-0.143-0.143-0.498-0.186-0.684-0.108-0.183 0.077-0.309 0.255-0.309 0.462 0 0 0 0 0 0v0 2.39c0 0 0 0 0 0 0 0.138 0.056 0.263 0.146 0.354v0l0.383 0.244 0.863 2.014-5.392-0.003c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0l6.294 0.001 2.5 2.498h-8.795c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h9.796l1.35 1.354c0.090 0.091 0.216 0.146 0.354 0.146s0.263-0.056 0.354-0.146l2.5-2.5c0.090-0.090 0.146-0.215 0.146-0.353s-0.056-0.262-0.146-0.353l0 0zM9.554 10.495l-0.513-1.197 0.772-0.771 1.197 0.513zM14.996 16.274l-4.898-4.899 1.793-1.793 4.898 4.899z"></path>
  </>
);
