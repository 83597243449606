// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { useUncontrolled } from 'uncontrollable';

import { Checkbox } from '../Checkbox';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { CopyToClipboard } from '../CopyToClipboard';

import { getLayoutClasses } from '../../ace-internal/shared-component/CheckboxRadioGroupHelpers';
import type { ElementGroup } from '../../ace-internal/shared-component/CheckboxRadioGroupHelpers';

import styles from './CheckboxGroup.styles.scss';

type CheckboxGroupProps = {
  ...ElementGroup,
  anchors?: boolean,
};

/**
 * Warning: This component is duplicated here and in RadioGroup and they should be updated together (they're only split because of react-docgen)
 *
 * Creates a group of elements with extra props. The benefit is that it wraps
 * its child elements with a styled div and adds extra props to them. Mainly used
 * for RadioGroup and CheckboxGroup since both components follow the same pattern.
 */
function CheckboxRadioGroupHOC(
  props: CheckboxGroupProps,
  addChildrenExtraProps: (React.Element<any>) => Object,
): React.Element<'div'> {
  return (
    <div className={classnames(getLayoutClasses(props.layout), props.className)}>
      {React.Children.map(props.children, element => {
        if (!element) return element;
        const clone = React.cloneElement(element, addChildrenExtraProps(element));
        const { anchorUrl, id } = clone.props;
        return !props.anchors ? (
          clone
        ) : (
          <div className={styles.container}>
            {React.cloneElement(clone, { hasAnchor: true })}
            <span className={styles.trigger}>
              <CopyToClipboard
                target={anchorUrl || `${window.location}#${String(id)}`}
                trigger={
                  <Button
                    size="Compact"
                    variant="Ghost"
                    icon={<Icon glyph="ActionLink" />}
                  ></Button>
                }
              />
            </span>
          </div>
        );
      })}
    </div>
  );
}

export type CheckboxGroupType = 'CheckboxGroup';

type BaseProps = {|
  children: React.ChildrenArray<React.Element<typeof Checkbox> | void | false>,
  layout?: 'Block' | 'Inline',
  className?: string,
  'aria-describedby'?: string,
  anchors?: boolean,
  anchorUrl?: string,
|};

type ControlledValue<T> = {|
  value?: T,
  onChangeValue: (value: T) => void,
|};

type UncontrolledValue<T> = {|
  defaultValue: T,
  onChangeValue?: (value: T) => void,
|};

type Value<T> = ControlledValue<T> | UncontrolledValue<T>;

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledValue<Array<string>>,
|};

type CheckboxGroupPropType = {|
  ...BaseProps,
  ...Value<Array<string>>,
|};

function onCheck(props: ControlledPropType, value: string) {
  if (Array.isArray(props.value) && !props.value.includes(value)) {
    props.onChangeValue([...props.value, value]);
  }

  if (Array.isArray(props.value) && props.value.includes(value)) {
    props.onChangeValue(props.value.filter(item => item !== value));
  }
}

const ControlledCheckboxGroup = (props: ControlledPropType): React.Element<'div'> =>
  CheckboxRadioGroupHOC(props, (checkbox: React.Element<typeof Checkbox>) => ({
    checked: Array.isArray(props.value) && props.value.includes(checkbox.props.value),
    onChecked: () => {
      onCheck(props, checkbox.props.value);
    },
    'aria-describedby': props['aria-describedby'],
  }));

/**
 *
 * Checkboxes allow users to make multiple selections within a group of options, such as no option, a single or multiple option.
 *
 *
 * Each checkbox works independently to the others in the list, and checking one box does not uncheck others.
 *
 *
 * Checkboxes require the use of a button to apply the user selections.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Checkbox, Radio, RadioGroup
 * @category Form
 */
const CheckboxGroup = (
  props: CheckboxGroupPropType,
): React$Element<typeof ControlledCheckboxGroup> => {
  const allProps = useUncontrolled(props, {
    value: 'onChangeValue',
  });
  return <ControlledCheckboxGroup {...allProps} />;
};

export { CheckboxGroup };
