// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnAForward = (): React.Element<*> => (
  <>
    <path d="M15.506 1h-11c-1.379 0-2.5 1.122-2.5 2.5v11c0 1.378 1.121 2.5 2.5 2.5h4.017l1.055 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.428-0.241l0.001-0.002 1.054-1.757h4.017c1.378 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.122-2.5-2.5-2.5zM17.006 14.5c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.428 0.241l-0.001 0.002-0.771 1.285-0.772-1.285c-0.089-0.146-0.247-0.243-0.428-0.243h-4.3c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M14.885 9.318l0.008-0.009c0.003-0.004 0.006-0.008 0.009-0.012l0.006-0.007 0.009-0.013 0.005-0.007 0.009-0.014c0.001-0.002 0.003-0.005 0.004-0.007s0.005-0.009 0.008-0.014l0.004-0.008c0.002-0.004 0.005-0.009 0.007-0.013s0.003-0.006 0.005-0.009 0.004-0.008 0.005-0.012 0.003-0.007 0.005-0.011 0.003-0.007 0.004-0.011l0.005-0.012c0.001-0.003 0.002-0.006 0.003-0.009s0.003-0.009 0.005-0.014l0.002-0.008c0.002-0.005 0.003-0.010 0.004-0.016 0-0.003 0.001-0.005 0.002-0.008 0.001-0.005 0.002-0.011 0.004-0.016l0.001-0.008c0.001-0.005 0.002-0.011 0.003-0.016 0-0.003 0.001-0.006 0.001-0.009s0.001-0.010 0.002-0.016 0-0.008 0.001-0.012l0.001-0.012c0-0.008 0-0.016 0-0.023 0 0 0-0.001 0-0.001s0-0.001 0-0.001q0-0.012 0-0.023l-0.001-0.012c0-0.004 0-0.008-0.001-0.012s-0.001-0.010-0.002-0.016 0-0.006-0.001-0.009-0.002-0.011-0.003-0.016l-0.001-0.008c-0.001-0.005-0.002-0.011-0.004-0.016 0-0.002-0.001-0.005-0.002-0.008q-0.002-0.008-0.004-0.016l-0.002-0.008c-0.001-0.005-0.003-0.010-0.005-0.014s-0.002-0.006-0.003-0.009l-0.005-0.012c-0.001-0.004-0.003-0.007-0.004-0.011s-0.003-0.007-0.005-0.011l-0.005-0.012c-0.001-0.003-0.003-0.006-0.005-0.009s-0.004-0.009-0.007-0.013l-0.004-0.008c-0.003-0.005-0.005-0.009-0.008-0.014s-0.003-0.005-0.004-0.007c-0.003-0.005-0.006-0.009-0.009-0.014l-0.005-0.007-0.009-0.013-0.006-0.008c-0.003-0.004-0.006-0.008-0.009-0.012l-0.008-0.009c-0.003-0.003-0.005-0.006-0.008-0.009-0.005-0.006-0.011-0.012-0.016-0.017h-0.001l-3-3c-0.090-0.090-0.215-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l2.146 2.146h-7.793c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7.793l-2.146 2.146c-0.086 0.090-0.139 0.212-0.139 0.346 0 0.276 0.224 0.5 0.5 0.5 0.134 0 0.256-0.053 0.346-0.139l-0 0 3.001-3.001c0.006-0.005 0.011-0.011 0.016-0.017 0.004-0.003 0.006-0.006 0.009-0.009z"></path>
  </>
);
