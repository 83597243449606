// @flow strict
import * as React from 'react';
import classNames from 'classnames';
// eslint-disable-next-line
import styles from './styles.scss';

type Variants = 'Person' | 'Group' | 'Project';
type Sizes = 'XXSmall' | 'XSmall' | 'Small' | 'Medium' | 'Large' | 'XLarge' | 'XXLarge';

type AvatarPropType = {
  variant?: Variants,
  src: string,
  text: string,
  size?: Sizes,
  href?: string,
  className?: string,
};

const imgSize = {
  small: 28,
  medium: 32,
  large: 36,
};
/**
 * Avatars are used to represent an individual person, team or project a group or individuals are working on together.
 * @status released
 * @date 26/09/2018
 * @version 12.0.0
 * @tags Card
 * @category Data
 */
const Avatar = (props: AvatarPropType) => {
  const { variant = 'Person', size, src, text, href, className, ...rest } = props;
  const isSizeDesignSystem = size && (size === 'Small' || size === 'Medium' || size === 'Large');

  const sizeCSS = size && !isSizeDesignSystem ? `ace-avatar-${size.toLowerCase()}` : undefined;

  const classesName = classNames(
    'ace-avatar',
    { [`ace-avatar-${variant.toLowerCase()}`]: true },
    sizeCSS,
    className,
    size && isSizeDesignSystem && styles[`avatar-${size.toLowerCase()}`],
  );

  const contents = (
    <span
      className={classNames(
        'ace-avatar-inner',
        size && isSizeDesignSystem && styles[`avatar-${size.toLowerCase()}`],
      )}
    >
      <img
        src={src}
        alt={text}
        {...(size &&
          isSizeDesignSystem && {
            style: { width: imgSize[size.toLowerCase()], height: imgSize[size.toLowerCase()] },
          })}
      />
    </span>
  );

  const attrs = {
    ...rest,
    className: classesName,
  };

  return href ? (
    <a {...attrs} href={href}>
      {contents}
    </a>
  ) : (
    <span {...attrs}>{contents}</span>
  );
};

export { Avatar };
export type { Variants, Sizes };
