// @flow strict
import * as React from 'react';
import classnames from 'classnames/bind';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

import { Close } from '../../legacy-components/Close';

const cx = classnames.bind(styles);

// Variants
const Neutral = 'default';
const Information = 'info';
const Good = 'success';
const Bad = 'warning';
const Critical = 'error';

const Variants = {
  Neutral,
  Good,
  Bad,
  Critical,
  Information,
};

// Sizes
const Medium = 'medium';
const Large = 'large';

const Sizes = { Medium, Large };

const Page = 'page';
const Content = 'content';

const Contexts = { Page, Content };

export type MessagePropType = {
  size?: 'medium' | 'large',
  variant?: 'default' | 'success' | 'warning' | 'error' | 'info',
  context?: 'page' | 'content',
  close?: React.Element<typeof Close>,
  className?: string,
  children?: React.Node,
};

/**
 * Messages are used to provide clarity on the status of the product or an action. They may be informational; indicate success or failure for an interaction; or alert the user to a system failure.
 *
 * Messages have two behaviours:
 *
 * - timed, where the message disappears without user interaction
 * - persistent, where the message does not disappear unless the user chooses to dismiss it
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Button
 * @category Feedback
 */
const Message = (props: MessagePropType) => {
  const {
    size = Sizes.Medium,
    variant = Variants.Neutral,
    context = Contexts.Content,
    close,
    children,
    className,
    ...rest
  } = props;

  const classes = cx(
    'ace-message',
    `ace-message-${variant}`,
    `ace-message-${size}`,
    { 'ace-message-pagecontext': context === Contexts.Page },
    className,
  );

  return (
    <div {...rest} className={classes}>
      <span className="ace-message-content">{children}</span>
      <span className={cx('ace-message-close')}>{close}</span>
    </div>
  );
};

export { Message, Variants, Sizes, Contexts };
