// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const EditCross = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M13.506 7.994c-3.037 0-5.5 2.463-5.5 5.5s2.463 5.5 5.5 5.5 5.5-2.462 5.5-5.5-2.462-5.5-5.5-5.5zM16.688 16.676c-0.811 0.808-1.929 1.308-3.164 1.308-2.476 0-4.482-2.007-4.482-4.482s2.007-4.482 4.482-4.482c2.476 0 4.482 2.007 4.482 4.482 0 0.63-0.13 1.229-0.364 1.773l0.011-0.029c-0.239 0.556-0.564 1.031-0.965 1.431l-0 0z"
    ></path>
    <path
      fill="#fb0069"
      d="M15.859 11.141c-0.090-0.090-0.215-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-1.646 1.646-1.647-1.646c-0.090-0.090-0.215-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l1.647 1.646-1.646 1.647c-0.084 0.089-0.135 0.209-0.135 0.342 0 0.276 0.224 0.5 0.5 0.5 0.132 0 0.253-0.051 0.342-0.135l-0 0 1.647-1.646 1.647 1.646c0.091 0.098 0.221 0.158 0.365 0.158 0.276 0 0.5-0.224 0.5-0.5 0-0.144-0.061-0.274-0.158-0.365l-0-0-1.646-1.647 1.646-1.646c0.090-0.090 0.146-0.215 0.146-0.353s-0.056-0.263-0.146-0.353v0z"
    ></path>
    <path d="M8.063 14.086l-0.27 0.27-2.121-2.121 7.643-7.638 2.119 2.118-1.309 1.309c0.431 0.047 0.824 0.136 1.199 0.265l-0.038-0.012 1.953-1.953c0.036-0.032 0.071-0.065 0.103-0.097 0.975-0.975 0.975-2.561 0-3.535-0.958-0.958-2.506-0.975-3.484-0.050-0.020 0.015-0.038 0.030-0.054 0.047l-9.191 9.192c-0.054 0.054-0.096 0.121-0.121 0.196l-0.001 0.004-2.449 5.248c-0.030 0.062-0.047 0.135-0.047 0.212 0 0.276 0.224 0.5 0.5 0.5 0.077 0 0.15-0.017 0.215-0.048l-0.003 0.001 5.303-2.474c0.074-0.035 0.136-0.085 0.183-0.146l0.001-0.001 0.125-0.125c-0.118-0.336-0.207-0.729-0.252-1.136l-0.002-0.025zM14.465 3.441c0.018-0.014 0.034-0.028 0.048-0.043l0-0c0.271-0.271 0.646-0.439 1.061-0.439 0.828 0 1.5 0.672 1.5 1.5 0 0.414-0.168 0.789-0.439 1.061v0c-0.022 0.022-0.045 0.043-0.070 0.065-0.008 0.007-0.016 0.014-0.023 0.021l-0.401 0.401-2.121-2.121zM3.528 16.506l1.591-3.41 1.818 1.819z"></path>
  </>
);
