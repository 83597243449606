// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import styles from './style.scss';

import type { ansaradaCCDPropType, testIdPropType } from '../../ace-internal/types/general';

export type FormattedContentBlockPropType = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  children: React.Node,
  className?: string,
|};

/**
 * Use FormattedContentBlocks to apply text styles to text.
 *
 *
 * Recommended usage is for user generated content.
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Card, Button
 * @category Data
 */
const FormattedContentBlock = (props: FormattedContentBlockPropType) => (
  <div
    className={classnames(styles.formattedContentBlock, props.className)}
    data-test-id={props['data-test-id']}
    data-ansarada-ccd={props['data-ansarada-ccd'] || undefined}
  >
    {props.children}
  </div>
);

export { FormattedContentBlock };
