// @flow strict

import * as React from 'react';
import { layout, Group, Item, FormattedContentBlock } from '../components';

const LayoutTest = () => (
  <div className="layout-test">
    <FormattedContentBlock>
      <h2>Layout</h2>
      <Group>
        <Item>
          <p>normal items</p>
        </Item>
      </Group>
      <Group>
        <Item>
          <p>normal items</p>
        </Item>
        <Item>
          <p>item</p>
        </Item>
      </Group>
      <Group>
        <Item>
          <p>normal items</p>
        </Item>
        <Item>
          <p>item</p>
        </Item>
        <Item>
          <p>item</p>
        </Item>
      </Group>
      <Group variant={layout.Variants.Split}>
        <Item>
          <p>split group</p>
        </Item>
        <Item>
          <p>item</p>
        </Item>
      </Group>
      <Group variant={layout.Variants.Split}>
        <Item>
          <p>split group</p>
        </Item>
        <Item>
          <p>item</p>
        </Item>
        <Item>
          <p>item</p>
        </Item>
      </Group>
      <Group>
        <Item width="220px">
          <p>item fixed at 220px</p>
        </Item>
        <Item>
          <p>rest with fluid width</p>
        </Item>
      </Group>
      <Group>
        <Item width="220px">
          <p>item fixed at 220px</p>
        </Item>
        <Item>
          <p>rest with fluid width</p>
        </Item>
        <Item>
          <p>rest with fluid width</p>
        </Item>
        <Item>
          <p>rest with fluid width</p>
        </Item>
      </Group>
      <Group>
        <Item width="220px" style={{ color: 'red' }}>
          <p>item fixed at 220px and inline styles set</p>
        </Item>
        <Item>
          <p>rest with fluid width</p>
        </Item>
      </Group>
    </FormattedContentBlock>
  </div>
);

export default LayoutTest;
