// @flow strict
import * as React from 'react';

type OptionPropType = {|
  value: string,
  text?: string,
  disabled?: boolean,
|};

/**
 * @ignore
 */
const Option = ({ value, text, disabled }: OptionPropType) => (
  <option value={value} disabled={disabled}>
    {text || value}
  </option>
);

export { Option };
