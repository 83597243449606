// @flow strict
import * as React from 'react';
import cx from 'classnames';
import { useUncontrolled } from 'uncontrollable';

import type { ansaradaCCDPropType, testIdPropType } from '../../ace-internal/types/general';

import { TextInput } from '../TextInput';
import { KeyEvent } from '../../ace-internal/types/keys';
import styles from './styles.scss';

const htmlEntities = (str: string): string =>
  String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');

type BaseProps = {|
  ...testIdPropType,
  /** See [ansaradaCCD](docs/architecture/ansarada-ccd.md) */
  ...ansaradaCCDPropType,
  /** An optional classname, which could be used for setting styles on the text */
  className?: string,
  /** Used when value is empty */
  placeholder: string,
  onSave?: (value: string) => void,
|};

type ControlledValue<T> = {|
  value: T,
  onChangeValue: (value: T) => void,
|};
type UncontrolledValue<T> = {|
  defaultValue: T,
  onChangeValue?: (value: T) => void,
|};
type Value<T> = ControlledValue<T> | UncontrolledValue<T>;

type ControlledProps = {|
  ...BaseProps,
  ...ControlledValue<string>,
|};

type Props = {|
  ...BaseProps,
  ...Value<string>,
|};

const ControlledTextEditable = ({
  value,
  className,
  placeholder,
  'data-ansarada-ccd': ansaradaCCD,
  'data-test-id': testId,
  onChangeValue,
  onSave,
}: ControlledProps) => {
  const hiddenEl = React.useRef<?HTMLSpanElement>(null);
  const [inputEl, setInputEl] = React.useState<?HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const [savedValue, setSavedValue] = React.useState<string>(value);
  const [cancelSave, setCancelSave] = React.useState<boolean>(false);

  const containerClassName = cx(!hasFocus && styles.text);
  const hiddenClassName = cx(className, styles.hidden);
  const textInputClassName = cx(className, styles.input);
  const sanitizedValue = htmlEntities(value || placeholder);

  const updateInputWidth = () => {
    if (!hiddenEl || !inputEl || !hiddenEl.current) return;

    inputEl.style.width = `0px`;
    hiddenEl.current.innerHTML = sanitizedValue;
    const { width } = hiddenEl.current.getBoundingClientRect();
    inputEl.style.width = `${width}px`;
  };

  const onKeyDown = (ev: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (!inputEl) {
      return;
    }

    if (KeyEvent.isEscape(ev)) {
      setCancelSave(true);
      ev.currentTarget.blur();
      onChangeValue(savedValue);
      setHasFocus(false);
      updateInputWidth();
    }

    if (KeyEvent.isEnter(ev)) {
      ev.currentTarget.blur();
    }
  };

  const focusInput = () => {
    setHasFocus(true);
  };

  const save = () => {
    if (cancelSave) {
      setCancelSave(false);
      return;
    }
    if (onSave) onSave(value);
    setSavedValue(value);
    setHasFocus(false);
  };

  const onUpdateInput = (val: string) => {
    onChangeValue(val);
    updateInputWidth();
  };

  React.useEffect(() => {
    let unbindListener;
    let animation;

    if (document.readyState === 'loading') {
      unbindListener = window.addEventListener('load', () => {
        animation = window.requestAnimationFrame(() => {
          updateInputWidth();
        });
      });
    } else {
      animation = window.requestAnimationFrame(() => {
        updateInputWidth();
      });
    }

    return () => {
      if (unbindListener) unbindListener();
      window.cancelAnimationFrame(animation);
    };
  });

  return (
    <>
      <span data-ansarada-ccd={ansaradaCCD} ref={hiddenEl} className={hiddenClassName}>
        {sanitizedValue}
      </span>
      <div data-test-id={testId} className={containerClassName}>
        <TextInput
          inputRef={el => setInputEl(el)}
          data-ansarada-ccd={ansaradaCCD}
          placeholder={placeholder}
          value={value}
          className={textInputClassName}
          onFocus={focusInput}
          onBlur={save}
          onChangeValue={onUpdateInput}
          onKeyDown={onKeyDown}
        />
      </div>
    </>
  );
};

/**
 * TextEditable provides an editable text field
 * @status unreleased
 * @date 29/03/2019
 * @version 13.0.1
 * @tags TextInput
 * @category Form
 */
const TextEditable = (props: Props) => {
  const allProps = useUncontrolled(props, {
    value: 'onChangeValue',
  });

  return <ControlledTextEditable {...allProps} />;
};

export { TextEditable };
