// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import styles from './styles.scss';

type ProgressbarPropType = {|
  /** How much it progressed */
  percentValue: number,
  /** Apply styles to make it look disabled */
  isInactive?: boolean,
  /** Position it at the top of the page */
  isPageContext?: boolean,
  /** Define a human readable text alternative of aria-valuenow */
  ariaValuetext?: string,
  className?: string,
|};

/**
 *
 * A Progress Bar provides at-a-glance visual feedback to users, communicating the context around their current position, while also providing assurance that they are progressing through.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Button
 * @category Feedback
 */
const Progressbar = (props: ProgressbarPropType) => {
  const { percentValue, isInactive, isPageContext, ariaValuetext, className } = props;

  if (isPageContext && (isInactive || percentValue <= 0 || percentValue >= 100)) {
    return null;
  }

  const classes = classnames(
    styles.progressbar,
    isPageContext && styles.contextPage,
    isInactive && styles.inactive,
    className,
  );

  return (
    <div
      className={classes}
      role="progressbar"
      aria-valuenow={percentValue}
      {...(ariaValuetext ? { 'aria-valuetext': ariaValuetext } : null)}
    >
      <div className={styles.value} style={{ width: `${percentValue}%` }} />
    </div>
  );
};

export { Progressbar };
