// @flow strict
import * as React from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useUncontrolled } from 'uncontrollable';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './style.scss';

import { ExpanderGroup } from './ExpanderGroup';
import { Lozenge } from '../Lozenge';
import { Dropdown } from '../Dropdown';
import { TruncateText } from '../TruncateText';
import type { ansaradaCCDPropType, testIdPropType } from '../../ace-internal/types/general';
import { KeyEvent } from '../../ace-internal/types/keys';
import { configInternalTestId } from '../../ace-internal/util/util';

type BaseProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  /** Can either be a string or a `TruncateText` component */
  title: string | React.Element<typeof TruncateText>,
  /** Subtitle tells the type of title, e.g.: subTeam */
  subTitle?: string,
  /** Summary of the content, e.g.: the number of items */
  summary?: string,
  /** Optional lozenge on the top right corner */
  lozenge?: React.Element<typeof Lozenge>,
  /** Support actions. Accepts a Dropdown component */
  dropdown?: React.Element<typeof Dropdown>,
  children?: any,
  /** An optional className for adjusting margins + widths. Is applied in addition to the other ExpanderPanel styles */
  className?: string,
  /** Sets a custom color for the expander panel to allow you to visually categorize different expander panels, for example: to differentiate expander panels for different user groups */
  colour?: string,
  panelContentClassName?: string,
  panelContentRef?: { current: null | HTMLDivElement },
|};

type ControlledExpanded<T> = {|
  expanded: T,
  onExpand?: (expanded: T) => void,
|};

type UncontrolledExpanded<T> = {|
  defaultExpanded: T,
  onExpand?: (expanded: T) => void,
|};

type Expanded<T> = ControlledExpanded<T> | UncontrolledExpanded<T>;

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledExpanded<boolean>,
|};

type ExpanderPropType = {
  ...BaseProps,
  ...Expanded<boolean>,
};

const stopPropagation = event => event.stopPropagation();
const createInternalTestId = configInternalTestId({ componentName: 'ExpanderPanel' });

const ControlledExpanderPanel = React.memo<ControlledPropType>(
  ({
    'data-ansarada-ccd': ansaradaCCD,
    'data-test-id': testId,
    children,
    className,
    colour,
    dropdown,
    expanded,
    lozenge,
    onExpand,
    subTitle,
    summary,
    title,
    panelContentClassName,
    panelContentRef,
  }: ControlledPropType) => {
    const id = uuid();

    const expanderClasses = classNames(className, styles.expander, {
      [styles.expanded]: expanded,
    });

    const expanderContentClasses = classNames(panelContentClassName, styles.content);

    const onToggleExpand = () => {
      if (onExpand) {
        onExpand(!expanded);
      }
    };

    const onKeyDown = (event: SyntheticKeyboardEvent<*>) => {
      if (KeyEvent.isEnter(event) || KeyEvent.isSpace(event)) {
        event.preventDefault();
        onToggleExpand();
      }
    };

    return (
      <div className={expanderClasses} data-test-id={testId}>
        <div
          data-test-id={createInternalTestId('header')}
          className={styles.header}
          onClick={onToggleExpand}
          onKeyDown={onKeyDown}
          tabIndex={0}
          role="button"
          aria-expanded={expanded}
          aria-controls={expanded ? id : undefined}
        >
          <div style={{ backgroundColor: colour }} className={styles.arrow}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
          <div className={styles.main}>
            <div className={classNames(styles.heading, { [styles.hasSubTitle]: subTitle })}>
              <h2
                data-test-id={createInternalTestId('title')}
                className={styles.title}
                data-ansarada-ccd={ansaradaCCD}
              >
                {title}
              </h2>
              {subTitle && (
                <h3
                  data-test-id={createInternalTestId('subtitle')}
                  className={styles.subTitle}
                  data-ansarada-ccd={ansaradaCCD}
                >
                  {subTitle}
                </h3>
              )}
            </div>
            {summary && (
              <div
                data-test-id={createInternalTestId('summary')}
                className={styles.summary}
                data-ansarada-ccd={ansaradaCCD}
              >
                {summary}
              </div>
            )}
            {lozenge && <div className={styles.lozenge}>{lozenge}</div>}
          </div>
          {dropdown && (
            <div
              className={styles.actions}
              onClick={stopPropagation}
              onKeyDown={stopPropagation}
              role="presentation"
            >
              {dropdown}
            </div>
          )}
        </div>
        {expanded && children && (
          <div
            data-test-id={createInternalTestId('content')}
            className={expanderContentClasses}
            aria-labelledby={id}
            ref={panelContentRef}
          >
            {children}
          </div>
        )}
      </div>
    );
  },
);

/**
 * The Expander Panel helps users to only see the content they need by allowing the user to expand and collapse sections of content.
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Card
 * @category Layout
 */
const ExpanderPanel = (props: ExpanderPropType): React$Element<typeof ControlledExpanderPanel> => {
  const controlledProps = useUncontrolled(props, {
    expanded: 'onExpand',
  });

  return <ControlledExpanderPanel {...controlledProps} />;
};

export { ExpanderPanel, ExpanderGroup };
