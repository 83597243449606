// @flow strict
import * as React from 'react';

import styles from './MarkdownPage.scss';
import { FormattedContentBlockWrapper } from '../FormattedContentBlockWrapper';

type Props = {|
  contentHtml: string,
  uri?: string,
|};

const getPath = (uri?: string) =>
  !uri
    ? '/'
    : uri
        .split('/')
        .slice(0, -1)
        .join('/');

const fixUrls = (contentHtml: string, folderUri?: string) =>
  contentHtml
    // Removes the #md (which we prefix all markdown links with)
    // and optionally the source subdirectory (which we re-route for prettier urls)
    .replace(/href="#\/md(\/source)?\//g, `href="#/`)
    // fix ./ relative paths
    // TODO - move these replace's into a single line - or first run a match for #md so that we don't transform non markdown links
    .replace(/href="#\/\./g, `href="#${getPath(folderUri)}`);

export const MarkdownPage = ({ contentHtml, uri }: Props) => {
  const content = fixUrls(contentHtml, uri);
  /* eslint-disable react/no-danger */
  return (
    <div className={styles.container}>
      <FormattedContentBlockWrapper>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </FormattedContentBlockWrapper>
    </div>
  );
  /* eslint-enable react/no-danger */
};
