// @flow strict
import React from 'react';

import { AssetPickerFileItem } from '../components';

const AssetPickerFileItemTest = () => (
  <div>
    <AssetPickerFileItem name="Tasty" alt="Tasty file" id="3" />
    <AssetPickerFileItem name="Tasty" fileType="code" alt="Tasty code file" id="3" />
    <AssetPickerFileItem
      name="Tasty"
      fileType="code"
      alt="Tasty code file"
      id="11"
      onSelect={() => {}}
    />

    <AssetPickerFileItem
      name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut metus nec risus tristique varius eget at nisl. Duis a laoreet libero. Mauris eget pulvinar sem"
      fileType="code"
      alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit code file"
      id="4"
    />

    <AssetPickerFileItem
      name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut metus nec risus tristique varius eget at nisl. Duis a laoreet libero. Mauris eget pulvinar sem"
      fileType="code"
      annotation="Already added"
      className="overwrite-css"
      id="5"
      alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit code file disabled"
    />

    <AssetPickerFileItem
      name="Tasty"
      fileType="code"
      annotation="Already added"
      className="overwrite-css"
      disabled
      id="6"
      alt="Lorem ipsum dolor sit amet, consectetur adipiscing elit code file disabled"
    />
  </div>
);

export default AssetPickerFileItemTest;
