// @flow strict
import * as React from 'react';
import { Link } from '@ansarada/ace-react';

import type { ComponentType, ComponentDocsType } from '../../../types/orbit';
import { HowToUse, ComponentHeader } from '../../components';
import styles from './Component.scss';
import loadComponent from '../../util/loadComponent';

const { version } = window.ORBIT;

type Props = {|
  match: {
    params: {
      id: string,
    },
  },
|};

const mapComponentsDocs = (componentDocs: ComponentDocsType) => ({
  ...componentDocs,
  fixtures: componentDocs.fixtures.map<any>(f => ({ ...f, $open: false })),
});

const Component = ({ match }: Props) => {
  const [componentDocsState, setComponentDocsState] = React.useState<ComponentDocsType | void>({
    name: '',
    importSnippet: '',
    description: '',
    fixtures: [],
    type: 'row',
  });

  const getComponent = React.useCallback(
    (): ComponentType =>
      window.ORBIT.js.parsed.find(
        item =>
          item &&
          item.displayName &&
          item.displayName.toLowerCase() === match.params.id.toLowerCase(),
      ),
    [match.params.id],
  );

  const getFixtures = React.useCallback(async (): Promise<any> => {
    const component = getComponent();
    const result = loadComponent(component.displayName, 'fixtures');
    return result;
  }, [getComponent]);

  const mountComponent = React.useCallback(async () => {
    try {
      const fixtures = await getFixtures();
      const componentDocs = mapComponentsDocs(fixtures.default);
      setComponentDocsState(componentDocs);
    } catch (e) {
      setComponentDocsState(undefined);
    }
  }, [getFixtures]);

  React.useEffect(() => {
    mountComponent();
  }, [mountComponent]);

  React.useEffect(() => {
    mountComponent();
  }, [match, mountComponent]);

  const component = getComponent();
  const tags =
    component.tags &&
    component.tags
      .split(',')
      .map(t => t.trim())
      .map((t, index) =>
        index !== 0 ? (
          <React.Fragment key={t}>
            <span>, </span>
            <Link variant="Subtle" href={`#/component/${t}`}>
              {t}
            </Link>
          </React.Fragment>
        ) : (
          <Link key={t} variant="Subtle" href={`#/component/${t}`}>
            {t}
          </Link>
        ),
      );
  return (
    <div>
      <ComponentHeader component={component} version={version} tags={tags} isModule />
      {componentDocsState && componentDocsState.fixtures ? (
        <div className={styles.wrapper}>
          <HowToUse componentConfig={component} componentDocs={componentDocsState} />
        </div>
      ) : (
        <div>Not found</div>
      )}
    </div>
  );
};

export { Component };
