// @flow strict
import * as React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

export type ContentPropsType = {|
  children: React.ChildrenArray<string>,
|};

/**
 * @ignore
 */

const Content = ({ children }: ContentPropsType) => (
  <div className={styles.labelText}>{children}</div>
);

export { Content };
