// @flow strict

import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import { Button, Icon, icon, TextInput } from '@ansarada/ace-react';

import { SidebarGroup, SidebarCategory } from './Internal';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Sidebar.scss';
import type { NavItem, NavItemWithCategory, NavItemWithImage } from '../../../types/orbit';

type NavItems = { [string]: Array<NavItem> };
type NavItemsWithCategory = { [string]: Array<NavItemWithCategory> };

type Props = {|
  mainNavItems: Array<NavItem>,
  codingNavItems: NavItems,
  componentsNavItems: NavItemsWithCategory,
  designTokensNavItems: Array<NavItemWithImage>,
  className?: string,
|};

const isActiveComponent = (match: { url: string }, location: Location) =>
  match && location.pathname.startsWith(match.url);

const filterNavItems = (
  searchText: string,
  items: NavItems | NavItemsWithCategory,
): NavItems | NavItemsWithCategory => {
  if (searchText === '') return items;

  const result = {};
  const keys = Object.keys(items);
  keys.forEach(key => {
    // $FlowFixMe
    result[key] = items[key].filter(item =>
      item.label.toLowerCase().includes(searchText.toLowerCase()),
    );
  });
  return result;
};

const filterSimpleNavItems = (searchText: string, items: Array<any>) => {
  if (searchText === '') return items;
  return items.filter(item => item.label.toLowerCase().includes(searchText.toLowerCase()));
};

const hasItemsObj = items =>
  Object.keys(items).reduce((acc, key) => items[key].length + acc, 0) > 0;

const hasItems = items => items.length > 0;

export const Sidebar = ({
  className,
  codingNavItems,
  componentsNavItems,
  designTokensNavItems,
  mainNavItems,
}: Props) => {
  const [isOpen, toggle] = useState(false);
  const [searchText, onSearch] = useState('');
  const inputRef = useRef();
  const isSearching = searchText !== '';

  const docs = filterNavItems(searchText, codingNavItems);
  const components = filterNavItems(searchText, componentsNavItems);
  const designTokens = filterSimpleNavItems(searchText, designTokensNavItems);
  const other = filterSimpleNavItems(searchText, mainNavItems);

  useEffect(() => {
    const listener = window.addEventListener('keydown', e => {
      if (e.key === '/' && inputRef && inputRef.current) {
        e.preventDefault();
        inputRef.current.focus();
      }
    });

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [inputRef]);

  return (
    <div className={classnames(styles.container, { [styles.isOpen]: isOpen }, className)}>
      <div className={styles.menuButtonContainer}>
        <Button variant="Ghost" icon={<Icon glyph={icon.Glyphs.ControlMenu} />} onClick={toggle}>
          Menu
        </Button>
      </div>

      <div className={styles.searchContainer}>
        <TextInput
          value={searchText}
          onChangeValue={onSearch}
          icon={<Icon glyph="ActionSearch" />}
          placeholder="Search"
          inputRef={inputRef}
          clearable
        />
      </div>

      <div className={styles.subContainer}>
        {hasItemsObj(docs) && (
          <SidebarCategory title="Docs" isSearching={isSearching}>
            {Object.keys(docs).map(item => (
              <SidebarGroup
                title={item}
                items={docs[item]}
                isActiveComponent={isActiveComponent}
                isSearching={isSearching}
              />
            ))}
          </SidebarCategory>
        )}
      </div>

      <div className={styles.subContainer}>
        {hasItemsObj(components) && (
          <SidebarCategory title="Components" isSearching={isSearching}>
            {Object.keys(components).map(item => (
              <SidebarGroup
                title={item}
                items={components[item]}
                isActiveComponent={isActiveComponent}
                isSearching={isSearching}
              />
            ))}
          </SidebarCategory>
        )}
      </div>

      <div className={styles.subContainer}>
        {hasItems(designTokens) && (
          <SidebarCategory title="Design tokens" isSearching={isSearching}>
            <ul>
              {designTokens.map(item => (
                <li key={item.label}>
                  <NavLink
                    className={styles.subItem}
                    to={item.url}
                    activeClassName={styles.activeSubItem}
                    isActive={isActiveComponent}
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </SidebarCategory>
        )}
      </div>

      <ul className={styles.mainNav}>
        {other.map(item => (
          <li key={item.label} className={styles.itemContainer}>
            <NavLink
              className={styles.item}
              to={item.url}
              activeClassName={styles.activeItem}
              isActive={isActiveComponent}
            >
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
