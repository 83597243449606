// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { Options } from '../../Select2/Select2.util';
import { DropdownGroup } from '../DropdownGroup';
import type { DropdownMessageUpdateType } from './DropdownMessage';

import { KeyEvent } from '../../../ace-internal/types/keys';
import { addEventListener } from '../../../ace-internal/util/dom';

// eslint-disable-next-line css-modules/no-unused-class
import cssStyles from './DropdownContent.scss';

import type { testIdPropType, ansaradaCCDPropType } from '../../../ace-internal/types/general';

const FocusItems = Object.freeze({
  First: 'First',
  Last: 'Last',
});

export type FocusItemType = $Values<typeof FocusItems>;

export type DropdownContentPropsType = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  children?: React.ChildrenArray<
    React.Element<typeof DropdownGroup> | React.Element<typeof Options> | void | false,
  >,
  id?: string,
  className?: string,
  focusItem: ?FocusItemType,
  onUpdate: DropdownMessageUpdateType,
  triggerNode: ?HTMLElement,
|};

/** @ignore */
class DropdownContent extends React.Component<DropdownContentPropsType> {
  _element: ?HTMLElement;

  /* So we don't have to recheck if focusable items are undefined
   * we create a blank array if there no elements.
   */
  _focusableItems: Array<HTMLElement>;

  _lastfocusableItems: Array<HTMLElement>;

  _currentFocusItemIndex: number;

  _lastFocusItemIndex: number;

  _unbindHandlers: Array<() => void> | void;

  _initialiseFocusManager = () => {
    /*
      Added to make focus styles add after the update/mount has finished and css is added.
    */
    window.requestAnimationFrame(() => {
      if (this._isFocusIndexInBounds()) {
        this._renderFocusOnItem(this._currentFocusItemIndex);
      }
    });
  };

  _hasFocusItems = () => this._focusableItems && this._focusableItems.length > 0;

  _isFocusIndexInBounds = () =>
    this._hasFocusItems() && this._currentFocusItemIndex < this._focusableItems.length;

  _isNextFocusIndexInBounds = (focusItemIndex: number) =>
    this._hasFocusItems() && focusItemIndex >= 0 && focusItemIndex < this._focusableItems.length;

  _unBindEvents = () => {
    if (this._unbindHandlers !== undefined) {
      this._unbindHandlers.forEach(func => func());
      this._unbindHandlers = undefined;
    }
  };

  _moveFocus = (moveForward: boolean) => {
    if (this._hasFocusItems()) {
      const step = moveForward ? 1 : -1;
      const nextFocusItemIndex =
        (this._currentFocusItemIndex + step + this._focusableItems.length) %
        this._focusableItems.length;
      this._moveFocusToIndex(nextFocusItemIndex);
    }
  };

  _moveFocusToIndex = (nextFocusItemIndex: number) => {
    if (this._isNextFocusIndexInBounds(nextFocusItemIndex)) {
      this._lastFocusItemIndex = this._currentFocusItemIndex;
      this._currentFocusItemIndex = nextFocusItemIndex;
      this._renderFocusOnItem(this._currentFocusItemIndex);
    }
  };

  _scrollItemIntoFocus = (element: HTMLElement, wrapperElement: HTMLElement) => {
    const { scrollTop } = wrapperElement;

    const scrollBottom = scrollTop + wrapperElement.offsetHeight;
    const optionTop = element.offsetTop;
    const optionBottom = optionTop + element.offsetHeight;

    if (scrollTop > optionTop || scrollBottom < optionBottom) {
      // eslint-disable-next-line no-param-reassign
      wrapperElement.scrollTop = element.offsetTop;
    }
  };

  // eslint-disable-next-line consistent-return
  _onKeyDown = (event: SyntheticKeyboardEvent<*>) => {
    if (KeyEvent.isArrowDown(event)) {
      event.preventDefault();
      event.stopPropagation();
      if (typeof this._currentFocusItemIndex !== 'undefined') {
        return this._moveFocus(true);
      }

      this._setFocusIndexDefault(FocusItems.First);
      this._initialiseFocusManager();
    }

    if (KeyEvent.isArrowUp(event)) {
      event.preventDefault();
      event.stopPropagation();
      if (typeof this._currentFocusItemIndex !== 'undefined') {
        return this._moveFocus(false);
      }

      this._setFocusIndexDefault(FocusItems.Last);
      this._initialiseFocusManager();
    }

    if (KeyEvent.isEscape(event) || KeyEvent.isTab(event)) {
      this.props.onUpdate(false);
    }

    if (KeyEvent.isEnter(event)) {
      if (
        this._focusableItems[this._currentFocusItemIndex] &&
        this._focusableItems[this._currentFocusItemIndex].click
      ) {
        this._focusableItems[this._currentFocusItemIndex].click();
      }
    }
  };

  _onFocus = (event: SyntheticMouseEvent<>) => {
    const nextFocusItemIndex = this._focusableItems.findIndex(item =>
      item.contains((event.target: any)),
    );
    this._moveFocusToIndex(nextFocusItemIndex);
  };

  _setFocusIndexDefault = (focusItem: ?FocusItemType) => {
    switch (focusItem) {
      case FocusItems.First:
        this._currentFocusItemIndex = 0;
        break;
      case FocusItems.Last:
        if (this._hasFocusItems()) {
          this._currentFocusItemIndex = this._focusableItems.length - 1;
        }
        break;
      default:
    }
  };

  _renderFocusOnItem = (focusItemIndex: number) => {
    const currentFocusItem =
      typeof focusItemIndex !== 'undefined' && this._focusableItems[focusItemIndex]
        ? this._focusableItems[focusItemIndex]
        : undefined;

    const lastFocusItem =
      typeof this._lastFocusItemIndex !== 'undefined' &&
      this._focusableItems[this._lastFocusItemIndex]
        ? this._focusableItems[this._lastFocusItemIndex]
        : undefined;

    if (typeof this._lastFocusItemIndex !== 'undefined' && lastFocusItem) {
      lastFocusItem.classList.remove(cssStyles.isActiveFocus);
    }

    if (currentFocusItem && this._element) {
      currentFocusItem.classList.add(cssStyles.isActiveFocus);

      if (this.props.triggerNode && currentFocusItem.id) {
        this.props.triggerNode.setAttribute('aria-activedescendant', currentFocusItem.id);
      }

      if (this._element) {
        this._scrollItemIntoFocus(currentFocusItem, this._element);
      }
    }
  };

  _setMapFocusableItems = () => {
    this._lastfocusableItems = this._focusableItems;
    this._focusableItems = this._element
      ? [...this._element.querySelectorAll('a, button:not(:disabled)')]
      : [];
  };

  _bindkeyboardInputToWindow = () => {
    if (this._unbindHandlers === undefined || this._unbindHandlers.length === 0) {
      this._unbindHandlers = [addEventListener(window, 'keydown', this._onKeyDown, true)];
    }
  };

  _setNewFocusIndexAfterUpdate = () => {
    const currentFocusElement = this._lastfocusableItems[this._currentFocusItemIndex];
    const findExistingElement = (element?: HTMLElement) => element === currentFocusElement;
    const newIndex = this._focusableItems.findIndex(findExistingElement);

    this._currentFocusItemIndex = newIndex >= 0 ? newIndex : 0;
  };

  componentDidMount() {
    this._setMapFocusableItems();
    this._setFocusIndexDefault(this.props.focusItem);
    this._initialiseFocusManager();
    this._bindkeyboardInputToWindow();
  }

  componentWillUnmount() {
    this._unBindEvents();
  }

  componentDidUpdate(prevProps: DropdownContentPropsType) {
    this._setMapFocusableItems();
    this._setNewFocusIndexAfterUpdate();
    if (prevProps.focusItem !== this.props.focusItem) {
      this._setFocusIndexDefault(this.props.focusItem);
    }
    this._initialiseFocusManager();
  }

  render() {
    const width = this.props.triggerNode ? `${this.props.triggerNode.clientWidth}px` : 'auto';

    return (
      <div
        ref={el => {
          this._element = el;
        }}
        style={{ width }}
        id={this.props.id}
        data-test-id={this.props['data-test-id']}
        data-ansarada-ccd={this.props['data-ansarada-ccd']}
        onMouseOver={this._onFocus}
        onFocus={this._onFocus}
        className={classnames(cssStyles.dropdownContent, this.props.className)}
      >
        {this.props.children}
      </div>
    );
  }
}

export { DropdownContent };
