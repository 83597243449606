// @flow strict

import * as React from 'react';
import classnames from 'classnames/bind';

import type { CellDataType } from '../Table.shared';

import { defaultBackgroundColor } from '../Table.shared';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

export type TablePropType = {|
  children?: React.Node | Date,
  dataType?: CellDataType,
  sticky?: boolean,
  backgroundColor?: string,
  className?: string,
|};

const renderContent = (content?: React.Node | Date) => {
  if (content instanceof Date) {
    return content.toLocaleString();
  }

  return content;
};

/** @ignore */
const Cell = (props: TablePropType) => {
  const {
    children,
    dataType = 'Text',
    sticky = false,
    backgroundColor = defaultBackgroundColor,
    className,
  } = props;
  const cx = classnames.bind(styles);
  const classes = cx('cell', `is${dataType}`, className, { isSticky: sticky });

  return (
    <td className={classes} style={{ backgroundColor }}>
      {renderContent(children)}
    </td>
  );
};

export { Cell };
