// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const RedactArea = (): React.Element<*> => (
  <>
    <path d="M18.506 0.981h-17c-0.276 0-0.5 0.224-0.5 0.5v0 17c0 0.276 0.224 0.5 0.5 0.5v0h17c0.276 0 0.5-0.224 0.5-0.5v0-17c0-0.276-0.224-0.5-0.5-0.5v0zM18.006 17.981h-16v-16h16z"></path>
    <path d="M4.5 12.481h6.5c0.276 0 0.5-0.224 0.5-0.5v0-1.481h4c0.276 0 0.5-0.224 0.5-0.5v0-2.019c0-0.276-0.224-0.5-0.5-0.5v0h-11c-0.276 0-0.5 0.224-0.5 0.5v0 4c0 0.276 0.224 0.5 0.5 0.5v0z"></path>
    <path d="M5.5 5.981h8.438c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-8.438c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
    <path d="M13 13.981h-7.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
