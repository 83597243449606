// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const RedactPattern = (): React.Element<*> => (
  <>
    <path d="M10.496 5.981v-2c0-0.276-0.224-0.5-0.5-0.5v0h-6c-0.276 0-0.5 0.224-0.5 0.5v0 2c0 0.276 0.224 0.5 0.5 0.5v0h6c0.276 0 0.5-0.224 0.5-0.5v0z"></path>
    <path d="M15.996 8.481h-6c-0.276 0-0.5 0.224-0.5 0.5v0 2c0 0.276 0.224 0.5 0.5 0.5v0h6c0.276 0 0.5-0.224 0.5-0.5v0-2c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M12.996 13.481h-6c-0.276 0-0.5 0.224-0.5 0.5v0 2c0 0.276 0.224 0.5 0.5 0.5v0h6c0.276 0 0.5-0.224 0.5-0.5v0-2c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M16.498 0.981h-13c-1.378 0-2.5 1.122-2.5 2.5v13c0 1.378 1.122 2.5 2.5 2.5h13c1.378 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.122-2.5-2.5-2.5zM17.998 16.481c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
  </>
);
