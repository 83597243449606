// @flow strict
import React from 'react';
import type { DraftInlineStyle } from 'draft-js/lib/DraftInlineStyle';
import styles from './controls.scss';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { ButtonGroup } from '../ButtonGroup';
import { Select, Option } from '../Select';
import type { ControlType, HeadingControlType } from './constants';

type ControlsPropType = {
  currentStyles: DraftInlineStyle,
  controls: Array<ControlType>,
  onUpdate: (style: string) => void,
};

/** @ignore */
const Controls = ({ currentStyles, controls, onUpdate }: ControlsPropType) => {
  if (!controls || controls.length === 0) {
    return null;
  }

  const buttons = controls.map(type => (
    <Button
      variant="Ghost"
      key={type.text}
      size="Compact"
      pressed={currentStyles.has(type.style)}
      icon={
        type.icon ? (
          <Icon className={styles.hackStyle} glyph={type.icon} text={type.text} />
        ) : (
          undefined
        )
      }
      onMouseDown={e => {
        e.preventDefault();
        onUpdate(type.style);
      }}
    >
      {type.icon ? undefined : type.text}
    </Button>
  ));

  return (
    <div className={styles.editorControls}>
      <ButtonGroup>{buttons}</ButtonGroup>
    </div>
  );
};

type HeadingControlsPropType = {
  currentStyles: DraftInlineStyle,
  options: Array<HeadingControlType>,
  onUpdate: (style: string) => void,
};

/** @ignore */
const HeadingControls = ({ options, currentStyles, onUpdate }: HeadingControlsPropType) => {
  const activeOption = options.find(opt => currentStyles.has(opt.style));
  const activeHeadingStyle = activeOption && activeOption.style;
  return (
    <div className={styles.editorControls}>
      <Select
        size="Small"
        className={styles.headingControls}
        value={activeHeadingStyle || 'normal'}
        onChangeValue={onUpdate}
      >
        <Option value="normal" text="Normal text" />
        {options.map(opt => (
          <Option key={opt.style} value={opt.style} text={opt.text} />
        ))}
      </Select>
    </div>
  );
};

export { Controls, HeadingControls };
