// @flow strict
/* eslint react/no-unescaped-entities: off */

import * as React from 'react';
import { message, Message, Close, Button, FormattedContentBlock } from '../components';

class MessageTest extends React.Component<{}, { [string]: boolean }> {
  constructor() {
    super();
    this.state = {
      closableMessage1: true,
      closableMessage2: true,
      pageContextMessage: false,
    };
  }

  update(address: string, value: boolean) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Message</h2>
          <p>Basic variants</p>
        </FormattedContentBlock>
        <Message>Stuff is running well.</Message>
        <Message variant={message.Variants.Good}>Great job everyone</Message>
        <Message variant={message.Variants.Bad}>Oh no. It got bad again</Message>
        <Message variant={message.Variants.Critical}>
          There's smoke everywhere and the Queen's on Line 1!
        </Message>

        <FormattedContentBlock>
          <h2>Closeable Messages</h2>
        </FormattedContentBlock>

        {this.state.closableMessage1 && (
          <Message
            variant={message.Variants.Bad}
            id="closableMessage"
            close={<Close onUpdate={value => this.update('closableMessage1', value)} />}
          >
            False alarm. Close this message and go home.
          </Message>
        )}

        {this.state.closableMessage2 && (
          <Message
            variant={message.Variants.Good}
            close={<Close onUpdate={value => this.update('closableMessage2', value)} />}
          >
            Good job! I can be closed as well
          </Message>
        )}

        <FormattedContentBlock>
          <h2>Large Messages</h2>
          <p>
            Large message used for "empty set" pattern, eg. for a search correctly returning zero
            results.
          </p>
        </FormattedContentBlock>
        <Message size={message.Sizes.Large}>
          <p>No results found.</p>
        </Message>

        <FormattedContentBlock>
          <h2>Page Context Message</h2>
        </FormattedContentBlock>

        <Button onClick={() => this.update('pageContextMessage', true)}>Show message</Button>
        {this.state.pageContextMessage && (
          <Message
            context={message.Contexts.Page}
            close={<Close onUpdate={() => this.update('pageContextMessage', false)} />}
          >
            Page context message
          </Message>
        )}
      </div>
    );
  }
}

export default MessageTest;
