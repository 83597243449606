// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const SaveTick = (): React.Element<*> => (
  <>
    <path
      fill="#93b703"
      d="M8.5 13.5c-0.276 0-0.5 0.224-0.5 0.5v0 1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-1.5c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#1e8c0d"
      d="M13.5 7.492c-3.037 0-5.5 2.463-5.5 5.5s2.463 5.5 5.5 5.5 5.5-2.462 5.5-5.5-2.462-5.5-5.5-5.5zM16.682 16.174c-0.811 0.809-1.93 1.309-3.166 1.309-2.477 0-4.484-2.008-4.484-4.484s2.008-4.484 4.484-4.484c2.477 0 4.484 2.008 4.484 4.484 0 0.63-0.13 1.23-0.364 1.774l0.011-0.029c-0.239 0.556-0.564 1.031-0.965 1.431l-0 0z"
    ></path>
    <path
      fill="#1e8c0d"
      d="M15.364 10.185c-0.058-0.026-0.126-0.040-0.197-0.040-0.205 0-0.381 0.123-0.458 0.3l-0.001 0.003-1.637 3.819-0.821-1.232c-0.091-0.133-0.243-0.22-0.414-0.22-0.276 0-0.5 0.224-0.5 0.5 0 0.102 0.031 0.197 0.083 0.276l-0.001-0.002 1.333 2c0.091 0.135 0.243 0.223 0.416 0.223v0c0.016 0 0.031 0 0.046-0.002 0.186-0.018 0.342-0.135 0.413-0.298l0.001-0.003 2-4.667c0.025-0.058 0.040-0.126 0.040-0.197 0-0.205-0.124-0.381-0.3-0.459l-0.003-0.001z"
    ></path>
    <path d="M15.333 7.805v-4.779c0-0.009 0-0.017 0-0.026h1.667v5.749c0.379 0.315 0.709 0.669 0.989 1.062l0.011 0.017v-6.916c0-0.504-0.408-0.912-0.912-0.912v0h-14.176c-0.504 0-0.912 0.408-0.912 0.912v0 12.422l2.667 2.666h6.557c-0.573-0.265-1.065-0.6-1.492-1.003l0.003 0.003h-2.708c-0.014 0-0.026-0.012-0.026-0.026v0-4.448c0-0.014 0.012-0.026 0.026-0.026v0h0.996c0.033-0.371 0.098-0.711 0.193-1.039l-0.010 0.039h-1.179c-0.567 0-1.026 0.459-1.026 1.026v0 4.448c0 0.009 0 0.017 0 0.026h-0.919l-2.081-2.081v-11.919h1.667c0 0.009 0 0.017 0 0.026v5.948c0 0.566 0.459 1.026 1.025 1.026h3.192c0.247-0.377 0.522-0.705 0.83-0.998l0.002-0.002h-4.024c0 0 0 0 0 0-0.014 0-0.026-0.012-0.026-0.026 0-0 0-0 0-0v0-5.948c0-0 0-0 0-0 0-0.014 0.012-0.026 0.026-0.026 0 0 0 0 0 0h8.615c0 0 0 0 0 0 0.014 0 0.026 0.012 0.026 0.026 0 0 0 0 0 0v0 4.528c0.378 0.058 0.715 0.145 1.038 0.262l-0.038-0.012z"></path>
  </>
);
