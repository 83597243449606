// @flow strict
import * as React from 'react';

import { ModuleHeader, ModuleContent } from '.';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Module.scss';

type ModulePropType = {
  children: React.ChildrenArray<
    React.Element<typeof ModuleHeader> | React.Element<typeof ModuleContent> | void | false,
  >,
};

export const Module = (props: ModulePropType) => {
  const { children } = props;

  return <div className={styles.container}>{children}</div>;
};
