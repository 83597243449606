// @flow strict
import * as React from 'react';
import classnames from 'classnames';

export type AceTextPropType = {
  text: string,
  className?: string,
};

const AceText = (props: AceTextPropType) => {
  const { text, className, ...rest } = props;

  const classes = classnames('ace-text', className);

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <span className={classes} {...rest}>
      {text}
    </span>
  );
};

export { AceText };
