// @flow strict
import * as React from 'react';
import { TableRowLegacy } from './TableRowLegacy';

export type TableHeadPropType = {
  // TODO: ACEREACT-498 There all react element allow due to an issue with TreeHead cause tree and table to not load
  children?: React.ChildrenArray<
    React.Element<any> | React.Element<typeof TableRowLegacy> | void | false,
  >,
};

/** @ignore */
export const TableHeadLegacy = (props: TableHeadPropType) => {
  const { children, ...rest } = props;
  return <thead {...rest}>{children}</thead>;
};
