// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileVisio = (): React.Element<*> => (
  <>
    <path
      fill="#3949ab"
      d="M16.502 0.981h-13c-1.378 0-2.5 1.121-2.5 2.5v13c0 1.378 1.122 2.5 2.5 2.5h13c1.379 0 2.5-1.122 2.5-2.5v-13c0-1.379-1.121-2.5-2.5-2.5zM18.002 16.481c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"
    ></path>
    <path
      fill="#3949ab"
      d="M12.807 4.684q-0.172-0.157-0.428-0.157-0.435 0-0.54 0.435l-1.845 7.845-1.845-7.845q-0.105-0.435-0.54-0.435-0.255 0-0.42 0.157t-0.165 0.398c0 0.070 0.005 0.12 0.016 0.15l2.355 9.391q0.105 0.435 0.6 0.435 0.51 0 0.615-0.435l2.34-9.391 0.030-0.15c0-0.004 0-0.008 0-0.012 0-0.153-0.067-0.291-0.173-0.385l-0.001-0z"
    ></path>
  </>
);
