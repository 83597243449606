// @flow strict

import * as React from 'react';

import {
  Toggle,
  form,
  Form,
  FormSection,
  formMultiGroup,
  FormMultiGroup,
  FormSubItem,
  FormattedContentBlock,
} from '../components';

type StateType = { [string]: boolean };

class ToggleTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      toggle1: false,
      toggle2: false,
      toggle3: true,
      toggle4: false,
      toggle5: true,
      toggle6: false,
      toggle7: false,
    };
  }

  update(address: string, value: boolean) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Toggles</h2>
          <h3>Plain Toggle</h3>
        </FormattedContentBlock>
        <Toggle
          id="toggle1"
          checked={this.state.toggle1}
          onChecked={v => this.update('toggle1', v)}
        />
        <FormattedContentBlock>
          <h3>Caution Toggle</h3>
        </FormattedContentBlock>
        <Toggle
          id="toggle2"
          variant="Caution"
          checked={this.state.toggle2}
          onChecked={v => this.update('toggle2', v)}
        />
        <FormattedContentBlock>
          <h3>Disabled Toggle</h3>
        </FormattedContentBlock>
        <Toggle
          id="toggle3"
          disabled
          checked={this.state.toggle3}
          onChecked={v => this.update('toggle3', v)}
        />

        <Form variant={form.Variants.SideLabel} method={form.Method.Post}>
          <FormSection header="Toggles in Form">
            <FormMultiGroup contentType={formMultiGroup.ContentType.Toggle} legend="Multi Group 1">
              <FormSubItem>
                <Toggle
                  id="toggle4"
                  checked={this.state.toggle4}
                  onChecked={v => this.update('toggle4', v)}
                />
              </FormSubItem>
              <FormSubItem>
                <Toggle
                  id="toggle5"
                  variant="Caution"
                  checked={this.state.toggle5}
                  onChecked={v => this.update('toggle5', v)}
                />
              </FormSubItem>
              <FormSubItem>
                <Toggle
                  id="toggle6"
                  checked={this.state.toggle6}
                  onChecked={v => this.update('toggle6', v)}
                />
              </FormSubItem>
            </FormMultiGroup>
            <FormMultiGroup contentType={formMultiGroup.ContentType.Toggle} legend="Multi Group 2">
              <Toggle
                id="toggle7"
                checked={this.state.toggle7}
                onChecked={v => this.update('toggle7', v)}
              />
            </FormMultiGroup>
            <FormMultiGroup contentType={formMultiGroup.ContentType.Toggle} legend="Multi Group 2">
              <Toggle id="toggle8" defaultChecked={false} />
            </FormMultiGroup>
          </FormSection>
        </Form>
      </div>
    );
  }
}

export default ToggleTest;
