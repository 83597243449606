// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FormatListActive = (): React.Element<*> => (
  <>
    <path d="M16.505 18.981h-13c-1.379 0-2.5-1.122-2.5-2.5v-13c0-1.379 1.121-2.5 2.5-2.5h13c1.378 0 2.5 1.121 2.5 2.5v13c0 1.378-1.122 2.5-2.5 2.5zM3.505 1.981c-0.827 0.003-1.497 0.673-1.5 1.5v13c0.003 0.827 0.673 1.497 1.5 1.5h13c0.827-0.003 1.497-0.673 1.5-1.5v-13c-0.003-0.827-0.673-1.497-1.5-1.5h-0z"></path>
    <path d="M11.351 3.638c0-0.256 0-0.256-0.128 0l-4.484 8.202 2.691-0.384-0.77 4.87c0 0.256 0 0.256 0.128 0l4.485-8.202-2.691 0.384z"></path>
  </>
);
