// @flow strict
import * as React from 'react';
import cx from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { v4 as uuid } from 'uuid';
import type { testIdPropType } from '../../ace-internal/types/general';
import { BaseToast } from './BaseToast';

import { PageHidden } from '../PageHidden';
import { Toast } from './Toast';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './toaster.scss';

type Props = {
  children: React.ChildrenArray<
    React.Element<typeof Toast> | React.Element<typeof BaseToast> | void,
  >,
  position?: 'top' | 'bottom',
  offset?: number,
  ...testIdPropType,
};

// These should match the $transition-duration values in styles-variables.scss
const transitionTimeout = {
  enter: 300,
  exit: 500,
};

const getContainerStyles = (position: 'top' | 'bottom', offset?: number) => {
  const result = offset || 0;
  switch (position) {
    case 'top':
      return { top: result };
    default:
      return { bottom: result };
  }
};

/** @ignore */
const Toaster = (props: Props) => {
  const { children, position, offset, 'data-test-id': testId } = props;
  const toasterPosition: 'top' | 'bottom' = position || 'bottom';
  const containerStyles = getContainerStyles(toasterPosition, offset);
  const ariaId = uuid();
  return (
    <PageHidden data-test-id={testId}>
      <div
        role="alert"
        aria-describedby={ariaId}
        className={styles.toastContainer}
        style={containerStyles}
      >
        <TransitionGroup className={cx(styles.container, styles[toasterPosition])}>
          {React.Children.map(children, child => (
            <CSSTransition
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
              timeout={transitionTimeout}
            >
              {React.cloneElement(child, { ariaId })}
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </PageHidden>
  );
};

export default Toaster;
