// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Move = (): React.Element<*> => (
  <>
    <path d="M2.002 16.981h1v1h-1v-1z"></path>
    <path d="M2.002 14.981h1v1h-1v-1z"></path>
    <path d="M2.002 12.981h1v1h-1v-1z"></path>
    <path d="M2.002 10.981h1v1h-1v-1z"></path>
    <path d="M2.002 8.981h1v1h-1v-1z"></path>
    <path d="M2.002 6.981h1v1h-1v-1z"></path>
    <path d="M4.002 6.981h1v1h-1v-1z"></path>
    <path d="M4.002 16.981h1v1h-1v-1z"></path>
    <path d="M6.002 16.981h1v1h-1v-1z"></path>
    <path d="M8.002 16.981h1v1h-1v-1z"></path>
    <path d="M10.002 16.981h1v1h-1v-1z"></path>
    <path d="M12.002 16.981h1v1h-1v-1z"></path>
    <path d="M12.002 14.981h1v1h-1v-1z"></path>
    <path d="M16.036 1.981h-8.067c-1.086 0.001-1.965 0.881-1.967 1.967v8.067c0.001 1.086 0.881 1.965 1.966 1.967h8.067c1.086-0.001 1.965-0.881 1.967-1.967v-8.067c-0.001-1.086-0.881-1.965-1.966-1.967h-0zM17.002 12.015c-0.001 0.534-0.433 0.966-0.967 0.967h-8.067c-0.534-0.001-0.966-0.433-0.967-0.967v-8.067c0.001-0.534 0.433-0.966 0.967-0.967h8.067c0.534 0.001 0.966 0.433 0.967 0.967v0z"></path>
  </>
);
