// @flow strict

import * as React from 'react';
import classnames from 'classnames';

export type InputStatePropType = {
  className?: string,
  children?: React.Node,
  status?: 'Good' | 'Bad',
};

/** @ignore */
const InputState = (props: InputStatePropType) => {
  const { status, className, ...rest } = props;

  const classes = classnames(
    'ace-form-input-state',
    { 'ace-form-error': status === 'Bad' },
    className,
  );

  return (
    <div
      className={classes}
      // $FlowFixMe 0.112.0 - inexact rest of object pattern
      {...rest}
    >
      {props.children}
    </div>
  );
};

export { InputState };
