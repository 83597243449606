// @flow strict
/* eslint-disable css-modules/no-unused-class */

import React from 'react';
import cx from 'classnames';
import { PrismCode } from 'react-prism';

import { CopyClipboard } from '..';
import styles from './CodeSnippet.scss';
import type { LanguageType } from '../../../types/orbit';

type Props = {|
  snippet: string,
  language?: LanguageType,
  hideCopyButton?: boolean,
  className?: string,
|};

export function CodeSnippet(props: Props) {
  const { language, snippet, hideCopyButton, className } = props;

  return (
    <div className={cx(styles.codeContainer, className)}>
      <pre>
        <PrismCode className={cx(language && `language-${language}`)}>{snippet}</PrismCode>
      </pre>
      <span className={cx(hideCopyButton && styles.hide)}>
        <CopyClipboard target={snippet}>Copy</CopyClipboard>
      </span>
    </div>
  );
}
