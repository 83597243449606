// @flow strict
import * as React from 'react';
import cx from 'classnames';
import type { testIdPropType } from '../../ace-internal/types/general';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Columns.scss';

type Props = {|
  ...testIdPropType,
  /** Content of the column */
  children: React.Node,
  /** Alignment */
  alignment: 'Left' | 'Middle' | 'Right' | 'None',
|};

/**
 * @ignore
 */
export const Column = ({ 'data-test-id': testId, children, alignment }: Props) => {
  return (
    <div data-test-id={testId} className={cx([styles.column, styles[`is${alignment}`]])}>
      <div>{children}</div>
    </div>
  );
};

Column.defaultProps = {
  alignment: 'None',
};
