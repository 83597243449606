// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

import type { ansaradaCCDPropType } from '../../ace-internal/types/general';
import { removeStyleOnElement } from '../../ace-internal/util/dom';

type Props = {
  /** See [ansaradaCCD](docs/architecture/ansarada-ccd.md) */
  ...ansaradaCCDPropType,
  /** 'Tail' or 'Middle' - sets which part of the text should be truncated.s */
  truncatePosition: 'Tail' | 'Middle',
  /** Text to be truncated */
  children: string,
  /** An optional classname, which could be used for setting widths or styling inner text */
  className?: string,
};

const MAX_ITERATIONS = 100;

/**
 * Truncation is set to default in the middle with an ellipses. However, truncation can also occur at the end.
 * @status released
 * @date 26/09/2018
 * @version 12.0.0
 * @tags Card, FormattedContentBlock
 * @category Data
 */
class TruncateText extends React.PureComponent<Props> {
  containerEl: ?HTMLSpanElement;

  _resourcesLoadedEvent: Function | void;

  getPadding = (elem: Element): number => {
    const style = window.getComputedStyle(elem);
    const paddingLeft = +style.paddingLeft.slice(0, -2);
    const paddingRight = +style.paddingRight.slice(0, -2);
    return paddingLeft + paddingRight;
  };

  getWidth = (elem: Element): number => {
    const { width } = elem.getBoundingClientRect();
    const padding = this.getPadding(elem);
    // Round width up to avoid inaccurate comparisons later
    // with scrollWidth (scrollWidth is rounded up)
    return Math.ceil(width) - padding;
  };

  // scroll width includes overflow hidden content
  // so we can use this to figure out the length of the total text
  getScrollWidth = (elem: Element): number => {
    const width = elem.scrollWidth;
    const padding = this.getPadding(elem);
    return width - padding;
  };

  buildMiddleEllipsis = (text: string, visibleChars: number) => {
    const start = text.slice(0, visibleChars);
    const end = text.slice(-visibleChars);

    return `${start}…${end}`;
  };

  middleTruncate = () => {
    const { containerEl } = this;
    const text = this.props.children;
    if (!containerEl) return;

    const containerWidth = this.getWidth(containerEl);
    const textWidth = this.getScrollWidth(containerEl);

    // escape if no need to truncate
    if (textWidth <= containerWidth) {
      removeStyleOnElement(containerEl, 'visibility');
      return;
    }

    // get initial amount of chars to truncate
    const overflowPercent = (textWidth - containerWidth) / textWidth;
    const overflowChars = Math.ceil(text.length * overflowPercent) + 1; // add an extra char for the ellipsis we will add
    const visibleChars = Math.floor((text.length - overflowChars) / 2);

    containerEl.textContent = this.buildMiddleEllipsis(text, visibleChars);

    // because some chars are wider than others,
    // the display text could still be overflowing
    // We also safeguard against infinite loop (most iterations seen in
    // test data was 13; MAX_ITERATIONS chosen as arbitrary buffer):
    let i = 1;
    while (this.getScrollWidth(containerEl) > containerWidth && i < MAX_ITERATIONS) {
      containerEl.textContent = this.buildMiddleEllipsis(text, visibleChars - i);
      i += 1;
    }

    // Reset initial inline styles from calculating phase
    // (and therefore unhide result)
    removeStyleOnElement(containerEl, 'visibility');
  };

  componentDidMount() {
    if (this.props.truncatePosition === 'Middle') {
      if (document.readyState === 'loading') {
        this._resourcesLoadedEvent = window.addEventListener('load', () => {
          window.requestAnimationFrame(() => {
            this.middleTruncate();
            this._resourcesLoadedEvent = undefined;
          });
        });
      } else {
        window.requestAnimationFrame(() => {
          this.middleTruncate();
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props.truncatePosition === 'Middle') {
      window.requestAnimationFrame(() => {
        this.middleTruncate();
      });
    }
  }

  render() {
    const { truncatePosition, children, className, 'data-ansarada-ccd': ansaradaCCD } = this.props;
    if (!children) return null;

    const classes = classnames(
      styles.nowrap,
      truncatePosition === 'Tail' && styles.truncateTail,
      className,
    );

    return (
      <span
        className={classes}
        title={children}
        data-ansarada-ccd={ansaradaCCD}
        ref={el => {
          this.containerEl = el;
        }}
        style={truncatePosition === 'Middle' ? { visibility: 'hidden' } : {}}
      >
        {children}
      </span>
    );
  }
}

export { TruncateText };
