// @flow strict
import * as React from 'react';

import AceTextTest from '@ansarada/ace-react/tests/AceTextTest';
import AssetTest from '@ansarada/ace-react/tests/AssetTest';
import AssetV2Test from '@ansarada/ace-react/tests/AssetV2Test';
import AssetPickerFolderItemTest from '@ansarada/ace-react/tests/AssetPickerFolderItemTest';
import AssetPickerFileItemTest from '@ansarada/ace-react/tests/AssetPickerFileItemTest';
import AssetPickerTest from '@ansarada/ace-react/tests/AssetPickerTest';
import AvatarTest from '@ansarada/ace-react/tests/AvatarTest';
import AutocompleteTest from '@ansarada/ace-react/tests/AutocompleteTest';
import ButtonTest from '@ansarada/ace-react/tests/ButtonTest';
import BreadcrumbTest from '@ansarada/ace-react/tests/BreadcrumbTest';
import CardTest from '@ansarada/ace-react/tests/CardTest';
import DatepickerTest from '@ansarada/ace-react/tests/DatepickerTest';
import DropdownTest from '@ansarada/ace-react/tests/DropdownTest';
import DropdownTestComplex from '@ansarada/ace-react/tests/DropdownTestComplex';
import EditorTest from '@ansarada/ace-react/tests/EditorTest';
// $FlowFixMe
import EmptyStateTest from '@ansarada/ace-react/tests/EmptyStateTest';
import ExpanderPanelTest from '@ansarada/ace-react/tests/ExpanderPanelTest';
import FormGridTest from '@ansarada/ace-react/tests/FormGridTest';
import FormLayoutTest from '@ansarada/ace-react/tests/FormLayoutTest';
import FormNonTextInputTest from '@ansarada/ace-react/tests/FormNonTextInputTest';
import FormSelectTest from '@ansarada/ace-react/tests/FormSelectTest';
import FormTextInputTest from '@ansarada/ace-react/tests/FormTextInputTest';
import FormattedContentBlockTest from '@ansarada/ace-react/tests/FormattedContentBlockTest';
import HelpLinkTest from '@ansarada/ace-react/tests/HelpLinkTest';
import HighlightTest from '@ansarada/ace-react/tests/HighlightTest';
import IconTest from '@ansarada/ace-react/tests/IconTest';
import LayoutTest from '@ansarada/ace-react/tests/LayoutTest';
import LozengeTest from '@ansarada/ace-react/tests/LozengeTest';
import LozengeGroupTest from '@ansarada/ace-react/tests/LozengeGroupTest';
import MessageTest from '@ansarada/ace-react/tests/MessageTest';
import PanelTest from '@ansarada/ace-react/tests/PanelTest';
import { PaginationTest } from '@ansarada/ace-react/tests/PaginationTest';
import PageTest from '@ansarada/ace-react/tests/PageTest';
import PageHiddenTest from '@ansarada/ace-react/tests/PageHiddenTest';
import ProgressbarTest from '@ansarada/ace-react/tests/ProgressbarTest';
import SidebarMenuTest from '@ansarada/ace-react/tests/SidebarMenuTest';
import TableTest from '@ansarada/ace-react/tests/TableTest';
import TabTest from '@ansarada/ace-react/tests/TabTest';
import ToastTest from '@ansarada/ace-react/tests/ToastTest';
import ToggleTest from '@ansarada/ace-react/tests/ToggleTest';
import TooltipTest from '@ansarada/ace-react/tests/TooltipTest';
import TextEditableTest from '@ansarada/ace-react/tests/TextEditableTest';
import TreeTest from '@ansarada/ace-react/tests/TreeTest';
import TruncateTextTest from '@ansarada/ace-react/tests/TruncateTextTest';
import XSSTest from '@ansarada/ace-react/tests/XSSTest';
import HeaderTest from '@ansarada/ace-react/tests/HeaderTest';

import styles from './Test.scss';

const findComponent = id => {
  switch (id) {
    case 'AceText':
      return <AceTextTest />;
    case 'Asset':
      return <AssetTest />;
    case 'AssetV2':
      return <AssetV2Test />;
    case 'AssetPickerFolderItem':
      return <AssetPickerFolderItemTest />;
    case 'AssetPickerFileItem':
      return <AssetPickerFileItemTest />;
    case 'AssetPicker':
      return <AssetPickerTest />;
    case 'Autocomplete':
      return <AutocompleteTest />;
    case 'Avatar':
      return <AvatarTest />;
    case 'Button':
      return <ButtonTest />;
    case 'Breadcrumb':
      return <BreadcrumbTest />;
    case 'Card':
      return <CardTest />;
    case 'Datepicker':
      return <DatepickerTest />;
    case 'Dropdown':
      return <DropdownTest />;
    case 'DropdownComplex':
      return <DropdownTestComplex />;
    case 'Editor':
      return <EditorTest />;
    case 'EmptyState':
      return <EmptyStateTest />;
    case 'ExpanderPanel':
      return <ExpanderPanelTest />;
    case 'FormGrid':
      return <FormGridTest />;
    case 'FormLayout':
      return <FormLayoutTest />;
    case 'FormNonTextInput':
      return <FormNonTextInputTest />;
    case 'FormSelect':
      return <FormSelectTest />;
    case 'FormTextInput':
      return <FormTextInputTest />;
    case 'FormattedContentBlock':
      return <FormattedContentBlockTest />;
    case 'HelpLink':
      return <HelpLinkTest />;
    case 'Highlight':
      return <HighlightTest />;
    case 'Icon':
      return <IconTest />;
    case 'Layout':
      return <LayoutTest />;
    case 'Lozenge':
      return <LozengeTest />;
    case 'LozengeGroup':
      return <LozengeGroupTest />;
    case 'Message':
      return <MessageTest />;
    case 'Panel':
      return <PanelTest />;
    case 'Page':
      return <PageTest />;
    case 'PageHidden':
      return <PageHiddenTest />;
    case 'Progressbar':
      return <ProgressbarTest />;
    case 'Pagination':
      return <PaginationTest />;
    case 'SidebarMenu':
      return <SidebarMenuTest />;
    case 'Table':
      return <TableTest />;
    case 'Tab':
      return <TabTest />;
    case 'TextEditable':
      return <TextEditableTest />;
    case 'Toast':
      return <ToastTest />;
    case 'Toggle':
      return <ToggleTest />;
    case 'Tooltip':
      return <TooltipTest />;
    case 'Tree':
      return <TreeTest />;
    case 'TruncateText':
      return <TruncateTextTest />;
    case 'XSS':
      return <XSSTest />;
    case 'Header':
      return <HeaderTest />;
    default:
      return <p>404 - no test page found</p>;
  }
};

type Props = {
  match: { params: { id: string } },
};

export const Test = ({ match }: Props) => (
  <div className={styles.container}>{findComponent(match.params.id)}</div>
);
