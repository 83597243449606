// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Redact = (): React.Element<*> => (
  <>
    <path d="M17.502 6.981h-15c-0.276 0-0.5 0.224-0.5 0.5v0 1.5c0 0.276 0.224 0.5 0.5 0.5v0h15c0.276 0 0.5-0.224 0.5-0.5v0-1.5c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M3.496 4.463h12.188c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-12.188c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
    <path d="M15.684 11.981h-12.188c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h12.188c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M14.434 15.469h-10.938c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h10.938c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
