// @flow strict
import * as React from 'react';

import classNames from 'classnames';
import { AssetPickerFileItem } from './AssetPickerFileItem';
import { AssetPickerFolderItem } from './AssetPickerFolderItem';

import styles from './styles.scss';

export type SelectedType = Array<string>;

export type AssetPickerPropType = {
  className?: string,
  children?: React.ChildrenArray<
    | React.Element<typeof AssetPickerFileItem>
    | React.Element<typeof AssetPickerFolderItem>
    | void
    | false,
  >,
};

/**
 * AssetPicker wraps files and folders created using it's sub-components.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Asset
 * @category Data
 */
const AssetPicker = (props: AssetPickerPropType) => {
  const { className, children, ...rest } = props;

  const classes = classNames(styles.assetPicker, className);

  return (
    <ul {...rest} className={classes}>
      {children}
    </ul>
  );
};

export { AssetPicker };
