// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useUncontrolled } from 'uncontrollable';

import styles from './styles.scss';
import { HelpLink } from '../HelpLink';
import type { ansaradaCCDPropType, testIdPropType } from '../../ace-internal/types/general';

export type CheckboxType = 'Checkbox';

type BaseProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  /** Sets the checkbox ID property */
  id?: string,
  /** Optional attribute, it sets the checkbox name property. If empty the ID will be used */
  name?: string,
  /** Text or component used as label value. It accepts a span element with JSX in it */
  label: string | React.Element<'span'>,
  /** Text or component used as label value. It accepts a span element with JSX in it */
  hideLabel?: boolean,
  /** Sets the checkbox value property. If empty the name or ID will be used */
  value: string,
  /** Set the visual property indeterminate. If true the checkbox will display a dash regardless of the checked state. This does not replace the checked state or modify it in any way, it is simply a display property */
  indeterminate?: boolean,
  /** Set the disabled state of the checkbox */
  disabled?: boolean,
  /** Adds a class to the checkbox top element */
  className?: string,
  helpLink?: React.Element<typeof HelpLink>,
  hasAnchor?: boolean,
  'aria-describedby'?: string,
|};

type ControlledChecked<T> = {|
  /** Text or component used as label value. It accepts a span element with JSX in it */
  checked?: T,
  /** Function used to let the caller component know the checkbox state has changed */
  onChecked: (checked: T) => void,
|};

type UncontrolledChecked<T> = {|
  defaultChecked: T,
  onChecked?: (checked: T) => void,
|};

type Checked<T> = ControlledChecked<T> | UncontrolledChecked<T>;

type CheckboxControlledProps = {|
  ...BaseProps,
  ...ControlledChecked<boolean>,
|};

export type CheckboxPropType = {|
  ...BaseProps,
  ...Checked<boolean>,
|};

function getLabelText(
  label: string | React.Element<'span'>,
  ansaradaCCD?: true,
): React.Element<'span'> {
  if (typeof label === 'string') {
    return (
      <span className={styles.labelText} data-ansarada-ccd={ansaradaCCD}>
        {label}
      </span>
    );
  }

  return React.cloneElement(label, {
    className: styles.labelText,
    'data-ansarada-ccd': ansaradaCCD,
  });
}

function getIcon(
  checked: ?boolean,
  indeterminate: ?boolean,
  disabled: ?boolean,
): ?React.Element<'rect' | 'polygon'> {
  if (indeterminate) {
    return (
      <rect
        className={disabled ? styles.disabledDash : styles.activeDash}
        x="5"
        y="9"
        width="10"
        height="2"
        fillOpacity={0}
      />
    );
  }

  return checked ? (
    <polygon
      className={disabled ? styles.disabledTick : styles.tick}
      fillRule="nonzero"
      fillOpacity={0}
      transform="translate(10.533462, 8.437191) rotate(40.000000) translate(-10.533462, -8.437191) "
      points="7.03346213 12.2553731 7.03346213 14.4371912 14.0334621 14.4371912 14.0334621 2.43719124 11.7001288 2.43719124 11.7001288 12.2553731"
    />
  ) : null;
}

const ControlledCheckbox = ({
  id = uuid(),
  name,
  label,
  checked = false,
  onChecked,
  disabled,
  className,
  value = 'on',
  indeterminate,
  helpLink,
  hideLabel,
  hasAnchor,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  'aria-describedby': describedBy,
}: CheckboxControlledProps): React.Element<'label'> => (
  <label
    data-test-id={testId}
    htmlFor={id}
    className={classnames(
      styles.checkbox,
      className,
      disabled ? styles.isDisabled : undefined,
      hideLabel ? styles.hideLabel : undefined,
      hasAnchor ? styles.anchor : undefined,
    )}
  >
    <input
      type="checkbox"
      id={id}
      className={classnames(
        styles.checkboxField,
        indeterminate ? styles.isIndeterminate : styles.isNormal,
      )}
      value={value}
      name={name || id}
      aria-describedby={describedBy}
      onChange={() => {
        if (typeof onChecked === 'function') {
          onChecked(!checked);
        }
      }}
      checked={checked}
      disabled={disabled}
    />
    <svg
      className={styles.iconWrapper}
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fillOpacity={0}
        className={styles.icon}
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="3"
        strokeLinecap="square"
      />
      {getIcon(checked, indeterminate, disabled)}
    </svg>
    {getLabelText(label, ansaradaCCD)}
    {helpLink && React.cloneElement(helpLink, { className: styles.helpLink })}
  </label>
);

/**
 * Checkboxes allow users to make multiple selections within a group of options, such as no option, a single or multiple option.
 *
 *
 * Each checkbox works independently to the others in the list, and checking one box does not uncheck others.
 *
 *
 * Checkboxes require the use of a button to apply the user selections.
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags CheckboxGroup, Radio, RadioGroup
 * @category Form
 */
const Checkbox = (props: CheckboxPropType): React$Element<typeof ControlledCheckbox> => {
  const allProps = useUncontrolled(props, {
    checked: 'onChecked',
  });
  return <ControlledCheckbox {...allProps} />;
};

export { Checkbox };
