// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Notification = (): React.Element<*> => (
  <>
    <path d="M16.316 14.359v0c-0.582-0.841-0.599-1.847-0.6-1.869v-3.999c0-0.002 0-0.005 0-0.008 0-0.775-0.166-1.51-0.465-2.173l0.013 0.033c-0.306-0.682-0.719-1.262-1.226-1.746l-0.002-0.002c-0.509-0.489-1.111-0.885-1.776-1.16l-0.038-0.014q-0.169-0.068-0.341-0.126c-0.291-0.768-1.020-1.304-1.875-1.304s-1.584 0.536-1.87 1.29l-0.005 0.014q-0.172 0.058-0.341 0.126c-0.703 0.288-1.305 0.685-1.816 1.176l0.002-0.002c-0.509 0.486-0.923 1.066-1.214 1.713l-0.014 0.035c-0.285 0.629-0.451 1.365-0.451 2.139 0 0.003 0 0.006 0 0.010v-0 3.999c0 0.022-0.017 1.028-0.6 1.869-0.115 0.163-0.183 0.366-0.183 0.585 0 0.174 0.043 0.338 0.12 0.482l-0.003-0.006c0.183 0.342 0.538 0.571 0.947 0.571 0.002 0 0.005 0 0.007-0h2.971c0.24 1.15 1.245 2.002 2.45 2.002s2.21-0.852 2.447-1.986l0.003-0.016h2.972c0.002 0 0.004 0 0.007 0 0.409 0 0.764-0.229 0.944-0.565l0.003-0.006c0.074-0.138 0.118-0.302 0.118-0.476 0-0.219-0.069-0.422-0.186-0.588l0.002 0.003zM6.669 5.317c0.891-0.855 2.076-1.325 3.338-1.325s2.447 0.471 3.337 1.325c0.885 0.849 1.372 1.977 1.372 3.175v4h-9.419v-4c0-1.198 0.487-2.326 1.372-3.175zM10.006 16.992c-0.649-0.001-1.202-0.413-1.411-0.99l-0.003-0.010h2.828c-0.213 0.587-0.765 0.999-1.414 1h-0zM15.496 14.957c-0.007 0.013-0.025 0.035-0.068 0.035h-10.843c-0.043 0-0.061-0.022-0.068-0.035-0.003-0.003-0.004-0.008-0.004-0.013 0-0.006 0.002-0.011 0.006-0.015v0c0.337-0.486 0.528-1.004 0.636-1.438h9.703c0.108 0.433 0.299 0.951 0.636 1.438v0c0.004 0.004 0.006 0.009 0.006 0.015s-0.002 0.010-0.005 0.013l0-0z"></path>
    <path d="M7 8.492c0.276 0 0.5-0.224 0.5-0.5v0c0.001-1.104 0.896-1.999 2-2h0c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0c-1.654 0-3 1.346-3 3 0 0.276 0.224 0.5 0.5 0.5v0z"></path>
  </>
);
