// @flow strict
import * as React from 'react';

import type { NavigationGroup } from '../types';
import { NavigationItem } from './NavigationItem';

import styles from './MainNavigation.scss';

type Props = {|
  items: $ReadOnlyArray<NavigationGroup>,
  label: string,
  testId: string,
  navRef?: React$Ref<'nav'>,
|};

/** @ignore */
export const MainNavigation = ({ items, testId, label, navRef }: Props) => {
  if (!items.length) return null;

  return (
    <nav ref={navRef} className={styles.container} aria-label={label} data-test-id={testId}>
      <h2 className={styles.heading}>{label}</h2>
      {items.map(group => (
        <ul key={group.items.map(l => l.name).join('-')} className={styles.landmarks}>
          {group.items.map(item => {
            if (item.link || item.onClick) {
              return (
                <li key={item.name} className={styles.landmark}>
                  <NavigationItem item={item} />
                </li>
              );
            }
            return null;
          })}
        </ul>
      ))}
    </nav>
  );
};
