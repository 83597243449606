// @flow strict

import * as React from 'react';
import { panel, Panel, icon, Icon, FormattedContentBlock } from '../components';

const PanelTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>Panel</h2>
    </FormattedContentBlock>

    <Panel>
      <FormattedContentBlock>
        <p>This is a default Panel</p>
      </FormattedContentBlock>
    </Panel>
    <Panel variant={panel.Variants.Primary}>
      <FormattedContentBlock>
        <p>This is a Primary Panel</p>
      </FormattedContentBlock>
    </Panel>
    <Panel variant={panel.Variants.Secondary}>
      <FormattedContentBlock>
        <p>This is a Secondary Panel</p>
      </FormattedContentBlock>
    </Panel>
    <Panel layout={panel.Layouts.IconSpace}>
      <FormattedContentBlock>
        <p>This is a default Panel with Icon Space</p>
      </FormattedContentBlock>
    </Panel>

    <Panel variant={panel.Variants.Primary} layout={panel.Layouts.IconSpace}>
      <FormattedContentBlock>
        <p>This is a Primary Panel with Icon Space</p>
      </FormattedContentBlock>
    </Panel>
    <Panel
      variant={panel.Variants.Primary}
      layout={panel.Layouts.Icon}
      layoutIcon={<Icon glyph={icon.Glyphs.ObjectsQuestion} text="ace.ansarada.com" />}
    >
      <FormattedContentBlock>
        <p>This is a Primary Panel with Icon </p>
      </FormattedContentBlock>
    </Panel>
    <Panel
      variant={panel.Variants.Secondary}
      layout={panel.Layouts.Icon}
      layoutIcon={<Icon glyph={icon.Glyphs.ObjectsSubject} text="ace.ansarada.com" />}
    >
      <FormattedContentBlock>
        <p>This is a Secondary Panel with Icon </p>
      </FormattedContentBlock>
    </Panel>
  </div>
);

export default PanelTest;
