// @flow strict
import * as React from 'react';

import { DropdownGroup } from '../../Dropdown/DropdownGroup';
import { DropdownAction } from '../../Dropdown/DropdownAction';

import { useDisplay } from './TimepickerContext';

import styles from './TimepickerOptions.scss';

export type TimepickerOptionsPropTypes = {||};

const ignoreFocus = (event: SyntheticMouseEvent<>) => {
  event.preventDefault();
};

const TimepickerOptions = React.forwardRef<TimepickerOptionsPropTypes, any>(
  (props: TimepickerOptionsPropTypes, ref) => {
    const state = useDisplay();

    const onOptionClick = (e: SyntheticEvent<>, time: string) => {
      // once a selection is made, change focus to the next element
      if (document && document.activeElement) {
        document.activeElement.blur();
      }

      state.onSelectTime(time);
      state.onOpen(false);
    };

    const { selectedTime, timeOptions } = state;

    return (
      <div
        className={styles.timepickerPopoverWrapper}
        onMouseDown={ignoreFocus}
        role="button"
        tabIndex="0"
        ref={ref}
      >
        <DropdownGroup className={styles.popoverDropdownGroup}>
          {timeOptions &&
            timeOptions.map(option => (
              <DropdownAction
                onClick={e => onOptionClick(e, option)}
                disabled={selectedTime === option}
                key={`dd_action_${option}`}
              >
                {option}
              </DropdownAction>
            ))}
        </DropdownGroup>
      </div>
    );
  },
);

export { TimepickerOptions };
