// @flow strict
import * as React from 'react';

// TODO: ACEREACT-498 Work out a better way of dealing with nested types
export type TableBodyPropType = {
  children?: React.ChildrenArray<React.Element<any> | void | false>,
};

/** @ignore */
export const TableBodyLegacy = (props: TableBodyPropType) => {
  const { children, ...rest } = props;
  return <tbody {...rest}>{children}</tbody>;
};
