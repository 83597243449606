// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FormatBoldActive = (): React.Element<*> => (
  <>
    <path d="M10.010 10.524h-1.462v3.082h1.462q0.608 0 1.020-0.427t0.412-1.020v-0.13q0-0.622-0.427-1.064t-1.006-0.441z"></path>
    <path d="M10.951 8.737q0.377-0.412 0.377-1.064 0-0.608-0.355-0.962t-0.962-0.355h-1.462v2.793h1.462q0.565 0 0.941-0.412z"></path>
    <path d="M0.996 0.981v18h18v-18zM12.832 12.159q0 1.172-0.825 1.997t-1.997 0.825h-2.156c-0.003 0-0.007 0-0.010 0-0.19 0-0.361-0.081-0.481-0.21l-0-0q-0.203-0.21-0.203-0.484v-8.611q0-0.275 0.203-0.484c0.121-0.129 0.292-0.21 0.482-0.21 0.003 0 0.007 0 0.010 0h2.156q1.172 0 1.939 0.76t0.767 1.932q0 1.404-1.027 2.127 0.521 0.391 0.832 0.991t0.311 1.237z"></path>
  </>
);
