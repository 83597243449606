// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const HeartFill = (): React.Element<*> => (
  <>
    <path d="M10.006 16.99c-0 0-0 0-0 0-0.149 0-0.283-0.065-0.374-0.169l-0-0.001c-0.165-0.188-0.656-0.518-1.225-0.901-1.138-0.766-2.695-1.815-4.004-3.23-0.729-0.787-1.298-1.607-1.692-2.435-0.467-0.982-0.704-2.007-0.704-3.047 0-1.115 0.386-2.167 1.086-2.96 0.331-0.377 0.73-0.686 1.18-0.907l0.022-0.010c0.436-0.215 0.95-0.341 1.493-0.341s1.057 0.126 1.514 0.35l-0.020-0.009c0.472 0.231 0.871 0.54 1.198 0.913l0.004 0.004c0.7 0.794 1.086 1.845 1.086 2.96 0 0.305 0.2 0.563 0.438 0.563s0.438-0.258 0.438-0.563c0-1.115 0.386-2.167 1.086-2.96 0.331-0.377 0.73-0.686 1.18-0.907l0.022-0.010c0.436-0.215 0.95-0.341 1.493-0.341s1.057 0.126 1.514 0.35l-0.020-0.009c0.472 0.231 0.871 0.54 1.198 0.913l0.004 0.004c0.7 0.794 1.086 1.845 1.086 2.96 0 1.040-0.237 2.065-0.704 3.047-0.394 0.828-0.963 1.647-1.692 2.435-1.309 1.414-2.867 2.464-4.004 3.23-0.568 0.383-1.059 0.713-1.225 0.901-0.092 0.104-0.226 0.169-0.375 0.169-0 0-0 0-0 0v0z"></path>
  </>
);
