// @flow strict

// See:
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values

const KeyCode = {
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  Enter: 'Enter',
  Escape: 'Escape',
  PageDown: 'PageDown',
  PageUp: 'PageUp',
  Space: 'Space',
  Tab: 'Tab',
  Delete: 'Delete',
  Backspace: 'Backspace',
};

// Mapping of deprecated keys to standard keys (with the exception of Space).
const NormalizeMap = {
  ' ': KeyCode.Space, // Convert standard ' ' to our own standard named key
  Down: KeyCode.ArrowDown, // IE11, Edge
  Left: KeyCode.ArrowLeft, // IE11, Edge
  Right: KeyCode.ArrowRight, // IE11, Edge
  Spacebar: KeyCode.Space, // IE11
  Up: KeyCode.ArrowUp, // IE11, Edge
  Esc: KeyCode.Escape, // IE11, Edge
};

// Returns the standard key for a keyboard event.
const getKey = (e: SyntheticKeyboardEvent<*>): ?$Keys<typeof KeyCode> =>
  NormalizeMap[e.key] || KeyCode[e.key];

const keyFn = (match: $Keys<typeof KeyCode>) => (e: SyntheticKeyboardEvent<*>): boolean =>
  getKey(e) === match;

const numberKeyFn = (e: SyntheticKeyboardEvent<*>): boolean =>
  // eslint-disable-next-line no-restricted-globals
  isFinite(e.key);

const charKeyFn = (e: SyntheticKeyboardEvent<*>): boolean =>
  e.key.length === 1 && !e.altKey && !e.metaKey && !e.ctrlKey;

// Set of helper functions to simplify testing events for our standard keys.
const KeyEvent = {
  isArrowDown: keyFn(KeyCode.ArrowDown),
  isArrowLeft: keyFn(KeyCode.ArrowLeft),
  isArrowRight: keyFn(KeyCode.ArrowRight),
  isArrowUp: keyFn(KeyCode.ArrowUp),
  isEnter: keyFn(KeyCode.Enter),
  isEscape: keyFn(KeyCode.Escape),
  isPageUp: keyFn(KeyCode.PageUp),
  isPageDown: keyFn(KeyCode.PageDown),
  isSpace: keyFn(KeyCode.Space),
  isTab: keyFn(KeyCode.Tab),
  isBackspace: keyFn(KeyCode.Backspace),
  isDelete: keyFn(KeyCode.Delete),
  isNumber: numberKeyFn,
  isChar: charKeyFn,
};

export { KeyCode, KeyEvent, getKey };
