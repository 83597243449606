// @flow strict
/* eslint react/jsx-no-bind: off */

import React from 'react';

import {
  Button,
  Link,
  SubmitButton,
  ButtonGroup,
  icon,
  Icon,
  FormattedContentBlock,
} from '../components';

type StateType = {
  isLoading: boolean,
};

class ButtonTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Button</h2>
        </FormattedContentBlock>
        <div>
          <Button>Default</Button>
          <SubmitButton>Submit</SubmitButton>
          <Button icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />}>Icon Button</Button>
          <Button icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />} />
          <Button size="Compact" icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />} />
          <Button icon={<Icon glyph={icon.Glyphs.NOGLYPH} text="Foo" className="custom-icon" />} />
          <Button disabled>Disabled</Button>
        </div>
        <div>
          <Button>Default</Button>
          <Button size="Compact">S</Button>
          <Button size="Regular">M</Button>
          <Button size="Large">L</Button>
        </div>

        <div>
          <Button pressed>Pressed</Button>
        </div>
        <div>
          <Button variant="Ghost">Ghost</Button>
          <Button variant="Caution" size="Large">
            Caution
          </Button>
        </div>
        <div>
          <Button variant="Primary">Primary</Button>
        </div>
        <div>
          <Link href="http://ace.ansarada.com">ace.ansarada.com</Link>
          <Link
            href="http://ace.ansarada.com"
            icon={<Icon glyph={icon.Glyphs.ControlArrowRight} text="ace.ansarada.com" />}
          >
            ace.ansarada.com
          </Link>
        </div>

        <FormattedContentBlock>
          <h2>Button Group</h2>
        </FormattedContentBlock>
        <ButtonGroup>
          <Button>These</Button>
          <Button size="Compact">buttons</Button>
          <Button size="Regular">are</Button>
          <Button size="Large">together</Button>
        </ButtonGroup>
        <br />
        <FormattedContentBlock>
          <h2>Loading Buttons</h2>
        </FormattedContentBlock>
        <Button size="Regular" loading={this.state.isLoading}>
          Load
        </Button>
        <Button onClick={this._onToggleIsLoadingClick.bind(this)}>Toggle IsLoading</Button>
        <FormattedContentBlock>
          <h2>CCD</h2>
        </FormattedContentBlock>
        <Button data-ansarada-ccd>Test of CCD</Button>
        <FormattedContentBlock>
          <h2>TEST ID</h2>
        </FormattedContentBlock>
        <Button data-test-id="buttontest">Test of test id</Button>
      </div>
    );
  }

  _onToggleIsLoadingClick() {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  }
}

export default ButtonTest;
