// @flow strict
import * as React from 'react';
import cx from 'classnames';
import { Link } from '@ansarada/ace-react';

import styles from './TableOfContents.scss';

type TableOfContentsProps = {
  selector: string,
  container?: string,
};

type Item = {
  id: string,
  url: string,
  text: string,
  type: string,
};

const formatUrl = (element: HTMLElement) => {
  const id = element.getAttribute('id') || '';
  const { hash } = window.location;
  return `${hash}#${id}`;
};

const getHeadings = ($container, selector, defaultSelector): Item[] => {
  const items = [...$container.querySelectorAll(selector || defaultSelector)];
  return items
    .filter(elem => elem.hasAttribute('id'))
    .map(elem => {
      const id = elem.getAttribute('id');
      return {
        id: id ? `#${id}` : '',
        url: formatUrl(elem),
        text: elem.textContent ? elem.textContent : '',
        type: elem.nodeName,
      };
    });
};

const scrollIntoView = (id: string) => {
  const elem = document.querySelector(id);
  if (elem) {
    elem.scrollIntoView({ behavior: 'smooth' });
  }
};

export const TableOfContents = ({ container, selector }: TableOfContentsProps) => {
  const [items, setItems] = React.useState<Item[]>([]);
  const defaultSelector = 'h2';

  React.useEffect(() => {
    const $container = container ? document.querySelector(container) : document;
    if (!$container) return;
    const $headings = getHeadings($container, selector, defaultSelector);
    setItems($headings);
  }, [container, selector]);

  return (
    <div className={styles.container}>
      <ol>
        {items.map(heading => (
          <li key={heading.text} className={cx(heading.type === 'H3' && styles.subLevel)}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              className={styles.link}
              variant="Subtle"
              onClick={() => scrollIntoView(heading.id)}
            >
              {heading.text}
            </Link>
          </li>
        ))}
      </ol>
    </div>
  );
};
