// @flow strict
import * as React from 'react';
import { FormattedContentBlock } from '@ansarada/ace-react';
import styles from './PageWrapper.scss';

type Props = {
  children: React.Node,
};

export const PageWrapper = ({ children }: Props) => (
  <div className={styles.wrapper}>
    <FormattedContentBlock>{children}</FormattedContentBlock>
  </div>
);
