// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ExternalLink = (): React.Element<*> => (
  <>
    <path d="M18.847 1.146c-0.090-0.090-0.215-0.146-0.353-0.146-0 0-0.001 0-0.001 0h-6c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h4.793l-7.643 7.632c-0.090 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146v0l7.643-7.632v4.793c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6c0-0 0-0 0-0.001 0-0.138-0.056-0.263-0.146-0.353v0z"></path>
    <path d="M18.496 10.005c-0.276 0-0.5 0.224-0.5 0.5v0 5.972c-0.001 0.844-0.685 1.527-1.528 1.528h-12.959c-0.844-0.001-1.527-0.685-1.528-1.528v-12.959c0.001-0.844 0.685-1.527 1.528-1.528h5.987c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-6.016c-1.381 0.016-2.5 1.143-2.5 2.528v12.959c0 1.394 1.134 2.528 2.528 2.528h12.959c1.385 0 2.513-1.119 2.528-2.5v0-6c0-0.276-0.224-0.5-0.5-0.5h-0z"></path>
  </>
);
