// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const CrossLarge = (): React.Element<*> => (
  <>
    <path d="M10.709 10l6.146-6.146c0.091-0.090 0.146-0.216 0.146-0.354 0-0.276-0.224-0.5-0.5-0.5-0.138 0-0.263 0.056-0.354 0.146l-6.147 6.147-6.146-6.147c-0.090-0.090-0.215-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l6.146 6.146-6.146 6.146c-0.091 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146l6.146-6.146 6.147 6.146c0.090 0.090 0.215 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354v0z"></path>
  </>
);
