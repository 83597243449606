// @flow strict
/* eslint react/no-unused-prop-types: off */
import * as React from 'react';
import styles from './tabContent.styles.scss';
/*
  Tab component, the tab is returned inside TABS so we can determine which is active
*/

export type TabPropType = {
  id: string,
  /** Title of the tab */
  header: string,
  /** Identifier */
  name?: string,
  /** State */
  active?: boolean,
  /** JSX content */
  children?: React.Node,
};

/** @ignore */
const Tab = (props: TabPropType) => (
  <div role="tabpanel" aria-labelledby={props.id} id={props.id} className={styles.tabContent}>
    {props.children}
  </div>
);

export default Tab;
