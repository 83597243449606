// @flow strict
import * as React from 'react';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

export type RowPropType = {|
  children?: React.Node,
|};

/** @ignore */
const Row = (props: RowPropType) => <tr className={styles.row}>{props.children}</tr>;

export { Row };
