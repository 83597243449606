// @flow strict
import * as React from 'react';
import { message, Message, FormattedContentBlock } from '@ansarada/ace-react';

import { CodeSnippet } from '../../components';
import styles from './Keyboard.scss';

const getKeyExample = `
const key = getKey(event: SyntheticKeyboardEvent<*>);
`.trim();

const keyCodeExample = `
const key = getKey(event);
if (key === KeyCode.ArrowDown) {
  //
}
`.trim();

const keyEventExample = `
if (KeyEvent.isArrowDown(event)) {
  //
};
`.trim();

const flowExample = `
// KeyEvent method typo will generate a Flow error
if (KeyEvent.isArrowDownz(event)) {}; // Error

// KeyCode typo will not generate a Flow error
if (getKey(event) === KeyCode.ArrowDownz) {} // OK
`.trim();

const CodeBlock = ({ children }: { children: string }) => (
  <CodeSnippet snippet={children} language="jsx" />
);

export const Keyboard = () => (
  <div className={styles.container}>
    <FormattedContentBlock>
      <h1>Keyboard Handling</h1>

      <Message variant={message.Variants.Information}>
        Note that this documentation refers to the ACE React implementation only and is not
        available in the ACE implementation.
      </Message>

      <p>
        The <code>components/helpers/key.js</code> module helps with keyboard handling, such as
        normalising different keyboard codes across browsers.
      </p>

      <h2>Module Exports</h2>

      <p>
        The <code>key</code> module provides the following exports:
      </p>

      <h3>getKey()</h3>

      <p>Takes an event and returns a standard normalised code for events we support.</p>

      <CodeBlock>{getKeyExample}</CodeBlock>

      <h3>KeyCode</h3>

      <p>Provides a map of standard codes we support.</p>

      <CodeBlock>{keyCodeExample}</CodeBlock>

      <h3>KeyEvent</h3>

      <p>Provides a map of helper functions to check for key events.</p>

      <CodeBlock>{keyEventExample}</CodeBlock>

      <h2>Key Values</h2>

      <p>
        For the key values we use see the{' '}
        <a href="https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values">
          MDN Keyboard Event Key Values
        </a>
        .
      </p>

      <p>
        Our standard key codes match the documented key codes, with the exception of the space key.
        We use the string <code>Space</code> rather than an actual &quot;space&quot; character.
      </p>

      <h2>Note about Flow</h2>

      <p>
        Where possible use the <code>KeyEvent</code> methods as they also provide type safety with
        Flow.
      </p>

      <p>For example:</p>

      <CodeBlock>{flowExample}</CodeBlock>
    </FormattedContentBlock>
  </div>
);
