// @flow strict
import * as React from 'react';
import cx from 'classnames';
import type { testIdPropType } from '../../ace-internal/types/general';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Stack.scss';

type Props = {|
  ...testIdPropType,
  /** Adjust spacing based on Shoelace spaces */
  spacing: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | 'None',
  /** This is the documentation for this prop */
  children: React.Node,
|};

/**
 * The `Stack` component creates a vertical navigation
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Button, Page
 * @category Layout
 */
const Stack = ({ 'data-test-id': testId, children, spacing }: Props) => {
  return (
    <div data-test-id={testId} className={cx([styles.container, styles[`is${spacing}`]])}>
      {children}
    </div>
  );
};

Stack.defaultProps = {
  spacing: '400',
};

export { Stack };
