// @flow strict
/* eslint-disable prettier/prettier */
import * as React from 'react';

const loadComponent = (name: string, type: string): Promise<{ default: { fixtures: any } }> =>
  // $FlowFixMe
  import(`../../../ace/components/${name}/${type}`);

export const loadMdx = (name: string): Promise<{ default: React.Node }> =>
  // $FlowFixMe
  import(`../../../shoelace/docs/${name}.mdx`);

export default loadComponent;
