// @flow strict
/* eslint css-modules/no-unused-class: off */

import * as React from 'react';
import classnames from 'classnames/bind';

import type { BaseButtonProps, ButtonProps, SubmitButtonProps, LinkProps } from '../Button.types';

import LoadingIndicator from '../../LoadingIndicator';
import styles from '../style.scss';

const cx = classnames.bind(styles);

type BaseButtonPropType = {
  type?: 'link' | 'submit' | 'button' | 'customLink',
  variant?: 'Secondary' | 'Primary' | 'Caution' | 'Ghost' | 'Link' | 'CautionLink' | 'SubtleLink',
  ...BaseButtonProps,
  ...ButtonProps,
  ...SubmitButtonProps,
  ...LinkProps,
};

/**
 * @ignore
 */
export const BaseButton = ({
  id,
  disabled = false,
  download,
  size = 'Regular',
  variant = 'Primary',
  type = 'button',
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  'aria-expanded': ariaExpanded,
  'aria-controls': ariaControls,
  'aria-labelledby': ariaLabelledby,
  icon,
  children,
  className,
  pressed,
  loading = false,
  onBlur,
  onClick,
  onFocus,
  onMouseDown,
  onMouseUp,
  onMouseOver,
  onMouseOut,
  onMouseEnter,
  onMouseLeave,
  onKeyDown,
  buttonRef,
  form,
  href,
  target,
  customLink,
}: BaseButtonPropType) => {
  const classes = cx('button', className, `is${variant}`, `is${size}`, {
    isIcon: icon !== undefined,
    [`isIconBefore`]: icon !== undefined && children !== undefined,
    isIconOnly: icon !== undefined && children === undefined,
    isPressed: pressed,
    isLoading: loading,
    isDisabled: disabled,
    isLinkButton: type === 'link',
  });

  // if pressed is undefined then the button is not a toggle button
  // otherwise, it is likely being controlled as a toggle button see
  // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_button_role
  const pressedAttrs = typeof pressed === 'undefined' ? {} : { 'aria-pressed': pressed };

  let content = children;

  if (icon) {
    const zeroWidthSpace = '\u200B';
    const textContent = children && <span className={styles.iconText}>{children}</span>;
    const iconContent = React.cloneElement(icon, {
      className: styles.buttonIcon,
    });

    // Include a zero-width space in front to solve two problems:
    // 1. if there are no children, the height/line-height stays consistent
    // 2. Fixes an issue where the icon line-height is different to text-line-height
    // and changes the alignment of the button if the icon comes before any text content
    content = (
      <React.Fragment>
        {zeroWidthSpace}
        {iconContent}
        {textContent}
      </React.Fragment>
    );
  }

  if (loading) {
    // Make sure to continue to render the original content so that the width doesn't change when
    // the loading prop is passed in
    content = (
      <React.Fragment>
        <span className={styles.loadingContainer}>
          <LoadingIndicator
            size={size === 'Compact' ? 12 : 20}
            className={styles.loader}
            dark={variant === 'Ghost' || variant === 'Link' || variant === 'SubtleLink'}
          />
        </span>
        <span className={styles.loadingContent}>{content}</span>
      </React.Fragment>
    );
  }

  const sharedProps = {
    disabled: disabled || loading,
    'data-test-id': testId,
    'data-ansarada-ccd': ansaradaCCD || undefined,
    'aria-expanded': ariaExpanded || undefined,
    'aria-controls': ariaControls || undefined,
    'aria-labelledby': ariaLabelledby || undefined,
    onClick,
    onBlur,
    onFocus,
    onMouseDown,
    onMouseUp,
    onMouseOver,
    onMouseOut,
    onMouseEnter,
    onMouseLeave,
    onKeyDown,
    className: classes,
    ref: buttonRef,
    id,
  };

  if (type === 'link') {
    return (
      <a
        {...sharedProps}
        href={href}
        target={target}
        rel={target ? 'noopener noreferrer' : undefined}
        download={download}
      >
        {content}
      </a>
    );
  }

  if (type === 'customLink') {
    if (!customLink) {
      return null;
    }

    return (
      <customLink.component
        {...sharedProps}
        {...customLink.props}
        target={target}
        rel={target ? 'noopener noreferrer' : undefined}
        download={download}
      >
        {content}
      </customLink.component>
    );
  }

  return (
    <button {...sharedProps} {...pressedAttrs} type={type} form={form}>
      {content}
    </button>
  );
};
