// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const MenuContextual = (): React.Element<*> => (
  <>
    <path d="M11.502 14h-3c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h3c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
    <path d="M11.502 4.5h-3c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h3c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
    <path d="M11.502 9.25h-3c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h3c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
  </>
);
