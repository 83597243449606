// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Help = (): React.Element<*> => (
  <>
    <path d="M10.374 13.625c-0.096-0.087-0.225-0.141-0.365-0.141-0.001 0-0.003 0-0.004 0h0c-0.001-0-0.003-0-0.004-0-0.142 0-0.271 0.053-0.369 0.141l0.001-0c-0.107 0.094-0.163 0.223-0.163 0.392v0.449c0 0.168 0.054 0.297 0.156 0.385s0.23 0.133 0.379 0.133 0.272-0.044 0.375-0.133 0.152-0.217 0.152-0.385v-0.449c0-0.168-0.050-0.298-0.157-0.392z"></path>
    <path d="M10.111 4.974h-0.098c-0.625 0-1.159 0.217-1.598 0.655s-0.659 0.969-0.659 1.594v0.126c0 0.168 0.055 0.299 0.162 0.392 0.097 0.087 0.226 0.141 0.367 0.141 0.002 0 0.003 0 0.005-0h-0c0.001 0 0.003 0 0.004 0 0.141 0 0.269-0.053 0.365-0.141l-0 0c0.107-0.094 0.159-0.224 0.159-0.392v-0.126c0-0.327 0.119-0.605 0.352-0.838s0.516-0.348 0.842-0.348h0.098c0.327 0 0.608 0.115 0.841 0.348s0.351 0.512 0.351 0.838v0.56c0 0.261-0.155 0.546-0.463 0.854-0.112 0.103-0.262 0.233-0.449 0.392s-0.318 0.278-0.392 0.357c-0.091 0.1-0.176 0.21-0.253 0.326l-0.006 0.010c-0.096 0.14-0.168 0.304-0.204 0.481l-0.002 0.009c-0.037 0.182-0.059 0.404-0.059 0.665v1.091c0 0.177 0.054 0.31 0.156 0.399s0.231 0.132 0.38 0.132 0.272-0.044 0.375-0.133 0.151-0.222 0.151-0.399v-1.091c0-0.27 0.053-0.492 0.151-0.665s0.28-0.359 0.541-0.56 0.43-0.344 0.505-0.427q0.63-0.686 0.631-1.442v-0.56c0-0.625-0.22-1.155-0.658-1.594s-0.971-0.655-1.596-0.655z"></path>
    <path d="M10.006 1.99c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM10.006 16.99c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"></path>
  </>
);
