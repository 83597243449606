// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ChevronCollapsed = (): React.Element<*> => (
  <>
    <path d="M7.752 15c-0 0-0 0-0 0-0.276 0-0.5-0.224-0.5-0.5 0-0.138 0.056-0.263 0.146-0.354l4.147-4.146-4.147-4.146c-0.090-0.090-0.146-0.216-0.146-0.354 0-0.276 0.224-0.5 0.5-0.5 0.138 0 0.263 0.056 0.354 0.146l4.5 4.5c0.091 0.090 0.146 0.216 0.146 0.354s-0.056 0.263-0.146 0.354l-4.5 4.5c-0.090 0.090-0.215 0.146-0.353 0.146-0 0-0.001 0-0.001 0h0z"></path>
  </>
);
