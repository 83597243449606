// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileImage = (): React.Element<*> => (
  <>
    <path d="M16.506 0.981h-13c-1.379 0-2.5 1.121-2.5 2.5v13c0 1.378 1.121 2.5 2.5 2.5h13c1.378 0 2.5-1.122 2.5-2.5v-13c0-1.379-1.122-2.5-2.5-2.5zM18.006 16.481c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M16.006 2.981h-12c-0.552 0.002-0.998 0.448-1 1v10.5c0 0 0 0 0 0 0 0.184 0.050 0.356 0.138 0.504l-0.003-0.005h1.865v-0.641h0.203v-0.453h0.297v-0.375h0.179l-0.308-0.28 1.38-0.32c-0.16-0.555-0.471-1.558-0.72-1.868 0 0 1.204-0.133 2.492 0.252-0.069-0.609-0.182-1.127-0.182-1.127 1.381 0.017 2.095 0.405 2.281 0.523-0.265-1.118-0.549-1.935-0.549-1.935s2.179-0.134 4.479 3.063c0.762-0.547 1.681-0.703 2.423-0.719l0.024 0.023v-7.143c-0.002-0.552-0.448-0.998-1-1h-0zM5.756 7.481c-0.967 0-1.75-0.783-1.75-1.75s0.783-1.75 1.75-1.75c0.967 0 1.75 0.783 1.75 1.75v0c0 0.967-0.783 1.75-1.75 1.75v0z"></path>
  </>
);
