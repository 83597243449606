// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Disable = (): React.Element<*> => (
  <path
    fill="#fb0069"
    d="M10.002 1.99c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM3.002 9.99c0-3.866 3.134-7 7-7 0.003 0 0.007 0 0.010 0 1.754 0 3.356 0.647 4.582 1.716l-0.008-0.007-9.875 9.875c-1.062-1.217-1.709-2.819-1.709-4.573 0-0.004 0-0.008 0-0.012v0.001zM10.002 16.99c-0.003 0-0.007 0-0.011 0-1.753 0-3.356-0.647-4.581-1.716l0.008 0.007 9.874-9.875c1.062 1.217 1.709 2.82 1.709 4.573 0 0.004 0 0.007 0 0.011v-0.001c0 3.866-3.134 7-7 7z"
  ></path>
);
