// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const EmailTick = (): React.Element<*> => (
  <>
    <path
      fill="#93b703"
      d="M13.498 7.99c-3.037 0-5.5 2.463-5.5 5.5s2.463 5.5 5.5 5.5 5.5-2.462 5.5-5.5-2.462-5.5-5.5-5.5zM16.68 16.672c-0.811 0.808-1.929 1.308-3.164 1.308-2.476 0-4.482-2.007-4.482-4.482s2.007-4.482 4.482-4.482c2.476 0 4.482 2.007 4.482 4.482 0 0.63-0.13 1.229-0.364 1.773l0.011-0.029c-0.239 0.556-0.564 1.031-0.965 1.431l-0 0z"
    ></path>
    <path
      fill="#93b703"
      d="M15.361 10.683c-0.058-0.025-0.126-0.040-0.197-0.040-0.205 0-0.381 0.123-0.458 0.3l-0.001 0.003-1.637 3.818-0.821-1.232c-0.091-0.133-0.243-0.22-0.414-0.22-0.276 0-0.5 0.224-0.5 0.5 0 0.102 0.031 0.197 0.083 0.276l-0.001-0.002 1.333 2c0.091 0.135 0.243 0.223 0.416 0.223v0c0.016 0 0.031 0 0.046-0.002 0.186-0.018 0.341-0.135 0.412-0.298l0.001-0.003 2-4.667c0.026-0.058 0.040-0.126 0.040-0.197 0-0.205-0.123-0.381-0.3-0.458l-0.003-0.001z"
    ></path>
    <path d="M8.598 10.99h-5.575c-0.568-0.001-1.028-0.461-1.029-1.029v-6.552l5.7 4.275c0.083 0.062 0.187 0.1 0.3 0.1s0.217-0.038 0.301-0.101l-0.001 0.001 5.7-4.275v4.603c0.371 0.034 0.711 0.099 1.039 0.193l-0.039-0.010v-5.165c0-1.116-0.895-2.025-2.001-2.041h-9.969c-1.076 0-1.959 0.847-2.025 1.913v7.173c0.066 1.066 0.949 1.914 2.025 1.914h5.181c0.11-0.385 0.244-0.717 0.407-1.031l-0.014 0.030zM3.023 2.052h9.941c0 0 0 0 0 0 0.293 0 0.557 0.123 0.744 0.32l0 0-5.715 4.287-5.715-4.287c0.187-0.197 0.452-0.32 0.745-0.32 0 0 0 0 0 0h-0z"></path>
  </>
);
