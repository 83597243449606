// @flow strict
import * as React from 'react';
import classnames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './TypographySample.scss';

const Variants = Object.freeze({
  'text-400': 'text-400',
  'text-450': 'text-450',
  'text-500': 'text-500',
  'text-550': 'text-550',
  'text-600': 'text-600',
  'text-650': 'text-650',
  'text-700': 'text-700',
  'text-750': 'text-750',
  'text-800': 'text-800',
  'text-900': 'text-900',
  'text-950': 'text-950',
});

type Props = {|
  children: React.Node,
  variant: $Values<typeof Variants>,
|};

export const TypographySample = ({ children, variant }: Props) => (
  <div className={classnames(styles.container, styles[variant])}>{children}</div>
);
