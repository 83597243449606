// @flow strict

import React from 'react';

import { Button, ButtonGroup, Dialog, icon, Icon, TruncateText } from '../components';

type State = {
  showDefaultExample: boolean,
  showCCDExample: boolean,
  showDialogTail: boolean,
};

class TruncateTextTest extends React.Component<{}, State> {
  constructor() {
    super();
    this.state = {
      showDefaultExample: false,
      showCCDExample: false,
      showDialogTail: false,
    };
  }

  render() {
    const filename = '0123456789012345678901234567890123456789012345678901234567890.pdf';
    const truncated = <TruncateText truncatePosition="Middle">{`${filename}?`}</TruncateText>;
    const truncatedTail = <TruncateText truncatePosition="Tail">{`${filename}?`}</TruncateText>;
    const truncatedCCD = (
      <TruncateText truncatePosition="Middle" data-ansarada-ccd>
        {`${filename}?`}
      </TruncateText>
    );
    const title = string => <span>Are you sure you want to delete {string}</span>;

    return (
      <div>
        <div style={{ width: '250px', border: '1px solid black' }}>
          Tail truncation example:
          <TruncateText truncatePosition="Tail">{filename}</TruncateText>
        </div>

        <div style={{ width: '250px', border: '1px solid black' }}>
          Middle truncation example:
          <TruncateText truncatePosition="Middle">{filename}</TruncateText>
        </div>

        <div style={{ display: 'flex', width: '450px', border: '1px solid black' }}>
          <span style={{ width: '200px', flex: '0 0 auto' }}>Flex truncation example:</span>
          <TruncateText truncatePosition="Middle">{filename}</TruncateText>
        </div>

        <ButtonGroup>
          <Button
            icon={<Icon glyph={icon.Glyphs.ObjectsComment} />}
            onClick={() => {
              this.setState({ showDefaultExample: true });
            }}
          >
            Truncation in dialog header
          </Button>
          <Button
            icon={<Icon glyph={icon.Glyphs.ObjectsComment} />}
            onClick={() => {
              this.setState({ showCCDExample: true });
            }}
          >
            Truncation in dialog header inc. CCD
          </Button>
          <Button
            icon={<Icon glyph={icon.Glyphs.ObjectsComment} />}
            onClick={() => {
              this.setState({ showDialogTail: true });
            }}
          >
            Tail Truncation in dialog header
          </Button>
        </ButtonGroup>
        {this.state.showDefaultExample && (
          <Dialog
            title={title(truncated)}
            size="Small"
            onClose={() => {}}
            footerPrimaryActions={[
              <Button
                key="1"
                onClick={() => {
                  this.setState({ showDefaultExample: false });
                }}
              >
                Close
              </Button>,
            ]}
          >
            This document will be permanently deleted.
          </Dialog>
        )}
        {this.state.showCCDExample && (
          <Dialog
            title={title(truncatedCCD)}
            size="Small"
            onClose={() => {}}
            footerPrimaryActions={[
              <Button
                key="1"
                onClick={() => {
                  this.setState({ showCCDExample: false });
                }}
              >
                Close
              </Button>,
            ]}
          >
            This document will be permanently deleted.
          </Dialog>
        )}
        {this.state.showDialogTail && (
          <Dialog
            title={title(truncatedTail)}
            size="Small"
            onClose={() => {}}
            footerPrimaryActions={[
              <Button
                key="1"
                onClick={() => {
                  this.setState({ showDialogTail: false });
                }}
              >
                Close
              </Button>,
            ]}
          >
            This document will be permanently deleted.
          </Dialog>
        )}
      </div>
    );
  }
}

export default TruncateTextTest;
