// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileWord = (): React.Element<*> => (
  <path
    fill="#2c5797"
    d="M18.998 3.481c0-1.379-1.121-2.5-2.5-2.5h-13c-1.378 0-2.5 1.121-2.5 2.5v13c0 1.378 1.122 2.5 2.5 2.5h13c1.379 0 2.5-1.122 2.5-2.5zM17.998 16.481c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0zM15.083 4.729c0.109 0.135 0.145 0.298 0.105 0.488l-2.010 9.406c-0.060 0.29-0.245 0.437-0.555 0.437h-0.27c-0.15 0-0.273-0.044-0.367-0.129-0.088-0.076-0.154-0.176-0.186-0.29l-0.001-0.004-1.8-7.695-1.8 7.696c-0.060 0.28-0.245 0.422-0.555 0.422h-0.27c-0.31 0-0.495-0.146-0.555-0.437l-2.010-9.406q-0.060-0.285 0.105-0.488t0.465-0.202q0.435 0 0.54 0.45l1.62 7.545 1.77-7.59c0.070-0.27 0.26-0.404 0.57-0.404h0.24c0.31 0 0.5 0.134 0.57 0.404l1.77 7.589 1.62-7.545q0.090-0.45 0.54-0.45 0.3 0 0.465 0.203z"
  ></path>
);
