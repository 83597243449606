// @flow strict
import * as React from 'react';

import formatDate from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import endOfMonth from 'date-fns/endOfMonth';
import type { ansaradaCCDPropType } from '../../../ace-internal/types/general';
import styles from './DatepickerCalendar.scss';
import {
  YMToDate,
  addMToYM,
  formatDateAsYMD,
  getDefaultDate,
  getMonth,
  getYear,
} from './DateFunctions';

import { Icon, Glyphs as IconGlyphs } from '../../Icon';
import { Button } from '../../Button';
import { DatepickerMonth } from './DatepickerMonth';
import { useDisplay } from './DatepickerContext';

type State = {|
  currentYear: number,
  currentMonth: number,
|};

const ignoreFocus = (event: SyntheticMouseEvent<>) => {
  event.preventDefault();
};

type GetCurrentMonth = ({
  selectedDate: ?Date,
  minDate: Date,
  maxDate: Date,
}) => State;
const getCurrentMonth: GetCurrentMonth = ({ selectedDate, minDate, maxDate }) => {
  let currentDate;

  if (selectedDate) {
    currentDate = selectedDate;
  } else {
    currentDate = getDefaultDate({
      year: getYear(selectedDate),
      month: getMonth(selectedDate),
    });
  }

  if (isBefore(currentDate, minDate)) {
    currentDate = minDate;
  } else if (isAfter(currentDate, maxDate)) {
    currentDate = maxDate;
  }

  const currentDateYMD = formatDateAsYMD(currentDate);

  return {
    currentYear: currentDateYMD.year,
    currentMonth: currentDateYMD.month,
  };
};

type Calendar = {|
  currentMonth: number,
  currentYear: number,
|};

/**
 * @ignore
 */
const DatepickerCalendar = ({ 'data-ansarada-ccd': ansaradaCCD }: { ...ansaradaCCDPropType }) => {
  const display = useDisplay();
  const initialState: State = getCurrentMonth({
    maxDate: display.maxDate,
    minDate: display.minDate,
    selectedDate: display.selectedDate,
  });

  const [state, setState] = React.useState<Calendar>({ ...initialState });

  React.useEffect(() => {
    setState({
      currentYear: initialState.currentYear,
      currentMonth: initialState.currentMonth,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display.selectedDate]);

  const selectNextMonth = () => {
    const { year: currentYear, month: currentMonth } = addMToYM(
      state.currentYear,
      state.currentMonth,
      1,
    );

    setState({ currentYear, currentMonth });
  };

  const selectPrevMonth = () => {
    const { year: currentYear, month: currentMonth } = addMToYM(
      state.currentYear,
      state.currentMonth,
      -1,
    );

    setState({ currentYear, currentMonth });
  };

  const currentMonthDate = YMToDate(state.currentYear, state.currentMonth);

  return (
    <div className={styles.datepicker}>
      <div className={styles.header} data-ansarada-ccd={ansaradaCCD || undefined}>
        <div className={styles.title}>
          {formatDate(currentMonthDate, 'MMMM')} {formatDate(currentMonthDate, 'yyyy')}
        </div>

        <div className={styles.buttonContainer}>
          <Button
            disabled={
              isBefore(currentMonthDate, display.minDate) ||
              isEqual(currentMonthDate, display.minDate)
            }
            icon={<Icon glyph={IconGlyphs.ControlArrowLeft} text="previous month" />}
            variant="Ghost"
            onClick={selectPrevMonth}
            onMouseDown={ignoreFocus}
            size="Compact"
          />

          <Button
            disabled={isAfter(endOfMonth(currentMonthDate), display.maxDate)}
            icon={<Icon glyph={IconGlyphs.ControlArrowRight} text="next month" />}
            variant="Ghost"
            onClick={selectNextMonth}
            onMouseDown={ignoreFocus}
            size="Compact"
          />
        </div>
      </div>

      <DatepickerMonth currentMonth={currentMonthDate} />
    </div>
  );
};

export { DatepickerCalendar };
