// @flow strict
import Toaster from './Toaster';
import { Toast } from './Toast';
import { BaseToast } from './BaseToast';

export type ToastType = {|
  id: string,
  text: string,
  actionText?: string,
  onAction?: () => void,
  timeoutDuration?: number,
  variant?: 'Neutral' | 'Success' | 'Caution' | 'Error' | 'Info',
|};

const Position = Object.freeze({
  TOP: 'top',
  BOTTOM: 'bottom',
});

export { Toast, BaseToast, Toaster, Position };
