// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { useUncontrolled } from 'uncontrollable';

import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { Option } from './Option';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

export type BaseProps = {|
  /** Sets the data-test-id for e2e testing */
  ...testIdPropType,
  /** Sets the data-ansarada-ccd attrib */
  ...ansaradaCCDPropType,
  status?: 'Good' | 'Bad',
  /** Sets the size of the component */
  size?: 'Small' | 'Medium' | 'Large',
  /** An optional id for connecting labels to select elements */
  id?: string,
  disabled?: boolean,
  /** Adds extra classes in the select element */
  className?: string,
  children?: React.ChildrenArray<
    React.Element<typeof Option> | React.Element<'optgroup'> | void | false,
  >,
|};

type ControlledValue<T> = {|
  value?: T,
  onChangeValue: (value: T) => void,
|};
type UncontrolledValue<T> = {|
  defaultValue: T,
  onChangeValue?: (value: T) => void,
|};
type Value<T> = ControlledValue<T> | UncontrolledValue<T>;

type ControlledProps = {|
  ...BaseProps,
  ...ControlledValue<string>,
|};

type Props = {|
  ...BaseProps,
  ...Value<string>,
|};

const ControlledSelect = ({
  size = 'Medium',
  id,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  value,
  disabled = false,
  status = 'Good',
  className,
  children,
  onChangeValue,
}: ControlledProps) => {
  const classes = classnames(
    styles.select,
    styles[`is${size}`],
    { [styles.isBad]: status === 'Bad' },
    className,
  );

  return (
    <select
      id={id}
      data-test-id={testId}
      data-ansarada-ccd={ansaradaCCD || undefined}
      className={classes}
      value={value}
      onChange={e => onChangeValue(e.target.value)}
      disabled={disabled}
      aria-invalid={status === 'Bad'}
    >
      {children}
    </select>
  );
};

/**
 * Select allows the user to make a single selection within a group of options.
 *
 * List items can be grouped under a sub-heading; the sub-heading itself can not be selected.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  FormGrid, Select, TextInput
 * @category Form
 */
const Select = (props: Props): React$Element<typeof ControlledSelect> => {
  const allProps = useUncontrolled(props, {
    value: 'onChangeValue',
  });
  return <ControlledSelect {...allProps} />;
};

export { Select, Option };
