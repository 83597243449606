// @flow strict

import * as React from 'react';
import classnames from 'classnames';

import { Button, SubmitButton } from '../../components/Button';
import { ButtonGroup } from '../../components/ButtonGroup';
import { ContentType } from '../../ace-internal/types/forms';
import { ExpanderPanel } from '../../components/ExpanderPanel';
import { FormItem } from '../FormItem';
import { FormSubItem } from '../FormSubItem';
import { InputInfo } from '../InputInfo';
import { InputState } from '../InputState';
import { Label } from '../Label';
import { Message } from '../../components/Message';
import { Select } from '../../components/Select';
import { TableLegacy } from '../Table';
import { TextInput } from '../../components/TextInput';
import { Tree } from '../../components/Tree';

type FormContentChildren = React.ChildrenArray<
  | React.Element<typeof Button>
  | React.Element<typeof SubmitButton>
  | React.Element<typeof ButtonGroup>
  | React.Element<typeof ExpanderPanel>
  | React.Element<typeof FormSubItem>
  | React.Element<typeof InputInfo>
  | React.Element<typeof InputState>
  | React.Element<typeof Label>
  | React.Element<typeof Message>
  | React.Element<typeof Select>
  | React.Element<typeof TableLegacy>
  | React.Element<typeof TextInput>
  | React.Element<typeof Tree>
  | void
  | false,
>;

export type FormContentGroupProps = {
  className?: string,
  contentType?: $Values<typeof ContentType>,
  children?: FormContentChildren,
};

/** @ignore */
const FormContentGroup = (props: FormContentGroupProps) => {
  const { className, contentType = ContentType.Text, children, ...rest } = props;

  const classes = classnames(
    'ace-form-group',
    [`ace-form-group-${contentType.toLowerCase()}`],
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <div className={classes} {...rest}>
      <FormItem contentType={contentType}>{children}</FormItem>
    </div>
  );
};

export { FormContentGroup, ContentType };
