// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { FormSingleGroup } from '../FormSingleGroup';
import { FormSection } from '../FormSection';
import { FormContentGroup } from '../FormContentGroup';
import { FormMultiGroup } from '../FormMultiGroup';

export const Variants = Object.freeze({
  Filter: 'Filter',
  Inline: 'Inline',
  SideLabel: 'SideLabel',
  TopLabel: 'TopLabel',
});

export const Method = Object.freeze({
  Get: 'Get',
  Post: 'Post',
});

type FormChildren = React.ChildrenArray<
  | React.Element<typeof FormSingleGroup>
  | React.Element<typeof FormSection>
  | React.Element<typeof FormContentGroup>
  | React.Element<typeof FormMultiGroup>
  | void
  | false
  // ACEREACT-499: Overright allowed children because mips form patterns are not supported until ACE 3.0
  | React.Element<any>,
>;

type FormPropType = {
  method?: $Values<typeof Method>,
  variant?: $Values<typeof Variants>,
  action?: string,
  className?: string,
  children?: FormChildren,
  onSubmit?: (event: SyntheticEvent<HTMLFormElement>) => any,
};

/** @ignore */
const Form = (props: FormPropType) => {
  const {
    method,
    variant = Variants.SideLabel,
    action,
    className,
    children,
    onSubmit,
    ...rest
  } = props;

  const classes = classnames('ace-form', `ace-form-${variant.toString().toLowerCase()}`, className);

  // eslint-disable-next-line consistent-return
  const onSubmitEvent = (event: SyntheticEvent<HTMLFormElement>) => {
    if (onSubmit) {
      onSubmit(event);
    }

    if (!action) {
      event.preventDefault();
      return false;
    }
  };

  return (
    <form
      className={classes}
      action={action || '#'}
      method={method !== undefined && method !== null ? method.toString().toUpperCase() : undefined}
      onSubmit={onSubmitEvent}
      // $FlowFixMe 0.112.0 - inexact rest of object pattern
      {...rest}
    >
      {children}
    </form>
  );
};

export { Form };
