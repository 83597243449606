// @flow strict
import * as React from 'react';
import { Card, FormattedContentBlock } from '../components';

const CardTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>Card</h2>
    </FormattedContentBlock>

    <Card>
      <p>Paragraph lorem ipsum.</p>
    </Card>

    <Card className="card-example" testId="card-example">
      <FormattedContentBlock>
        <h2>Heading 2</h2>
        <p>Paragraph lorem ipsum.</p>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">Link</a>
        <p>
          Paragraph lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices, risus eu
          fermentum laoreet, nulla neque ornare dui, ut aliquam lorem nibh ut ante. Class aptent
          taciti sociosqu.
        </p>
      </FormattedContentBlock>
    </Card>

    <div style={{ maxWidth: '300px' }}>
      <Card>
        <FormattedContentBlock>
          <p>Paragraph lorem.</p>
        </FormattedContentBlock>
      </Card>
      <Card>
        <FormattedContentBlock>
          <p>
            Paragraph lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultrices, risus eu
            fermentum laoreet, nulla neque ornare dui, ut aliquam lorem nibh ut ante. Class aptent
            taciti sociosqu.
          </p>
        </FormattedContentBlock>
      </Card>
    </div>
  </div>
);

export default CardTest;
