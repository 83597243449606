// @flow strict
import * as React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

export type HintContentType = React.ChildrenArray<string | React$Element<'span'>>;

export type HintPropsType = {|
  children: HintContentType,
|};

/**
 * @ignore
 */

const Hint = ({ children }: HintPropsType) => <div className={styles.hintText}>{children}</div>;

export { Hint };
