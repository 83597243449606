// @flow strict

import * as React from 'react';
import classnames from 'classnames';

import { FormSubItem } from '../FormSubItem';
import { ContentType } from '../../ace-internal/types/forms';
import { Datepicker } from '../../components/Datepicker';
import { Autocomplete } from '../../components/Autocomplete';
import { AceText } from '../AceText';
import { Label } from '../Label';
import { Select } from '../../components/Select';
import { TextInput } from '../../components/TextInput';
import { TextArea } from '../../components/TextArea';
import { Toggle } from '../../components/Toggle';
import { InputInfo } from '../InputInfo';
import { InputState } from '../InputState';
import { FormItem } from '../FormItem';
import { Checkbox } from '../../components/Checkbox';
import { Radio } from '../../components/Radio';
import { SubmitButton, Button } from '../../components/Button';
import { ButtonGroup } from '../../components/ButtonGroup';
import { RadioGroup } from '../../components/RadioGroup';
import { CheckboxGroup } from '../../components/CheckboxGroup';

type FormMultiGroupChildren = React.ChildrenArray<
  | React.Element<typeof Autocomplete>
  | React.Element<typeof AceText>
  | React.Element<typeof Button>
  | React.Element<typeof SubmitButton>
  | React.Element<typeof ButtonGroup>
  | React.Element<typeof Checkbox>
  | React.Element<typeof CheckboxGroup>
  | React.Element<typeof Datepicker>
  | React.Element<typeof FormSubItem>
  | React.Element<typeof InputInfo>
  | React.Element<typeof InputState>
  | React.Element<typeof Label>
  | React.Element<typeof Radio>
  | React.Element<typeof RadioGroup>
  | React.Element<typeof Select>
  | React.Element<typeof TextArea>
  | React.Element<typeof TextInput>
  | React.Element<typeof Toggle>
  | void
  | false,
>;

export type FormMultiGroupPropType = {
  legend: string,
  status?: 'Good' | 'Bad',
  legendId?: string,
  className?: string,
  contentType?: $Keys<typeof ContentType>,
  inline?: boolean,
  assistiveHeader?: boolean,
  children?: FormMultiGroupChildren,
};

/** @ignore */
const FormMultiGroup = (props: FormMultiGroupPropType) => {
  const {
    legend,
    legendId,
    className,
    contentType = ContentType.Mixed,
    inline = false,
    status = 'Good',
    assistiveHeader = false,
    children,
    ...rest
  } = props;

  const fieldsetClasses = classnames(
    'ace-form-group',
    `ace-form-group-${contentType.toLowerCase()}`,
    { 'ace-form-error': status === 'Bad' },
    { 'ace-form-group-inline': !!inline },
    className,
  );

  const legendClasses = classnames('ace-form-item', 'ace-form-item-label', {
    'ace-assistive': !!assistiveHeader,
  });

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <fieldset className={fieldsetClasses} {...rest}>
      <legend className={legendClasses} id={legendId}>
        <span>{legend}</span>
      </legend>
      <FormItem contentType={ContentType.Input}>{children}</FormItem>
    </fieldset>
  );
};

export { FormMultiGroup, ContentType };
