// @flow strict
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import type { TextInputType } from '../TextInput';
import type { TextAreaType } from '../TextArea';
import type { AutocompleteType } from '../Autocomplete';
import type { DatepickerType } from '../Datepicker';
import type { SelectType } from '../Select';
import type { RadioType } from '../Radio';
import type { RadioGroupType } from '../RadioGroup';
import type { CheckboxType } from '../Checkbox';
import type { CheckboxGroupType } from '../CheckboxGroup';
import type { ToggleType } from '../Toggle';
import type { EditorType } from '../Editor';
import { Label } from './Internal/Label';
import { Hint } from './Internal/Hint';
import { Errors } from './Internal/Errors';
import { BaseGridCell } from './Internal/BaseGridCell';

import type { ErrorContentType } from './Internal/Errors';
import type { HintContentType } from './Internal/Hint';
import type { BaseGridCellType } from './Internal/BaseGridCell';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

export type FormGridCellPropsType = {|
  ...BaseGridCellType,
  /** Sets error(s) to display close to form input */
  errors?: ErrorContentType,
  /** Text to describe the purpose of form input.  */
  labelText?: string,
  /** Hint text or span element */
  hint?: HintContentType,
  /** Hides the label or legend */
  hideLabel?: true,
  /** Force label html to be used for label text */
  useLabel?: boolean,
  /** Sets label htmlFor to this value or Legend's id to this. */
  labelId?: string,
  /** Don't flex grow keep size of content */
  autoFit?: boolean,
  /** Error render position */
  errorPosition?: 'Top' | 'Bottom',
  /** Hint render position */
  hintPosition?: 'Top' | 'Bottom',
|};

type SingleInputComponents =
  | EditorType
  | DatepickerType
  | TextInputType
  | TextAreaType
  | AutocompleteType
  | SelectType;

const isSingleFormInput = (item: React$Element<*>) => {
  const { name }: { name: SingleInputComponents } = item.type;

  switch (name) {
    case 'TextInput':
    case 'TextArea':
    case 'Select':
    case 'Autocomplete':
    case 'Datepicker':
    case 'Editor':
      return true;
    default:
      return false;
  }
};

type MultiInputComponents =
  | RadioType
  | RadioGroupType
  | CheckboxType
  | CheckboxGroupType
  | ToggleType;

const isMultiFormInput = (item: React$Element<*>) => {
  const { name }: { name: MultiInputComponents } = item.type;

  switch (name) {
    case 'Radio':
    case 'RadioGroup':
    case 'Checkbox':
    case 'CheckboxGroup':
    case 'Toggle':
      return true;
    default:
      return false;
  }
};

const addIdToChild = (item: React$Element<*>, labelId: string) => {
  if (item.props && !item.props.id && isSingleFormInput(item)) {
    return {
      element: React.cloneElement(item, {
        id: labelId,
      }),
      updated: true,
    };
  }

  return {
    element: item,
    updated: false,
  };
};

const addDescribedByToChild = (item: React$Element<*>, labelId: string) =>
  item.props && !item.props['aria-describedby'] && isMultiFormInput(item)
    ? React.cloneElement(item, {
        'aria-describedby': labelId,
      })
    : item;

const addIdtoFirstChild = (items: Array<React$Element<*>>, labelId) => {
  let alreadyAddedId = false;
  return items.map((item: React$Element<*>) => {
    if (!alreadyAddedId) {
      const result = addIdToChild(item, labelId);
      alreadyAddedId = result.updated;
      return result.element;
    }
    return item;
  });
};

const addDescribedByToChildren = (items: Array<React$Element<*>>, labelId) =>
  items.map((item: React$Element<*>) => addDescribedByToChild(item, labelId));

const renderChildren = (children: Array<React$Element<*>>, useLabel: boolean, labelId: string) =>
  useLabel ? addIdtoFirstChild(children, labelId) : addDescribedByToChildren(children, labelId);

const detectLabel = (children: Array<React$Element<*>>) =>
  children.reduce(
    (accumulator, currentValue: React$Element<*>) =>
      !accumulator ? isSingleFormInput(currentValue) : accumulator,
    false,
  );

const getFirstFormElementWithId = (children: Array<React$Element<*>>) =>
  children.find(
    (element: React$Element<*>) =>
      isSingleFormInput(element) &&
      element.props &&
      element.props.id &&
      typeof element.props.id === 'string',
  );

const getLabelId = (children: Array<React$Element<*>>): string => {
  const firstElement = getFirstFormElementWithId(children);
  return firstElement &&
    firstElement.props &&
    firstElement.props.id &&
    typeof firstElement.props.id === 'string'
    ? firstElement.props.id
    : uuid();
};

/**
 * @ignore
 */
const FormGridCell = (props: FormGridCellPropsType) => {
  const children = React.Children.toArray(props.children);

  const {
    errors,
    labelText,
    hint,
    hideLabel,
    'data-test-id': testId,
    'data-ansarada-ccd': ansaradaCCD,
    useLabel = detectLabel(children),
    labelId = getLabelId(children),
    autoFit = false,
    errorPosition = hideLabel ? 'Bottom' : 'Top',
    hintPosition = hideLabel ? 'Bottom' : 'Top',
  } = props;

  const labelComponent = labelText ? (
    <Label
      useLabel={useLabel}
      labelId={labelId}
      hide={hideLabel}
      hint={hintPosition === 'Top' ? hint : undefined}
      errors={errorPosition === 'Top' ? errors : undefined}
    >
      {labelText}
    </Label>
  ) : (
    undefined
  );

  const hintComponent =
    hintPosition === 'Bottom' && hint ? (
      <div className={styles.isTextBottom}>
        <Hint>{hint}</Hint>
      </div>
    ) : (
      undefined
    );
  const errorsComponent =
    errorPosition === 'Bottom' && errors ? (
      <div className={styles.isTextBottom} aria-live="polite">
        <Errors>{errors}</Errors>
      </div>
    ) : (
      undefined
    );

  return (
    <BaseGridCell data-test-id={testId} data-ansarada-ccd={ansaradaCCD} autoFit={autoFit}>
      {labelComponent}
      {renderChildren(children, useLabel, labelId)}
      {errorsComponent}
      {hintComponent}
    </BaseGridCell>
  );
};

export { FormGridCell };
