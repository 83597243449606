// @flow strict
import * as React from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { DropdownGroup } from '../Dropdown/DropdownGroup';
import { DropdownAction } from '../Dropdown/DropdownAction';
import type {
  Option,
  State,
  Msg,
  Group,
  OnChange,
  Size,
  Status,
  OnCreateOption,
} from './Select2.types';
import { fuseSearch } from '../../ace-internal/util/util';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Select2.styles.scss';

export function reducer(state: State, action: Msg): State {
  switch (action.type) {
    case 'TOGGLE':
      return {
        ...state,
        open: action.payload.open,
        filter: action.payload.filter ? action.payload.filter : state.filter,
      };
    case 'FILTER':
      return { ...state, filter: action.payload.filter, open: action.payload.open };
    case 'CLEAR_FILTER':
      return { ...state, filter: '', open: false };
    default:
      throw new Error(`There is no action: '${action.type}'`);
  }
}

export const getLabel = (value: string, options: Array<Option>): string => {
  const found = options.find(option => option.value === value);
  return found ? found.label : '';
};

export const findNextItem = (active: ?HTMLElement, items: Array<HTMLElement>): HTMLElement => {
  if (!active) return items[0];
  const index = items.indexOf(active);
  const maybeNext = items[index + 1];
  return !maybeNext ? items[0] : maybeNext;
};

export const findPrevItem = (active: ?HTMLElement, items: Array<HTMLElement>): HTMLElement => {
  if (!active) return items[0];
  const index = items.indexOf(active);
  const maybeNext = items[index - 1];
  return !maybeNext ? items[items.length - 1] : maybeNext;
};

export const handleBodyEscape = (
  e: SyntheticKeyboardEvent<*>,
  state: State,
  close: () => void,
  cleanFilter: () => void,
) => {
  if (e.key === 'Escape') {
    if (state.filter === '') {
      close();
    } else {
      cleanFilter();
    }
  }
};

export const flatValueExist = (data: Array<Option> | Array<Group>): Array<Option> =>
  data.flatMap(item => (item.items ? item.items : item));

export const valueExist = (data: Array<Option>, value: string): boolean =>
  data.filter(item => item.label && item.label.toLowerCase() === value.toLowerCase().trim())
    .length > 0;

export const handleBodyUpDown = (e: SyntheticKeyboardEvent<*>, state: State, containerRef: any) => {
  if (e.key === 'ArrowDown') {
    if (state.open) {
      if (containerRef.current) {
        const buttons = [...containerRef.current.querySelectorAll('button')];
        const next = findNextItem(document.activeElement, buttons);
        next.focus();
      }
    }
  }

  if (e.key === 'ArrowUp') {
    if (containerRef.current) {
      const buttons = [...containerRef.current.querySelectorAll('button')];
      const next = findPrevItem(document.activeElement, buttons);
      next.focus();
    }
  }
};

export const handleInputKeydown = (e: any, state: State, open: () => void, containerRef: any) => {
  switch (e.code) {
    case 'Space': {
      if (!state.open) {
        open();
        e.preventDefault();
        e.stopPropagation();
      }
      break;
    }
    case 'ArrowDown': {
      if (!state.open) {
        open();
      }
      break;
    }
    case 'ArrowUp': {
      if (!state.open) {
        open();
      }
      break;
    }
    default: {
      if (containerRef.current) {
        const buttons = [...containerRef.current.querySelectorAll('button')];
        const first = buttons[0];

        if (e.key === 'Tab') {
          e.preventDefault();
          e.stopPropagation();
          first.focus();
        }
      }
    }
  }
};

type TriggerProps = {|
  id?: string,
  name?: string,
  placeholder?: string,
  size: 'Small' | 'Medium' | 'Large',
  status: 'Good' | 'Bad',
  // TODO: type this properly
  state: any,
  triggerRef: React.Ref<'input'>,
  optionsData: Array<Option>,
  options: Array<Group>,
  // TODO: make it a callback function instead
  // onChange: (newValue: string) => void
  dispatch: any,
  ariaControls: string[],
  open: boolean,
  value: string,
  onKeyDown?: (SyntheticKeyboardEvent<'Input'>) => void,
  onClick?: (SyntheticMouseEvent<'Input'>) => void,
  onCreateOption?: OnCreateOption,
  'aria-expanded'?: boolean,
  disable?: boolean,
|};

export const Trigger = ({
  id,
  name,
  placeholder,
  size,
  status,
  state,
  triggerRef,
  optionsData,
  options,
  dispatch,
  ariaControls,
  open = false,
  value,
  onKeyDown,
  onClick,
  onCreateOption,
  disable = false,
  'aria-expanded': ariaExpanded,
}: TriggerProps): React.Element<'input'> => {
  const label = getLabel(value, optionsData);
  return (
    <input
      id={id}
      name={name}
      type="text"
      autoComplete="chrome-off"
      autoCorrect="off"
      spellCheck="false"
      aria-autocomplete="list"
      role="combobox"
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      ref={triggerRef}
      disabled={disable ? true : undefined}
      className={cx([
        styles.select,
        size === 'Small' && styles.selectSmall,
        size === 'Medium' && styles.selectMedium,
        size === 'Large' && styles.selectLarge,
        status === 'Bad' && styles.selectBad,
        styles.customSelect,
        styles.input,
        disable && styles.disabled,
      ])}
      value={open ? state.filter : label}
      placeholder={placeholder}
      onChange={e => dispatch({ type: 'FILTER', payload: { filter: e.target.value, open: true } })}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onBlur={() => {
        if (onCreateOption) {
          if (
            state.filter.trim().length > 0 &&
            !valueExist(flatValueExist(options), state.filter)
          ) {
            onCreateOption(state.filter);
            dispatch({ type: 'CLEAR_FILTER' });
          }
        }
      }}
    />
  );
};

/** @ignore */
export const NativeSelect = ({
  size,
  status,
  id,
  name,
  optionsData,
  onChange,
  value,
  placeholder = '',
}: {
  size: Size,
  status: Status,
  id?: string,
  name?: string,
  optionsData: Array<Option>,
  onChange?: OnChange,
  value: string,
  placeholder?: string,
}): React.Element<'div'> => (
  <div>
    <select
      id={id}
      name={name}
      className={cx([
        styles.select,
        size === 'Small' && styles.selectSmall,
        size === 'Medium' && styles.selectMedium,
        size === 'Large' && styles.selectLarge,
        status === 'Bad' && styles.selectBad,
        styles.nativeSelect,
        value === '' && styles.placeholder,
      ])}
      value={value}
      onChange={e => {
        if (onChange) {
          const option = optionsData.find(item => e.target.value === item.value);
          if (typeof option !== 'undefined') {
            onChange(option);
          }
        }
      }}
    >
      <option value="" key="option-placeholder" disabled className={styles.placeholder}>
        {placeholder}
      </option>
      {optionsData.map(item => (
        <option value={item.value} key={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  </div>
);

export const DropdownItems = ({
  filteredOptions,
  onChange,
  value,
  groupHeader,
  dispatch,
}: {|
  filteredOptions: Array<Option>,
  onChange?: OnChange,
  value: string,
  groupHeader?: string,
  dispatch?: ({ type: 'CLEAR_FILTER' }) => void,
|}): any => (
  <DropdownGroup key={uuid()} header={groupHeader}>
    {filteredOptions.map(item => (
      <DropdownAction
        key={item.value}
        selected={value === item.value}
        onClick={() => {
          if (onChange && dispatch) {
            onChange(item);
            dispatch({ type: 'CLEAR_FILTER' });
          }
        }}
      >
        {item.label}
      </DropdownAction>
    ))}
  </DropdownGroup>
);

/** @ignore */
export const Options = ({
  onChange,
  state,
  options,
  filteredOptions,
  value,
  dispatch,
}: {|
  filteredOptions: Array<Option>,
  onChange?: OnChange,
  value: string,
  state: State,
  options: Array<Group>,
  dispatch: ({ type: 'CLEAR_FILTER' }) => void,
|}): React.ChildrenArray<React.Element<typeof DropdownItems>> => {
  if (state.filter.length === 0) {
    return options.map<React.Element<typeof DropdownItems>>(option => (
      <DropdownItems
        onChange={onChange}
        filteredOptions={option.items}
        value={value}
        groupHeader={option.title}
        key={uuid()}
        dispatch={dispatch}
      />
    ));
  }

  return (
    <DropdownItems
      onChange={onChange}
      filteredOptions={filteredOptions}
      value={value}
      dispatch={dispatch}
    />
  );
};

export const search = (data: Array<Option>, text: string): Array<Option> =>
  fuseSearch(data, text, { keys: ['label'] });
