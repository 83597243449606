// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { Lozenge } from '../Lozenge';

type LozengeGroupPropType = {
  className?: string,
  children: React.ChildrenArray<React.Element<typeof Lozenge> | void | false>,
};

/**
 * This component groups lozenges semantically and visually
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Lozenge
 * @category Data
 */
const LozengeGroup = ({ className, children }: LozengeGroupPropType) => {
  const classes = classnames('ace-lozenge-group', className);
  return <div className={classnames(classes, className)}>{children}</div>;
};

export { LozengeGroup };
