// @flow strict

import * as React from 'react';

import classnames from 'classnames';
import { ClassNames } from '@emotion/react';

import type { ColumnType } from '../Tree2.types';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

type Props = {|
  column: ColumnType,
|};

export const Column = ({ column }: Props): React.Element<typeof ClassNames> => {
  const {
    width = '100%',
    minWidth,
    maxWidth,
    mobileWidth = width,
    mobileMinWidth,
    mobileMaxWidth,
    displayOnMobile = true,
    className,
    title,
  } = column;
  return (
    <ClassNames>
      {({ css }) => (
        <div
          className={classnames(
            styles.columnsHeaderCellStyles,
            { [styles.columHideMobileStyles]: !displayOnMobile },
            className,
            css({
              width,
              minWidth,
              maxWidth,
              '@media (max-width: 767px)': {
                width: mobileWidth,
                minWidth: mobileMinWidth,
                maxWidth: mobileMaxWidth,
              },
            }),
          )}
        >
          {title}
        </div>
      )}
    </ClassNames>
  );
};
