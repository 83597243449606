// @flow strict
import React from 'react';

import { asset, Asset, FormattedContentBlock } from '../components';

const AssetTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>Asset</h2>
    </FormattedContentBlock>
    <div>
      <Asset
        variant={asset.Variants.File}
        fileType="code"
        name="Elm"
        href="#"
        number="1.7.1"
        path="/usr/local/bin/elm"
        size="1.9 mb"
        disabled
        timestamp="Jun 8 2016"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.Directory}
        name="Important documents"
        number="01"
        path="/Financial"
        size="6.4gb"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.Question}
        name="How do things work?"
        author="Wondering William"
        path="/42/"
        timestamp="34 hours ago"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.Answer}
        name="They just do. Obviously."
        author="Informed Ingrid"
        timestamp="2 hours ago"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.Comment}
        name="I think there's more to it than that..."
        author="Commenting Kim"
        size="massive"
        iconColour="#9932CC"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.User}
        name="Jason"
        path="/the/attic/"
        timestamp="moments ago"
        iconColour="#4B0082"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.UserGroup}
        name="Interface"
        path="/badger/den/"
        size="04"
        iconColour="#3991ff"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.Generic}
        name="Typical entity"
        number="01"
        path="/path/"
        size="medium"
      />
    </div>
    <div>
      <Asset
        variant={asset.Variants.Directory}
        name="Tasty"
        href="http://www.example.com/"
        number="1.1"
      />
    </div>

    <div>
      <Asset
        variant={asset.Variants.Directory}
        name="New window"
        href="http://www.example.com/"
        number="1.1"
        rel="nofollow"
        target="_blank"
      />
    </div>
  </div>
);

export default AssetTest;
