// @flow strict

import * as React from 'react';
import type { ButtonType } from '../Button';
import type { LinkType } from '../Link';
import type { testIdPropType } from '../../ace-internal/types/general';
import styles from './style.scss';

type EmptyStatePropType = {
  ...testIdPropType,
  /** Image URL */
  image: string,
  /** Heading text */
  heading: string,
  /** Description text */
  description: string,
  /** Action button */
  action: React.Element<ButtonType | LinkType>,
};

/**
 * @status released
 * @version 13.8.0
 * @date 10/09/2019
 * @tags Card, Button
 * @category Data
 */
const EmptyState = ({
  'data-test-id': testId,
  image,
  heading,
  description,
  action,
}: EmptyStatePropType) => {
  return (
    <div className={styles.container} data-test-id={testId}>
      <img className={styles.image} src={image} alt="" data-test-id="empty-state-image" />
      <h2 className={styles.heading}>{heading}</h2>
      <p className={styles.description}>{description}</p>
      {action}
    </div>
  );
};

export { EmptyState };
