// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnASubject = (): React.Element<*> => (
  <>
    <path d="M15.496 1h-11c-1.378 0-2.5 1.122-2.5 2.5v11c0 1.378 1.122 2.5 2.5 2.5h4.017l1.054 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.427-0.241l0.001-0.002 1.054-1.757h4.017c1.379 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.121-2.5-2.5-2.5zM16.996 14.5c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.428 0.241l-0.001 0.002-0.771 1.285-0.771-1.285c-0.089-0.146-0.248-0.243-0.429-0.243h-4.3c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M14.496 10.5h-9c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h9c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M14.496 6.5h-9c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h9c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
