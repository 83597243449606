// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const LogomarkAnsarada = (): React.Element<*> => (
  <>
    <path
      fill="#07070c"
      d="M5.324 19c-2.392 0-4.322-1.783-4.322-4.133 0-2.391 1.93-4.28 4.322-4.28h4.143v4.28c0 2.35-1.794 4.133-4.143 4.133z"
    ></path>
    <path fill="#07070c" d="M10.641 9.295v-8.295c4.79 0 8.362 3.504 8.362 8.295z"></path>
    <path fill="#07070c" d="M14.969 19c-2.395 0-4.328-1.576-4.328-3.929v-4.483h8.362v8.412z"></path>
    <path
      fill="#07070c"
      d="M9.46 5.225c0 2.333-1.892 4.225-4.225 4.225s-4.225-1.892-4.225-4.225c0-2.333 1.892-4.225 4.225-4.225s4.225 1.892 4.225 4.225z"
    ></path>
  </>
);
