// @flow strict
import Fuse from 'fuse.js-ie11';

export const noOp = () => {};

export const selectorForTestId = (testId: string): string => `[data-test-id="${testId}"]`;

export const configInternalTestId = ({ componentName }: { componentName: string }) => (
  partName: string,
): string => `ace-${componentName}-${partName}`.toLowerCase();

export const configInternalSelectors = ({
  componentName,
  parts,
}: {
  componentName: string,
  parts: Array<{ name: string }>,
}) => (rootSelector: string): { [partName: string]: string } =>
  parts.reduce(
    (output, part) => ({
      [part.name]: `${rootSelector} [data-test-id="ace-${componentName}-${part.name}"]`,
      ...output,
    }),

    {},
  );

type FuseSearch = (data: any, text: string, options?: { keys: string[] }) => any[];
export const fuseSearch: FuseSearch = (data, text, options = {}) => {
  const fuse = new Fuse(data, options);
  return fuse.search(text).map(({ item }) => item);
};
