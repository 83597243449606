/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// @flow strict
import React, { useState } from 'react';
import classnames from 'classnames';
import styles from './footer.styles.scss';

const FooterLinks = [
  {
    href: 'https://help.ansarada.com/en/',
    label: 'Help and Support',
  },
  {
    href: 'quote',
    label: 'Get a quote',
  },
  {
    href: 'resources',
    label: 'Resources',
  },
  {
    href: 'legal-policies',
    label: 'Legal',
  },
  {
    href: 'contact',
    label: 'Contact Us',
  },
  {
    href: 'pricing',
    label: 'Pricing',
  },
  {
    href: 'faqs',
    label: 'FAQs',
  },
  {
    href: 'privacy',
    label: 'Privacy',
  },
];

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFooter = () => {
    setIsOpen(!isOpen);
  };

  const footerClass = classnames(styles.footer, {
    [styles.open]: isOpen,
  });

  return (
    <footer className={footerClass}>
      <div className={classnames(styles.trigger)} onClick={toggleFooter}>
        <img
          src="https://media.graphassets.com/ssohrfRUT4d2ywdEcMHt"
          alt="Footer Icon"
          onClick={toggleFooter}
        />
      </div>

      <div className={styles.content}>
        <div className={classnames(styles.linksWrapper)}>
          {FooterLinks.map(({ href, label }) => (
            <a
              key={href}
              href={href.includes('https') ? href : `https://ansarada.com/${href}`}
              className={classnames(styles.link)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {label}
            </a>
          ))}
        </div>

        <div className={classnames(styles.bottomWrapper)}>
          <p style={{ color: 'white', fontSize: '12px', textTransform: 'uppercase' }}>
            © 2005-{new Date().getFullYear()} ansarada pty ltd. All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
