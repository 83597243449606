// @flow strict
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { Link } from '@ansarada/ace-react';

type LinkRouteProps = {
  component: string,
  name: string,
  history: RouterHistory,
};

const LinkRouteFactory = ({ component, name, history }: LinkRouteProps) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link variant="DefaultButton" onClick={() => history.push(`${component}/fullscreen/${name}`)}>
    This is a whole-viewport pattern. View live example at full size
  </Link>
);

export const LinkRoute = withRouter(LinkRouteFactory);
