// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ArrowRestore = (): React.Element<*> => (
  <>
    <path d="M16.502 1h-13c-1.378 0-2.5 1.122-2.5 2.5v13c0 1.378 1.122 2.5 2.5 2.5h13c1.379 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.121-2.5-2.5-2.5zM18.002 16.5c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M11.002 7h-4.304l2.149-2.138c0.091-0.091 0.147-0.216 0.147-0.355 0-0.276-0.224-0.5-0.5-0.5-0.138 0-0.262 0.056-0.353 0.145l-3 2.984c-0.091 0.091-0.147 0.216-0.147 0.355s0.056 0.262 0.146 0.353l3 3.016c0.091 0.091 0.216 0.147 0.354 0.147 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.262-0.146-0.353l-2.144-2.155h4.297c1.654 0 3 1.346 3 3s-1.345 3-3 3h-0.5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h0.5c2.209 0 4-1.791 4-4s-1.791-4-4-4v0z"></path>
  </>
);
