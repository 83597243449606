// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Number = (): React.Element<*> => (
  <>
    <path d="M15.498 1.99h-11c-1.378 0-2.5 1.122-2.5 2.5v11c0 1.378 1.122 2.5 2.5 2.5h11c1.379 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.121-2.5-2.5-2.5zM16.998 15.49c-0.001 0.828-0.672 1.499-1.5 1.5h-11c-0.828-0.001-1.499-0.672-1.5-1.5v-11c0.001-0.828 0.672-1.499 1.5-1.5h11c0.828 0.001 1.499 0.672 1.5 1.5v0z"></path>
    <path d="M6.969 5.006h-0.157c-0.641 0-1.188 0.223-1.637 0.672s-0.677 0.992-0.677 1.632v5.459c0 0.641 0.228 1.185 0.677 1.629s0.997 0.67 1.637 0.67h0.157c0.649 0 1.201-0.225 1.65-0.67s0.676-0.989 0.676-1.629v-5.459c0-0.641-0.227-1.183-0.676-1.632s-1-0.672-1.65-0.672zM8.201 12.769c0 0.334-0.12 0.622-0.358 0.861s-0.53 0.359-0.874 0.359h-0.157c-0.335 0-0.622-0.121-0.861-0.359s-0.359-0.527-0.359-0.861v-5.459c0-0.334 0.12-0.617 0.359-0.856s0.526-0.355 0.861-0.355h0.157c0.344 0 0.635 0.116 0.874 0.355s0.358 0.522 0.358 0.856z"></path>
    <path d="M13.177 8.209h-0.158c-0.449 0-0.803 0.133-1.053 0.391v-2.422h2.686q0.258 0 0.401-0.164c0.089-0.099 0.143-0.231 0.143-0.375 0-0.002 0-0.003-0-0.005v0c0-0.001 0-0.002 0-0.003 0-0.147-0.057-0.28-0.151-0.379l0 0c-0.1-0.109-0.236-0.168-0.408-0.168h-3.224c-0.143 0-0.269 0.058-0.378 0.168s-0.163 0.239-0.163 0.383v3.997c0 0.172 0.056 0.308 0.17 0.408s0.252 0.15 0.415 0.15q0.344 0 0.53-0.301 0.358-0.587 1.046-0.587h0.157c0.334 0 0.618 0.119 0.857 0.358s0.356 0.53 0.356 0.874v2.235c0 0.344-0.117 0.634-0.356 0.868s-0.528 0.353-0.872 0.353h-0.158q-1.132 0-1.132-1.334c0-0.172-0.055-0.306-0.165-0.402-0.101-0.089-0.234-0.143-0.38-0.143s-0.279 0.054-0.38 0.144l0.001-0.001q-0.165 0.143-0.165 0.401c0 0.755 0.205 1.348 0.616 1.773s0.95 0.641 1.619 0.641h0.157c0.641 0 1.184-0.225 1.633-0.67s0.673-0.989 0.673-1.629v-2.236c0-0.649-0.227-1.201-0.681-1.65s-1.001-0.675-1.64-0.675z"></path>
  </>
);
