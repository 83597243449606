// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ArrowUndo = (): React.Element<*> => (
  <>
    <path d="M14.733 9.143c-0.801-0.747-1.864-1.158-2.993-1.158h-6.518l2.641-2.641c0.091-0.091 0.146-0.216 0.146-0.354 0-0.276-0.224-0.5-0.5-0.5-0.138 0-0.263 0.056-0.354 0.147l-3.413 3.413c-0.152 0.088-0.253 0.249-0.253 0.435 0 0.173 0.088 0.325 0.221 0.415l0.002 0.001 3.443 3.444c0.090 0.090 0.216 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354l-2.652-2.652h6.529c1.792 0 3.25 1.345 3.25 2.997s-1.458 2.997-3.25 2.997h-0.734c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h0.734c1.129 0 2.191-0.411 2.993-1.159 0.811-0.756 1.257-1.764 1.257-2.839s-0.447-2.083-1.257-2.839z"></path>
  </>
);
