// @flow strict
import * as React from 'react';
import cx from 'classnames';
import type { testIdPropType } from '../../ace-internal/types/general';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Columns.scss';
import { Column } from './Column';

type Props = {|
  ...testIdPropType,
  /** Adjust spacing based on Shoelace spaces */
  spacing: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | 'None',
  /** You can only put `Column` inside `Columns` */
  children: React.ChildrenArray<React.Element<typeof Column>>,
|};

/**
 * The `Columns` component creates evenly distributed columns
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Stack, Inline, Section
 * @category Layout
 */
export const Columns = ({ 'data-test-id': testId, children, spacing }: Props) => {
  return (
    <div data-test-id={testId} className={cx([styles.columns, styles[`is${spacing}`]])}>
      {children}
    </div>
  );
};

Columns.defaultProps = {
  spacing: '400',
};
