// @flow strict
import React from 'react';
import { AssetPickerFolderItem, AssetPickerFileItem, AssetPicker } from '../components';

const AssetPickerTest = () => (
  <div>
    <AssetPicker>
      <AssetPickerFolderItem
        name="Tasty"
        openControlAlt="open Tasty folder"
        id="1"
        alt="Tasy folder"
      />
      <AssetPickerFolderItem
        name="Tasty"
        openControlAlt="open Tasty folder"
        id="2"
        selected
        alt="Tasty folder"
      />
      <AssetPickerFileItem name="Tasty" fileType="code" id="3" alt="Tasty code file" />

      <AssetPickerFileItem
        name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut metus nec risus tristique varius eget at nisl. Duis a laoreet libero. Mauris eget pulvinar sem"
        fileType="code"
        id="4"
        alt="Tasty code file"
      />

      <AssetPickerFileItem
        name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut metus nec risus tristique varius eget at nisl. Duis a laoreet libero. Mauris eget pulvinar sem"
        fileType="code"
        annotation="Already added"
        className="overwrite-css"
        id="5"
        alt="Lorem ipsum dolor sit amet file"
      />

      <AssetPickerFileItem
        name="Tasty"
        fileType="code"
        annotation="Already added"
        className="overwrite-css"
        disabled
        id="6"
        alt="Tasty file"
      />
    </AssetPicker>
  </div>
);

export default AssetPickerTest;
