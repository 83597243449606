// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileText = (): React.Element<*> => (
  <>
    <path d="M16.506 18.981h-13c-1.379 0-2.5-1.122-2.5-2.5v-13c0-1.379 1.121-2.5 2.5-2.5h13c1.378 0 2.5 1.121 2.5 2.5v13c0 1.378-1.122 2.5-2.5 2.5zM3.506 1.981c-0.827 0.003-1.497 0.673-1.5 1.5v13c0.003 0.827 0.673 1.497 1.5 1.5h13c0.827-0.003 1.497-0.673 1.5-1.5v-13c-0.003-0.827-0.673-1.497-1.5-1.5h-0z"></path>
    <path d="M14.506 8.981h-9c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h9c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M5.506 5.981h9c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-9c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
    <path d="M14.506 12.981h-9c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h9c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
