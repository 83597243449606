// @flow strict

import * as React from 'react';
import { Tabs, Tab, FormattedContentBlock } from '../components';

class TabTest extends React.Component<{}, any> {
  constructor() {
    super();

    this.state = {
      tabset1: 3,
      tabset2: 'whatever',
    };
  }

  update(address: string, value: any) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Tabs</h2>
        </FormattedContentBlock>

        <Tabs active={this.state.tabset1} onUpdate={v => this.update('tabset1', v)}>
          <Tab id="tabset1_1" header="First tab">
            <FormattedContentBlock>
              <p>first tab content</p>
              <p>whatever</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset1_2" header="second tab">
            <FormattedContentBlock>
              <p>second tab content</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset1_3" header="Third Tab">
            <FormattedContentBlock>
              <p>third tab content</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset1_4" header="fourth tab">
            <FormattedContentBlock>
              <p>fourth tab content</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset1_5" header="fifth tab">
            <FormattedContentBlock>
              <p>fifth tab content</p>
            </FormattedContentBlock>
          </Tab>
        </Tabs>

        <Tabs active={this.state.tabset2} onUpdate={v => this.update('tabset2', v)}>
          <Tab id="tabset2_1" header="first tab">
            <FormattedContentBlock>
              <p>first tab content</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset2_2" header="second tab">
            <FormattedContentBlock>
              <p>second tab content</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset2_3" header="third tab" name="whatever">
            <FormattedContentBlock>
              <p>third tab content</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset2_4" header="fourth tab">
            <FormattedContentBlock>
              <p>fourth tab content</p>
            </FormattedContentBlock>
          </Tab>
          <Tab id="tabset2_5" header="fifth tab">
            <p>fifth tab content</p>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default TabTest;
