// @flow strict
import type { NavItem, Token } from '../../types/sass-export';

const convertName = (name: string): string => {
  // uppercase the first two letter since the first is always $
  const capitalized = name.slice(0, 2).toUpperCase() + name.slice(2);
  return capitalized.replace(/-/g, ' ').replace(/^\$/, '');
};

export const mapNavItems = (tokens: Token[]) =>
  tokens
    .filter((d: Token) => !d.name.includes('$-'))
    .filter((d: Token) => !d.name.includes('typography-'))
    .map<NavItem>((d: Token) => {
      const name = d.name.replace('$', '');
      return {
        label: convertName(d.name),
        url: `/shoelace/${name}`,
        image: `${name}.png`,
      };
    });
