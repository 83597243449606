// @flow strict
import * as React from 'react';
import { v4 as uuid } from 'uuid';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

export type ErrorContentType = React.ChildrenArray<string | React$Element<'span'>>;

export type ErrorPropsType = {|
  children: ErrorContentType,
|};

const renderContent = children =>
  children.map(item => (
    <li className={styles.errorText} key={uuid()}>
      {item}
    </li>
  ));

/**
 * @ignore
 */

const Errors = ({ children }: ErrorPropsType) => (
  <ul className={styles.errorWrapper}>{renderContent(React.Children.toArray(children))}</ul>
);

export { Errors };
