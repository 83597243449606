// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const CircleFill = (): React.Element<*> => (
  <>
    <path d="M18.002 9.99c0 4.418-3.582 8-8 8s-8-3.582-8-8c0-4.418 3.582-8 8-8s8 3.582 8 8z"></path>
  </>
);
