// @flow strict

import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import type { testIdPropType } from '../../types/general';

type Props = {|
  ...testIdPropType,
  onClick?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<HTMLElement>) => void,
  'aria-label'?: string,
  className?: string,
|};

export const CloseButton = ({
  onClick,
  onKeyDown,
  className,
  'data-test-id': testId,
  'aria-label': ariaLabel,
}: Props) => (
  <button
    className={cx(styles.close, className)}
    onClick={onClick}
    onKeyDown={onKeyDown}
    data-test-id={testId}
    aria-label={ariaLabel}
    type="button"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9">
      <g className={styles.icon} transform="translate(-460.000000, -101.000000)" fillRule="nonzero">
        <path d="M464.5,106.884615 L461.384615,110 L460,108.615385 L463.115385,105.5 L460,102.384615 L461.384615,101 L464.5,104.115385 L467.615385,101 L469,102.384615 L465.884615,105.5 L469,108.615385 L467.615385,110 L464.5,106.884615 Z" />
      </g>
    </svg>
  </button>
);
