// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileAcrobat = (): React.Element<*> => (
  <>
    <path
      fill="#ff3500"
      d="M3.081 16.73l-0.001 0.003 0.348 0.178c0.085 0.044 0.186 0.070 0.293 0.070 0.002 0 0.004 0 0.005-0h-0c0.764 0 1.858-1.234 3.25-3.631 1.57-0.566 3.745-1.026 5.716-1.224 1.204 0.64 2.54 1.036 3.383 1.036 0.609 0 0.771-0.201 0.798-0.368l0.016-0.088-0.080 0.038c-0.098 0.047-0.237 0.070-0.415 0.070-0.627 0-1.635-0.288-2.667-0.767 0.313-0.016 0.615-0.026 0.903-0.026 1.133 0 1.891 0.144 2.134 0.402 0.053 0.051 0.085 0.123 0.085 0.203 0 0.005-0 0.009-0 0.014l0-0.001 0.089 0.023c0.095-0.201 0.092-0.383-0.010-0.54-0.242-0.383-1.070-0.594-2.327-0.594-0.492 0-1.063 0.034-1.683 0.106-0.481-0.271-0.894-0.549-1.282-0.857l0.020 0.016c-0.984-0.808-1.693-2.156-2.141-3.505 0.172-0.969 0.193-1.819 0.21-2.687 0.001-0.142 0.003-0.285 0.007-0.429l-0.092-0.011c-0.033 0.162-0.063 0.361-0.098 0.592-0.060 0.407-0.144 0.946-0.291 1.604-0.294-1.225-0.363-2.351-0.165-2.932 0.042-0.109 0.099-0.204 0.17-0.286l-0.001 0.001c0.148 0.083 0.373 0.289 0.422 0.772l0.094 0.003c0.072-0.38 0.046-0.648-0.077-0.797-0.115-0.137-0.276-0.137-0.337-0.137l-0.311-0.002c-0.185 0-0.337 0.154-0.406 0.413-0.244 0.906-0.118 2.439 0.354 3.969-0.297 1.040-0.753 2.3-1.496 3.792-1.819 3.662-3.149 5.461-4.075 5.523-0.008-0.504 0.937-1.798 2.197-2.739l-0.049-0.078c-1.682 0.893-2.422 1.916-2.542 2.52-0.029 0.154-0.017 0.271 0.039 0.34zM7.986 11.514c0.667-1.287 1.067-2.34 1.315-3.269 0.455 1.134 1.103 2.197 1.944 2.922 0.246 0.211 0.524 0.406 0.812 0.589-1.57 0.252-3.347 0.723-4.987 1.433 0.293-0.513 0.595-1.058 0.916-1.675z"
    ></path>
    <path
      fill="#ff3500"
      d="M16.506 0.981h-13c-1.381 0-2.5 1.119-2.5 2.5v0 13c0 1.381 1.119 2.5 2.5 2.5v0h13c1.381 0 2.5-1.119 2.5-2.5v0-13c0-1.381-1.119-2.5-2.5-2.5v0zM18.006 16.481c0 0.828-0.672 1.5-1.5 1.5v0h-13c-0.828 0-1.5-0.672-1.5-1.5v0-13c0-0.828 0.672-1.5 1.5-1.5v0h13c0.828 0 1.5 0.672 1.5 1.5v0z"
    ></path>
  </>
);
