// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import { v4 as uuid } from 'uuid';

import { Item } from './Internal/Item';
import { MoreItems } from './Internal/MoreItems';
import {
  addEventListener,
  setStyleOnElement,
  removeStyleOnElement,
  visibleElement,
} from '../../ace-internal/util/dom';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';
import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';

export type BreadcrumbArrayType = Array<React.Element<typeof Item>>;

type ItemBaseType = {|
  text: string,
  ...testIdPropType,
  ...ansaradaCCDPropType,
|};

export type ItemUrlType = {|
  href: string,
  target?: string,
  ...ItemBaseType,
|};

export type ItemOnClickType = {|
  onClick: () => void,
  ...ItemBaseType,
|};

export type ItemType = ItemUrlType | ItemOnClickType | ItemBaseType;
export type ItemsType = Array<ItemType>;
type Size = 'Small' | 'Medium';

export type BreadcrumbPropType = {|
  /** Breadcrumb items */
  items: ItemsType,
  /** Class name property for extra styling */
  className?: string,
  /** Visually adjusts the size of the breadcrumbs */
  size: Size,
  ...testIdPropType,
|};

const moreButtonSize = 56;

const getChildrenElements = (items: ItemsType, size?: Size) => {
  const itemsLength = items.length - 1;
  return items.map<React$Element<typeof Item>>((item: ItemType, index: number) => {
    const key = uuid();
    const extraProps = index < itemsLength ? {} : { href: undefined, onClick: undefined };
    const itemProps = {
      ...item,
      ...extraProps,
      key,
      size,
    };
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    return <Item {...itemProps} />;
  });
};

const calcOverflowWidth = (DomElementsListWidth: Array<number>, wrapperWidth: number) =>
  DomElementsListWidth.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
  wrapperWidth;

/**
 * Breadcrumbs are used to show where the user is within the folder/website hierarchy. As well as allowing them to navigate to previous pages quickly and easily, without having to use to their browser window navigation.
 * @status released
 * @version 10.0.0
 * @date 26/09/2015
 * @tags Link, Button
 * @category Navigation
 */
const Breadcrumb = ({ className, 'data-test-id': testId, items, size }: BreadcrumbPropType) => {
  const wrapperNode = React.useRef();
  const moreNode = React.useRef();
  const [collapsedChildren, setCollapsedChildren] = React.useState<ItemsType>([]);

  const resetDisplayedItems = (wrapperElement: HTMLElement) => {
    [...wrapperElement.querySelectorAll('li')]
      .filter(item => item !== moreNode)
      .forEach((item: HTMLElement) => {
        removeStyleOnElement(item, 'display');
        item.classList.remove(styles.isLastLong);
        item.classList.remove(styles.isSecondLastLong);
        item.classList.remove(styles.first);
        item.classList.remove(styles.second);
      });
  };

  const renderDisplayedItems = React.useCallback(
    (wrapperElement: HTMLElement) => {
      visibleElement(wrapperElement, false);
      resetDisplayedItems(wrapperElement);
      const DomElementsList = [...wrapperElement.querySelectorAll('li')].filter(
        item => item !== moreNode.current,
      );
      const DomElementsListWidth = DomElementsList.map(item => item.clientWidth);
      const overFlowWidth = calcOverflowWidth(DomElementsListWidth, wrapperElement.clientWidth) - 1;
      const collapsedChildrenArray = [];

      if (overFlowWidth > 0) {
        let domElementsRemoveWidth = 0;

        DomElementsList.forEach((domElement, index) => {
          if (
            domElementsRemoveWidth <= overFlowWidth + moreButtonSize &&
            collapsedChildrenArray.length < items.length - 2
          ) {
            collapsedChildrenArray.push(items[index]);
            domElementsRemoveWidth += DomElementsListWidth[index];
            setStyleOnElement(domElement, 'display', 'none');
          }
        });
      } else {
        setCollapsedChildren(collapsedChildrenArray);
      }

      if (
        DomElementsList.length > 2 &&
        collapsedChildrenArray.length === DomElementsList.length - 2
      ) {
        DomElementsList[DomElementsList.length - 1].classList.add(styles.isLastLong);
        DomElementsList[DomElementsList.length - 2].classList.add(styles.isSecondLastLong);
      } else if (DomElementsList.length === 2) {
        DomElementsList[0].classList.add(styles.first);
        DomElementsList[1].classList.add(styles.second);
      }

      if (collapsedChildren.length !== collapsedChildrenArray.length) {
        setCollapsedChildren(collapsedChildrenArray);
      }

      visibleElement(wrapperElement, true);
    },
    [collapsedChildren.length, items],
  );

  const requestUpdateDisplayedItems = React.useCallback(() => {
    if (wrapperNode && wrapperNode.current) {
      renderDisplayedItems(wrapperNode.current);
    }
  }, [renderDisplayedItems]);

  const debouncedWindowResize = debounce(requestUpdateDisplayedItems, 100);

  const windowResize = () => {
    debouncedWindowResize();
  };

  const updateDisplayedItemsNow = React.useCallback(
    () => window.requestAnimationFrame(requestUpdateDisplayedItems),
    [requestUpdateDisplayedItems],
  );

  const resourcesHasLoaded = () => {
    updateDisplayedItemsNow();
  };

  React.useEffect(() => {
    const unbindHandlers = [addEventListener(window, 'resize', windowResize)];
    if (document.readyState === 'loading') {
      unbindHandlers.push(window.addEventListener('load', resourcesHasLoaded));
    } else {
      updateDisplayedItemsNow();
    }

    return () => {
      unbindHandlers.forEach(unbindFn => unbindFn());
      debouncedWindowResize.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    updateDisplayedItemsNow();
  }, [wrapperNode, updateDisplayedItemsNow]);

  return (
    <div
      ref={wrapperNode}
      style={{ visibility: 'hidden', maxWidth: '100%', minWidth: '100%', overflow: 'hidden' }}
      data-test-id={testId}
    >
      <ul className={classnames(styles.container, className)}>
        {collapsedChildren.length > 0 && <MoreItems items={collapsedChildren} ref={moreNode} />}
        {getChildrenElements(items, size)}
      </ul>
    </div>
  );
};

Breadcrumb.defaultProps = {
  size: 'Medium',
};

export { Breadcrumb };
export type { Size };
