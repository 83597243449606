// @flow strict
/* eslint-disable no-param-reassign */
import * as React from 'react';
import * as ReactDOM from 'react-dom';

export type Bounds = {
  top: number,
  bottom: number,
  left: number,
  right: number,
  width: number,
  height: number,
};

export type PositionData = {
  anchorBounds: Bounds,
  // Visual bounds within which the fixed position element should try to stay within
  visualBounds: ?Bounds,
};

/**
 * positioningInfo
 *
 * Gathers the relevant positioning information for doing fixed position elements correctly.
 * */
export const positioningInfo = (anchorNode: Element): PositionData => {
  const boundingRect = anchorNode.getBoundingClientRect();
  return {
    anchorBounds: {
      top: boundingRect.top,
      bottom: boundingRect.bottom,
      left: boundingRect.left,
      right: boundingRect.right,
      width: boundingRect.width,
      height: boundingRect.height,
    },
    visualBounds: null,
  };
};

/**
 * addEventListener
 *
 * TODO, put a type on listener
 * https://github.com/facebook/flow/issues/1155. https://github.com/facebook/flow/issues/1298
 *
 * Binds an event listener. Use instead of native one to easily unbind event listener.
 * NOTE: Be sure that the listener bound is unique to the instance of your Component.
 * This means you've either:
 * a) guaranteed the component is a singleton
 * b) done something like `listener.bind(this)` to generate a unique instance.
 *
 * If you don't, if unbind is called when any instance is removed, then all listeners are removed.
 * This makes thing like listening to window KeyUp event very brittle and buggy
 * */
export const addEventListener = (
  target: EventTarget,
  type: string,
  listener: any,
  useCapture?: boolean,
) => {
  target.addEventListener(type, listener, useCapture);
  return () => {
    target.removeEventListener(type, listener, useCapture);
  };
};

export const outerWidth = (element: HTMLElement) => {
  const style = getComputedStyle(element);
  return element.offsetWidth + parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
};

export const outerHeight = (element: HTMLElement) => {
  const style = getComputedStyle(element);
  return element.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
};

export const getRealDomNode = (reactNode: ?React.ElementRef<*>) => {
  if (reactNode instanceof HTMLElement) {
    return reactNode;
  }
  if (reactNode instanceof React.Component) {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = ReactDOM.findDOMNode(reactNode);
    if (domNode instanceof HTMLElement) {
      return domNode;
    }
  }
  return null;
};

export const setStyleOnElement = (element: HTMLElement, propName: string, propValue: string) => {
  // $FlowFixMe
  element.style[propName] = propValue;
};

export const removeStyleOnElement = (element: HTMLElement, propName: string) => {
  /* 
    The web standard for removing a style is to set it to null but IE11 does not support this
    so we need to use removeAttribute
   */
  // $FlowFixMe
  if (element.style.removeProperty) {
    element.style.removeProperty(propName);
  }
  // $FlowFixMe
  element.style[propName] = null;
};

export const visibleElement = (element: HTMLElement, visible: boolean) => {
  setStyleOnElement(element, 'visibility', visible ? 'visible' : 'hidden');
};

export const getEventDomPath = (
  e: SyntheticMouseEvent<HTMLElement>,
  lastLevelElement: HTMLElement,
): Array<*> => {
  if (!(e.nativeEvent.target instanceof Element && lastLevelElement instanceof Element)) {
    return [];
  }

  const paths = [e.nativeEvent.target];

  do {
    const lastDom = paths[paths.length - 1];
    if (lastDom.parentNode && lastDom.parentNode instanceof Element) {
      paths.push(lastDom.parentNode);
    } else {
      // If the component gets unmounted. while running we cancel while
      break;
    }
  } while (paths[paths.length - 1] !== lastLevelElement);

  return paths;
};

export const hasActiveElementInEventPath = (domList: Array<*>): boolean => {
  return (
    domList.filter(item => {
      if (item instanceof Element) {
        switch (item.tagName.toLowerCase()) {
          case 'input':
          case 'select':
          case 'label':
          case 'button':
          case 'a':
          case 'textarea':
          case 'option':
            return true;
          default:
            return false;
        }
      }
      return false;
    }).length > 0
  );
};

export const isActiveElement = (element: any): boolean => {
  if (element instanceof Element) {
    switch (element.tagName.toLowerCase()) {
      case 'input':
      case 'select':
      case 'label':
      case 'button':
      case 'a':
      case 'textarea':
      case 'option':
        return true;
      default:
        return false;
    }
  }
  return false;
};
