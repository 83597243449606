// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import formatDate from 'date-fns/format';
import addDays from 'date-fns/addDays';
import getISODay from 'date-fns/getISODay';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import styles from './DatepickerMonth.scss';

import { useDisplay } from './DatepickerContext';
import { isEqual, isDateInRange } from './DateFunctions';

type DatepickerMonthPropTypes = {|
  currentMonth: Date,
|};

const MAXCALENDARSIZE = 42;

//  Generate a calendar
function chunk(array: Array<?Date> = [], size: number = 1) {
  if (!array.length || size < 1) {
    return [];
  }
  let index = 0;
  let resIndex = 0;
  const result = new Array(Math.ceil(array.length / size));
  while (index < array.length) {
    result[(resIndex += 1)] = array.slice(index, (index += size));
  }
  return result;
}

const emptyArray = (size: number) => new Array(size).fill(null);

const weekDayCells = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map(cell => (
  <th className={styles.weekDay} scope="col" key={cell}>
    {cell}
  </th>
));

const ignoreFocus = (event: SyntheticMouseEvent<>) => {
  event.preventDefault();
};

/**
 * @ignore
 */
const DatepickerMonth = ({ currentMonth }: DatepickerMonthPropTypes) => {
  const state = useDisplay();

  const daysInMonth = getDaysInMonth(currentMonth);
  const dayCells = emptyArray(daysInMonth).map((item, index) => addDays(currentMonth, index));
  const emptyCellsBefore = emptyArray(getISODay(dayCells[0]) - 1);
  const emptyCellsAfter = emptyArray(MAXCALENDARSIZE - emptyCellsBefore.length - dayCells.length);
  const calendarCells = chunk([...emptyCellsBefore, ...dayCells, ...emptyCellsAfter], 7);

  return (
    <table className={styles.calendarMonth}>
      <thead>
        <tr role="presentation">{weekDayCells}</tr>
      </thead>

      <tbody>
        {calendarCells.map((row, rowIndex) => (
          /* eslint-disable react/no-array-index-key */
          <tr role="row" key={`daterow-${rowIndex}`}>
            {row.map((cell, cellIndex) => {
              if (!cell) {
                // create empty cell
                return <td key={`datecell-${cellIndex}`} />;
              }

              const inRange = isDateInRange(cell, state.minDate, state.maxDate);
              const isCellSelected = state.selectedDate && isEqual(state.selectedDate, cell);
              const isCellToday = isEqual(cell, new Date());

              const cellClasses = classnames(styles.day, {
                [styles.isSelected]: isCellSelected,
                [styles.isToday]: isCellToday,
              });

              const selectDate = () => {
                if (inRange) {
                  // once a selection is made, change focus to the next element
                  if (document && document.activeElement) {
                    document.activeElement.blur();
                  }
                  state.onSelectDate(cell);
                }
              };

              return (
                <td key={`datecell-${cellIndex}`}>
                  <button
                    className={cellClasses}
                    disabled={!inRange}
                    onClick={selectDate}
                    onMouseDown={ignoreFocus}
                    tabIndex="-1"
                  >
                    {formatDate(cell, 'd')}
                  </button>
                </td>
              );
            })}
          </tr>
          /* eslint-enable react/no-array-index-key */
        ))}
      </tbody>
    </table>
  );
};

export { DatepickerMonth };
