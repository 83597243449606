// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Print = (): React.Element<*> => (
  <>
    <path d="M16.518 5.5h-1.517c0-0.009 0-0.017 0-0.026v-1.448c0-0.566-0.459-1.026-1.025-1.026h-7.948c-0.567 0-1.026 0.459-1.026 1.026v1.448c0 0.009 0 0.017 0 0.026h-1.516c-0.82 0-1.484 0.665-1.484 1.484v0 7.031c0 0.82 0.665 1.484 1.484 1.484v0h0.516v0.016c0 0.82 0.665 1.484 1.484 1.484v0h9.032c0.82 0 1.484-0.665 1.484-1.484v0-0.016h0.516c0.82 0 1.484-0.665 1.484-1.484v0-7.031c0-0.82-0.665-1.484-1.484-1.484v0zM6.002 5.474v-1.448c0 0 0 0 0 0 0-0.014 0.011-0.026 0.026-0.026h7.948c0.014 0 0.026 0.012 0.026 0.026 0 0 0 0 0 0v0 1.448c0 0 0 0 0 0 0 0.014-0.012 0.026-0.026 0.026v0h-7.948c-0.014 0-0.026-0.012-0.026-0.026v0zM15.003 15.516c0 0.268-0.217 0.484-0.484 0.484v0h-9.031c-0.268 0-0.484-0.217-0.484-0.484v0-5.516h10zM17.003 14.016c0 0.268-0.217 0.484-0.484 0.484v0h-0.516v-4.958c0-0 0-0 0-0 0-0.299-0.243-0.542-0.542-0.542-0 0-0 0-0 0h-10.916c-0.299 0-0.542 0.243-0.542 0.542v0 4.958h-0.516c-0.268 0-0.484-0.217-0.484-0.484v0-7.031c0-0.268 0.217-0.484 0.484-0.484v0h13.032c0.268 0 0.484 0.217 0.484 0.484v0z"></path>
    <path d="M7.002 12.5h6c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-6c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
    <path d="M7.002 14.5h6c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-6c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
  </>
);
