// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { ExpanderPanel } from '.';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './style.scss';

const Variants = Object.freeze({
  Default: 'Default',
  Compact: 'Compact',
});

type ExpanderGroupPropType = {
  children?: React.ChildrenArray<React.Element<typeof ExpanderPanel> | void | false>,
  className?: string,
  variant?: $Values<typeof Variants>,
};

/** @ignore */
const ExpanderGroup = ({
  children,
  className,
  variant = Variants.Default,
}: ExpanderGroupPropType) => {
  const classes = classnames(className, styles.expanderGroup, {
    // eslint-disable-next-line css-modules/no-undef-class
    [styles.compact]: variant === Variants.Compact,
  });

  return <div className={classes}>{children}</div>;
};

export { ExpanderGroup, Variants };
