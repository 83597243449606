// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { Autocomplete } from '../../components/Autocomplete';
import { AceText } from '../AceText';
import { Datepicker } from '../../components/Datepicker';
import { Button, SubmitButton } from '../../components/Button';
import { ButtonGroup } from '../../components/ButtonGroup';
import { Message } from '../../components/Message';
import { TableLegacy } from '../Table';
import { ExpanderPanel } from '../../components/ExpanderPanel';
import { Tree } from '../../components/Tree';
import { Select } from '../../components/Select';
import { TextInput } from '../../components/TextInput';
import { TextArea } from '../../components/TextArea';
import { Toggle } from '../../components/Toggle';
import { InputInfo } from '../InputInfo';
import { InputState } from '../InputState';
import { Label } from '../Label';
import { FormSubItem } from '../FormSubItem';
import { Checkbox } from '../../components/Checkbox';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { Radio } from '../../components/Radio';
import { ControlledTextInput } from '../../ace-internal/shared-component/ControlledTextInput';

import { ContentType } from '../../ace-internal/types/forms';

import styles from './hacks.scss';
import { RadioGroup } from '../../components/RadioGroup';

type FormItemChildren = React.ChildrenArray<
  | React.Element<typeof Autocomplete>
  | React.Element<typeof AceText>
  | React.Element<typeof Button>
  | React.Element<typeof SubmitButton>
  | React.Element<typeof ButtonGroup>
  | React.Element<typeof Checkbox>
  | React.Element<typeof CheckboxGroup>
  | React.Element<typeof Datepicker>
  | React.Element<typeof ExpanderPanel>
  | React.Element<typeof FormSubItem>
  | React.Element<typeof InputInfo>
  | React.Element<typeof InputState>
  | React.Element<typeof Label>
  | React.Element<typeof Message>
  | React.Element<typeof Radio>
  | React.Element<typeof RadioGroup>
  | React.Element<typeof Select>
  | React.Element<typeof TableLegacy>
  | React.Element<typeof TextArea>
  | React.Element<typeof TextInput>
  | React.Element<typeof Toggle>
  | React.Element<typeof Tree>
  | React.Element<typeof ControlledTextInput>
  | void
  | false
  // ACEREACT-503: We only support string due to bug in FormSingleGroup
  // where string can be set as part of label
  | string,
>;

export type FormItemPropType = {
  className?: string,
  contentType?: $Values<typeof ContentType>,
  children?: FormItemChildren,
};

/** @ignore */
const FormItem = (props: FormItemPropType) => {
  const { className, children, contentType = ContentType.Input, ...rest } = props;

  const classes = classnames(
    'ace-form-item',
    `ace-form-item-${contentType.toLowerCase()}`,
    { [styles.formGroupButtons]: contentType.toLowerCase() === 'buttons' },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export { FormItem, ContentType };
