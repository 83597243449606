// @flow strict
import * as React from 'react';
import { TreeNode } from './TreeNode';
import type {
  Node,
  NodesType,
  ExpandedNodeIdsType,
  OnChangeExpanded,
  OnChangeNodes,
  Id,
  ColumnType,
  OnExpandType,
  OnCollapsedType,
  Size,
  NodeTypes,
  OnSelectType,
  OnChangeSelected,
  SelectedNodeIdsType,
} from '../Tree2.types';

export const getChildenNodes = (
  id: Id,
  nodes: NodesType,
  level: number,
  expandedNodeIds: ExpandedNodeIdsType,
  onChangeExpanded?: OnChangeExpanded,
  onChangeNodes?: OnChangeNodes,
  size?: Size,
  renderNode?: (treeNode: Node) => React.Node,
  columns?: Array<ColumnType>,
  onExpand?: OnExpandType,
  onCollapsed?: OnCollapsedType,
  checkable?: boolean,
  selectedNodeIds?: SelectedNodeIdsType,
  onChangeSelected?: OnChangeSelected,
  onSelect?: OnSelectType,
): React.ChildrenArray<React$Element<typeof TreeNode>> =>
  nodes
    .filter((item: Node): any => {
      const { parentId = 'Root' } = item;
      return parentId === id;
    })
    .map<React$Element<typeof TreeNode>>((item: Node) => {
      return (
        <TreeNode
          node={item}
          nodes={nodes}
          expandedNodeIds={expandedNodeIds}
          onChangeExpanded={onChangeExpanded}
          onChangeNodes={onChangeNodes}
          renderNode={renderNode}
          level={level}
          key={`node-${item.id}`}
          size={size}
          columns={columns}
          onExpand={onExpand}
          onCollapsed={onCollapsed}
          checkable={checkable}
          selectedNodeIds={selectedNodeIds}
          onChangeSelected={onChangeSelected}
          onSelect={onSelect}
        />
      );
    });

export const triggerExpandNode = (
  id: Id,
  onChangeExpanded?: OnChangeExpanded,
  expandedNodeIds: ExpandedNodeIdsType,
  node: Node,
  onExpand?: OnExpandType,
): void => {
  if (onChangeExpanded && !expandedNodeIds.includes(id)) {
    onChangeExpanded([id, ...expandedNodeIds]);
  }
  if (onExpand) {
    onExpand(node);
  }
};

export const triggerCollapseNode = (
  id: Id,
  onChangeExpanded?: OnChangeExpanded,
  expandedNodeIds: ExpandedNodeIdsType,
  node: Node,
  onCollapsed?: OnCollapsedType,
): void => {
  if (onChangeExpanded) {
    onChangeExpanded(expandedNodeIds.filter((lstId: Id): boolean => lstId !== id));
  }
  if (onCollapsed) {
    onCollapsed(node);
  }
};

export const getPaddingLevel = (level: number, type: NodeTypes): number => {
  const STATUS_ICON_SIZE = 20;
  const LEVEL_PADDING = 29;
  const FIRST_LEVEL = 0;

  const statusIconSize = type === 'Leaf' ? STATUS_ICON_SIZE : 0;
  const paddingByLevel = level > FIRST_LEVEL ? (level - FIRST_LEVEL) * LEVEL_PADDING : 0;
  return paddingByLevel + statusIconSize;
};

export const getChildrenIds = (id: Id, nodes: Array<Node>): Array<Id> => {
  const childenNodes = nodes.filter((node: Node) => node.parentId === id);

  // $FlowFixMe
  return childenNodes.flatMap((node: Node) => {
    if (node.type === 'Leaf') {
      return node.id;
    }

    const newChilden = getChildrenIds(node.id, nodes);

    newChilden.push(node.id);

    return newChilden;
  });
};

export const getShallowChildrenIds = (id: Id, nodes: Array<Node>): Array<Id> =>
  nodes.filter((node: Node) => node.parentId === id).map((node: Node) => node.id);

export const getShallowIsAllChildenSelected = (
  id: Id,
  nodes: Array<Node>,
  SelectedNodeIds: SelectedNodeIdsType,
): boolean =>
  getShallowChildrenIds(id, nodes).every(childenId => SelectedNodeIds.includes(childenId));

export const getParentId = (parentId: Id, nodes: Array<Node>): Id => {
  const parentNode = nodes.find((node: Node) => node.id === parentId);

  return parentNode && parentNode.parentId ? parentNode.parentId : 'Root';
};

export const getIsAtLastOneChildenSelected = (
  id: Id,
  nodes: Array<Node>,
  SelectedNodeIds: SelectedNodeIdsType,
): boolean =>
  getChildrenIds(id, nodes).filter(childenId => SelectedNodeIds.includes(childenId)).length > 0;

export const getParentIds = (parentId: Id, nodes: Array<Node>): Array<Id> => {
  const parentIds = [];

  for (
    let newParentId = parentId;
    typeof newParentId !== 'undefined' && newParentId !== 'Root';
    newParentId = getParentId(newParentId, nodes)
  ) {
    parentIds.push(newParentId);
  }

  return parentIds;
};

export const getSelectedNodes = (
  selectedNodeIds: SelectedNodeIdsType,
  nodes: Array<Node>,
  node: Node,
): 'true' | 'false' | 'indeterminate' => {
  if (selectedNodeIds.includes(node.id)) {
    return 'true';
  }

  if (
    node.type === 'Branch' &&
    (typeof node.childrenStatus === 'undefined' || node.childrenStatus === 'Loaded')
  ) {
    return getIsAtLastOneChildenSelected(node.id, nodes, selectedNodeIds)
      ? 'indeterminate'
      : 'false';
  }

  return 'false';
};

export const updateStatusNodebyId = (
  currentNode: Node,
  nodes: Array<Node>,
  status?: 'NotLoaded' | 'Loading' | 'Loaded',
): Array<Node> => {
  return nodes.map((node: Node): Node => {
    if (node.id === currentNode.id && node.childrenStatus) {
      return Object.assign(node, { childrenStatus: status });
    }
    return node;
  });
};
