// @flow strict
import * as React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Module.scss';

type ModuleHeaderPropType = {
  children: React.Node,
};

export const ModuleHeader = (props: ModuleHeaderPropType) => {
  const { children } = props;
  return <h2 className={styles.title}>{children}</h2>;
};
