// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const PriorityHigh = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M6.998 5c-0.276 0-0.5 0.224-0.5 0.5v0 6c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M6.998 13.5c-0.276 0-0.5 0.224-0.5 0.5v0 0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-0.5c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M10 5c-0.276 0-0.5 0.224-0.5 0.5v0 6c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M10 13.5c-0.276 0-0.5 0.224-0.5 0.5v0 0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-0.5c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M12.998 5c-0.276 0-0.5 0.224-0.5 0.5v0 6c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M12.998 13.5c-0.276 0-0.5 0.224-0.5 0.5v0 0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-0.5c0-0.276-0.224-0.5-0.5-0.5v0z"
    ></path>
  </>
);
