// @flow strict
export const getScrollbarWidth = () => {
  const { documentElement } = document;

  if (!documentElement) {
    return 0;
  }

  return window.innerWidth - documentElement.clientWidth;
};

export const doesScrollbarTakeWidth = () => {
  const { body } = document;

  if (!body) {
    return false;
  }

  const rect = body.getBoundingClientRect();

  return rect.left + rect.right < window.innerWidth;
};
