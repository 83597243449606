// @flow strict

import * as React from 'react';

export type SortDirectionType = 'ASC' | 'DESC';

export type CellDataType = 'Icon' | 'Number' | 'DateTime' | 'Currency' | 'Email' | 'Text';

export type renderFuncString = (content: string) => React.Node;

export type renderFuncElement = (content: React.Node) => React.Node;

export type renderFuncDate = (content: Date) => React.Node;

export type renderFuncObject = (content: Object) => React.Node;

export type ColumnType = {|
  title: string,
  field: string,
  sticky?: boolean,
  sortable?: boolean,
  defaultSort?: SortDirectionType,
  type?: CellDataType,
  backgroundColor?: string,
  /* TODO: Work out the patten for this func */
  render?: renderFuncDate | renderFuncString | renderFuncElement,
  /* -1 < last value, 0 same as last value, 1 > then last value  */
  customSort?: (a: React.Node | Date, b: React.Node | Date) => -1 | 0 | 1,
  className?: string,
|};

export const defaultBackgroundColor = '#fff';
