// @flow strict
import * as React from 'react';
import { v4 as uuid } from 'uuid';

import * as icon from '../../Icon';
import * as button from '../../Button';
import { Dropdown } from '../../Dropdown';
import { DropdownGroup } from '../../Dropdown/DropdownGroup';
import { DropdownAction } from '../../Dropdown/DropdownAction';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';
import type { ItemsType } from '..';

const { Icon } = icon;
const { Button } = button;

export type MoreItemsType = {
  items: ItemsType,
};

type IconType = React.Element<typeof Icon>;

/**
 * @ignore
 */
const MoreItems = React.forwardRef<MoreItemsType, HTMLLIElement>(
  ({ items }: MoreItemsType, ref) => {
    const [open, setOpen] = React.useState(false);

    const renderIcon = (firstItem: boolean) => (
      <Icon glyph={firstItem ? icon.Glyphs.ObjectsFolder : icon.Glyphs.ObjectsFolderRoot} />
    );

    const renderDropdownActionLink = (href: string, text: string, iconComponent: IconType) => (
      <DropdownAction href={href} key={uuid()} icon={iconComponent}>
        {text}
      </DropdownAction>
    );

    const renderDropdownActionButton = (
      onClick: (e: SyntheticMouseEvent<>) => void,
      text: string,
      iconComponent: IconType,
    ) => (
      <DropdownAction onClick={onClick} key={uuid()} icon={iconComponent}>
        {text}
      </DropdownAction>
    );

    return (
      <li ref={ref} className={styles.moreItems}>
        <Dropdown
          trigger={
            <Button
              variant="Ghost"
              size="Compact"
              icon={<Icon glyph="ControlMenuContextual" colour="#57575A" />}
            />
          }
          open={open}
          onToggle={setOpen}
        >
          <DropdownGroup>
            {items.map((item, index: number) => {
              const iconComponent = renderIcon(index > 0);

              if (item.href) {
                return renderDropdownActionLink(item.href, item.text, iconComponent);
              }

              if (!item.href && item.onClick) {
                return renderDropdownActionButton(item.onClick, item.text, iconComponent);
              }

              return undefined;
            })}
          </DropdownGroup>
        </Dropdown>
      </li>
    );
  },
);

export { MoreItems };
