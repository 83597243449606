// @flow strict
import { Glyphs } from '../Icon';

export type ControlType = {
  text: string,
  style: string,
  icon: 'ActionBold' | 'ActionItalic' | 'ActionUnderline' | 'ActionList',
};

export type HeadingControlType = {
  text: string,
  style: string,
};

export default {
  InlineStyles: [
    { text: 'Bold', style: 'BOLD', icon: Glyphs.ActionBold },
    { text: 'Italic', style: 'ITALIC', icon: Glyphs.ActionItalic },
    { text: 'Underline', style: 'UNDERLINE', icon: Glyphs.ActionUnderline },
  ],
  ListItemTypesBasic: [{ text: 'UL', style: 'unordered-list-item', icon: Glyphs.ActionList }],
  HeadingStyles: [
    { text: 'Heading 1', style: 'header-one' },
    { text: 'Heading 2', style: 'header-two' },
    { text: 'Heading 3', style: 'header-three' },
  ],
};
