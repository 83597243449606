// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ArrowSortDown = (): React.Element<*> => (
  <>
    <path d="M10.602 2.639v0zM10.005 3.725l2.347 4.267h-4.694l2.347-4.267zM10.005 1.992c-0 0-0 0-0 0-0.119 0-0.223 0.066-0.278 0.163l-0.001 0.002-3.5 6.364c-0.025 0.044-0.039 0.097-0.039 0.153 0 0.176 0.142 0.318 0.318 0.318 0 0 0 0 0 0h7c0.176-0 0.318-0.143 0.318-0.318 0-0.056-0.015-0.109-0.040-0.155l0.001 0.002-3.5-6.364c-0.055-0.099-0.159-0.165-0.279-0.165v0z"></path>
    <path d="M10.284 17.827l3.5-6.364c0.025-0.044 0.039-0.097 0.039-0.153 0-0.176-0.142-0.318-0.318-0.318-0 0-0 0-0 0h-7c-0.176 0-0.318 0.143-0.318 0.318 0 0.056 0.015 0.109 0.040 0.155l-0.001-0.002 3.5 6.364c0.055 0.099 0.159 0.165 0.279 0.165s0.224-0.066 0.278-0.163l0.001-0.002z"></path>
  </>
);
