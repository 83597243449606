// @flow strict
import * as React from 'react';
import { render } from 'react-dom';
import { decode } from 'he';
import { FormattedContentBlock } from '@ansarada/ace-react';

import { CodeSnippet } from '../CodeSnippet';
import { languages } from '../../../types/orbit';
import type { LanguageType } from '../../../types/orbit';

const getLanguageFromClassName = (className: string = ''): LanguageType => {
  const lang = className && className.replace(/.+-/, '');
  return lang in languages ? lang : languages.none;
};

const replacePreWithCodeSnippet = (el, index) => {
  if (el.className.includes('language-jsx')) return; // do nothing if prisma is already applied to code snippet
  const code = el.querySelector('code');
  if (code) {
    const className = code.classList.item(0);
    const language = getLanguageFromClassName(className);
    const tmp = document.createElement('div');
    const identifierClassName = `code-snippet-${index}`;

    render(
      <CodeSnippet
        language={language}
        snippet={decode(code.textContent)}
        className={identifierClassName}
      />,
      tmp,
      () => {
        const newEl = tmp.querySelector(`.${identifierClassName}`);
        if (newEl && el.parentNode) el.parentNode.replaceChild(newEl, el);
      },
    );
  }
};

const enableInputs = item => item.removeAttribute('disabled');

type FormattedContentBlockWrapperProps = {
  children: React.Node,
  className?: string,
};

export const FormattedContentBlockWrapper = (props: FormattedContentBlockWrapperProps) => {
  const { children, className } = props;
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref && ref.current) {
      const div = ref.current;
      const pres = [...div.querySelectorAll('pre')];
      pres.forEach(replacePreWithCodeSnippet);
      [...div.querySelectorAll('input[disabled]')].forEach(enableInputs);
    }
  }, [ref]);

  return (
    <div ref={ref} className={className}>
      <FormattedContentBlock>{children}</FormattedContentBlock>
    </div>
  );
};
