// @flow strict
import * as React from 'react';

// importing individual modules, so that ace-react does not pull in the entire
// date-fn library to minimise bundle size
import formatDate from 'date-fns/format';
import toDate from 'date-fns/toDate';
import isValid from 'date-fns/isValid';
import styles from './DatepickerInput.scss';
import type { ansaradaCCDPropType } from '../../../ace-internal/types/general';

import { KeyEvent } from '../../../ace-internal/types/keys';

import { TextInput } from '../../TextInput';
import { useDisplay } from './DatepickerContext';
import { formatDateAsYMD, getDay, getMonth, getYear } from './DateFunctions';

type selectionType = 'day' | 'month' | 'year';
type formatType = 'D/M/Y' | 'M/D/Y' | 'Y/M/D';

const getSelectionParts = (format: formatType) => {
  if (format === 'D/M/Y') {
    return {
      day: [0, 2],
      month: [3, 5],
      year: [6, 10],
    };
  }
  if (format === 'M/D/Y') {
    return {
      month: [0, 2],
      day: [3, 5],
      year: [6, 10],
    };
  }
  return {
    year: [0, 4],
    month: [5, 7],
    day: [8, 10],
  };
};

const changeCurrentSelection = (
  format: formatType,
  currentSelection: selectionType,
  step: -1 | 1,
) => {
  switch (format) {
    case 'D/M/Y':
      switch (currentSelection) {
        case 'year':
          return step === 1 ? 'year' : 'month';
        case 'month':
          return step === 1 ? 'year' : 'day';
        default:
          return step === 1 ? 'month' : 'day';
      }
    case 'M/D/Y':
      switch (currentSelection) {
        case 'year':
          return step === 1 ? 'year' : 'day';
        case 'month':
          return step === 1 ? 'day' : 'month';
        default:
          return step === 1 ? 'year' : 'month';
      }
    default:
      switch (currentSelection) {
        case 'year':
          return step === 1 ? 'month' : 'year';
        case 'month':
          return step === 1 ? 'day' : 'year';
        default:
          return step === 1 ? 'day' : 'month';
      }
  }
};

const getFirstSelection = (format: formatType) => {
  if (format === 'D/M/Y') {
    return 'day';
  }
  if (format === 'M/D/Y') {
    return 'month';
  }
  return 'year';
};

const parseFormattedDateString = (format: formatType, date: string) => {
  const match = date.match(/(\d+)\D(\d+)\D(\d+)/);
  if (match) {
    let parts;
    if (format === 'D/M/Y') {
      parts = { year: match[3], month: match[2], day: match[1] };
    } else if (format === 'M/D/Y') {
      parts = { year: match[3], month: match[1], day: match[2] };
    } else {
      parts = { year: match[1], month: match[2], day: match[3] };
    }
    // toDate only works with month day year format
    const newDate = toDate(
      new Date(parseFloat(parts.year), parseFloat(parts.month) - 1, parseFloat(parts.day)),
    );
    if (isValid(newDate)) {
      return newDate;
    }
  }
  return undefined;
};

const formatDisplayDate = (format: formatType, date: Date) => {
  if (format === 'D/M/Y') {
    return formatDate(date, 'd MMM yyyy');
  }
  if (format === 'M/D/Y') {
    return formatDate(date, 'MMM d yyyy');
  }
  return formatDate(date, 'yyyy MMM d');
};

export type Props = {
  ...ansaradaCCDPropType,
  id?: string,
  format: formatType,
  placeholder: string,
  inputRef: (?HTMLInputElement) => void,
  size: 'Small' | 'Medium' | 'Large',
};

const padDateValue = (str: string, padLen: number): string => {
  const len = padLen - str.length;
  if (len <= 0) {
    return str;
  }
  return new Array(len)
    .fill(0)
    .concat(str)
    .join('');
};

// Modulus function that doesn't return negative numbers
function mod(n, m) {
  return ((n % m) + m) % m;
}

const cycleDate = (value: number, step: number, maxSize: number): number => {
  const newValue = mod(value + step, maxSize);
  if (newValue === 0) {
    // add an extra step to skip 0
    return mod(newValue + step, maxSize);
  }
  return newValue;
};

const inputDateValue = (
  input: string,
  currentInput?: number,
  inputLeft: number,
  maxInputLeft: number,
  maxInput: number,
  minInput: number,
) => {
  let newInput;
  let newInputLeft;
  let nextSelection = false;

  if (inputLeft > 0) {
    newInput = parseInt(`${currentInput || 0}${input}`, 10);
    newInputLeft = inputLeft - 1;
  } else {
    newInput = parseInt(input, 10);
    newInputLeft = maxInputLeft;
  }

  if (newInput > maxInput) {
    newInput = maxInput;
  } else if (newInput < minInput && newInputLeft === 0) {
    newInput = minInput;
  }

  if (newInputLeft === 0) {
    nextSelection = true;
  }
  return { newInput, newInputLeft, nextSelection };
};

const getValue = (selectedDate, calendarVisible, inputDay, inputMonth, inputYear, format) => {
  let value;

  if (selectedDate && isValid(selectedDate) && !calendarVisible) {
    value = formatDisplayDate(format, selectedDate);
  } else if (calendarVisible) {
    const day = inputDay !== undefined ? padDateValue(inputDay.toString(), 2) : 'dd';
    const month = inputMonth !== undefined ? padDateValue(inputMonth.toString(), 2) : 'mm';
    const year = inputYear !== undefined ? padDateValue(inputYear.toString(), 4) : 'yyyy';
    if (format === 'D/M/Y') {
      value = [day, month, year].join('/');
    } else if (format === 'M/D/Y') {
      value = [month, day, year].join('/');
    } else {
      value = [year, month, day].join('/');
    }
  } else {
    value = '';
  }
  return value;
};

const getNewDate = ({ inputDay, inputMonth, inputYear, minDate, maxDate }) => ({
  inputDay,
  inputMonth,
  inputYear,
  minDate,
  maxDate,
});

type State = {|
  inputDay: void | number,
  inputMonth: void | number,
  inputYear: void | number,
|};
type Action = {| payload: State |};
function reducer(state: State, action: Action) {
  return { ...state, ...action.payload };
}

/**
 * @ignore
 */
const DatepickerInput = ({
  format,
  id,
  inputRef,
  placeholder,
  size,
  'data-ansarada-ccd': ansaradaCCD,
}: Props) => {
  const [componentNodeInput, setComponentNodeInput] = React.useState();
  const [inputLeft, setInputLeft] = React.useState(0);
  const [currentSelection, setCurrentSelection] = React.useState(getFirstSelection(format));

  const state = useDisplay();
  const [display, updateDisplay] = React.useReducer(reducer, {
    inputDay: undefined,
    inputMonth: undefined,
    inputYear: undefined,
  });

  React.useEffect(() => {
    if (state.selectedDate) {
      updateDisplay({
        payload: {
          inputDay: getDay(state.selectedDate),
          inputMonth: getMonth(state.selectedDate),
          inputYear: getYear(state.selectedDate),
        },
      });
    }
  }, [state.selectedDate]);

  const value = getValue(
    state.selectedDate,
    state.calendarVisible,
    display.inputDay,
    display.inputMonth,
    display.inputYear,
    format,
  );

  const setSelection = () => {
    window.requestAnimationFrame(() => {
      if (componentNodeInput) {
        componentNodeInput.setSelectionRange(...getSelectionParts(format)[currentSelection]);
      }
    });
  };

  React.useEffect(setSelection, []);

  const changeSelection = () => {
    const newSelection = componentNodeInput ? componentNodeInput.selectionStart : 0;
    let selectionSet = false;

    Object.entries(getSelectionParts(format)).forEach(([part, slice]) => {
      // $FlowFixMe
      if (newSelection > slice[0] && newSelection < slice[1]) {
        // $FlowFixMe
        setCurrentSelection(part);
        setInputLeft(0);
        selectionSet = true;
      }
    });
    if (!selectionSet) {
      setSelection();
    }
  };

  const stepDate = (step: number) => {
    const newDate = getNewDate({ ...state, ...display });

    switch (currentSelection) {
      case 'year': {
        const newYear =
          display.inputYear !== undefined ? display.inputYear : formatDateAsYMD(new Date()).year;
        newDate.inputYear = cycleDate(newYear, step, 10000);
        break;
      }
      case 'month': {
        const newMonth = display.inputMonth !== undefined ? display.inputMonth : 0;
        newDate.inputMonth = cycleDate(newMonth, step, 13);
        break;
      }
      default: {
        const newDay = display.inputDay !== undefined ? display.inputDay : 0;
        newDate.inputDay = cycleDate(newDay, step, 32);
        break;
      }
    }

    return newDate;
  };

  const update = ({ inputDay, inputMonth, inputYear }) => {
    updateDisplay({ payload: { inputDay, inputMonth, inputYear } });
    if (inputDay && inputMonth && inputYear && inputYear.toString().length === 4) {
      state.onSelectDate(new Date(inputYear, inputMonth - 1, inputDay));
    }
  };

  const onKeyDown = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (KeyEvent.isArrowLeft(e)) {
      e.preventDefault();
      setCurrentSelection(changeCurrentSelection(format, currentSelection, -1));
      setInputLeft(0);
    } else if (KeyEvent.isArrowRight(e) || e.key === '/') {
      e.preventDefault();
      setCurrentSelection(changeCurrentSelection(format, currentSelection, 1));
      setInputLeft(0);
    } else if (KeyEvent.isArrowUp(e)) {
      e.preventDefault();
      update(stepDate(1));
    } else if (KeyEvent.isArrowDown(e)) {
      e.preventDefault();
      update(stepDate(-1));
    } else if (KeyEvent.isNumber(e)) {
      e.preventDefault();
      let newInput;
      let newInputLeft;
      let nextSelection;
      const newDate = getNewDate({ ...state, ...display });

      switch (currentSelection) {
        case 'year':
          ({ newInput, newInputLeft, nextSelection } = inputDateValue(
            e.key,
            display.inputYear,
            inputLeft,
            3,
            9999,
            0,
          ));
          newDate.inputYear = newInput;
          break;
        case 'month':
          ({ newInput, newInputLeft, nextSelection } = inputDateValue(
            e.key,
            display.inputMonth,
            inputLeft,
            1,
            12,
            1,
          ));
          newDate.inputMonth = newInput;
          break;
        default:
          ({ newInput, newInputLeft, nextSelection } = inputDateValue(
            e.key,
            display.inputDay,
            inputLeft,
            1,
            31,
            1,
          ));
          newDate.inputDay = newInput;
          break;
      }

      const _currentSelection = nextSelection
        ? changeCurrentSelection(format, currentSelection, 1)
        : currentSelection;
      setInputLeft(newInputLeft);
      setCurrentSelection(_currentSelection);
      update(newDate);
    } else if (KeyEvent.isBackspace(e) || KeyEvent.isDelete(e)) {
      e.preventDefault();
      let nextSelection = currentSelection;
      const newDate = getNewDate({ ...state, ...display });

      switch (currentSelection) {
        case 'year':
          if (newDate.inputYear === undefined) {
            nextSelection = changeCurrentSelection(format, currentSelection, -1);
          }
          newDate.inputYear = undefined;
          break;
        case 'month':
          if (newDate.inputMonth === undefined) {
            nextSelection = changeCurrentSelection(format, currentSelection, -1);
          }
          newDate.inputMonth = undefined;
          break;
        default:
          if (newDate.inputDay === undefined) {
            nextSelection = changeCurrentSelection(format, currentSelection, -1);
          }
          newDate.inputDay = undefined;
          break;
      }

      setInputLeft(0);
      setCurrentSelection(nextSelection);
      update(newDate);
    } else if (KeyEvent.isChar(e)) {
      // prevents the selection from changing when users insert character keys
      e.preventDefault();
    }
  };

  const onPaste = (e: SyntheticClipboardEvent<*>) => {
    e.preventDefault();
    const pasteValue = e.clipboardData.getData('Text');
    const date = parseFormattedDateString(format, pasteValue);
    if (date) {
      state.onSelectDate(date);
    }
  };

  return (
    <TextInput
      id={id}
      className={styles.datepickerInput}
      size={size}
      placeholder={placeholder}
      value={value}
      inputRef={el => {
        setComponentNodeInput(el);
        inputRef(el);
      }}
      onFocus={() => state.onCalendarVisible(true)}
      onBlur={() => state.onCalendarVisible(false)}
      onClick={changeSelection}
      onPaste={onPaste}
      onChangeValue={() => {}}
      onKeyDown={onKeyDown}
      data-ansarada-ccd={ansaradaCCD || undefined}
    />
  );
};

export { DatepickerInput };
