// @flow strict
import { SELECTION_PARTS, AM, PM, MERIDIEM } from './constants';

type TimeObjectType = {
  meridiem: string,
  minute: number,
  hour: number,
};

/*
 * private methods
 */
const _fillZero = (time: number) => (time < 10 ? `0${time}` : time);
const _to12hoursFormat = (hour: number) => (hour > 12 ? hour - 12 : hour);

/*
 * public methods
 */
export const get12HoursFormat = (date: Date): TimeObjectType => {
  const hours = date.getHours();
  const meridiem = hours >= 12 ? PM : AM;
  let newHour = hours % 12;
  newHour = newHour || 12; // default hour should be 0
  return {
    meridiem,
    minute: date.getMinutes(), // getMonth returns a 0 based index
    hour: newHour,
  };
};

export const getObjectFromTimeString = (timeStr: string): TimeObjectType => {
  if (!timeStr && timeStr.trim().length === 0) {
    return {};
  }
  const timeObj = {};
  Object.entries(SELECTION_PARTS).forEach(([part: string, slice: Array<number>]) => {
    // $FlowFixMe
    if (timeStr.length < slice[1]) return;
    // $FlowFixMe
    const value = timeStr.slice(slice[0], slice[1]);
    timeObj[part] = part !== MERIDIEM ? parseInt(value, 10) : value;
  });
  return timeObj;
};

/**
 * convert times to minutes
 */
export const timeToMinutes = (hours: number, minutes: number, meridiem?: string): number => {
  const hours24hFormat = meridiem === PM ? hours + 12 : hours;
  return minutes + hours24hFormat * 60;
};

export const timeObjToString = (display: TimeObjectType) =>
  display.hour >= 0 && display.minute >= 0 && display.meridiem
    ? `${_fillZero(display.hour)}:${_fillZero(display.minute)}${display.meridiem}`
    : '';

export const isInputTimeValid = (
  display: TimeObjectType,
  minTime: Object,
  maxTime: Object,
): boolean => {
  const selectedTimeInMinutes = timeToMinutes(display.hour, display.minute, display.meridiem);
  const minTimeInMinutes = minTime ? timeToMinutes(minTime.hour, minTime.minute) : null;
  const maxTimeInMinutes = maxTime ? timeToMinutes(maxTime.hour, maxTime.minute) : null;
  if (
    (minTimeInMinutes && minTimeInMinutes > selectedTimeInMinutes) ||
    (maxTimeInMinutes && maxTimeInMinutes < selectedTimeInMinutes)
  ) {
    return false;
  }

  return true;
};

export const findPossibleHour = (
  display: TimeObjectType,
  minTime: Object,
  maxTime: Object,
): number => {
  const selectedTimeInMinutes = timeToMinutes(display.hour, display.minute, display.meridiem);
  const minTimeInMinutes = minTime ? timeToMinutes(minTime.hour, minTime.minute) : null;
  const maxTimeInMinutes = maxTime ? timeToMinutes(maxTime.hour, maxTime.minute) : null;
  if (maxTimeInMinutes && maxTimeInMinutes < selectedTimeInMinutes) {
    return _to12hoursFormat(maxTime.hour);
  }
  if (minTimeInMinutes && minTimeInMinutes > selectedTimeInMinutes) {
    return _to12hoursFormat(maxTime.hour) || _to12hoursFormat(maxTime.hour);
  }

  return display.hour;
};

export const findPossibleMeridiem = (
  meridiem: string,
  availableMeridiems?: Array<string>,
): string => {
  return availableMeridiems && availableMeridiems.length === 1 ? availableMeridiems[0] : meridiem;
};

export const getAvailableMeridiems = (minTime: Object, maxTime: Object): Array<string> => {
  const startTime = minTime ? minTime.hour : 0;
  const endTime = maxTime ? maxTime.hour : 24;
  const meridiems = [];
  if (startTime < 12 || endTime < 12) {
    meridiems.push(AM);
  }
  if (startTime >= 12 || endTime >= 12) {
    meridiems.push(PM);
  }

  return meridiems;
};

export const getTimeOptions = (minTime: Object, maxTime: Object): Array<string> => {
  const startHour = minTime ? minTime.hour : 0;
  const endHour = maxTime ? maxTime.hour : 23;
  const minutes = [0, 30];
  const times = [];

  for (let hour = startHour; hour <= endHour; hour += 1) {
    minutes.forEach(minute => {
      const _12HourTime = get12HoursFormat(new Date(0, 0, 0, hour, minute, 0));
      const _12HourTimeString = `${_fillZero(_12HourTime.hour)}:${_fillZero(_12HourTime.minute)}${
        _12HourTime.meridiem
      }`;

      if (isInputTimeValid(_12HourTime, minTime, maxTime)) {
        times.push(_12HourTimeString);
      }
    });
  }

  return times;
};
