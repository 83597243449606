// @flow strict

import * as React from 'react';
import styles from './styles.scss';

export type ElementGroup = {
  children: React.ChildrenArray<React.Element<any> | void | false>,
  layout?: 'Inline' | 'Block',
  className?: string,
};

export function getLayoutClasses(layout?: 'Inline' | 'Block'): { [string]: boolean } {
  return {
    [styles.isInline]: layout === 'Inline',
    [styles.isBlock]: !layout || layout === 'Block',
  };
}
