// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ChevronAllExpand = (): React.Element<*> => (
  <>
    <path d="M14.859 9.638c-0.090-0.090-0.215-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-4.146 4.147-4.147-4.147c-0.090-0.090-0.215-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l4.5 4.5c0.090 0.090 0.215 0.146 0.354 0.146s0.263-0.056 0.354-0.146l4.5-4.5c0.091-0.090 0.146-0.216 0.146-0.354s-0.056-0.263-0.146-0.354v0z"></path>
    <path d="M9.653 8.351c0.090 0.090 0.215 0.146 0.354 0.146s0.263-0.056 0.354-0.146l2.506-2.506c0.090-0.090 0.146-0.216 0.146-0.354 0-0.276-0.224-0.5-0.5-0.5-0.138 0-0.263 0.056-0.354 0.146l-2.152 2.153-2.153-2.153c-0.090-0.090-0.216-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354v0z"></path>
  </>
);
