// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const LogomarkKira = (): React.Element<*> => (
  <>
    <path
      fill="#93b703"
      d="M5.061 13.418v-6.936c0-0.399 0.198-0.699 0.495-0.898 0.148-0.050 2.621-1.497 4.451-2.595-0.841-0.499-1.484-0.848-1.484-0.848-0.396-0.2-0.692-0.2-1.038 0-1.533 0.898-5.786 3.393-5.984 3.493-0.247 0.15-0.494 0.449-0.494 0.848v6.936c0 0.399 0.198 0.749 0.544 0.948 1.533 0.898 5.786 3.344 5.934 3.493 0.139 0.094 0.31 0.15 0.495 0.15s0.356-0.056 0.498-0.152l-0.003 0.002c0.346-0.2 0.891-0.549 1.533-0.898-1.434-0.848-3.462-2.046-4.451-2.595-0.297-0.2-0.495-0.549-0.495-0.948z"
    ></path>
    <path
      fill="#93b703"
      d="M19.006 6.482c0-0.349-0.198-0.699-0.544-0.898-0.297-0.2-5.934-3.443-5.934-3.443-0.396-0.2-0.692-0.2-1.038 0s-0.891 0.499-1.483 0.848c1.681 0.998 4.253 2.445 4.45 2.595 0.297 0.2 0.544 0.499 0.544 0.898v6.936c0 0.349-0.148 0.699-0.494 0.898-0.198 0.1-2.621 1.547-4.451 2.595 0.841 0.499 1.434 0.848 1.533 0.898 0.139 0.094 0.31 0.15 0.495 0.15s0.356-0.056 0.498-0.152l-0.003 0.002c1.483-0.848 5.736-3.344 5.983-3.493 0.346-0.2 0.445-0.549 0.445-0.898-0.050-0.25 0-5.19 0-6.936z"
    ></path>
  </>
);
