// @flow strict
import * as React from 'react';

import { Dropdown } from '../../Dropdown';
import { DropdownGroup } from '../../Dropdown/DropdownGroup';
import { DropdownAction } from '../../Dropdown/DropdownAction';

import type { NavigationItem } from '../types';
import styles from './ProductSwitcher.scss';

const itemsSort = (
  array: Array<*> | $ReadOnlyArray<*>,
  compareFunc?: (a: any, b: any) => number,
) => {
  const newArray = array.slice(0);
  newArray.sort(compareFunc);
  return newArray;
};

const home = 'Home';
const scorecards = 'Scorecards';
const rooms = 'Rooms';

const sortProductName = (previousItem: NavigationItem, currentItem: NavigationItem) => {
  // `home` goes first
  if (currentItem.name === home) return 1;
  // `scorecards` should go as much to the first positions as possible, but not before `home`
  if (currentItem.name === scorecards && previousItem.name === home) return -1;
  // `rooms` always go last
  if (currentItem.name === rooms) return -1;
  return 0;
};

type Props = {|
  items: $ReadOnlyArray<NavigationItem>,
  productName: string,
  productNameLabel: string,
  testId: string,
|};

/** @ignore */
export const ProductSwitcher = ({ items, productName, productNameLabel, testId }: Props) => {
  const [isProductSwitcherOpen, setIsProductSwitcherOpen] = React.useState(false);
  const brand = (
    <>
      <img
        className={styles.logo}
        alt="Ansarada"
        src="https://static.ansarada.com/logos/0.2.0/ansarada-logomark.svg"
      />
      {productName}
    </>
  );
  return (
    <div className={styles.container}>
      {items.length ? (
        <Dropdown
          data-test-id={testId}
          trigger={
            <button
              className={styles.button}
              aria-label={productNameLabel}
              data-test-id="product-dropdown-trigger"
            >
              {brand}
            </button>
          }
          open={isProductSwitcherOpen}
          onToggle={open => {
            setIsProductSwitcherOpen(open);
          }}
        >
          <DropdownGroup>
            {itemsSort(items, sortProductName).map(landmark => {
              if (landmark.link) {
                return (
                  <DropdownAction
                    key={landmark.name}
                    href={landmark.link}
                    target={landmark.target}
                    onClick={landmark.onClick}
                    data-test-id={landmark.name}
                  >
                    {landmark.name}
                  </DropdownAction>
                );
              }

              return (
                <DropdownAction
                  key={landmark.name}
                  /* Added blank func if onClick or URL is pass in what is not possible sadly flow
                   * is missing.
                   */
                  onClick={landmark.onClick ? landmark.onClick : () => {}}
                >
                  {landmark.name}
                </DropdownAction>
              );
            })}
          </DropdownGroup>
        </Dropdown>
      ) : (
        <div className={styles.noProductGroups}>{brand}</div>
      )}
    </div>
  );
};
