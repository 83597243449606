// @flow strict

import * as React from 'react';
import { v4 as uuid } from 'uuid';

import type { testIdPropType } from '../../ace-internal/types/general.js';
import type { ColumnType, SortDirectionType } from './Table.shared';
import { defaultBackgroundColor } from './Table.shared';
import { HeaderCell } from './Internal/HeaderCell';
import { Cell } from './Internal/Cell';
import { Row } from './Internal/Row';
import { sortData } from './Internal/SortingLogic';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

export type TableSortData = {|
  column: {
    field: string,
    sortDirection: SortDirectionType,
  },
  data: Array<Object>,
|};

export type TablePropType = {|
  ...testIdPropType,
  /** Sets column data e.g. title, field, sortable  */
  columns: Array<ColumnType>,
  /** Sets headers to sticky Note: IE11 does not support sticky so headers will be not sticky in IE11  */
  headerSticky?: boolean,
  /** Table data */
  data: Array<Object>,
  /** This sets background color will default to white if no value is set */
  backgroundColor?: string,
  /** Function is called when sort action is performed */
  onSort?: (newData: TableSortData) => TableSortData,
|};

const ControlledTable = (props: TablePropType) => {
  const {
    columns,
    headerSticky = false,
    data,
    backgroundColor = headerSticky && defaultBackgroundColor,
    onSort,
  } = props;
  const wrapperEl = React.useRef<null | HTMLElement>(null);
  const tableEl = React.useRef<null | HTMLElement>(null);
  const wrapperScrollEl = React.useRef<null | HTMLElement>(null);
  const [dataSorted, setdataSorted] = React.useState({
    column: undefined,
    data,
  });

  const applySort = (columnItem: ColumnType, sortDirection: SortDirectionType) => {
    // With hooks using set with useState if the values are the same it will not rerender if you have array of objects it will not know its changed.

    const newData = {
      column: {
        field: columnItem.field,
        sortDirection,
      },
      data: sortData(data, columnItem, sortDirection),
    };

    if (onSort) {
      return setdataSorted(onSort(newData));
    }

    return setdataSorted(newData);
  };

  const isMoreContentLeft = () => {
    if (wrapperEl && wrapperEl.current && tableEl && tableEl.current) {
      return (
        tableEl.current.clientWidth -
          (wrapperEl.current.scrollLeft + wrapperEl.current.clientWidth) >
        0
      );
    }
    return false;
  };

  const isMoreContentRight = () => {
    if (wrapperEl && wrapperEl.current && tableEl && tableEl.current) {
      return wrapperEl.current.scrollLeft > 0;
    }
    return false;
  };

  const renderStylesMoreContentLeft = () => {
    if (wrapperScrollEl && wrapperScrollEl.current && wrapperEl && wrapperEl.current) {
      if (isMoreContentLeft()) {
        if (wrapperScrollEl.current && typeof wrapperScrollEl.current.classList !== 'undefined') {
          wrapperScrollEl.current.classList.add(styles.isMoreContentLeft);
        }
      } else if (
        wrapperScrollEl.current &&
        typeof wrapperScrollEl.current.classList !== 'undefined'
      ) {
        wrapperScrollEl.current.classList.remove(styles.isMoreContentLeft);
      }
    }
  };

  const renderStylesMoreContentRight = () => {
    if (wrapperScrollEl && wrapperScrollEl.current && wrapperEl && wrapperEl.current) {
      if (isMoreContentRight()) {
        if (wrapperScrollEl.current && typeof wrapperScrollEl.current.classList !== 'undefined') {
          wrapperScrollEl.current.classList.add(styles.isMoreContentRight);
        }
      } else if (
        wrapperScrollEl.current &&
        typeof wrapperScrollEl.current.classList !== 'undefined'
      ) {
        wrapperScrollEl.current.classList.remove(styles.isMoreContentRight);
      }
    }
  };

  const onScroll = () => {
    renderStylesMoreContentLeft();
    renderStylesMoreContentRight();
  };

  React.useEffect(onScroll, [wrapperEl, tableEl]);

  return (
    <div ref={wrapperScrollEl} className={styles.tableScrollContainer}>
      <div className={styles.tableContainer} ref={wrapperEl} onScroll={onScroll}>
        <table className={styles.table} style={{ backgroundColor }} ref={tableEl}>
          <thead>
            <Row>
              {columns.map((item: ColumnType) => (
                <HeaderCell
                  column={item}
                  onSort={applySort}
                  sticky={headerSticky}
                  key={uuid()}
                  sorted={dataSorted.column && dataSorted.column.field === item.field}
                  className={item.className}
                  sortDirection={
                    dataSorted.column && dataSorted.column.field === item.field
                      ? dataSorted.column.sortDirection
                      : undefined
                  }
                />
              ))}
            </Row>
          </thead>
          <tbody>
            {dataSorted.data.map((dataRowItem: Object) => (
              <Row key={uuid()}>
                {columns.map((columnItem: ColumnType) => {
                  const { field, sticky, render, type, className } = columnItem;
                  if (Object.prototype.hasOwnProperty.call(dataRowItem, field)) {
                    return (
                      <Cell sticky={sticky} key={uuid()} dataType={type} className={className}>
                        {typeof render === 'undefined'
                          ? dataRowItem[field]
                          : render(dataRowItem[field])}
                      </Cell>
                    );
                  }
                  return <Cell key={uuid()}></Cell>;
                })}
              </Row>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

/**
 * Tables allow you to present static data in a tabular format.
 * Tables can be set to a specific width or take up the full width of the container, with values in the table cells left aligned by default.
 *
 * @status released
 * @date 11/03/2020
 * @version 1.0.0
 * @tags Table
 * @category Data
 */
const Table = (props: TablePropType): React$Element<typeof ControlledTable> => {
  return <ControlledTable {...props} />;
};

export { Table };
