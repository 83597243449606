// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { useUncontrolled } from 'uncontrollable';
import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { configInternalTestId } from '../../ace-internal/util/util';

import styles from './styles.scss';

import AutocompleteItem from './AutocompleteItem';
import { Dropdown, DropdownGroup, DropdownAction, Lozenge, LoadingIndicator } from '..';

import { KeyEvent } from '../../ace-internal/types/keys';

import { getRealDomNode } from '../../ace-internal/util/dom';

type AutocompleteType = 'Autocomplete';

type AutocompleteGroupType = {
  [string]: Array<AutocompleteItem>,
};
type ItemsType = Array<AutocompleteItem> | AutocompleteGroupType;
type Filter = 'none' | 'startsWith' | 'contains';

type BaseProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  status?: 'Good' | 'Bad',
  size?: 'Small' | 'Medium' | 'Large',
  id?: string,
  /** Placeholder text */
  placeholder?: string,
  /** Add custom CSS classes */
  className?: string,
  /** Flag to set the disabled state */
  disabled: boolean,
  /** Flag to enable multiple selection */
  multiple: boolean,
  /** Allow inserting new AutocompleteItems */
  insertMode: boolean,
  /** Filter strategy */
  filter: Filter,
  /** Loading state */
  loading?: boolean,
|};

type ControlledValue<T> = {|
  value?: T,
  onChangeValue: (value: T, selected: boolean) => void,
|};
type UncontrolledValue<T> = {|
  defaultValue: T,
  onChangeValue?: (value: T, selected: boolean) => void,
|};
type Value<T> = ControlledValue<T> | UncontrolledValue<T>;

type ControlledOpen<T> = {|
  open?: T,
  onToggle: (open: T) => void,
|};
type UncontrolledOpen<T> = {|
  defaultOpen?: T,
  onToggle?: (open: T) => void,
|};
type Open<T> = ControlledOpen<T> | UncontrolledOpen<T>;

type ControlledItems<T> = {|
  items: T,
  onChangeItems: (items: T) => void,
|};
type UncontrolledItems<T> = {|
  defaultItems?: T,
  onChangeItems?: (items: T) => void,
|};
type Items<T> = ControlledItems<T> | UncontrolledItems<T>;

type ControlledSelected<T> = {|
  selected?: T,
  onSelected: (selected: ?T) => void,
|};
type UncontrolledSelected<T> = {|
  defaultSelected: ?T,
  onSelected?: (selected: ?T) => void,
|};
type Selected<T> = ControlledSelected<T> | UncontrolledSelected<T>;

type ControlledProps = {|
  ...ControlledValue<string>,
  ...ControlledOpen<boolean>,
  ...ControlledItems<ItemsType>,
  ...ControlledSelected<AutocompleteItem | Array<AutocompleteItem>>,
|};

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledProps,
|};

type AutocompletePropType = {|
  ...BaseProps,
  ...Value<string>,
  // $FlowFixMe - 0.112.0 - too many cases
  ...Open<boolean>,
  ...Items<ItemsType>,
  ...Selected<AutocompleteItem | Array<AutocompleteItem>>,
|};

const trimLower = (item: string): string => item.trim().toLowerCase();
const prepareToCompare = (item: string): string =>
  trimLower(item)
    .split(' ')
    .join('');

const valueExists = (items, value): boolean =>
  Array.isArray(items) && !!items.find(item => trimLower(String(item.label)) === trimLower(value));

const createInternalTestId = configInternalTestId({ componentName: 'Autocomplete' });

const filterDropdownItems = (
  filter: Filter,
  items: Array<AutocompleteItem> | AutocompleteGroupType,
  value: string,
) => {
  switch (filter) {
    case 'none': {
      return items;
    }
    case 'startsWith': {
      if (typeof items === 'object' && !Array.isArray(items)) {
        const newItems: AutocompleteGroupType = {};
        Object.keys(items).forEach(key => {
          const result = items[key].filter(i =>
            prepareToCompare(i.label).startsWith(prepareToCompare(value)),
          );

          if (result.length > 0) {
            newItems[key] = result;
          }
        });
        return newItems;
      }

      return Array.isArray(items)
        ? [...items.filter(i => prepareToCompare(i.label).startsWith(prepareToCompare(value)))]
        : items;
    }
    case 'contains': {
      if (typeof items === 'object' && !Array.isArray(items)) {
        const newItems: AutocompleteGroupType = {};
        Object.keys(items).forEach(key => {
          const result = items[key].filter(i =>
            prepareToCompare(i.label).includes(prepareToCompare(value)),
          );

          if (result.length > 0) {
            newItems[key] = result;
          }
        });
        return newItems;
      }

      return Array.isArray(items)
        ? [...items.filter(i => prepareToCompare(i.label).includes(prepareToCompare(value)))]
        : items;
    }
    default:
      return [];
  }
};

const ControlledAutocomplete = ({
  insertMode,
  id,
  className,
  size,
  disabled,
  status,
  multiple,
  placeholder,
  open = false,
  filter,
  value = '',
  selected,
  items,
  onToggle,
  onChangeValue,
  onSelected,
  onChangeItems,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  loading = false,
}: ControlledPropType) => {
  const [hasFocus, setHasFocus] = React.useState(false);
  const [preventBackspaceDeletingValue, setPreventBackspaceDeletingValue] = React.useState(false);
  const dropdownItems = filterDropdownItems(filter, items, value);

  React.useEffect(() => {
    if (selected) {
      if (!Array.isArray(selected)) {
        onChangeValue(selected.label, true);
      } else {
        onChangeValue('', true);
      }
    } else {
      onChangeValue('', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onInsertItem = (event: SyntheticEvent<*>) => {
    event.preventDefault();
    if (value !== '') {
      const toSelect = Array.isArray(items)
        ? items.find(item => prepareToCompare(item.label) === prepareToCompare(value))
        : undefined;
      const newItem = toSelect || new AutocompleteItem(value);
      const newItems = Array.isArray(items) && !toSelect ? items.concat(newItem) : items;

      onChangeItems(newItems);
      onSelected(Array.isArray(selected) ? [...selected, newItem] : newItem);
    }
  };

  const removeItem = (item: AutocompleteItem) => {
    const _selected = Array.isArray(selected)
      ? selected.filter(i => i.value !== item.value)
      : undefined;
    const _value = (Array.isArray(selected) && multiple) || insertMode ? value : '';

    onSelected(_selected);
    onChangeValue(_value, false);
  };

  const onItemSelected = (item: AutocompleteItem) => {
    const itemIsSelected = Array.isArray(selected)
      ? !!selected.find(i => i.value === item.value)
      : selected && selected.value === item.value;

    if (multiple && itemIsSelected) {
      removeItem(item);
    } else {
      const previousItems = multiple ? selected || [] : undefined;
      onChangeValue(!multiple && item && item.value ? item.label : '', true);
      onSelected(multiple && Array.isArray(previousItems) ? previousItems.concat(item) : item);
    }
  };

  const onInputInput = (event: SyntheticInputEvent<*>) => {
    const val = event.target.value;
    if (!open) onToggle(true);
    if (val !== value) {
      onChangeValue(val, false);
    }
  };

  const onInputKeyDown = (event: SyntheticKeyboardEvent<*>) => {
    if (
      !KeyEvent.isBackspace(event) ||
      !Array.isArray(selected) ||
      !selected ||
      !selected.length ||
      preventBackspaceDeletingValue ||
      insertMode
    ) {
      return;
    }

    if (value) {
      // While backspace is deleting input value characters, prevent it from deleting
      // lozenges until backspace has been released and pressed again
      setPreventBackspaceDeletingValue(true);
      return;
    }

    const lastItem = selected[selected.length - 1];
    removeItem(lastItem);
  };

  const onInputKeyUp = () => {
    if (preventBackspaceDeletingValue) {
      setPreventBackspaceDeletingValue(false);
    }
  };

  const isItemSelected = (item: AutocompleteItem) => {
    if (!selected) return false;
    if (Array.isArray(selected)) {
      return !!selected.find(i => i.value === item.value);
    }
    return selected.value === item.value;
  };

  const _isOpen =
    open &&
    !disabled &&
    ((Array.isArray(items) ? items : Object.keys(items)).length > 0 ||
      (insertMode && value !== ''));

  const autocompleteClassName = classnames(
    styles.autocomplete,
    hasFocus && styles.focus, // eslint-disable-line css-modules/no-undef-class
    disabled && styles.disabled, // eslint-disable-line css-modules/no-undef-class
    size === 'Small' && styles.isSmall,
    size === 'Medium' && styles.isMedium,
    size === 'Large' && styles.isLarge,
    status === 'Bad' && styles.isBad,
  );

  const inputClassName = classnames(
    styles.input,
    size === 'Small' && styles.isSmall,
    size === 'Medium' && styles.isMedium,
    size === 'Large' && styles.isLarge,
    className,
  );

  const insertModeField = insertMode && (
    <DropdownGroup
      insertMode
      className={classnames({
        [styles.insertModeEmpty]: value === '' || valueExists(items, value),
      })}
    >
      <DropdownAction onClick={onInsertItem}>{value}</DropdownAction>
    </DropdownGroup>
  );

  const lozenges = Array.isArray(selected) ? (
    <React.Fragment>
      {selected.map(item => (
        <div className={styles.lozengeWrapper} key={item.value}>
          <Lozenge
            text={item.label}
            closeable={!disabled}
            className={styles.lozenge}
            onClose={() => {
              setTimeout(() => {
                removeItem(item);
              }, 0);
            }}
          />
        </div>
      ))}
    </React.Fragment>
  ) : null;

  const trigger = (
    <div className={styles.trigger} data-test-id={testId} data-ansarada-ccd={ansaradaCCD}>
      <input
        id={id}
        data-test-id={createInternalTestId('input')}
        type="text"
        autoComplete="chrome-off"
        autoCorrect="off"
        spellCheck="false"
        aria-autocomplete="list"
        value={value}
        className={inputClassName}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        onInput={onInputInput}
        onKeyDown={onInputKeyDown}
        onKeyUp={onInputKeyUp}
        onChange={() => {}}
        role="combobox"
        aria-expanded={_isOpen}
        aria-controls=""
        ref={el => getRealDomNode(el)}
        data-dropdown-keep
      />
    </div>
  );

  const itemsGrouped = Array.isArray(dropdownItems)
    ? { '': dropdownItems }
    : Object.assign({}, dropdownItems);

  const itemGroups = Object.keys(itemsGrouped).map(header => (
    <DropdownGroup key={`group-${header}`} header={header}>
      {itemsGrouped[header].map(item => (
        <DropdownAction
          key={item.value}
          selected={isItemSelected(item)}
          onClick={() => {
            setTimeout(() => {
              onItemSelected(item);
            }, 0);
          }}
        >
          {item.label}
        </DropdownAction>
      ))}
    </DropdownGroup>
  ));

  return (
    <div>
      <div className={autocompleteClassName}>
        <Dropdown
          open={_isOpen}
          trigger={trigger}
          data-ansarada-ccd={ansaradaCCD}
          onToggle={isOpen => {
            onToggle(isOpen);
          }}
        >
          {insertModeField}
          {itemGroups}
        </Dropdown>
        {loading && (
          <div className={styles.loader}>
            <LoadingIndicator dark className={styles.loaderIcon} />
          </div>
        )}
      </div>
      <div className={styles.lozengeContainer}>{lozenges}</div>
    </div>
  );
};

/**
 * You can use Autocomplete for creating assisted user-input fields.
 *
 *
 * Users can use the free-form text field to search the desired value and the element will show the list of matching suggestions below.
 *
 *
 * They can select a value from the suggestions and selected value will set as the value in field.
 *
 * @status released
 * @version 10.0.0
 * @date 26/09/2015
 * @tags Autocomplete
 * @category Form
 */
const Autocomplete = (
  props: AutocompletePropType,
): React$Element<typeof ControlledAutocomplete> => {
  const allProps = useUncontrolled(props, {
    open: 'onToggle',
    value: 'onChangeValue',
    selected: 'onSelected',
    items: 'onChangeItems',
  });

  return <ControlledAutocomplete {...allProps} />;
};

Autocomplete.defaultProps = {
  size: 'Medium',
  status: 'Good',
  disabled: false,
  multiple: false,
  filter: 'none',
  insertMode: false,
  loading: false,
};

export { Autocomplete, AutocompleteItem };
export type { Items, Filter, AutocompleteType, AutocompleteGroupType };
