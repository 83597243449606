// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ZipFile = (): React.Element<*> => (
  <>
    <path d="M16.496 0.981h-13c-1.381 0-2.5 1.119-2.5 2.5v0 13c0 1.381 1.119 2.5 2.5 2.5v0h13c1.381 0 2.5-1.119 2.5-2.5v0-13c0-1.381-1.119-2.5-2.5-2.5v0zM17.996 16.481c0 0.828-0.672 1.5-1.5 1.5v0h-6v-4.784l1.148 1.139c0.090 0.090 0.215 0.145 0.352 0.145 0.276 0 0.5-0.224 0.5-0.5 0-0.139-0.057-0.264-0.148-0.355l-0-0-1.934-1.919c-0.091-0.137-0.244-0.226-0.418-0.226h-0.018c-0.138 0.001-0.263 0.057-0.353 0.148l-1.984 2c-0.090 0.090-0.145 0.215-0.145 0.352 0 0.276 0.224 0.5 0.5 0.5 0.139 0 0.264-0.057 0.355-0.148l1.145-1.154v4.802h-6c-0.828 0-1.5-0.672-1.5-1.5v0-13c0-0.828 0.672-1.5 1.5-1.5v0h6v4.784l-1.148-1.139c-0.090-0.090-0.215-0.145-0.352-0.145-0.276 0-0.5 0.224-0.5 0.5 0 0.139 0.057 0.264 0.148 0.355l1.935 1.919c0.091 0.137 0.244 0.226 0.418 0.226h0.018c0.138-0.001 0.263-0.057 0.353-0.148l1.984-2c0.090-0.090 0.145-0.215 0.145-0.352 0-0.276-0.224-0.5-0.5-0.5-0.139 0-0.265 0.057-0.355 0.148l-0 0-1.145 1.154v-4.802h6c0.828 0 1.5 0.672 1.5 1.5v0z"></path>
    <path d="M14.496 9.481h-9c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h9c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
