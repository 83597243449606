// @flow strict
import React from 'react';
import { AceText } from '../components';

const AceTextTest = () => (
  <div>
    <AceText text="ACE text" />
    <AceText className="testClass" text="ACE text" />
    <AceText id="testID" text="ACE text" />
  </div>
);

export default AceTextTest;
