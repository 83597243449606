// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ArrowUp = (): React.Element<*> => (
  <>
    <path d="M5.149 8.354c0.090 0.090 0.215 0.146 0.354 0.146s0.263-0.056 0.354-0.146l3.646-3.647v11.793c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-11.793l3.646 3.647c0.090 0.090 0.216 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354l-4.5-4.5c-0.090-0.090-0.215-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-4.5 4.5c-0.091 0.090-0.146 0.216-0.146 0.354s0.056 0.263 0.146 0.354v0z"></path>
  </>
);
