// @flow strict
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse'; /* using alpha date-fns for this parse function take in format */
import addMonths from 'date-fns/addMonths';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';

const YYYMD = 'yyyy-M-d';

export const YMToDate = (year: number, month: number): Date =>
  parseDate(`${year}-${month}-01`, YYYMD, new Date());

export const YMDToDate = (year: number, month: number, day: number): Date =>
  parseDate(`${year}-${month}-${day}`, YYYMD, new Date());

export const formatDateAsYMD = (date: Date): { year: number, month: number, day: number } => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1, // getMonth returns a 0 based index
  day: date.getDate(),
});

export const addMToYM = (
  year: number,
  month: number,
  monthsToAdd: number,
): { month: number, year: number } =>
  formatDateAsYMD(addMonths(YMToDate(year, month), monthsToAdd));

export const getTimeZeroedDate = (date: Date): Date => startOfDay(date);

export const isEqual = (date1: Date, date2: Date) =>
  formatDate(date1, YYYMD) === formatDate(date2, YYYMD);

export const isDateInRange = (date: Date, minDate: Date, maxDate: Date): boolean =>
  !isBefore(date, minDate) && !isAfter(date, maxDate);

export const getDefaultDate = ({ year, month }: { year?: number, month?: number }): Date => {
  const today = formatDateAsYMD(new Date());
  return YMDToDate(year !== undefined ? year : today.year, month || today.month, today.day);
};

type GetDatePart = (selectedDate: ?Date) => number | typeof undefined;

export const getDay: GetDatePart = selectedDate =>
  (selectedDate && selectedDate.getDate()) || undefined;

export const getMonth: GetDatePart = selectedDate =>
  (selectedDate && selectedDate.getMonth() + 1) || undefined;

export const getYear: GetDatePart = selectedDate =>
  (selectedDate && selectedDate.getFullYear()) || undefined;
