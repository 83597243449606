// @flow strict

import * as React from 'react';
import classnames from 'classnames';

import { Autocomplete } from '../../components/Autocomplete';
import { AceText } from '../AceText';
import { Datepicker } from '../../components/Datepicker';
import { Label } from '../Label';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { InputInfo } from '../InputInfo';
import { InputState } from '../InputState';
import { FormItem } from '../FormItem';
import { Checkbox } from '../../components/Checkbox';
import { Radio } from '../../components/Radio';
import { SubmitButton, Button } from '../../components/Button';
import { ButtonGroup } from '../../components/ButtonGroup';
import { ControlledTextInput } from '../../ace-internal/shared-component/ControlledTextInput';

import { ContentType } from '../../ace-internal/types/forms';

type FormSingleGroupChildren = React.ChildrenArray<
  | React.Element<typeof Autocomplete>
  | React.Element<typeof AceText>
  | React.Element<typeof Button>
  | React.Element<typeof SubmitButton>
  | React.Element<typeof ButtonGroup>
  | React.Element<typeof Checkbox>
  | React.Element<typeof Datepicker>
  | React.Element<typeof InputInfo>
  | React.Element<typeof InputState>
  | React.Element<typeof Radio>
  | React.Element<typeof Select>
  | React.Element<typeof TextArea>
  | React.Element<typeof TextInput>
  | React.Element<typeof ControlledTextInput>
  | void
  | false,
>;

export type FormSingleGroupPropType = {
  // TODO: We need to create a label element and update for to input element inside.
  label?: React.Element<typeof Label> | string,
  className?: string,
  inline?: boolean,
  contentType?: $Keys<typeof ContentType>,
  children?: FormSingleGroupChildren,
  status?: 'Good' | 'Bad',
};

/** @ignore */
const FormSingleGroup = (props: FormSingleGroupPropType) => {
  const {
    label = '',
    className,
    inline = false,
    contentType = ContentType.Text,
    status = 'Good',
    children,
    ...rest
  } = props;

  const classes = classnames(
    'ace-form-group',
    `ace-form-group-${contentType.toLowerCase()}`,
    { 'ace-form-error': status === 'Bad' },
    { 'ace-form-group-inline': !!inline },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <div className={classes} {...rest}>
      <FormItem contentType={ContentType.Label}>{label}</FormItem>
      <FormItem contentType={contentType}>{children}</FormItem>
    </div>
  );
};

export { FormSingleGroup, ContentType };
