// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { Button } from '../Button';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../Button/style.scss';

type ButtonGroupPropType = {
  children?: React.ChildrenArray<React.Element<typeof Button> | void | false>,
  /** An optional className for adjusting margins + widths. Is applied in addition to the other button styles */
  className?: string,
};

/**
 * Buttons Group is an easy way to display a group of options together and
 * guarantee that only one button can be selected at a time.
 *
 *
 * They are best used when an interface presents up to four interrelated choices.
 * Once you have more than four selections, use another list based component.
 *
 *
 * They can also be used to segment and display content separately.
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Button
 * @category Buttons
 */
const ButtonGroup = ({ children, className }: $Exact<ButtonGroupPropType>) => {
  // eslint-disable-next-line css-modules/no-undef-class
  const classes = classnames(className, styles.buttonGroup);

  return <div className={classes}>{children}</div>;
};

export { ButtonGroup };
