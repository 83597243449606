// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { TableHeaderLegacy } from './TableHeaderLegacy';
import { TableDataLegacy } from './TableDataLegacy';

export type TableRowPropType = {
  disabled?: boolean,
  highlighted?: boolean,
  className?: string,
  // TODO: ACEREACT-498 There all react element allow due to an issue with TreeItem cause tree and table to not load
  children?: React.ChildrenArray<
    | React.Element<any>
    | React.Element<typeof TableHeaderLegacy>
    | React.Element<typeof TableDataLegacy>
    | void
    | false,
  >,
};

/** @ignore */
export const TableRowLegacy = (props: TableRowPropType) => {
  const { disabled, highlighted, className, children, ...rest } = props;

  const classes = classnames(
    { 'ace-table-disabled': !!disabled },
    { 'ace-table-highlighted': !!highlighted },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <tr className={classes} {...rest}>
      {children}
    </tr>
  );
};
