// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Tick = (): React.Element<*> => (
  <>
    <path d="M12.703 6.016c-0.058-0.026-0.126-0.040-0.197-0.040-0.205 0-0.381 0.123-0.458 0.3l-0.001 0.003-2.637 6.152-1.488-2.232c-0.091-0.137-0.244-0.225-0.418-0.225-0.276 0-0.5 0.224-0.5 0.5 0 0.105 0.032 0.202 0.087 0.282l-0.001-0.002 2 3c0.091 0.135 0.243 0.223 0.416 0.223v0c0.016 0 0.031 0 0.046-0.002 0.186-0.018 0.342-0.135 0.413-0.298l0.001-0.003 3-7c0.026-0.058 0.041-0.126 0.041-0.197 0-0.205-0.123-0.381-0.3-0.458l-0.003-0.001z"></path>
    <path d="M10.006 1.99c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM10.006 16.99c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"></path>
  </>
);
