// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import styles from './Stage.scss';

type Props = {|
  children: React.Node,
  clean?: boolean,
  className?: string,
|};

export const Stage = (props: Props) => {
  const { children, clean, className } = props;
  return (
    <div className={classnames(styles.container, className)}>
      {React.Children.map(children, elem => (clean ? elem : <div>{elem}</div>))}
    </div>
  );
};
