// @flow strict
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';

import { Checkbox } from '../../Checkbox';

import { removeDuplicates } from '../../../ace-internal/util/array';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

import { getParentIds, getChildrenIds, getShallowIsAllChildenSelected } from './Shared';

import type { OnChangeSelected, Node, SelectedNodeIdsType, OnSelectType } from '../Tree2.types';

export type Props = {|
  nodes: Array<Node>,
  node: Node,
  checked?: boolean,
  indeterminate?: boolean,
  onSelect?: OnSelectType,
  onChangeSelected?: OnChangeSelected,
  selectedNodeIds?: SelectedNodeIdsType,
|};

export const onChange = (
  node: Node,
  nodes: Array<Node>,
  selectedNodeIds: SelectedNodeIdsType,
  onSelect?: OnSelectType,
  onChangeSelected?: OnChangeSelected,
  checked: boolean,
): void => {
  const newCheckValue = !checked === true;
  let newSelected = [];
  const childrenIds = node.type === 'Branch' ? getChildrenIds(node.id, nodes) : [];
  const parentIds = node.parentId ? getParentIds(node.parentId, nodes) : [];

  if (newCheckValue) {
    newSelected = [...selectedNodeIds, ...childrenIds, node.id];

    parentIds.forEach(id => {
      if (getShallowIsAllChildenSelected(id, nodes, newSelected)) {
        newSelected.push(id);
      }
    });
  } else {
    newSelected = selectedNodeIds.filter(
      id => !childrenIds.includes(id) && !parentIds.includes(id) && node.id !== id,
    );
  }

  if (onChangeSelected) {
    onChangeSelected(removeDuplicates(newSelected));
  }

  if (onSelect) {
    onSelect(node, newCheckValue);
  }
};

export const CheckboxSelect = ({
  node,
  checked = false,
  indeterminate = false,
  onSelect,
  onChangeSelected,
  selectedNodeIds = [],
  nodes,
}: Props): React.Element<'div'> => (
  <div className={styles.checkBoxWrapperStyles}>
    <Checkbox
      id={`node-checkbox-${node.id}`}
      value={`${node.id}`}
      label="Selected"
      checked={checked}
      indeterminate={indeterminate}
      hideLabel
      onUpdate={() => {
        onChange(node, nodes, selectedNodeIds, onSelect, onChangeSelected, checked);
      }}
    />
  </div>
);
