// @flow strict

export const Variants: Object = Object.freeze({
  Normal: 'Normal',
  Subtle: 'Subtle',
});

export const Layout: Object = Object.freeze({
  Block: 'Block',
  Inline: 'Inline',
});

export const ContentType: Object = Object.freeze({
  Buttons: 'Buttons',
  Checkbox: 'Checkbox',
  Datepicker: 'Datepicker',
  Email: 'Email',
  Expander: 'Expander',
  File: 'File',
  Input: 'Input',
  Label: 'Label',
  Message: 'Message',
  Mixed: 'Mixed',
  Number: 'Number',
  Password: 'Password',
  Radio: 'Radio',
  Search: 'Search',
  Select: 'Select',
  Table: 'Table',
  Text: 'Text',
  TextArea: 'TextArea',
  Toggle: 'Toggle',
  Tree: 'Tree',
  URL: 'URL',
});
