// @flow strict
import * as React from 'react';
import classNames from 'classnames';

import * as icon from '../Icon';
import { TruncateText } from '../TruncateText';

import styles from './styles.scss';
import { configInternalTestId } from '../../ace-internal/util/util';

import type { testIdPropType } from '../../ace-internal/types/general';

const { Icon } = icon;

type Variants = 'Directory' | 'File';

type AssetPropType = {|
  ...testIdPropType,
  /** Sets the asset icon to be either File or Directory */
  variant?: Variants,
  /** Name to display next to the icon */
  name: string | React.Element<typeof TruncateText>,
  /** Apply disabled styles */
  disabled?: boolean,
  /** Adds extra classes in the root element */
  className?: string,
  /** Use when variant is Variants.File. Allows to set a different file icon e.g.: Video */
  fileType?: string,
  /** Sequence of items to be shown after the icon */
  number?: string,
|};

const getFileTypeCSS = (fileType: string) => `document-${fileType.toLowerCase()}`;

const glyph = (variant: Variants, fileType?: string) => {
  switch (variant) {
    case 'Directory':
      return icon.Glyphs.ObjectsFolder;

    default:
      return fileType ? getFileTypeCSS(fileType) : icon.Glyphs.DocumentGeneric;
  }
};

/**
 * Asset provides a way to represent files, folders or groups with icon and text.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Tree
 * @category Data
 */
const Asset = (props: AssetPropType) => {
  const {
    'data-test-id': testId,
    variant = 'File',
    name,
    disabled = false,
    className,
    fileType,
    number,
  } = props;

  const classes = classNames(styles.asset, className, { [styles.isDisabled]: disabled });

  const createInternalTestId = configInternalTestId({ componentName: 'asset' });

  return (
    <div data-test-id={testId} className={classes}>
      <Icon className={styles.icon} glyph={glyph(variant, fileType)} text={fileType} />
      {number && <span className={styles.number}>{number}</span>}
      <span data-test-id={createInternalTestId('name')} className={styles.name}>
        {name}
      </span>
    </div>
  );
};

export { Asset };
export type { Variants };
