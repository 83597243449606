// @flow strict
/* eslint prefer-destructuring: off */

import * as React from 'react';
import { Progressbar, Button, FormattedContentBlock } from '../components';

class ProgressbarTest extends React.Component<{}, { [string]: number }> {
  constructor() {
    super();
    this.state = { pageContextProgress: 0 };
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Progressbars</h2>
          <h3>Fluid width - Default (30%)</h3>
        </FormattedContentBlock>

        <Progressbar percentValue={30} />

        <div style={{ width: 400 }}>
          <FormattedContentBlock>
            <h3>Parent element fixed width 10%</h3>
          </FormattedContentBlock>

          <Progressbar percentValue={10} />

          <FormattedContentBlock>
            <h3>Parent element fixed width 20%</h3>
          </FormattedContentBlock>
          <Progressbar percentValue={20} />

          <FormattedContentBlock>
            <h3>Parent element fixed width 30%</h3>
          </FormattedContentBlock>
          <Progressbar percentValue={30} />

          <FormattedContentBlock>
            <h3>Parent element fixed width 40%</h3>
          </FormattedContentBlock>
          <Progressbar percentValue={40} />
        </div>
        <FormattedContentBlock>
          <h3>Page context (appears at top)</h3>
        </FormattedContentBlock>
        <Progressbar
          percentValue={this.state.pageContextProgress}
          isInactive={false}
          isPageContext
        />
        <Button onClick={() => this.updagePageContextProgressBar()}>Page Context Bar</Button>

        <FormattedContentBlock>
          <h3>Inactive</h3>
        </FormattedContentBlock>
        <Progressbar isInactive percentValue={40} />
      </div>
    );
  }

  updagePageContextProgressBar() {
    const intr = setInterval(() => {
      const pageContextProgress = this.state.pageContextProgress;
      if (pageContextProgress >= 100) {
        clearInterval(intr);
      }
      this.setState({ pageContextProgress: pageContextProgress + 10 });
    }, 1000);
  }
}

export default ProgressbarTest;
