// @flow strict
import * as React from 'react';

import { Icon } from '../Icon';
import { BaseButton } from './Internal/BaseButton';
import type {
  testIdPropType,
  ansaradaCCDPropType,
  ariaControlsPropType,
  ariaExpandedPropType,
  ariaLabelledbyPropType,
} from '../../ace-internal/types/general';

export type ButtonPropType = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  ...ariaExpandedPropType,
  ...ariaControlsPropType,
  ...ariaLabelledbyPropType,
  id?: string,
  size: 'Compact' | 'Regular' | 'Large' | 'Super',
  children?: React.ChildrenArray<string | void | false>,
  variant?: 'Secondary' | 'Primary' | 'Caution' | 'Ghost' | 'Link' | 'CautionLink' | 'SubtleLink',
  disabled?: boolean,
  className?: string,
  icon?: React.Element<typeof Icon>,
  loading?: boolean,
  pressed?: boolean,
  onMouseDown?: (e: SyntheticMouseEvent<>) => void,
  onMouseUp?: (e: SyntheticMouseEvent<>) => void,
  onMouseOver?: (e: SyntheticMouseEvent<>) => void,
  onMouseOut?: (e: SyntheticMouseEvent<>) => void,
  onBlur?: (e: SyntheticFocusEvent<>) => void,
  onClick?: (e: SyntheticMouseEvent<>) => void,
  onFocus?: (e: SyntheticFocusEvent<>) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<>) => void,
  buttonRef?: () => ?HTMLButtonElement | ?HTMLAnchorElement,
|};

/**
 * Buttons allow users to take actions and make choices, with a single tap. They should be easily identifiable, while clearly indicating the action they allow a user to complete; achieved through the use of text and/or icons
 *
 *
 * Use buttons when a user is expected to **take an action.**
 *
 * @status released
 * @version 10.0.0
 * @date 26/09/2015
 * @tags Button, Link
 * @category Buttons
 */
export const Button = (props: ButtonPropType) => <BaseButton {...props} />;

Button.defaultProps = {
  size: 'Regular',
};
