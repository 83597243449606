// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Date = (): React.Element<*> => (
  <>
    <path d="M8.091 10.6l-2.652 4.441q-0.188 0.257-0.093 0.608t0.456 0.351h3.016q0.21 0 0.327-0.134c0.073-0.081 0.117-0.188 0.117-0.306 0-0.001 0-0.003-0-0.004v0c0-0.001 0-0.002 0-0.003 0-0.12-0.047-0.228-0.123-0.309l0 0c-0.082-0.089-0.193-0.136-0.333-0.136h-2.373l2.465-4.136q0.304-0.514 0.303-1.167v-0.082c0-0.522-0.184-0.961-0.554-1.324s-0.815-0.541-1.337-0.541h-0.035c-0.514 0-0.951 0.178-1.313 0.541s-0.542 0.802-0.542 1.324v0.129c0 0.141 0.045 0.25 0.139 0.328 0.083 0.072 0.193 0.116 0.312 0.116 0.001 0 0.001 0 0.002 0h-0c0.001 0 0.003 0 0.004 0 0.117 0 0.223-0.044 0.303-0.117l-0 0c0.090-0.078 0.131-0.188 0.131-0.328v-0.129c-0-0.004-0-0.009-0-0.014 0-0.267 0.108-0.509 0.283-0.685v0c0.171-0.179 0.412-0.29 0.678-0.29 0.005 0 0.010 0 0.015 0h0.034c0.304 0 0.543 0.103 0.722 0.302s0.267 0.446 0.267 0.734v0.070c0 0.265-0.072 0.519-0.22 0.76z"></path>
    <path d="M10.991 14.188h2.085v1.438c0 0.141 0.040 0.248 0.126 0.321s0.19 0.111 0.315 0.111 0.232-0.037 0.318-0.111 0.131-0.181 0.131-0.321v-1.438h0.519c0.148 0 0.259-0.042 0.333-0.128s0.111-0.191 0.111-0.315c0-0.003 0-0.007 0-0.011 0-0.118-0.045-0.225-0.118-0.306l0 0c-0.078-0.086-0.191-0.13-0.339-0.13h-0.522v-0.932c0-0.148-0.044-0.259-0.13-0.333s-0.192-0.111-0.316-0.111-0.23 0.037-0.316 0.111-0.129 0.185-0.129 0.333v0.932h-1.543l2.15-4.825c0.023-0.039 0.035-0.1 0.035-0.186 0-0.001 0-0.002 0-0.003 0-0.118-0.049-0.224-0.128-0.3l-0-0q-0.129-0.129-0.339-0.128-0.257 0-0.386 0.269l-2.326 5.295q-0.059 0.152-0.058 0.257c-0 0.002-0 0.005-0 0.007 0 0.138 0.056 0.264 0.146 0.355l-0-0c0.097 0.101 0.224 0.151 0.38 0.151z"></path>
    <path d="M16.498 1h-13c-1.379 0-2.5 1.122-2.5 2.5v13c0 1.378 1.121 2.5 2.5 2.5h13c1.378 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.122-2.5-2.5-2.5zM17.998 16.5c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-10.5h16zM17.998 5h-16v-1.5c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
  </>
);
