// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileVideo = (): React.Element<*> => (
  <>
    <path d="M16.502 18.981h-13c-1.378 0-2.5-1.122-2.5-2.5v-13c0-1.379 1.122-2.5 2.5-2.5h13c1.379 0 2.5 1.121 2.5 2.5v13c0 1.378-1.121 2.5-2.5 2.5zM3.502 1.981c-0.827 0.003-1.497 0.673-1.5 1.5v13c0.003 0.827 0.673 1.497 1.5 1.5h13c0.827-0.003 1.497-0.673 1.5-1.5v-13c-0.003-0.827-0.673-1.497-1.5-1.5h-0z"></path>
    <path d="M14.747 9.546l-8-4.5c-0.071-0.040-0.155-0.064-0.245-0.064-0.276 0-0.5 0.224-0.5 0.5 0 0 0 0 0 0v0 9c0 0 0 0 0 0 0 0.276 0.224 0.5 0.5 0.5 0.090 0 0.175-0.024 0.248-0.066l-0.002 0.001 8-4.5c0.153-0.087 0.255-0.25 0.255-0.436s-0.102-0.348-0.252-0.434l-0.002-0.001zM7.002 13.626v-7.29l6.48 3.645z"></path>
  </>
);
