// @flow strict
import * as React from 'react';
import classnames from 'classnames/bind';

import type { testIdPropType } from '../../ace-internal/types/general';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './base-toast.scss';

const cx = classnames.bind(styles);

type Props = {|
  variant?: 'Neutral' | 'Success' | 'Caution' | 'Error' | 'Info',
  children: React.Node,
  ariaId?: string,
  onTimeout?: Function,
  timeoutDuration?: number,
  ...testIdPropType,
|};

export const DEFAULT_TIMEOUT = 3000;

/** @ignore */
export class BaseToast extends React.Component<Props> {
  timeoutId: ?TimeoutID;

  componentDidMount() {
    this.startTimeout();
  }

  componentDidUpdate(prevProps: Props) {
    const { timeoutDuration: prevTimeout } = prevProps;
    const { timeoutDuration: currTimeout } = this.props;
    if (prevTimeout !== currTimeout) {
      this.startTimeout();
    }
  }

  startTimeout = () => {
    const { onTimeout, timeoutDuration = DEFAULT_TIMEOUT } = this.props;
    this.stopTimeout();
    if (timeoutDuration > 0 && onTimeout) {
      this.timeoutId = setTimeout(onTimeout, timeoutDuration);
    }
  };

  stopTimeout = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  };

  render() {
    const { children, 'data-test-id': testId, ariaId, variant = 'Neutral' } = this.props;

    const classes = cx(styles.toast, `is${variant}`);
    return (
      <div data-test-id={testId} className={classes} aria-labelledby={ariaId}>
        {children}
      </div>
    );
  }
}
