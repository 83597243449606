// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnAAnswer = (): React.Element<*> => (
  <>
    <path d="M10.681 3.965q-0.135-0.42-0.69-0.42-0.54 0-0.675 0.42l-2.925 9.39c-0.020 0.1-0.030 0.16-0.030 0.18-0 0.002-0 0.005-0 0.008 0 0.148 0.063 0.282 0.165 0.375l0 0q0.165 0.157 0.42 0.157c0.27 0 0.445-0.127 0.525-0.387l0.675-2.156h3.705l0.66 2.156c0.080 0.26 0.26 0.388 0.54 0.388 0.17 0 0.31-0.053 0.42-0.158 0.102-0.093 0.165-0.227 0.165-0.376 0-0.003-0-0.005-0-0.008v0q0-0.030-0.030-0.18zM8.506 10.391l1.484-4.8 1.5 4.8z"></path>
    <path d="M15.498 1h-11c-1.378 0-2.5 1.122-2.5 2.5v11c0 1.378 1.122 2.5 2.5 2.5h4.018l1.054 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.428-0.241l0.001-0.002 1.054-1.757h4.017c1.379 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.121-2.5-2.5-2.5zM16.998 14.5c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.428 0.241l-0.001 0.002-0.771 1.285-0.771-1.285c-0.089-0.146-0.248-0.243-0.429-0.243h-4.3c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
  </>
);
