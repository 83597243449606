// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnATick = (): React.Element<*> => (
  <>
    <path d="M15.502 1h-11c-1.378 0-2.5 1.122-2.5 2.5v11c0 1.378 1.122 2.5 2.5 2.5h4.017l1.054 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.427-0.24l0.001-0.002 1.054-1.757h4.017c1.379 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.121-2.5-2.5-2.5zM17.002 14.5c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.427 0.241l-0.001 0.002-0.771 1.285-0.771-1.285c-0.089-0.146-0.248-0.243-0.429-0.243h-4.3c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M12.699 5.15c-0.058-0.026-0.126-0.040-0.197-0.040-0.205 0-0.381 0.123-0.458 0.3l-0.001 0.003-2.637 6.152-1.488-2.232c-0.091-0.137-0.244-0.225-0.418-0.225-0.276 0-0.5 0.224-0.5 0.5 0 0.105 0.032 0.202 0.087 0.282l-0.001-0.002 2 3c0.091 0.135 0.243 0.223 0.416 0.223v0c0.016 0 0.031 0 0.046-0.002 0.186-0.018 0.342-0.135 0.413-0.298l0.001-0.003 3-7c0.026-0.058 0.040-0.126 0.040-0.197 0-0.205-0.123-0.381-0.3-0.458l-0.003-0.001z"></path>
  </>
);
