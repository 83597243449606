// @flow strict

import React from 'react';
import { Editor, FormattedContentBlock } from '../components';

const contentHtmlInput =
  '<h1>Hola heading</h1>' +
  '<b>Bold text me</b>, <p>This is' +
  ' undisclosed, </p><i>Italic text</i><br/>' +
  '<p><u>This is underline text</u></p>' +
  '<p><ul><li>This is list item</li></ul></p>' +
  '<p>This is <a href="http://example.com">a link</a>.</p>' +
  "<h2>I'm a dumb ol secondary heading</h2>" +
  '<p>^^ yes you are</p>' +
  "<h3>I'm a silly ol baby heading</h3>" +
  '<p>Shhhh...</p>';

const emptyLinesHtmlInput =
  '<b>Bold text me</b>, <FormattedP>This is' +
  ' undisclosed, </FormattedP><i>Italic text</i>' +
  '<p></p>' +
  '<FormattedP><u>This is underline text</u></FormattedP>' +
  '<FormattedP><ul><li>This is list item</li></ul></FormattedP>';

type StateType = {
  plainText: string,
  html: string,
  controlledHtml: string,
  controlledText: string,
};

class EditorTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      plainText: '',
      html: '',
      controlledHtml: contentHtmlInput,
      controlledText: '',
    };
  }

  render() {
    return (
      <div>
        <div className="ace-group ace-group-gutters-on">
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h1>Editor Basic</h1>
            </FormattedContentBlock>
            <Editor
              html={this.state.html}
              maxLength={100}
              onHtmlChange={(contentHtml, contentPlainText) =>
                this.setState({
                  html: contentHtml,
                  plainText: contentPlainText,
                })
              }
            />
          </div>
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h1>Demo</h1>
              <p>output html: {this.state.html}</p>
              <p>output plainText: {this.state.plainText}</p>
              <pre>
                <code>
                  {'<Editor\n' +
                    '   onContentRawChange={(contentRaw, contentHtml, contentPlainText)' +
                    ' =>\n' +
                    '       contentRaw,\n' +
                    '       html: contentHtml,\n' +
                    '       plainText: contentPlainText,\n' +
                    '   }\n' +
                    '/>'}
                </code>
              </pre>
            </FormattedContentBlock>
          </div>
        </div>
        <div className="ace-group ace-group-gutters-on">
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h1>Editor Uncontrolled Version</h1>
            </FormattedContentBlock>
            <Editor defaultHtml={contentHtmlInput} status="Bad" />
          </div>
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <p>output html: {this.state.controlledHtml}</p>
              <p>output plainText: {this.state.controlledText}</p>
              <pre>
                <code>
                  {'<Editor\n' +
                    '   contentHtml={contentHtmlInput} \n' +
                    '   contentRaw={this.state.contentRaw} \n' +
                    '   onContentRawChange={contentRaw =>\n' +
                    '       this.setState({ contentRaw });' +
                    '   }\n' +
                    '/>'}
                </code>
              </pre>
            </FormattedContentBlock>
          </div>
        </div>
        <div className="ace-group ace-group-gutters-on">
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h1>Editor Rendering mode</h1>
            </FormattedContentBlock>
            <Editor defaultHtml={contentHtmlInput} readOnly />
          </div>
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h2>Input</h2>
              <p>contentHtml = {contentHtmlInput}</p>
              <pre>
                <code>
                  {'<Editor\n' + '   contentHtml={contentHtmlInput}\n' + '   readOnly\n' + '/>'}
                </code>
              </pre>
            </FormattedContentBlock>
          </div>
        </div>
        <div className="ace-group ace-group-gutters-on">
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h1>Editor keeps empty lines</h1>
            </FormattedContentBlock>
            <Editor
              keepEmptyLines
              defaultHtml={emptyLinesHtmlInput}
              onHtmlChange={(contentHtml, contentPlainText) =>
                this.setState({
                  html: contentHtml,
                  plainText: contentPlainText,
                })
              }
            />
          </div>
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h2>Input</h2>
              <p>contentHtml = {emptyLinesHtmlInput}</p>
              <pre>
                <code>
                  {'<Editor\n' +
                    '   keepEmptyLines\n' +
                    '   contentHtml={contentHtmlInput}\n' +
                    '   contentRaw={this.state.contentRaw}\n' +
                    '   onContentRawChange={(contentRaw, contentHtml, contentPlainText)' +
                    ' =>\n' +
                    '       this.setState({\n' +
                    '         contentRaw,\n' +
                    '         html: contentHtml,\n' +
                    '         plainText: contentPlainText,\n' +
                    '       })\n' +
                    '   }\n' +
                    '/>'}
                </code>
              </pre>
            </FormattedContentBlock>
          </div>
        </div>

        <div className="ace-group ace-group-gutters-on">
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h1>Editor with heading controls</h1>
            </FormattedContentBlock>
            <Editor
              resizable
              showHeadingControls
              defaultHtml={contentHtmlInput}
              onHtmlChange={(contentHtml, contentPlainText) =>
                this.setState({
                  html: contentHtml,
                  plainText: contentPlainText,
                })
              }
            />
          </div>
          <div className="ace-item ace-item-6">
            <FormattedContentBlock>
              <h2>Input</h2>
              <pre>
                <code>
                  {'<Editor\n' +
                    '   resizeble\n' +
                    '   showHeadingControls\n' +
                    '   contentHtml={contentHtmlInput}\n' +
                    '   contentRaw={this.state.contentRaw}\n' +
                    '   onContentRawChange={(contentRaw, contentHtml, contentPlainText)' +
                    ' =>\n' +
                    '       this.setState({\n' +
                    '         contentRaw,\n' +
                    '         html: contentHtml,\n' +
                    '         plainText: contentPlainText,\n' +
                    '       })\n' +
                    '   }\n' +
                    '/>'}
                </code>
              </pre>
            </FormattedContentBlock>
          </div>
        </div>
      </div>
    );
  }
}

export default EditorTest;
