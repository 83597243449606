// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const CopyCross = (): React.Element<*> => (
  <>
    <path d="M8.596 15.975h-3.779c-0.42-0.001-0.761-0.341-0.761-0.761v-0.003l-0.043-7.976c0.001-0.42 0.341-0.76 0.761-0.76h0.734l0.034 6.254c0.002 0.971 0.789 1.758 1.76 1.76h0.789c-0.058-0.301-0.092-0.646-0.092-1v-0h-0.697c-0.42-0-0.761-0.341-0.761-0.761v-0.003l-0.043-7.976c0.001-0.42 0.341-0.76 0.761-0.76h4.141v2.739c0 0.585 0.285 1.103 0.724 1.423l0.005 0.003c0.41-0.109 0.881-0.172 1.367-0.172 1.335 0 2.558 0.474 3.512 1.264l-0.009-0.007v-1.608c-0-0.138-0.056-0.263-0.147-0.354l-4.143-4.142c-0.090-0.090-0.215-0.146-0.353-0.146h-5.097c-0.972 0.001-1.76 0.789-1.761 1.762v0.003l0.004 0.723h-0.728c-0.972 0.001-1.76 0.789-1.761 1.76v0.003l0.043 7.977c0.002 0.971 0.79 1.758 1.761 1.76h4.434c-0.242-0.292-0.46-0.62-0.641-0.969l-0.014-0.031zM12.4 4.238l3.25 3.25h-2.489c-0.42-0.001-0.761-0.341-0.761-0.761v-0z"></path>
    <path
      fill="#fb0069"
      d="M13.5 7.981c-3.037 0-5.5 2.463-5.5 5.5s2.463 5.5 5.5 5.5 5.5-2.463 5.5-5.5-2.462-5.5-5.5-5.5zM16.682 16.663c-0.811 0.809-1.93 1.309-3.166 1.309-2.477 0-4.484-2.008-4.484-4.484s2.008-4.484 4.484-4.484c2.477 0 4.484 2.008 4.484 4.484 0 0.63-0.13 1.23-0.364 1.774l0.011-0.029c-0.238 0.556-0.564 1.031-0.965 1.431l-0 0z"
    ></path>
    <path
      fill="#fb0069"
      d="M15.854 11.128c-0.090-0.091-0.216-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-1.646 1.646-1.646-1.646c-0.090-0.090-0.216-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l1.646 1.646-1.646 1.646c-0.090 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146l1.646-1.646 1.646 1.646c0.090 0.090 0.216 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354l-1.646-1.646 1.646-1.646c0.091-0.090 0.146-0.216 0.146-0.354s-0.056-0.263-0.146-0.354v0z"
    ></path>
  </>
);
