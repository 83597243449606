// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Contact = (): React.Element<*> => (
  <>
    <path d="M16.502 2.99h-13c-1.378 0-2.5 1.122-2.5 2.5v9c0 1.378 1.122 2.5 2.5 2.5h13c1.379 0 2.5-1.122 2.5-2.5v-9c0-1.378-1.121-2.5-2.5-2.5zM18.002 14.49c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-9c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M7.002 4.99h-3c-0.552 0.001-0.999 0.448-1 1v3c0.001 0.552 0.448 0.999 1 1h3c0.552-0.001 0.999-0.448 1-1v-3c-0.001-0.552-0.448-0.999-1-1h-0zM7.002 8.989l-0.001 0.001-2.999-0.001v-2.998l0.001-0.001 2.999 0.001z"></path>
    <path d="M15.502 6.99h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M15.502 9.99h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M15.502 12.99h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
