// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Attachment = (): React.Element<*> => (
  <>
    <path d="M13.481 2.025c-0.633-0.633-1.508-1.025-2.475-1.025-1.933 0-3.5 1.567-3.5 3.5v0 10c0 1.378 1.121 2.5 2.5 2.5s2.5-1.122 2.5-2.5v-8c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0 8c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5v0-10c0-1.378 1.121-2.5 2.5-2.5s2.5 1.122 2.5 2.5v10c0 1.933-1.567 3.5-3.5 3.5s-3.5-1.567-3.5-3.5v0-8c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0 8c0 2.485 2.015 4.5 4.5 4.5s4.5-2.015 4.5-4.5v0-10c0-0.003 0-0.006 0-0.009 0-0.964-0.392-1.836-1.025-2.465l-0-0z"></path>
  </>
);
