// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileExcel = (): React.Element<*> => (
  <>
    <path
      fill="#1d7245"
      d="M16.506 18.981h-13c-1.379 0-2.5-1.122-2.5-2.5v-13c0-1.379 1.121-2.5 2.5-2.5h13c1.378 0 2.5 1.121 2.5 2.5v13c0 1.378-1.122 2.5-2.5 2.5zM3.506 1.981c-0.827 0.003-1.497 0.673-1.5 1.5v13c0.003 0.827 0.673 1.497 1.5 1.5h13c0.827-0.003 1.497-0.673 1.5-1.5v-13c-0.003-0.827-0.673-1.497-1.5-1.5h-0z"
    ></path>
    <path
      fill="#1d7245"
      d="M12.939 14.246q0.15 0.3-0.016 0.555t-0.51 0.255q-0.33 0-0.495-0.3l-1.904-3.84-1.92 3.84q-0.135 0.3-0.48 0.3t-0.518-0.255-0.022-0.555l2.295-4.62-2.115-4.275q-0.15-0.3 0.016-0.563t0.51-0.263 0.495 0.315l1.739 3.51 1.74-3.51q0.15-0.315 0.495-0.315t0.51 0.263 0.016 0.563l-2.131 4.275z"
    ></path>
  </>
);
