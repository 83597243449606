// @flow strict

import * as React from 'react';

import {
  Form,
  formSingleGroup,
  FormSingleGroup,
  Label,
  TextInput,
  FormattedContentBlock,
} from '../components';

class XSSTest extends React.Component<
  {},
  {
    unsafeValue?: string,
  },
> {
  constructor() {
    super();
    this.state = {
      unsafeValue: 'abc<script>alert("111")</script>',
    };
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h3>XSS</h3>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input" text="XSS Protect" />}
          >
            <TextInput
              id="text-input"
              name="email"
              value={this.state.unsafeValue}
              onChangeValue={() => {}}
            />
          </FormSingleGroup>
        </Form>
        <FormattedContentBlock>
          <p>
            By default, React DOM escapes any values embedded in JSX before rendering them. Thus it
            ensures that you can never inject anything that&apos;s not explicitly written in your
            application. Everything is converted to a string before being rendered. This helps
            prevent XSS (cross-site-scripting) attacks.
          </p>

          <p>
            <a href="https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml">
              {/* eslint-disable max-len */}
              https://facebook.github.io/react/docs/dom-elements.html#dangerouslysetinnerhtml
              {/* eslint-enable max-len */}
            </a>
          </p>

          <p>&nbsp must not be used</p>
        </FormattedContentBlock>
      </div>
    );
  }
}

export default XSSTest;
