// @flow strict
import * as React from 'react';
import cx from 'classnames';
import type { LozengeProps } from './Lozenge.types';
import { KeyEvent } from '../../ace-internal/types/keys';
import { configInternalTestId } from '../../ace-internal/util/util';
import { CloseButton } from '../../ace-internal/shared-component/CloseButton';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

/**
 * Lozenges are used to indicate metadata, usually to demonstrate the selections that define or summarize the information directly below the Lozenges.
 * @status released
 * @date 26/09/2018
 * @version 12.0.0
 * @tags Button
 * @category Data
 */
const Lozenge = React.memo<LozengeProps>(
  ({
    'data-test-id': testId,
    'data-ansarada-ccd': ansaradaCCD,
    text,
    closeable = false,
    className,
    closeAltText,
    onClose,
    backgroundColor,
    textColor,
  }: LozengeProps) => {
    const createInternalTestId = configInternalTestId({ componentName: 'Lozenge' });

    const _onClose = e => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (onClose) {
        onClose(true);
      }
    };

    const _handleKeys = e => {
      if (KeyEvent.isBackspace(e) || KeyEvent.isDelete(e)) {
        e.preventDefault();
        _onClose();
      }
    };

    return (
      <div
        className={cx(styles.lozenge, className)}
        data-test-id={testId}
        data-ansarada-ccd={ansaradaCCD || undefined}
        style={{ backgroundColor, color: textColor }}
      >
        <span>{text}</span>
        {closeable && (
          <CloseButton
            className={styles.close}
            onClick={_onClose}
            onKeyDown={_handleKeys}
            data-test-id={createInternalTestId('close')}
            aria-label={closeAltText || 'Close'}
          />
        )}
      </div>
    );
  },
);

Lozenge.displayName = 'Lozenge';

export { Lozenge };
