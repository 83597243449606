// @flow strict

import * as React from 'react';
import { Button } from '../components/Button';
import { EmptyState, FormattedContentBlock } from '../components';

const EmptyStateTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>EmptyState</h2>
    </FormattedContentBlock>

    <EmptyState
      image="https://static.ansarada.com/logos/0.1.0/ansarada-logo-symbol-only.svg"
      heading="Track activity on scorecards!"
      description="Track people and actions on any new scorecards you create. And it won't be long before you can track activity for all existing scorecards!"
      action={<Button variant="Primary">Create scorecard</Button>}
    />
  </div>
);

export default EmptyStateTest;
