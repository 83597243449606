// @flow strict
import React from 'react';

import {
  Button,
  Dropdown,
  DropdownAction,
  DropdownGroup,
  ExpanderPanel,
  ExpanderGroup,
  TruncateText,
  FormattedContentBlock,
  Icon,
  Lozenge,
} from '../components';

type StateType = { [string]: boolean };

class ExpanderPanelTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      expander1: false,
      expander2: true,
      dropdown1: false,
      dropdown2: false,
    };
  }

  update(address: string, value: boolean) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    const { expander1, expander2, dropdown1, dropdown2 } = this.state;
    const control1 = (
      <Dropdown
        trigger={
          <Button icon={<Icon glyph="ControlMenuNavigation" text="expand" />} size="Compact" />
        }
        open={dropdown1}
        onToggle={value => this.setState({ dropdown1: value })}
      >
        <DropdownGroup>
          <DropdownAction key="n" href="#" icon={<Icon glyph="DocumentGeneric" text="" />}>
            New
          </DropdownAction>
          <DropdownAction key="e" href="#" icon={<Icon glyph="ActionEdit" text="" />}>
            Edit
          </DropdownAction>
        </DropdownGroup>
      </Dropdown>
    );
    const control2 = (
      <Dropdown
        open={dropdown2}
        onToggle={value => this.setState({ dropdown2: value })}
        trigger={
          <Button icon={<Icon glyph="ControlMenuContextual" text="expand" />} size="Compact" />
        }
      >
        <DropdownGroup>
          <DropdownAction key="e" href="#" icon={<Icon glyph="ActionEdit" text="" />}>
            Edit
          </DropdownAction>
        </DropdownGroup>
      </Dropdown>
    );
    return (
      <div>
        <FormattedContentBlock>
          <h2>Expander Panel</h2>
        </FormattedContentBlock>

        <ExpanderGroup>
          <ExpanderPanel
            title="Initially Collapsed Expander"
            expanded={expander1}
            onExpand={value => this.update('expander1', value)}
          >
            <FormattedContentBlock>
              <p>Expander content.</p>
            </FormattedContentBlock>
          </ExpanderPanel>

          <ExpanderPanel
            title="Initially Expanded Expander"
            expanded={expander2}
            onExpand={value => this.update('expander2', value)}
          >
            <FormattedContentBlock>
              <p>Expander content.</p>
            </FormattedContentBlock>
          </ExpanderPanel>

          <ExpanderPanel defaultExpanded={false} title="Uncontrolled Expander">
            <FormattedContentBlock>
              <p>Expander content.</p>
            </FormattedContentBlock>
          </ExpanderPanel>

          <ExpanderPanel title="Uncontrolled Initially Expanded Expander" defaultExpanded>
            <FormattedContentBlock>
              <p>Expander content.</p>
            </FormattedContentBlock>
          </ExpanderPanel>

          <ExpanderPanel
            defaultExpanded={false}
            title="Expander With title and summary"
            subTitle="SubTeam"
            summary="3 people"
            lozenge={<Lozenge text="Ansarada" />}
            dropdown={control1}
          >
            <FormattedContentBlock>
              <p>Expander with border colour.</p>
            </FormattedContentBlock>
          </ExpanderPanel>

          <ExpanderPanel defaultExpanded={false} title="Expander With Colour" colour="#4A90E2">
            <FormattedContentBlock>
              <p>Expander with border colour.</p>
            </FormattedContentBlock>
          </ExpanderPanel>
        </ExpanderGroup>

        <div style={{ maxWidth: '700px', margin: '24px 0' }}>
          <ExpanderGroup>
            <ExpanderPanel
              defaultExpanded={false}
              title="Groups Expander has a really really really really really long long long long long title"
              subTitle="Sub team"
              summary="without action"
              lozenge={<Lozenge text="Ansarada" />}
            >
              not truncate title
            </ExpanderPanel>

            <ExpanderPanel
              defaultExpanded={false}
              title={
                <TruncateText truncatePosition="Tail">
                  Groups Expander has a really really really really really long long long long long
                  title
                </TruncateText>
              }
              subTitle="Sub team"
              summary="with action"
              lozenge={<Lozenge text="Ansarada" />}
              dropdown={control2}
            >
              Truncate title
            </ExpanderPanel>
          </ExpanderGroup>
        </div>
      </div>
    );
  }
}

export default ExpanderPanelTest;
