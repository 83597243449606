// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { TableDataLegacy } from '../../legacy-components/Table';

type TreeItemPropType = {
  className?: string,
  children?: React.Node,
};

/** @ignore */
export const TreeItem = (props: TreeItemPropType) => {
  const { className, children, ...rest } = props;

  const classes = classnames('ace-tree-item', className);

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <TableDataLegacy className={classes} {...rest}>
      {children}
    </TableDataLegacy>
  );
};
