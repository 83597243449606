// @flow strict
import * as React from 'react';
import classnames from 'classnames';

export type InputInfoPropType = {
  className?: String,
  children?: React.Node,
};

/** @ignore */
const InputInfo = (props: InputInfoPropType) => {
  const { className, ...rest } = props;

  const classes = classnames('ace-form-input-info', className);

  return (
    <div
      className={classes}
      // $FlowFixMe 0.112.0 - inexact rest of object pattern
      {...rest}
    >
      {props.children}
    </div>
  );
};

export { InputInfo };
