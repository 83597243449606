// @flow strict
import * as React from 'react';
import cx from 'classnames';
import { IconBeta } from '../../../components/IconBeta';
import styles from './styles.scss';
import type { testIdPropType } from '../../types/general';

type Props = {|
  ...testIdPropType,
  show: boolean,
  'aria-label'?: string,
  className?: string,
  onClick?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<HTMLElement>) => void,
  passwordShow: boolean,
|};

export const PasswordButton = ({
  onClick,
  onKeyDown,
  className,
  show = false,
  passwordShow = false,
  'data-test-id': testId,
  'aria-label': ariaLabel,
}: Props): React.Element<'button'> => (
  <button
    className={cx(styles.passwordStyles, show && styles.showStyles, className)}
    onClick={onClick}
    onKeyDown={onKeyDown}
    data-test-id={testId}
    aria-label={ariaLabel}
    type="button"
  >
    <IconBeta
      glyph={passwordShow ? 'Eye-view' : 'Eye-hide'}
      label={passwordShow ? 'Hide password' : 'Show password'}
    />
  </button>
);
