// @flow strict
import React from 'react';

import {
  Button,
  icon,
  Icon,
  Dropdown,
  DropdownGroup,
  DropdownAction,
  message,
  Message,
  Close,
  TableLegacy,
  TableHeadLegacy,
  TableBodyLegacy,
  TableRowLegacy,
  TableHeaderLegacy,
  TableDataLegacy,
  FormattedContentBlock,
} from '../components';

type StateType = {
  dropdown1: boolean,
  dropdown2: boolean,
  dropdown3: boolean,
  dropdown4: boolean,
  dropdown5: boolean,
  dropdown6: boolean,
  dropdown7: boolean,
  showMessage: boolean,
};

class DropdownTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      dropdown1: false,
      dropdown2: false,
      dropdown3: false,
      dropdown4: false,
      dropdown5: false,
      dropdown6: false,
      dropdown7: false,
      showMessage: false,
    };
  }

  update(address: string, value: boolean) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    const {
      dropdown1,
      dropdown2,
      dropdown3,
      dropdown4,
      dropdown5,
      dropdown6,
      dropdown7,
      showMessage,
    } = this.state;

    return (
      <div>
        <FormattedContentBlock>
          <h2>Dropdown</h2>
        </FormattedContentBlock>
        <Button>Random button</Button>
        <Dropdown
          data-test-id="dropdownWrapper"
          trigger={<Button>Click to open dropdown</Button>}
          open={dropdown1}
          onToggle={value => this.update('dropdown1', value)}
        >
          <DropdownGroup header="Heading">
            <DropdownAction href="#">Some action</DropdownAction>
            <DropdownAction href="#" target="_blank">
              target=_blank
            </DropdownAction>
            <DropdownAction href="#" target="_self">
              target=_self
            </DropdownAction>
            <DropdownAction disabled href="#">
              Disabled action
            </DropdownAction>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.ActionEdit} text="" />}
              href="#/tests/ButtonTest"
            >
              Go to Button tests
            </DropdownAction>
          </DropdownGroup>
          <DropdownGroup header="Another Heading">
            <DropdownAction href="#">Another action with a link</DropdownAction>
            <DropdownAction href="#" disabled>
              Another disabled action
            </DropdownAction>
            <DropdownAction icon={<Icon glyph={icon.Glyphs.ActionEdit} text="" />} href="#">
              Another icon action
            </DropdownAction>
          </DropdownGroup>
          <DropdownGroup>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.ObjectsQuestion} text="" />}
              onClick={() => {
                this.setState({ ...this.state, showMessage: true });
              }}
            >
              Knock knock
            </DropdownAction>
          </DropdownGroup>
        </Dropdown>

        {showMessage && (
          <Message
            variant={message.Variants.Good}
            close={<Close onUpdate={value => this.update('showMessage', value)} />}
          >
            Hi! Who is there?
          </Message>
        )}

        <FormattedContentBlock>
          <h2>A second dropdown</h2>
          <p>... added space to make it easier to see the dropdown position change</p>
          <p>... continuing to add space to make it easier to see the dropdown position change</p>
          <p>... and yet more space to make it easier to see the dropdown position change</p>
          <p>... and more words</p>
        </FormattedContentBlock>

        <Dropdown
          trigger={<Button>Click to open dropdown</Button>}
          open={dropdown2}
          onToggle={value => this.update('dropdown2', value)}
        >
          <DropdownGroup header="Vegetables">
            <DropdownAction href="#/tests/DropdownTest">Carrots</DropdownAction>
            <DropdownAction disabled href="#">
              Peas
            </DropdownAction>
          </DropdownGroup>
          <DropdownGroup header="Fruits">
            {false === true && <DropdownAction href="#">Apples</DropdownAction>}

            <DropdownAction disabled href="#">
              Oranges
            </DropdownAction>
          </DropdownGroup>
        </Dropdown>

        <FormattedContentBlock>
          <h2>A third oversized dropdown</h2>
        </FormattedContentBlock>

        <Dropdown
          trigger={<Button>Click to open dropdown</Button>}
          open={dropdown3}
          onToggle={value => this.update('dropdown3', value)}
        >
          <DropdownGroup>
            <DropdownAction href="#">A</DropdownAction>
            <DropdownAction href="#">B</DropdownAction>
            <DropdownAction href="#">C</DropdownAction>
            <DropdownAction href="#">D</DropdownAction>
            <DropdownAction href="#">E</DropdownAction>
            <DropdownAction href="#">F</DropdownAction>
            <DropdownAction href="#">G</DropdownAction>
            <DropdownAction href="#" selected>
              H
            </DropdownAction>
            <DropdownAction href="#">I</DropdownAction>
            <DropdownAction href="#">J</DropdownAction>
            <DropdownAction href="#">K</DropdownAction>
            <DropdownAction href="#">L</DropdownAction>
            <DropdownAction href="#">M</DropdownAction>
            <DropdownAction href="#">N</DropdownAction>
            <DropdownAction href="#">O</DropdownAction>
            <DropdownAction href="#">P</DropdownAction>
            <DropdownAction href="#">Q</DropdownAction>
            <DropdownAction href="#">R</DropdownAction>
            <DropdownAction href="#">S</DropdownAction>
            <DropdownAction href="#">T</DropdownAction>
            <DropdownAction href="#">U</DropdownAction>
            <DropdownAction href="#">V</DropdownAction>
            <DropdownAction href="#">W</DropdownAction>
            <DropdownAction href="#">X</DropdownAction>
            <DropdownAction href="#">Y</DropdownAction>
            <DropdownAction href="#">Z</DropdownAction>
          </DropdownGroup>
        </Dropdown>

        <h2>A dropdown with annotations</h2>
        <Dropdown
          trigger={<Button>Open Sesame</Button>}
          open={dropdown7}
          onToggle={value => this.update('dropdown7', value)}
        >
          <DropdownGroup>
            <DropdownAction href="#" annotation="New">
              Download all
            </DropdownAction>
            <DropdownAction href="#" annotation="Advanced">
              Encrypt
            </DropdownAction>
            <DropdownAction href="#" annotation="Risky" selected>
              Delete
            </DropdownAction>
          </DropdownGroup>
        </Dropdown>

        <h2>A dropdown too far to the right</h2>

        <div
          style={{
            display: 'flex',
            height: '40px',
            background: '#BABCBE',
            flexDirection: 'row-reverse',
          }}
        >
          <div style={{ marginRight: '-20px', marginTop: '5px' }}>
            <Dropdown
              trigger={<Button>Click to open dropdown</Button>}
              open={dropdown4}
              onToggle={value => this.update('dropdown4', value)}
            >
              <DropdownGroup header="Vegetables">
                <DropdownAction href="#">Carrots</DropdownAction>
                <DropdownAction disabled href="#">
                  Peas
                </DropdownAction>
              </DropdownGroup>
              <DropdownGroup header="Fruits">
                <DropdownAction href="#">Apples</DropdownAction>
                <DropdownAction disabled href="#">
                  Oranges
                </DropdownAction>
              </DropdownGroup>
            </Dropdown>
          </div>
        </div>

        <div style={{ height: '40px' }} />

        <div style={{ overflow: 'scroll', height: '600px', width: '100%' }}>
          <TableLegacy>
            <TableHeadLegacy>
              <TableRowLegacy>
                <TableHeaderLegacy>Name</TableHeaderLegacy>
                <TableHeaderLegacy>Controls</TableHeaderLegacy>
              </TableRowLegacy>
            </TableHeadLegacy>
            <TableBodyLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy>
                  <Dropdown
                    data-test-id="dropdownWrapper5"
                    trigger={<Button>Click to open dropdown</Button>}
                    open={dropdown5}
                    onToggle={value => this.update('dropdown5', value)}
                  >
                    <DropdownGroup header="Heading">
                      <DropdownAction disabled href="#">
                        Disabled action
                      </DropdownAction>
                      <DropdownAction
                        icon={<Icon glyph={icon.Glyphs.ActionEdit} text="" />}
                        href="#"
                      >
                        Icon action
                      </DropdownAction>
                    </DropdownGroup>
                    <DropdownGroup header="Another Heading">
                      <DropdownAction href="#">Another action with a link</DropdownAction>
                      <DropdownAction disabled href="#">
                        Another disabled action
                      </DropdownAction>
                      <DropdownAction disabled href="#">
                        This is a very very very very very very very very very very very very really
                        long link text and is not recommend
                      </DropdownAction>
                      <DropdownAction
                        icon={<Icon glyph={icon.Glyphs.ActionEdit} text="" />}
                        href="#"
                      >
                        Another icon action
                      </DropdownAction>
                      <DropdownAction href="#">
                        This is a very very very very very very very very very very very very really
                        long link text and is not recommend
                      </DropdownAction>
                      <DropdownAction icon={<Icon glyph={icon.Glyphs.ActionEdit} />} href="#">
                        This is a very very very very very very very very very very very very really
                        long link text and is not recommend
                      </DropdownAction>
                    </DropdownGroup>
                    <DropdownGroup>
                      <DropdownAction
                        icon={<Icon glyph={icon.Glyphs.ObjectsQuestion} text="" />}
                        onClick={() => {
                          this.setState({ ...this.state, showMessage: true });
                        }}
                      >
                        Knock knock
                      </DropdownAction>
                    </DropdownGroup>
                  </Dropdown>
                </TableDataLegacy>
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
            </TableBodyLegacy>
          </TableLegacy>
        </div>

        <div style={{ height: '400px' }} />

        <Dropdown
          data-test-id="dropdownWrapper6"
          trigger={<Button>Click to open dropdown</Button>}
          open={dropdown6}
          onToggle={value => this.update('dropdown6', value)}
        >
          <DropdownGroup header="Heading">
            <DropdownAction href="#">Some action</DropdownAction>
            <DropdownAction href="#" disabled>
              Disabled action
            </DropdownAction>
            <DropdownAction icon={<Icon glyph={icon.Glyphs.ActionEdit} text="" />} href="#">
              Icon action
            </DropdownAction>
          </DropdownGroup>
          <DropdownGroup header="Another Heading">
            <DropdownAction href="#">Another action with a link</DropdownAction>
            <DropdownAction disabled href="#">
              Another disabled action
            </DropdownAction>
            <DropdownAction icon={<Icon glyph={icon.Glyphs.ActionEdit} text="" />} href="#">
              Another icon action
            </DropdownAction>
          </DropdownGroup>
          <DropdownGroup>
            <DropdownAction href="#">hi</DropdownAction>
            <DropdownAction
              icon={<Icon glyph={icon.Glyphs.ObjectsQuestion} text="" />}
              onClick={() => {
                this.setState({
                  ...this.state,
                  dropdown1: false,
                  showMessage: true,
                });
              }}
            >
              Knock knock
            </DropdownAction>
          </DropdownGroup>
        </Dropdown>
      </div>
    );
  }
}

export default DropdownTest;
