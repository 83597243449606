// @flow strict
import React from 'react';
import { TextInput } from '../../components/TextInput';
import { TextArea } from '../../components/TextArea';

const withWrapper = (Component: *) =>
  class ControlledTextInputWrapper extends React.Component<*, *> {
    state: { [string]: string };

    constructor(props: *) {
      super(props);
      this.state = {
        input: props.initValue,
      };
    }

    update(address: string, value: string) {
      this.setState({ ...this.state, [address]: value });
    }

    render() {
      return (
        <Component
          {...this.props}
          value={this.state.input}
          onUpdate={v => this.update('input', v)}
        />
      );
    }
  };

export const ControlledTextInput = withWrapper(TextInput);
export const ControlledTextArea = withWrapper(TextArea);
