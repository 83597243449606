// @flow strict
import * as React from 'react';

import { BaseButton } from '../Button/Internal/BaseButton';
import { Icon } from '../Icon';
import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';

export type LinkPropType = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  children?: React.ChildrenArray<string | void | false>,
  className?: string,
  icon?: React.Element<typeof Icon>,
  loading?: boolean,
  pressed?: boolean,
  id?: string,
  onMouseDown?: (e: SyntheticMouseEvent<>) => void,
  onMouseUp?: (e: SyntheticMouseEvent<>) => void,
  onMouseOver?: (e: SyntheticMouseEvent<>) => void,
  onMouseEnter?: (e: SyntheticMouseEvent<>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<>) => void,
  onMouseOut?: (e: SyntheticMouseEvent<>) => void,
  onBlur?: (e: SyntheticFocusEvent<>) => void,
  onClick?: (e: SyntheticMouseEvent<>) => void,
  onFocus?: (e: SyntheticFocusEvent<>) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<>) => void,
  buttonRef?: () => ?HTMLButtonElement | ?HTMLAnchorElement,
  href?: string,
  target?: string,
  download?: string,
  disabled?: boolean,
|};

/**
 *
 * Links are used to take users from one page to another, and do not affect or change the content displayed on the current webpage.
 *
 *
 * Use a link when a user is expected to be **taken to a different page.**
 *
 * @version 9.6.0
 * @status released
 * @date 26/09/2015
 * @tags Button
 * @category Navigation
 */
export const Link = ({ ...props }: LinkPropType) => (
  // $FlowFixMe 0.112.0 - inexact rest of object pattern
  <BaseButton {...props} variant="Link" type="link" />
);
