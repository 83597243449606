// @flow strict
import * as React from 'react';
import classnames from 'classnames';

const Glyphs = Object.freeze({
  ActionAllow: 'ActionAllow',
  ActionApproveAnswer: 'ActionApproveAnswer',
  ActionApproveAnswers: 'ActionApproveAnswers',
  ActionArchive: 'ActionArchive',
  ActionBulkEmail: 'ActionBulkEmail',
  ActionCollapseAll: 'ActionCollapseAll',
  ActionCopy: 'ActionCopy',
  ActionCopyDisabled: 'ActionCopyDisabled',
  ActionCopyEnabled: 'ActionCopyEnabled',
  ActionCopyInactive: 'ActionCopyInactive',
  ActionDeny: 'ActionDeny',
  ActionDisable: 'ActionDisable',
  ActionView: 'ActionView',
  ActionDownload: 'ActionDownload',
  ActionEdit: 'ActionEdit',
  ActionEditDisabled: 'ActionEditDisabled',
  ActionEditEnabled: 'ActionEditEnabled',
  ActionEditInactive: 'ActionEditInactive',
  ActionEnable: 'ActionEnable',
  ActionExpandAll: 'ActionExpandAll',
  ActionForwardAnswer: 'ActionForwardAnswer',
  ActionForwardAnswers: 'ActionForwardAnswers',
  ActionLink: 'ActionLink',
  ActionExternalLink: 'ActionExternalLink',
  ActionManageQuestions: 'ActionManageQuestions',
  ActionMove: 'ActionMove',
  ActionNumber: 'ActionNumber',
  ActionPrint: 'ActionPrint',
  ActionPrintDisabled: 'ActionPrintDisabled',
  ActionPrintEnabled: 'ActionPrintEnabled',
  ActionPrintInactive: 'ActionPrintInactive',
  ActionRemove: 'ActionRemove',
  ActionRenumber: 'ActionRenumber',
  ActionRestore: 'ActionRestore',
  ActionSaveEnabled: 'ActionSaveEnabled',
  ActionSaveDisabled: 'ActionSaveDisabled',
  ActionSaveInactive: 'ActionSaveInactive',
  ActionSearch: 'ActionSearch',
  ActionSendInvite: 'ActionSendInvite',
  ActionUpload: 'ActionUpload',
  AddAttachment: 'AddAttachment',
  AddBuyside: 'AddBuyside',
  AddDocument: 'AddDocument',
  AddFolder: 'AddFolder',
  AddGeneric: 'AddGeneric',
  AddPolicy: 'AddPolicy',
  AddSecurity: 'AddSecurity',
  AddSellside: 'AddSellside',
  AddSubject: 'AddSubject',
  AddSubjectGroup: 'AddSubjectGroup',
  AddWatermark: 'AddWatermark',
  ControlArrowDown: 'ControlArrowDown',
  ControlArrowLeft: 'ControlArrowLeft',
  ControlArrowRight: 'ControlArrowRight',
  ControlArrowUp: 'ControlArrowUp',
  ControlClose: 'ControlClose',
  ControlCloseSmall: 'ControlCloseSmall',
  ControlCollapsed: 'ControlCollapsed',
  ControlCollapsedLight: 'ControlCollapsedLight',
  ControlExpanded: 'ControlExpanded',
  ControlFilter: 'ControlFilter',
  ControlMenu: 'ControlMenu',
  ControlMenuContextual: 'ControlMenuContextual',
  ControlMenuNavigation: 'ControlMenuNavigation',
  ControlOff: 'ControlOff',
  ControlOn: 'ControlOn',
  ControlSortUp: 'ControlSortUp',
  ControlSortDown: 'ControlSortDown',
  NOGLYPH: 'NOGLYPH',
  DocumentAcrobat: 'DocumentAcrobat',
  DocumentArchive: 'DocumentArchive',
  DocumentAudio: 'DocumentAudio',
  DocumentCode: 'DocumentCode',
  DocumentContact: 'DocumentContact',
  DocumentEmail: 'DocumentEmail',
  DocumentExcel: 'DocumentExcel',
  DocumentGeneric: 'DocumentGeneric',
  DocumentImage: 'DocumentImage',
  DocumentLayoutAndDesign: 'DocumentLayoutAndDesign',
  DocumentPowerpoint: 'DocumentPowerpoint',
  DocumentPowerPoint: 'DocumentPowerPoint',
  DocumentText: 'DocumentText',
  DocumentVideo: 'DocumentVideo',
  DocumentVisio: 'DocumentVisio',
  DocumentWord: 'DocumentWord',
  ObjectsActions: 'ObjectsActions',
  ObjectsAdministrator: 'ObjectsAdministrator',
  ObjectsArrowindent: 'ObjectsArrowindent',
  ObjectsAnswer: 'ObjectsAnswer',
  ObjectsBuyside: 'ObjectsBuyside',
  ObjectsBuysideGroup: 'ObjectsBuySideGroup',
  ObjectsChevronRight: 'ObjectsChevronRight',
  ObjectsChevronDown: 'ObjectsChevronDown',
  ObjectsCloud: 'ObjectsCloud',
  ObjectsColour: 'ObjectsColour',
  ObjectsComment: 'ObjectsComment',
  ObjectsDate: 'ObjectsDate',
  ObjectsEmptyFolder: 'ObjectsEmptyFolder',
  ObjectsFolder: 'ObjectsFolder',
  ObjectsFolderRoot: 'ObjectsFolderRoot',
  ObjectsHistory: 'ObjectsHistory',
  ObjectsLanguage: 'ObjectsLanguage',
  ObjectsPolicy: 'ObjectsPolicy',
  ObjectsQuestion: 'ObjectsQuestion',
  ObjectsReport: 'ObjectsReport',
  ObjectsSecurity: 'ObjectsSecurity',
  ObjectsSellside: 'ObjectsSellside',
  ObjectsSellsideGroup: 'ObjectsSellSideGroup',
  ObjectsSettings: 'ObjectsSettings',
  ObjectsSubject: 'ObjectsSubject',
  ObjectsSubjectGroup: 'ObjectsSubjectGroup',
  ObjectsSubmissionLimits: 'ObjectsSubmissionLimits',
  ObjectsUser: 'ObjectsUser',
  ObjectsUsergroup: 'ObjectsUsergroup',
  ObjectsWatermark: 'ObjectsWatermark',
  ObjectsAnswerapprover: 'ObjectsAnswerapprover',
  ObjectsAnswerauthor: 'ObjectsAnswerauthor',
  ObjectsQnaadministrator: 'ObjectsQnaadministrator',
  ObjectsQnaobserver: 'ObjectsQnaobserver',
  ObjectsQuestionapprover: 'ObjectsQuestionapprover',
  ObjectsQuestionauthor: 'ObjectsQuestionauthor',
  ObjectsQuestionviewer: 'ObjectsQuestionviewer',
  StatusAdded: 'StatusAdded',
  StatusAwaitingAction: 'StatusAwaitingAction',
  StatusCaution: 'StatusCaution',
  StatusDisabled: 'StatusDisabled',
  StatusEmailNotSent: 'StatusEmailNotSent',
  StatusEmailSent: 'StatusEmailSent',
  StatusError: 'StatusError',
  StatusHelp: 'StatusHelp',
  StatusInformation: 'StatusInformation',
  StatusLoading: 'StatusLoading',
  StatusNo: 'StatusNo',
  StatusNotViewed: 'StatusNotViewed',
  StatusPriorityHigh: 'StatusPriorityHigh',
  StatusPriorityLow: 'StatusPriorityLow',
  StatusPriorityMedium: 'StatusPriorityMedium',
  StatusSecure: 'StatusSecure',
  StatusSuccess: 'StatusSuccess',
  StatusSwatch: 'StatusSwatch',
  StatusUnsecure: 'StatusUnsecure',
  StatusYes: 'StatusYes',
  StatusHighPriority: 'StatusHighPriority',
  StatusMediumPriority: 'StatusMediumPriority',
  StatusLowPriority: 'StatusLowPriority',
  ActionBold: 'ActionBold',
  ActionBoldActive: 'ActionBoldActive',
  ActionItalic: 'ActionItalic',
  ActionItalicActive: 'ActionItalicActive',
  ActionUnderline: 'ActionUnderline',
  ActionUnderlineActive: 'ActionUnderlineActive',
  ActionList: 'ActionList',
  ActionListActive: 'ActionListActive',
  IntegrationKiraSystems: 'IntegrationKiraSystems',
});

export type IconPropType = {
  className?: string,
  glyph: $Keys<typeof Glyphs> | string,
  text?: string,
  title?: string,
  size?: 'Small' | 'Medium' | 'Large' | 'XLarge' | 'XXLarge',
  colour?: string,
};

/*
  Icon component
*/

const stringLowerCase = (text: string) => text.charAt(0).toLowerCase() + text.slice(1);

const getGlyphCssName = (name: string, baseCssClass: string) => {
  if (name === Glyphs.NOGLYPH) {
    return;
  }

  if (!name.includes('-')) {
    // eslint-disable-next-line consistent-return
    return `${baseCssClass}-${stringLowerCase(name)
      .replace(/([A-Z])/, '-$1')
      .toLowerCase()}`;
  }

  // eslint-disable-next-line consistent-return
  return `${baseCssClass}-${name}`;
};

/**
 * Icons are an important element in our product. Their purpose is to visually convey functions and concepts in a small space, while aiding people in recognising these functions and concepts, while also speeding up comprehension and interaction.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Button
 * @category Buttons
 */
const Icon = (props: IconPropType) => {
  const { glyph, size, text, title, colour, className, ...rest } = props;
  const baseCssClass = 'ace-icon';
  const iconSizeClass = size ? `${baseCssClass}-${size.toString().toLowerCase()}` : undefined;

  const classes = classnames(
    baseCssClass,
    getGlyphCssName(glyph, baseCssClass),
    iconSizeClass,
    className,
  );

  const style = { color: colour };

  return (
    // $FlowFixMe 0.125.0
    <span
      className={classes}
      style={colour ? style : undefined}
      title={title}
      role={text ? undefined : 'presentation'}
      {...rest}
    >
      {text}
    </span>
  );
};

export { Icon, Glyphs };
