// @flow strict
import * as React from 'react';
import type { testIdPropType } from '../../ace-internal/types/general';
import { Column, Columns } from '../Columns';
import styles from './styles.scss';

type DisplayOrder = 'ImageThenContent' | 'ContentThenImage';

export type Props = {|
  ...testIdPropType,
  /** Title for the hero component */
  title?: string,

  /** Source URL to the image that wil be displayed as part of the
   * Hero component */
  src: string,

  /** Image alt text */
  alt: string,

  /** In what order should the contents be displayed. ImageThenContent
   * renders the image on the left and the content on the right.
   * ContentThenImage renders the content on the right and the image
   * on the left
   * */
  displayOrder?: DisplayOrder,

  /** This is a collection or p tags to display inside the content
   * area. This assumes that only inline elements are inside the p
   * tags and only styles them as per typography rules. */
  content: React.ChildrenArray<React.Element<'p'>>,

  /** Collection of Buttons or Links to display below the content. */
  ctas?: React.ChildrenArray<React.Element<*>>,
|};

/**
 * Hero component is a simple layout component that allows a side
 * by side Image and content. It is a different representation of
 * the Columns component but fixed to always have these 2 columns
 * only.
 *
 * The order in which we display the image and the content can be
 * configured via props.
 *
 * @status released
 * @date 23/06/2020
 * @version 14.3.0
 * @tags Layout, Card
 * @category Data
 */
const Hero = ({ 'data-test-id': testId, displayOrder, src, alt, title, content, ctas }: Props) => {
  const order: DisplayOrder = displayOrder != null ? displayOrder : 'ImageThenContent';
  const columns: React.ChildrenArray<React.Element<typeof Column>> = [
    <Column key="heroImage" alignment="Middle">
      <img className={styles.heroImage} src={src} alt={alt} />
    </Column>,
    <Column key="heroContent">
      <div className={styles.content}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {content}
      </div>
      <div className={styles.ctas}>{ctas}</div>
    </Column>,
  ].sort(() => (order === 'ImageThenContent' ? 0 : -1));
  return <Columns data-test-id={testId}>{columns}</Columns>;
};

export { Hero };
