// @flow strict
import * as React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../style.scss';

type ChevronProps = {|
  disabled?: boolean,
  direction?: 'Right' | 'Left',
|};

/** @ignore */
const Chevron = ({ disabled = false, direction = 'Left' }: ChevronProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={styles.chevron}
    style={{
      transform: direction === 'Right' ? 'scaleX(-1)' : undefined,
    }}
  >
    <path
      fill={disabled ? '#A7A7AA' : '#57575A'}
      d="M13.89 10l-6.36 6.36-1.42-1.41L11.06 10 6.11 5.05l1.42-1.41L13.89 10z"
    />
  </svg>
);

export { Chevron };
