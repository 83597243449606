// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { configInternalTestId } from '../../ace-internal/util/util';
import { TruncateText } from '../TruncateText';

import { KeyEvent } from '../../ace-internal/types/keys';
import scopeTab from './scopeTab';
import * as focusManager from './focusManager';
import { getScrollbarWidth, doesScrollbarTakeWidth } from './helpers';

import { PageHidden } from '../PageHidden';
import { Button } from '../Button';
import { Icon, Glyphs } from '../Icon';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './style.scss';

type Props = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  /** Sets size of dialog. Noted that small dialog will not have X button in the header */
  size: 'Small' | 'Medium' | 'Large' | 'FullScreen',
  /** Alt text for icon close in header */
  closeAltText: string,
  /** Indicates if clicking outside of dialog or pressing esc key can close that dialog or not. If set, it also removes the X to close button in the dialog header */
  disableDefaultClose: boolean,
  /** Title in header */
  title: string | React.Element<'span'> | React.Element<typeof TruncateText>,
  /** Primary button actions on the right side of footer */
  footerPrimaryActions?: React.ChildrenArray<React.Element<typeof Button> | void | false>,
  /** Header actions */
  headerActions?: React.ChildrenArray<React.Element<typeof Button> | void | false>,
  /** Secondary actions on the left side of footer */
  footerSecondaryActions?: React.ChildrenArray<React.Element<*> | void | false>,
  /** Dialog contents */
  children: React.Node,
  /** Callback function for when the Dialog state changes */
  onClose: (open: boolean) => void,

  dynamicHeight?: boolean,
|};

const createInternalTestId = configInternalTestId({ componentName: 'Dialog' });

/**
 * The Dialog component is used to show content on top of an overlay. While open, it blocks any interaction with the page below – until the overlay is clicked, or a close action is triggered.
 *
 *
 * Best used when you want the user to interact with the platform however don’t want them to jump to a different page and break their workflow.
 *
 *
 * You can also use Dialog where you require confirmation from the user before doing a lengthy or dangerous action. This could be a deletion of some sorts or initiating a lengthy download.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Button
 * @category Feedback
 */
const Dialog = ({
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  title,
  disableDefaultClose,
  size,
  closeAltText,
  children,
  footerPrimaryActions,
  footerSecondaryActions,
  headerActions,
  onClose,
  dynamicHeight,
}: Props) => {
  const [dialog, setDialog] = React.useState();
  const classes = classnames(styles.wrapper, styles[`dialog${size}`]);
  const hasPrimaryActions = React.Children.count(footerPrimaryActions) > 0;

  const contentHasFocus = React.useCallback(() => {
    if (dialog) {
      return document.activeElement === dialog || dialog.contains(document.activeElement);
    }
    return true;
  }, [dialog]);

  // Don't steal focus from inner elements
  const focusContent = React.useCallback(() => {
    return dialog && !contentHasFocus() && dialog.focus();
  }, [dialog, contentHasFocus]);

  const closeDialog = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    onClose(false);
  };

  const onAutoClose = (e: SyntheticEvent<*>) => {
    if (!disableDefaultClose) {
      closeDialog(e);
    }
  };

  const handleKeyDown = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (KeyEvent.isTab(e)) {
      if (dialog) {
        scopeTab(dialog, e);
      }
      return;
    }
    if (KeyEvent.isEscape(e)) {
      onAutoClose(e);
    }
  };

  React.useEffect(() => {
    if (dialog) {
      if (doesScrollbarTakeWidth()) {
        const { body } = window.document;
        const bodyPad = parseInt(body.style.paddingRight || 0, 10);
        body.style.paddingRight = `${bodyPad + getScrollbarWidth()}px`;
      }

      window.document.body.style.setProperty('overflow', 'hidden');
      focusManager.setupScopedFocus(dialog);
      focusManager.markForFocusLater();
      focusContent();
    }

    return () => {
      window.document.body.style.removeProperty('overflow');
      window.document.body.style.removeProperty('padding-right');
      if (dialog) {
        focusManager.returnFocus();
      }
      focusManager.teardownScopedFocus();
    };
  }, [dialog, focusContent]);

  // interlace dividers in between footer elements
  const secondaryFooter = !footerSecondaryActions
    ? null
    : // eslint-disable-next-line unicorn/prefer-flat-map
      []
        .concat(
          ...React.Children.map(footerSecondaryActions, (item, index) => [
            // eslint-disable-next-line react/no-array-index-key
            <span className={styles.divider} key={`secondary-footer-divider${index}`} />,
            item,
          ]),
        )
        .slice(1);

  return (
    /* eslint jsx-a11y/click-events-have-key-events: off */
    /* eslint jsx-a11y/no-static-element-interactions: off */
    /* eslint jsx-a11y/no-noninteractive-element-interactions: off */
    <PageHidden data-test-id={testId}>
      <div
        data-test-id={createInternalTestId('mask')}
        onClick={onAutoClose}
        className={styles.backdrop}
      />
      <div
        data-test-id={createInternalTestId('wrapper')}
        role="dialog"
        aria-label={title}
        className={classes}
        onKeyDown={handleKeyDown}
        ref={el => setDialog(el)}
        tabIndex="-1"
      >
        <div className={classnames(styles.dialog, dynamicHeight && styles.dynamicHeight)}>
          <div className={styles.dialogHeader}>
            <div className={styles.dialogHeaderWrapper}>
              <h2
                data-test-id={createInternalTestId('title')}
                className={styles.dialogHeaderTitle}
                data-ansarada-ccd={ansaradaCCD || undefined}
              >
                {title}
              </h2>
              {!/Small|FullScreen/.test(size) && !disableDefaultClose && (
                <Button
                  data-test-id={createInternalTestId('close')}
                  variant="Ghost"
                  size="Compact"
                  icon={<Icon glyph={Glyphs.ControlClose} text={closeAltText} />}
                  onClick={closeDialog}
                />
              )}
              {size === 'FullScreen' && headerActions && (
                <div className={styles.headerActions}>{headerActions}</div>
              )}
            </div>
          </div>
          <div className={styles.dialogContent}>
            <div className={styles.dialogContentWrapper}>{children}</div>
          </div>
          {(secondaryFooter || hasPrimaryActions) && (
            <div className={styles.dialogFooter}>
              {secondaryFooter && (
                <div className={styles.dialogFooterSecondary}>{secondaryFooter}</div>
              )}
              {hasPrimaryActions && (
                <div className={styles.dialogFooterPrimary}>{footerPrimaryActions}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </PageHidden>
  );
};

Dialog.defaultProps = {
  size: 'Medium',
  disableDefaultClose: true,
  closeAltText: 'close',
};

export { Dialog };
