// @flow strict

import * as React from 'react';

import { Tooltip, Button, icon, Icon, Dialog, FormattedContentBlock } from '../components';

type StateType = { [string]: boolean | void };

class TooltipTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      tooltip1: false,
      tooltip2: false,
      tooltip3: false,
      tooltip4: false,
      tooltip5: false,
      tooltip6: false,
      tooltip7: false,
      tooltip8: false,
      tooltip9: false,
      tooltip10: false,
      tooltip11: false,
      tooltip12: false,
      tooltip13: undefined,
      dialog1: false,
    };
  }

  update(address: string, value: boolean) {
    this.setState({ [address]: value });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Default</h2>
        </FormattedContentBlock>
        <Tooltip
          id="tooltip1"
          text="Hello World"
          open={this.state.tooltip1}
          onTrigger={v => this.update('tooltip1', v)}
        >
          <Button>Open bottom</Button>
        </Tooltip>
        <FormattedContentBlock>
          <h2>Placement top</h2>
        </FormattedContentBlock>
        <Tooltip
          id="tooltip2"
          text="Hello World"
          open={this.state.tooltip2}
          onTrigger={v => this.update('tooltip2', v)}
          position="Top"
        >
          <Button>Open top</Button>
        </Tooltip>

        <FormattedContentBlock>
          <h2>Placement left</h2>
        </FormattedContentBlock>

        <Tooltip
          id="tooltip3"
          text="Hello World"
          open={this.state.tooltip3}
          onTrigger={v => this.update('tooltip3', v)}
          position="Left"
        >
          <Button>Open left</Button>
        </Tooltip>

        <FormattedContentBlock>
          <h2>Placement Right</h2>
        </FormattedContentBlock>

        <Tooltip
          id="tooltip4"
          text="Hello World"
          open={this.state.tooltip4}
          onTrigger={v => this.update('tooltip4', v)}
          position="Right"
        >
          <Button>Open right</Button>
        </Tooltip>

        <FormattedContentBlock>
          <h2>Icon small</h2>
        </FormattedContentBlock>

        <Tooltip
          id="tooltip5"
          text="Print enabled"
          open={this.state.tooltip5}
          onTrigger={v => this.update('tooltip5', v)}
        >
          <Button
            variant="Ghost"
            icon={<Icon glyph={icon.Glyphs.ActionPrintEnabled} key="4" text="Print enabled" />}
          />
        </Tooltip>
        <FormattedContentBlock>
          <h2>Icon large</h2>
        </FormattedContentBlock>
        <Tooltip
          id="tooltip6"
          text="Print enabled"
          open={this.state.tooltip6}
          onTrigger={v => this.update('tooltip6', v)}
        >
          <Button
            variant="Ghost"
            icon={
              <Icon
                glyph={icon.Glyphs.ActionPrintEnabled}
                key="4"
                text="Print enabled"
                size="Large"
              />
            }
          />
        </Tooltip>

        <FormattedContentBlock>
          <h2>Icon large</h2>
        </FormattedContentBlock>

        <Tooltip
          id="tooltip7"
          text="Print enabled"
          open={this.state.tooltip7}
          onTrigger={v => this.update('tooltip7', v)}
        >
          <Button
            variant="Ghost"
            icon={
              <Icon
                glyph={icon.Glyphs.ActionPrintEnabled}
                key="4"
                text="Print enabled"
                size="XLarge"
              />
            }
          />
        </Tooltip>

        <FormattedContentBlock>
          <h2>Icon large</h2>
        </FormattedContentBlock>
        <Tooltip
          id="tooltip8"
          text="Print enabled"
          open={this.state.tooltip8}
          onTrigger={v => this.update('tooltip8', v)}
        >
          <Button
            variant="Ghost"
            icon={
              <Icon
                glyph={icon.Glyphs.ActionPrintEnabled}
                key="4"
                text="Print enabled"
                size="XXLarge"
              />
            }
          />
        </Tooltip>

        <FormattedContentBlock>
          <h2>Icon large Right</h2>
        </FormattedContentBlock>

        <Tooltip
          id="tooltip9"
          text="Print enabled"
          open={this.state.tooltip9}
          onTrigger={v => this.update('tooltip9', v)}
          position="Right"
        >
          <Button
            variant="Ghost"
            icon={
              <Icon
                glyph={icon.Glyphs.ActionPrintEnabled}
                key="4"
                text="Print enabled"
                size="XXLarge"
              />
            }
          />
        </Tooltip>

        <FormattedContentBlock>
          <h2>Icon large Left</h2>
        </FormattedContentBlock>

        <Tooltip
          id="tooltip10"
          text="Print enabled"
          open={this.state.tooltip10}
          onTrigger={v => this.update('tooltip10', v)}
          position="Left"
        >
          <Button
            variant="Ghost"
            disabled
            icon={
              <Icon
                glyph={icon.Glyphs.ActionPrintEnabled}
                key="4"
                text="Print enabled"
                size="XXLarge"
              />
            }
          />
        </Tooltip>

        <FormattedContentBlock>
          <h2>Icon large Scroll</h2>
        </FormattedContentBlock>
        <div
          style={{
            overflow: 'scroll',
            height: '50px',
            paddingTop: '400px',
            width: '50px',
            paddingLeft: '400px',
          }}
        >
          <Tooltip
            id="tooltip11"
            text="Print enabled"
            open={this.state.tooltip11}
            onTrigger={v => this.update('tooltip11', v)}
          >
            <Button
              variant="Ghost"
              icon={
                <Icon
                  glyph={icon.Glyphs.ActionPrintEnabled}
                  key="4"
                  text="Print enabled"
                  size="XXLarge"
                />
              }
            />
          </Tooltip>
        </div>
        <div>
          <Button onClick={() => this.update('dialog1', true)}>
            Open medium dialog in page content
          </Button>

          {this.state.dialog1 && (
            <Dialog
              size="Medium"
              title="ACE Dialog"
              footerPrimaryActions={[
                <Button onClick={() => this.update('dialog1', false)}>Close</Button>,
              ]}
              onClose={v => this.update('dialog1', v)}
              data-test-id="mediumDialog"
            >
              <FormattedContentBlock>
                <p>Hello friendly ACE users.</p>
                <p>Please don&apos;t look. I&apos;m shy.</p>
              </FormattedContentBlock>
              <Tooltip
                id="tooltip12"
                text="Print enabled"
                open={this.state.tooltip12}
                onTrigger={v => this.update('tooltip12', v)}
              >
                <Button
                  variant="Ghost"
                  icon={
                    <Icon
                      glyph={icon.Glyphs.ActionPrintEnabled}
                      key="4"
                      text="Print enabled"
                      size="XXLarge"
                    />
                  }
                />
              </Tooltip>
              <Tooltip
                id="tooltip13"
                text="Print enabled"
                open={this.state.tooltip13}
                onTrigger={v => this.update('tooltip13', v)}
              >
                <Button
                  variant="Ghost"
                  icon={
                    <Icon
                      glyph={icon.Glyphs.ActionPrintEnabled}
                      key="4"
                      text="Print enabled"
                      size="XXLarge"
                    />
                  }
                />
              </Tooltip>
            </Dialog>
          )}
        </div>
      </div>
    );
  }
}

export default TooltipTest;
