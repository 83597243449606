// @flow strict
import * as React from 'react';

import type { BaseButtonProps } from './Button.types';
import { BaseButton } from './Internal/BaseButton';

export type SubmitButtonPropType = {|
  variant?: 'Secondary' | 'Primary' | 'Caution' | 'Ghost' | 'Link' | 'CautionLink' | 'SubtleLink',
  disabled?: boolean,
  form?: string,
  ...BaseButtonProps,
|};

/**
 * @ignore
 */
export const SubmitButton = (props: SubmitButtonPropType) => (
  <BaseButton {...props} type="submit" />
);

SubmitButton.defaultProps = {
  size: 'Regular',
};
