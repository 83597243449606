// @flow strict
import * as React from 'react';
import { Link, Icon, icon } from '@ansarada/ace-react';
import { FormattedContentBlockWrapper } from '..';
import styles from './Readme.scss';

type Props = {
  editUrl: string,
  reportBugUrl: string,
  readme: { __html: string },
};

export const Readme = ({ editUrl, reportBugUrl, readme }: Props) => (
  <>
    <ul className={styles.nav}>
      <li className={styles.navItem}>
        <Link variant="DefaultButton" icon={<Icon glyph={icon.Glyphs.ActionEdit} />} href={editUrl}>
          Edit these docs
        </Link>
      </li>

      <li className={styles.navItem}>
        <Link
          variant="DefaultButton"
          icon={<Icon glyph={icon.Glyphs.AddGeneric} />}
          href={reportBugUrl}
        >
          Report a bug
        </Link>
      </li>
    </ul>

    <FormattedContentBlockWrapper className={styles.content}>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={readme}
      />
    </FormattedContentBlockWrapper>
  </>
);
