// @flow strict

import React from 'react';

import {
  Autocomplete,
  AutocompleteItem,
  Form,
  FormSingleGroup,
  FormattedContentBlock,
} from '../components';

const citiesList = [
  'Sydney',
  'Ho Chi Minh City',
  'Chicago',
  'Melbourne',
  'San Francisco',
  'New York',
  'London',
  'Paris',
  'Seoul',
  'Houston',
  'New Delhi',
].map(v => new AutocompleteItem(v));

const businessList = {
  'Investment Banks': [
    'Goldman Sachs',
    'JPMorgan Chase',
    'Bank of America',
    'Morgan Stanley',
    'Citi Group',
    'Credit Suisse',
    'Deutsche Bank',
  ].map(v => new AutocompleteItem(v)),
  'Public companies': [
    'Apple Inc.',
    'Amazon Inc.',
    'Alphabet Inc.',
    'Microsoft Corporation',
    'Facebook Corporation',
    'Cisco Systems',
    'Booking Holdings',
  ].map(v => new AutocompleteItem(v)),
};

const categoryList = [
  'Antitrust and competition',
  'Banking & Finance',
  'Capital markets',
  'Compliance & Investigations',
  'Dispute Resolution',
  'Employment & Compensation',
  'Energy, Mining & Infrastructure',
  'Environment & Climate Change',
  'Immigration services',
  'Investment advise and stewardship',
  'Information Technology & Communications',
  'Intellectual Property',
  'International Commercial & Trade',
  'Mergers & Acquisition',
  'Private Equity',
  'Real estate',
  'Restructuring & Insolvency',
  'Sports law',
  'Tax',
].map((value, index) => new AutocompleteItem(index.toString(), value));

class AutocompleteTest extends React.Component<{}, any> {
  constructor() {
    super();
    this.state = {
      example1: {
        filter: '',
        items: citiesList,
      },

      example2: {
        filter: '',
        items: citiesList,
      },

      example3: {
        filter: '',
        items: businessList,
      },

      example4: {
        filter: '',
        items: businessList,
      },

      example5: {
        filter: '',
        items: businessList,
      },
      example6: {
        filter: '',
        items: categoryList,
        selected: [
          new AutocompleteItem('0', 'Intellectual Property'),
          new AutocompleteItem('1', 'Real estate'),
        ],
      },
    };
  }

  render() {
    const { example1, example2, example3, example4, example5 } = this.state;

    return (
      <div>
        <FormattedContentBlock>
          <h2>Autocomplete</h2>
          <h3>Single select without grouping</h3>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup>
            <Autocomplete
              placeholder="Enter your location"
              defaultValue=""
              defaultSelected={null}
              defaultItems={example1.items}
              defaultOpen={false}
            />
          </FormSingleGroup>
        </Form>
        <FormattedContentBlock>
          <h3>Single select without grouping (with async I/O delay)</h3>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup>
            <Autocomplete
              placeholder="Enter your location"
              defaultValue=""
              defaultSelected={null}
              defaultOpen={false}
              defaultItems={example2.items}
            />
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <h3>Single select with grouping</h3>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup>
            <Autocomplete
              defaultValue=""
              defaultSelected={null}
              defaultOpen={false}
              placeholder="Enter your location"
              defaultItems={example3.items}
            />
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <h3>Disabled</h3>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup>
            <Autocomplete
              defaultValue=""
              defaultSelected={null}
              defaultOpen={false}
              placeholder="Enter your location"
              disabled
              defaultItems={example4.items}
            />
          </FormSingleGroup>
        </Form>
        <FormattedContentBlock>
          <h3>Bad</h3>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup>
            <Autocomplete
              defaultValue=""
              defaultSelected={null}
              defaultOpen={false}
              placeholder="Enter your location"
              status="Bad"
              defaultItems={example5.items}
            />
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <h3>With Value and Label</h3>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup>
            <Autocomplete
              placeholder="Select a value"
              defaultValue=""
              defaultSelected={null}
              defaultOpen={false}
              defaultItems={this.state.example6.items}
              multiple
            />
          </FormSingleGroup>
        </Form>
      </div>
    );
  }
}

export default AutocompleteTest;
