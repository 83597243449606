// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Evaluation = (): React.Element<*> => (
  <>
    <path d="M6 6.498h0.996v0.996h-0.996v-0.996z"></path>
    <path d="M6 9.498h0.996v0.996h-0.996v-0.996z"></path>
    <path d="M6 12.498h0.996v0.996h-0.996v-0.996z"></path>
    <path d="M8.498 7.496h4.516c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-4.516c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
    <path d="M15.43 10.63c-0.058-0.025-0.126-0.040-0.197-0.040-0.205 0-0.381 0.123-0.458 0.3l-0.001 0.003-1.699 3.964-0.863-1.295c-0.091-0.133-0.243-0.22-0.414-0.22-0.276 0-0.5 0.224-0.5 0.5 0 0.102 0.031 0.197 0.083 0.276l-0.001-0.002 1.375 2.063c0.091 0.135 0.243 0.223 0.416 0.223v0c0.016 0 0.031 0 0.046-0.002 0.186-0.018 0.341-0.135 0.412-0.298l0.001-0.003 2.063-4.813c0.026-0.058 0.040-0.126 0.040-0.197 0-0.205-0.123-0.381-0.3-0.458l-0.003-0.001z"></path>
    <path d="M16.529 8.878v-6.023c0-0.475-0.385-0.859-0.859-0.859v0h-4.229c-0.011-0.032-0.023-0.065-0.037-0.097-0.235-0.543-0.765-0.915-1.383-0.915s-1.148 0.373-1.379 0.906l-0.004 0.010c-0.013 0.031-0.026 0.064-0.037 0.097h-4.241c-0.475 0-0.859 0.385-0.859 0.859v0 13.281c0 0.475 0.385 0.859 0.859 0.859v0h4.913c-0.243-0.292-0.461-0.62-0.643-0.969l-0.014-0.031h-4.115v-13h2.014v0.509c0 0.278 0.226 0.504 0.504 0.504v0h5.993c0.278-0 0.504-0.226 0.504-0.504v0-0.509h2.014v5.364c-0.596-0.241-1.287-0.38-2.010-0.38-1.472 0-2.809 0.577-3.797 1.518l0.002-0.002h-1.226c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h0.397c-0.551 0.84-0.879 1.869-0.879 2.975 0 0.003 0 0.006 0 0.010v-0.001c0 3.037 2.463 5.5 5.5 5.5s5.5-2.463 5.5-5.5c0-1.925-0.989-3.62-2.487-4.602zM16.698 16.662c-0.811 0.809-1.93 1.309-3.166 1.309-2.477 0-4.484-2.008-4.484-4.484s2.008-4.484 4.484-4.484c2.477 0 4.484 2.008 4.484 4.484 0 0.63-0.13 1.23-0.364 1.774l0.011-0.029c-0.238 0.556-0.564 1.031-0.965 1.431l-0 0z"></path>
  </>
);
