// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuGroup } from './SidebarMenuGroup';

import { KeyEvent } from '../../ace-internal/types/keys';
import { isActive } from '../../ace-internal/util/active';
import styles from './styles.scss';
import type { testIdPropType } from '../../ace-internal/types/general';

export type DisplayType = {
  mobileVisible?: boolean,
  showText?: boolean,
  active?: string | number,
};

export type SidebarMenuPropTypes = {
  id?: string,
  display: DisplayType,
  onUpdate: Function,
  mobileHeader?: React.Node,
  badges?: boolean,
  children: React.ChildrenArray<React.Element<typeof SidebarMenuGroup> | void | false>,
  fullHeight?: boolean,
  isNewSidebarEnabled?: boolean,
  ...testIdPropType,
};

export type ActiveItemType = string | number;

// Message types
const ShowMobile = 'SHOW_MOBILE';
const HideMobile = 'HIDE_MOBILE';
const ShowText = 'SHOW_TEXT';
const HideText = 'HIDE_TEXT';
const UpdateActive = 'UPDATE_ACTIVE';

const Messages = {
  ShowMobile,
  HideMobile,
  ShowText,
  HideText,
  UpdateActive,
};

const Message = (message: string) => (data?: string | number) => {
  if (data) {
    return { message, data };
  }
  return { message };
};

const init = (options: Object = {}) => {
  const { mobileVisible = false, showText = true, active } = options;

  return {
    mobileVisible,
    showText,
    active,
  };
};

// Update state according to a message
const transition = (
  old: DisplayType,
  msg: {
    message: string,
    data?: string | number,
  },
) => {
  const { message, data } = msg;
  switch (message) {
    case ShowMobile:
      return {
        ...old,
        mobileVisible: true,
        showText: true,
      };

    case HideMobile:
      return {
        ...old,
        mobileVisible: true,
        showText: false,
      };

    case HideText:
      return {
        ...old,
        mobileVisible: false,
        showText: false,
      };

    case ShowText:
      return {
        ...old,
        mobileVisible: false,
        showText: true,
      };

    case UpdateActive:
      return {
        ...old,
        active: data,
      };

    default:
      throw new Error(`Unknown message type: ${message}`);
  }
};

/**
 * Sidebar is intended to be used as a primary means of navigating around the platform.
 *
 *
 * The Sidebar has been designed to work closely with the header component and collapse down into a hamburger menu on smaller devices.
 *
 *
 * The platform's information architecture is displayed and split into sections and pages.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Page
 * @category Navigation
 */
const SidebarMenu = (props: SidebarMenuPropTypes) => {
  const {
    display,
    onUpdate,
    mobileHeader,
    badges = false,
    children,
    id: sidebarId,
    'data-test-id': sidebarTestId,
    fullHeight = false,
    isNewSidebarEnabled = false,
  } = props;

  const isOpen = display.showText || false;
  return (
    <div
      className={classnames(
        isNewSidebarEnabled && styles.sidebar,
        'ace-sidebar-menu',
        'ace-item',
        !isOpen && isNewSidebarEnabled && styles.sidebarClose,
      )}
      data-ace-expanded={display.showText}
      data-ace-sidebarmenu-badges={badges}
      data-ace-mobile-visible={display.mobileVisible}
      id={sidebarId}
      data-test-id={sidebarTestId}
    >
      <div className="ace-sidebar-menu-mobile-bar">{mobileHeader}</div>
      <div
        className={classnames(
          'ace-sidebar-menu-content',
          fullHeight && isNewSidebarEnabled && styles.menuContent,
        )}
      >
        {React.Children.map(children, (group, groupIndex) => {
          if (!group) {
            return group;
          }

          return (
            <SidebarMenuGroup
              header={group.props.header}
              // eslint-disable-next-line react/no-array-index-key
              key={`sidebarmenugroup-${groupIndex}`}
              open={isOpen}
              isNewSidebarEnabled={isNewSidebarEnabled}
            >
              {React.Children.map(group.props.children, (item, itemIndex) => {
                if (!item) {
                  return item;
                }
                // Calculate current item index
                let previtems = 0;

                const previousGroups = React.Children.toArray(children).slice(0, groupIndex);

                previousGroups.forEach(g => {
                  if (g && g.props && g.props.children && typeof g.props.children !== 'boolean') {
                    previtems += React.Children.toArray(g.props.children).length;
                  }
                });

                const currentItemIndex = previtems + itemIndex;

                // Get item properties
                const {
                  icon,
                  text,
                  link,
                  id,
                  badgeTitle,
                  badge,
                  onClick,
                  'data-test-id': sidebarMenuItemId,
                } = item.props;

                const handleAction =
                  onClick ||
                  (event => {
                    if (link) {
                      return;
                    }
                    const msg = Message(UpdateActive)(id !== undefined ? id : currentItemIndex);
                    onUpdate(transition(display, msg), msg, event);
                  });

                const isItemActive = isActive(display.active, currentItemIndex, id);

                const classNames = classnames({
                  'ace-sidebar-menu-active': isActive(display.active, currentItemIndex, id),
                });

                return (
                  <li
                    className={
                      isNewSidebarEnabled
                        ? classnames(styles.navItem, !isOpen && styles.navItemClose)
                        : classNames
                    }
                    id={id}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`sidebarmenuitem-${groupIndex}-${itemIndex}`}
                    data-test-id={sidebarMenuItemId}
                  >
                    <a
                      title={text}
                      href={link}
                      tabIndex="0"
                      onClick={handleAction}
                      onKeyPress={e => {
                        if (KeyEvent.isEnter(e)) {
                          handleAction(e);
                        }
                      }}
                      className={classnames(
                        isNewSidebarEnabled && styles.item,
                        isNewSidebarEnabled && isItemActive && styles.isActive,
                        !isOpen && isNewSidebarEnabled && styles.itemClose,
                      )}
                    >
                      <span className={isNewSidebarEnabled && styles.icon}>{icon}</span>
                      {badges && badgeTitle !== undefined && (
                        <span className="ace-sidebar-menu-badge" title={badgeTitle}>
                          {badge}
                        </span>
                      )}
                      <span
                        className={classnames(
                          'ace-text',
                          isNewSidebarEnabled && styles.label,
                          isNewSidebarEnabled && isItemActive && styles.activeText,
                        )}
                      >
                        {text}
                      </span>
                    </a>
                  </li>
                );
              })}
            </SidebarMenuGroup>
          );
        })}
      </div>
    </div>
  );
};

export { init, transition, SidebarMenu, SidebarMenuGroup, SidebarMenuItem, Message, Messages };
