// @flow strict

import * as React from 'react';
import { Icon } from '../Icon';

import type {
  testIdPropType,
  ansaradaCCDPropType,
  ariaControlsPropType,
  ariaExpandedPropType,
  ariaLabelledbyPropType,
} from '../../ace-internal/types/general';

export type Variant =
  | 'Secondary'
  | 'Primary'
  | 'Caution'
  | 'Ghost'
  | 'Link'
  | 'CautionLink'
  | 'SubtleLink';

export type LinkVariant =
  | 'Default'
  | 'Caution'
  | 'Subtle'
  | 'DefaultButton'
  | 'PrimaryButton'
  | 'CautionButton'
  | 'GhostButton';

export const LinkVariantMap = {
  Default: 'Link',
  Caution: 'CautionLink',
  Subtle: 'SubtleLink',
  DefaultButton: 'Primary',
  PrimaryButton: 'Primary',
  CautionButton: 'Caution',
  GhostButton: 'Ghost',
};

export type Type = 'link' | 'submit' | 'button' | 'customLink';

export type IconPosition = 'Before' | 'After';

export type BaseButtonProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  ...ariaExpandedPropType,
  ...ariaControlsPropType,
  ...ariaLabelledbyPropType,
  id?: string,
  size?: 'Compact' | 'Super' | 'Regular' | 'Large',
  children?: React.ChildrenArray<string | void | false>,
  className?: string,
  icon?: React.Element<typeof Icon>,
  loading?: boolean,
  pressed?: boolean,
  onMouseDown?: (e: SyntheticMouseEvent<>) => void,
  onMouseUp?: (e: SyntheticMouseEvent<>) => void,
  onMouseOver?: (e: SyntheticMouseEvent<>) => void,
  onMouseEnter?: (e: SyntheticMouseEvent<>) => void,
  onMouseLeave?: (e: SyntheticMouseEvent<>) => void,
  onMouseOut?: (e: SyntheticMouseEvent<>) => void,
  onBlur?: (e: SyntheticFocusEvent<>) => void,
  onClick?: (e: SyntheticMouseEvent<>) => void,
  onFocus?: (e: SyntheticFocusEvent<>) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<>) => void,
  buttonRef?: () => ?HTMLButtonElement | ?HTMLAnchorElement,
  // component props + react component that allows support for other link libraries like react-router
  customLink?: {|
    component: React.ComponentType<*>,
    props: Object,
  |},
|};

export type ButtonProps = {|
  disabled?: boolean,
|};

export type SubmitButtonProps = {|
  disabled?: boolean,
  form?: string,
|};

export type LinkProps = {|
  href?: string,
  target?: string,
  download?: string,
|};

export type ButtonPropType = {|
  variant?: Variant,
  ...BaseButtonProps,
  ...ButtonProps,
|};
