// @flow strict
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import classnames from 'classnames';
import { useUncontrolled } from 'uncontrollable';

import styles from './styles.scss';

import { PositionPopover, positions } from '../../ace-internal/shared-component/PositionPopover';
import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { configInternalTestId } from '../../ace-internal/util/util';

import { Icon, Glyphs as IconGlyphs } from '../Icon';
import { TimepickerOptions } from './internal/TimepickerOptions';
import { TimepickerInput } from './internal/TimepickerInput';
import { TimepickerProvider } from './internal/TimepickerContext';

const ignoreFocus = (event: SyntheticMouseEvent<>) => {
  event.preventDefault();
};

export type BaseProps = {|
  /** HTML id attribute */
  id?: string,
  /** the size of the input (matches other text input sizes) */
  size: 'Small' | 'Medium' | 'Large',
  /** the max time in a time range the user is allowed to pick from. ex: 10:30 */
  maxTime?: string,
  /** the min time in a time range the user is allowed to pick from. ex: 15:30 */
  minTime?: string,
  /** an optional classname to adjust width or margins */
  className?: string,
  /** an optional placeholder value. Defaults to "Time picker" */
  placeholder: string,
  ...testIdPropType,
  ...ansaradaCCDPropType,
|};

type ControlledSelectedTime<T> = {|
  selectedTime?: T,
  onSelectTime: (selectedTime?: T) => void,
|};
type UncontrolledSelectedTime<T> = {|
  defaultSelectedTime: ?T,
  onSelectTime?: (selectedTime?: T) => void,
|};
type SelectedTime<T> = ControlledSelectedTime<T> | UncontrolledSelectedTime<T>;

type ControlledOpen<T> = {|
  open?: T,
  onOpen: (open?: T) => void,
|};
type UncontrolledOpen<T> = {|
  defaultOpen: ?T,
  onOpen?: (open?: T) => void,
|};
type Open<T> = ControlledOpen<T> | UncontrolledOpen<T>;

type ControlledProps = {|
  ...BaseProps,
  ...ControlledSelectedTime<string>,
  ...ControlledOpen<boolean>,
|};

type Props = {|
  ...BaseProps,
  ...SelectedTime<string>,
  ...Open<boolean>,
|};

const createInternalTestId = configInternalTestId({ componentName: 'Timepicker' });

const ControlledTimepicker = ({
  className,
  id,
  placeholder,
  size,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  onOpen,
  open,
}: ControlledProps) => {
  const [inputNode, setInputNode] = React.useState();

  const openTimepicker = () => {
    if (inputNode) {
      inputNode.focus();
    }
    onOpen(true);
  };

  return (
    <div className={classnames(styles.timepicker, className)} data-test-id={testId}>
      <TimepickerInput
        id={id}
        size={size}
        placeholder={placeholder}
        inputRef={el => setInputNode(el)}
        testId={createInternalTestId('displayTime')}
        ansaradaCCD={ansaradaCCD}
      />

      <Icon
        glyph={IconGlyphs.ObjectsDate}
        size="Medium"
        className={styles.timepickerButton}
        onClick={open ? undefined : openTimepicker}
        onMouseDown={open ? ignoreFocus : undefined}
      />

      {open && (
        <PositionPopover
          verticalOffset={10}
          triggerRef={inputNode}
          idealPosition={positions.BottomLeft}
        >
          <TimepickerOptions />
        </PositionPopover>
      )}
    </div>
  );
};

/**
 * Allow users to select time.
 *
 *
 * Use to select a single time from the options dialog or by typing in the time from text input.
 *
 * @status released
 * @date 16/07/2019
 * @version 1.0.0
 * @tags Datepicker, TextInput, Dropdown
 * @category Form
 */
export const Timepicker = (props: Props): React$Element<typeof TimepickerProvider> => {
  const allProps = useUncontrolled(props, {
    selectedTime: 'onSelectTime',
    open: 'onOpen',
  });

  return (
    <TimepickerProvider
      initial={{
        open: allProps.open,
        selectedTime: allProps.selectedTime,
        onOpen: allProps.onOpen,
        onSelectTime: allProps.onSelectTime,
        minTime: allProps.minTime,
        maxTime: allProps.maxTime,
      }}
    >
      <ControlledTimepicker {...allProps} />
    </TimepickerProvider>
  );
};
Timepicker.defaultProps = {
  placeholder: '--:--',
  size: 'Medium',
};

export type TimepickerType = 'Timepicker';
