// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Enable = (): React.Element<*> => (
  <>
    <path d="M16.506 0.99h-13c-1.379 0-2.5 1.122-2.5 2.5v13c0 1.378 1.121 2.5 2.5 2.5h13c1.378 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.122-2.5-2.5-2.5zM18.006 16.49c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M13.711 5.036c-0.061-0.029-0.133-0.046-0.209-0.046-0.2 0-0.373 0.118-0.453 0.288l-0.001 0.003-3.777 8.216-2.352-3.296c-0.092-0.128-0.24-0.21-0.407-0.21-0.276 0-0.5 0.224-0.5 0.5 0 0.109 0.035 0.21 0.094 0.292l-0.001-0.001 2.853 3.999c0.092 0.127 0.239 0.209 0.406 0.21h0c0.016 0 0.031-0.001 0.047-0.002 0.181-0.018 0.333-0.13 0.406-0.286l0.001-0.003 4.138-9c0.029-0.062 0.046-0.134 0.046-0.21 0-0.2-0.118-0.373-0.287-0.453l-0.003-0.001z"></path>
  </>
);
