// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Watermark = (): React.Element<*> => (
  <>
    <path d="M16.506 0.99h-13c-1.379 0-2.5 1.122-2.5 2.5v13c0 1.378 1.121 2.5 2.5 2.5h13c1.378 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.122-2.5-2.5-2.5zM18.006 16.49c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M16.743 11.050l-13-7c-0.069-0.038-0.15-0.060-0.237-0.060-0.276 0-0.5 0.224-0.5 0.5 0 0.189 0.105 0.354 0.26 0.439l0.003 0.001 13 7c0.069 0.038 0.15 0.060 0.237 0.060 0.276 0 0.5-0.224 0.5-0.5 0-0.189-0.105-0.354-0.26-0.439l-0.003-0.001z"></path>
    <path d="M16.743 15.050l-13-7c-0.069-0.038-0.15-0.060-0.237-0.060-0.276 0-0.5 0.224-0.5 0.5 0 0.189 0.105 0.354 0.26 0.439l0.003 0.001 13 7c0.069 0.038 0.15 0.060 0.237 0.060 0.276 0 0.5-0.224 0.5-0.5 0-0.189-0.105-0.354-0.26-0.439l-0.003-0.001z"></path>
  </>
);
