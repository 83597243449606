// @flow strict

import * as React from 'react';
import addMinutes from 'date-fns/addMinutes';
import {
  timeObjToString,
  get12HoursFormat,
  findPossibleHour,
  findPossibleMeridiem,
  isInputTimeValid,
  getAvailableMeridiems,
  getTimeOptions,
  timeToMinutes,
} from './TimeFunctions';

import type { SharedProps, ProviderProps, TimeObject } from '../types';

type State = {
  ...SharedProps,
};

const TimepickerStateContext = React.createContext<State | typeof undefined>(undefined);

const _parseTimeStringToObject = (timeStr?: string): ?TimeObject => {
  if (timeStr && timeStr.trim().length > 0) {
    const times = timeStr.split(':');
    if (!times || times.length !== 2) return null;

    return {
      hour: parseInt(times[0], 10),
      minute: parseInt(times[1], 10),
    };
  }
  return null;
};

/** @ignore */
function TimepickerProvider({ children, initial }: ProviderProps) {
  const currentDate = addMinutes(new Date(), 30);
  const timeWith12Hours = get12HoursFormat(currentDate);
  const display = {
    hour: timeWith12Hours.hour,
    minute: timeWith12Hours.minute,
    meridiem: timeWith12Hours.meridiem,
  };
  const maxTimeObject = _parseTimeStringToObject(initial.maxTime);
  const minTimeObject = _parseTimeStringToObject(initial.minTime);
  const availableMeridiems = getAvailableMeridiems(minTimeObject, maxTimeObject);

  const hour = findPossibleHour(display, minTimeObject, maxTimeObject);
  // only reset minute in case invalid selectedTime
  const minute = isInputTimeValid(display, minTimeObject, maxTimeObject)
    ? timeWith12Hours.minute
    : 0;
  const meridiem = findPossibleMeridiem(timeWith12Hours.meridiem, availableMeridiems);

  const selectedTime = initial.selectedTime
    ? initial.selectedTime.toLowerCase()
    : timeObjToString({ hour, minute, meridiem });

  const timeOptions = getTimeOptions(minTimeObject, maxTimeObject);

  const state = {
    selectedTime,
    open: initial.open || false,
    isValid: initial.isValid || true,
    timeOptions: initial.timeOptions || timeOptions,
    onOpen: initial.onOpen,
    onSelectTime: initial.onSelectTime,
    minTime: initial.minTime,
    maxTime: initial.maxTime,
    maxTimeObject,
    minTimeObject,
  };

  // invalid min/max time range
  if (
    minTimeObject &&
    maxTimeObject &&
    timeToMinutes(minTimeObject.hour, minTimeObject.minute) >
      timeToMinutes(maxTimeObject.hour, maxTimeObject.minute)
  ) {
    throw new Error(
      `Timepicker: 'minTime: ${String(state.minTime)}' has to be greater than 'maxTime: ${String(
        state.maxTime,
      )}'`,
    );
  }

  return (
    <TimepickerStateContext.Provider value={state}>{children}</TimepickerStateContext.Provider>
  );
}

function useTimepickerState() {
  const context = React.useContext(TimepickerStateContext);
  if (context === undefined) {
    throw new Error('useTimepickerState must be used within a TimepickerProvider');
  }
  return context;
}

function useDisplay() {
  return useTimepickerState();
}

export { TimepickerProvider, useDisplay };
