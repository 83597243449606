// @flow strict
import React from 'react';
import { AssetV2, FormattedContentBlock, TruncateText } from '../components';

const AssetTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>AssetV2</h2>
    </FormattedContentBlock>
    <div>
      <AssetV2 variant="File" fileType="code" name="Elm" />
    </div>
    <div>
      <AssetV2 name="Default" />
    </div>

    <div>
      <AssetV2
        name={
          <TruncateText truncatePosition="Tail">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec cursus sollicitudin lacus
            vitae mattis. Quisque blandit turpis eu accumsan facilisis. Suspendisse non tristique
            nibh, ornare facilisis nibh
          </TruncateText>
        }
      />
    </div>
    <div>
      <AssetV2 variant="File" fileType="code" name="Elm" disabled />
    </div>
    <div>
      <AssetV2 variant="Directory" name="Important documents" />
    </div>
    <div>
      <AssetV2 variant="Directory" name="Tasty" disabled />
    </div>
  </div>
);

export default AssetTest;
