// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Archive = (): React.Element<*> => (
  <>
    <path d="M16.496 0.99h-13c-1.378 0-2.5 1.122-2.5 2.5v13c0 1.378 1.122 2.5 2.5 2.5h13c1.379 0 2.5-1.122 2.5-2.5v-13c0-1.378-1.121-2.5-2.5-2.5zM17.996 16.49c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h13c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M14.426 7.603c-0.32-0.87-0.86-1.596-1.552-2.135l-0.011-0.008c-0.785-0.608-1.783-0.975-2.868-0.975-2.559 0-4.641 2.043-4.705 4.586l-0 0.006c-1.276 0.061-2.295 1.118-2.295 2.408 0 1.329 1.082 2.411 2.412 2.411h8.412c1.754-0 3.175-1.422 3.175-3.176 0-1.539-1.095-2.822-2.548-3.114l-0.020-0.003zM13.819 12.896h-8.412c-0.779 0-1.411-0.632-1.411-1.411s0.632-1.411 1.411-1.411v0c0 0 0.001 0 0.001 0 0.105 0 0.208 0.011 0.307 0.033l-0.009-0.002c0.032 0.007 0.068 0.011 0.105 0.011 0.276 0 0.5-0.224 0.5-0.5 0-0.018-0.001-0.035-0.003-0.052l0 0.002c-0.012-0.112-0.019-0.243-0.019-0.374 0-0 0-0 0-0.001v0c0-2.047 1.66-3.706 3.706-3.706 0.853 0 1.639 0.288 2.266 0.773l-0.009-0.006c0.622 0.482 1.082 1.146 1.305 1.91l0.007 0.026c0.057 0.199 0.229 0.345 0.438 0.363l0.002 0c1.12 0.098 1.991 1.031 1.992 2.168v0c0 1.2-0.976 2.175-2.176 2.175z"></path>
  </>
);
