// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { AceText } from '../AceText';
import { SubmitButton, Button } from '../../components/Button';
import { ButtonGroup } from '../../components/ButtonGroup';
import { Checkbox } from '../../components/Checkbox';
import { Datepicker } from '../../components/Datepicker';
import { InputInfo } from '../InputInfo';
import { InputState } from '../InputState';
import { Label } from '../Label';
import { Radio } from '../../components/Radio';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextInput } from '../../components/TextInput';
import { Toggle } from '../../components/Toggle';

type FormSubItemChildren = React.ChildrenArray<
  | React.Element<typeof AceText>
  | React.Element<typeof Button>
  | React.Element<typeof SubmitButton>
  | React.Element<typeof ButtonGroup>
  | React.Element<typeof Checkbox>
  | React.Element<typeof Datepicker>
  | React.Element<typeof InputInfo>
  | React.Element<typeof InputState>
  | React.Element<typeof Label>
  | React.Element<typeof Radio>
  | React.Element<typeof Select>
  | React.Element<typeof TextArea>
  | React.Element<typeof TextInput>
  | React.Element<typeof Toggle>
  | void
  | false,
>;

export type FormSubItemPropType = {
  className?: string,
  children?: FormSubItemChildren,
};

/** @ignore */
const FormSubItem = (props: FormSubItemPropType) => {
  const { className, children, ...rest } = props;

  const classes = classnames('ace-form-subitem', className);

  return (
    <div
      className={classes}
      // $FlowFixMe 0.112.0 - inexact rest of object pattern
      {...rest}
    >
      {children}
    </div>
  );
};

export { FormSubItem };
