// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { FormSingleGroup } from '../FormSingleGroup';
import { FormMultiGroup } from '../FormMultiGroup';
import { FormContentGroup } from '../FormContentGroup';

type FormSectionChildren = React.ChildrenArray<
  | React.Element<typeof FormSingleGroup>
  | React.Element<typeof FormMultiGroup>
  | React.Element<typeof FormContentGroup>
  | void
  | false,
>;

export type FormSectionPropType = {
  className?: string,
  children?: FormSectionChildren,
  header: string,
  assistiveHeader?: boolean,
};

/** @ignore */
const FormSection = (props: FormSectionPropType) => {
  const { className, children, header, assistiveHeader = false, ...rest } = props;

  const fieldsetClasses = classnames('ace-form-section', className);

  const legendClasses = classnames('ace-form-legend', {
    'ace-assistive': !!assistiveHeader,
  });

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <fieldset className={fieldsetClasses} {...rest}>
      <legend className={legendClasses}>
        <span>{header}</span>
      </legend>
      {children}
    </fieldset>
  );
};

export { FormSection };
