// @flow strict

/*
  Message type constants
*/

type Active = string | number;

export type MessageType = {
  message: string,
  data: Active,
};

export const UpdateActiveTab = 'UPDATE_ACTIVE_TAB';

/*
  Message constructors
*/
export const Message = (data: Active) => ({ message: UpdateActiveTab, data });

// THE TABS component below

export const transition = (oldValue: Active, msg: MessageType) => {
  const { message, data } = msg;

  switch (message) {
    case UpdateActiveTab:
      return data;
    default:
      throw new Error(`Unknown message type: ${message}`);
  }
};
