// @flow strict

import * as React from 'react';
import { Lozenge, FormattedContentBlock } from '../components';

type StateType = { [string]: boolean };

class LozengeTest extends React.Component<{}, StateType> {
  handleClose: string => void;

  constructor() {
    super();
    this.state = {
      isClosed: false,
    };
    this.handleClose = this._handleClose.bind(this);
  }

  update(address: string, value: boolean) {
    this.setState({ ...this.state, [address]: value });
  }

  _handleClose(message: string) {
    if (message === 'CLOSED') {
      this.setState({
        isClosed: true,
      });
    }
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Lozenge</h2>
        </FormattedContentBlock>

        <Lozenge text="Normal" />

        <style>{'.customStyle { color: red; }'}</style>
        <Lozenge text="Custom style applied" className="customStyle" />

        <Lozenge text="Closeable lozenge (unhandled)" closeable />
        {!this.state.isClosed && (
          <Lozenge
            text="Closeable lozenge (handled)"
            closeable
            onClose={() => this.handleClose('CLOSED')}
          />
        )}

        <Lozenge text="Lozenge (translated)" closeable closeAltText="Cerca" />
      </div>
    );
  }
}

export default LozengeTest;
