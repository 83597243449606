// @flow strict

import * as React from 'react';
import { Lozenge, LozengeGroup, FormattedContentBlock } from '../components';

const LozengeGroupTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>LozengeGroup</h2>
    </FormattedContentBlock>
    <LozengeGroup>
      <Lozenge text="Contracts" />
      <Lozenge text="Insurance" />
      <Lozenge text="IT" />
      <Lozenge text="Financials" />
    </LozengeGroup>
  </div>
);

export default LozengeGroupTest;
