// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FormatList = (): React.Element<*> => (
  <>
    <path d="M14.5 8.981h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M7.5 6.981h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0z"></path>
    <path d="M14.5 11.981h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M5 8.981h1v1h-1v-1z"></path>
    <path d="M5 5.981h1v1h-1v-1z"></path>
    <path d="M5 11.981h1v1h-1v-1z"></path>
  </>
);
