// @flow strict

import * as React from 'react';
import marked from 'marked';
import { MarkdownPage } from '../../components';
// $FlowFixMe - this comes from outside Orbit project
import readme from '../../../../ace/README.md';

export const Home = () => {
  const content = marked(readme);
  return <MarkdownPage contentHtml={content} />;
};
