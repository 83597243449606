// @flow strict
import * as React from 'react';
import classnames from 'classnames';

const Variants = {
  Normal: 'Normal',
  Split: 'Split',
};

type ItemPropType = {
  className?: string,
  children?: React.Node,
  width?: string,
  style?: Object,
};

const getStyleWithWidth = (width, styles) => ({ width, ...styles });

const Item = (props: ItemPropType) => {
  const { className, children, width, style, ...rest } = props;

  const classes = classnames('ace-item', className);

  const styles = width !== undefined ? getStyleWithWidth(width, style) : style;

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <div className={classes} style={styles} {...rest}>
      {children}
    </div>
  );
};

type GroupPropType = {
  variant?: $Keys<typeof Variants>,
  className?: string,
  children?: React.ChildrenArray<React.Element<typeof Item> | void | false>,
};

// Sizes
const Group = (props: GroupPropType) => {
  const { variant = Variants.Normal, className, children, ...rest } = props;

  const classes = classnames(
    'ace-group',
    { 'ace-group-split': variant === Variants.Split },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export { Group, Item, Variants };

export const Layout = { Group, Item };
