// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const EyeView = (): React.Element<*> => (
  <>
    <path d="M10.002 6.481c-0 0-0 0-0 0-1.933 0-3.5 1.567-3.5 3.5s1.567 3.5 3.5 3.5c1.933 0 3.5-1.567 3.5-3.5 0-0.966-0.392-1.841-1.025-2.475v0c-0.63-0.633-1.502-1.025-2.465-1.025-0.003 0-0.007 0-0.010 0h0.001zM10.002 12.481c-1.379 0-2.5-1.122-2.5-2.5s1.121-2.5 2.5-2.5 2.5 1.121 2.5 2.5-1.122 2.5-2.5 2.5z"></path>
    <path d="M18.957 9.789c-0.027-0.065-0.675-1.599-2.094-3.145-0.837-0.914-1.772-1.644-2.777-2.168-1.264-0.66-2.641-0.994-4.091-0.994s-2.826 0.335-4.091 0.994c-1.006 0.524-1.94 1.254-2.778 2.168-1.418 1.547-2.066 3.081-2.093 3.145-0.024 0.057-0.038 0.123-0.038 0.192s0.014 0.136 0.040 0.196l-0.001-0.003c0.027 0.065 0.675 1.599 2.094 3.145 0.838 0.914 1.772 1.643 2.778 2.168 1.264 0.66 2.641 0.994 4.091 0.994s2.826-0.335 4.091-0.994c1.006-0.525 1.94-1.254 2.778-2.168 1.418-1.547 2.066-3.081 2.094-3.145 0.024-0.056 0.038-0.122 0.038-0.19 0-0.070-0.014-0.137-0.041-0.197l0.001 0.003zM16.105 12.667c-0.758 0.822-1.599 1.475-2.498 1.942-1.115 0.578-2.33 0.872-3.611 0.872s-2.496-0.294-3.611-0.872c-0.9-0.467-1.74-1.12-2.498-1.942-1.027-1.114-1.627-2.245-1.84-2.686 0.213-0.441 0.813-1.572 1.84-2.686 0.758-0.822 1.598-1.475 2.498-1.942 1.115-0.578 2.33-0.872 3.611-0.872s2.496 0.293 3.611 0.872c0.9 0.467 1.74 1.12 2.498 1.942 1.028 1.114 1.627 2.245 1.84 2.686-0.213 0.44-0.813 1.572-1.84 2.686z"></path>
  </>
);
