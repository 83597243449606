// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const PriorityMedium = (): React.Element<*> => (
  <>
    <path d="M8.506 5c-0.276 0-0.5 0.224-0.5 0.5v0 6c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M8.506 13.5c-0.276 0-0.5 0.224-0.5 0.5v0 0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-0.5c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M11.506 5c-0.276 0-0.5 0.224-0.5 0.5v0 6c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M11.506 13.5c-0.276 0-0.5 0.224-0.5 0.5v0 0.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-0.5c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
