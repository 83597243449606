// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const LockClosed = (): React.Element<*> => (
  <>
    <path d="M13.5 14.959h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M13.5 11.959h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M15.206 8.959h-0.19v-2.974c0-2.77-2.246-5.016-5.016-5.016s-5.016 2.246-5.016 5.016v0 2.974h-0.19c-0.99 0.002-1.792 0.804-1.794 1.794v6.412c0 0.989 0.805 1.794 1.793 1.794l10.413 0.031c0.99-0.002 1.792-0.804 1.794-1.794v-6.443c-0.002-0.99-0.804-1.792-1.794-1.794h-0zM5.984 5.984c0-2.218 1.798-4.016 4.016-4.016s4.016 1.798 4.016 4.016v0 2.974h-8.031zM16 17.196c-0.001 0.438-0.356 0.793-0.794 0.794h-0l-10.412-0.031c-0.438-0.001-0.794-0.356-0.794-0.794v-6.412c0.001-0.438 0.356-0.794 0.794-0.794h10.412c0.438 0.001 0.794 0.356 0.794 0.794v0z"></path>
  </>
);
