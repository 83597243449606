// @flow strict
import * as React from 'react';

type ModuleContentPropType = {
  children: React.Node,
};

export const ModuleContent = (props: ModuleContentPropType) => {
  const { children } = props;
  return <div>{children}</div>;
};
