// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Submissions = (): React.Element<*> => (
  <>
    <path d="M16.5 0.981h-7c-1.378 0-2.5 1.122-2.5 2.5v2.5h-3.5c-1.378 0-2.5 1.122-2.5 2.5v8c0 1.378 1.122 2.5 2.5 2.5h7c1.378 0 2.5-1.122 2.5-2.5v-2.5h3.5c1.378 0 2.5-1.122 2.5-2.5v-8c0-1.378-1.122-2.5-2.5-2.5zM12 16.481c-0.003 0.827-0.673 1.497-1.5 1.5h-7c-0.827-0.003-1.497-0.673-1.5-1.5v-8c0.003-0.827 0.673-1.497 1.5-1.5h7c0.827 0.003 1.497 0.673 1.5 1.5v0zM18 11.481c-0.003 0.827-0.673 1.497-1.5 1.5h-3.5v-2h2.5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-2.5v-1.5c0-0.001 0-0.001 0-0.002 0-0.176-0.018-0.348-0.053-0.514l0.003 0.016h2.55c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0h-3.001c-0.46-0.61-1.184-1-1.999-1h-2.5v-2.5c0.003-0.827 0.673-1.497 1.5-1.5h7c0.827 0.003 1.497 0.673 1.5 1.5v0zM15.5 3.981h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M9.5 8.981h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M9.5 11.981h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M9.5 14.981h-5c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h5c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
