// @flow strict

import * as React from 'react';
import cx from 'classnames';

import { PageHidden } from '..';
import type { testIdPropType } from '../../ace-internal/types/general';

import styles from './offCanvas.scss';

type Position = 'Left' | 'Right';

type Props = {|
  ...testIdPropType,
  /** Content */
  children: React.Node,
  /** Controls whether the off-canvas container is hidden or shown */
  isOpen: boolean,
  /** Side where the off-canvas container will appear  */
  position: Position,
  /** Add a class to the container element */
  className?: string,
|};

const getPositionClassName = (position: Position): string => {
  switch (position) {
    case 'Left':
      return styles.isPositionLeft;
    case 'Right':
      return styles.isPositionRight;
    default:
      throw new Error('Invalid position');
  }
};

/**
 * The `OffCanvas` component allows users to create containers off-canvas
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags PageHidden, Page
 * @category Layout
 */
export const OffCanvas = ({
  'data-test-id': testId,
  children,
  isOpen,
  position,
  className,
}: Props) => {
  const containerClasses = cx([
    styles.container,
    isOpen && styles.isOpen,
    getPositionClassName(position),
    className,
  ]);
  return (
    <PageHidden data-test-id={testId}>
      <div className={containerClasses}>{children}</div>
    </PageHidden>
  );
};

OffCanvas.defaultProps = {
  isOpen: false,
};
