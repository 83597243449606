// @flow strict
import * as React from 'react';
import type { WebpackOptions } from 'webpack';

import type { VariableType } from './sass-export';

export type ComponentMethodParamType = {
  name: string,
  type: { name: string, alias: string },
};

export type ComponentMethodType = {
  name: string,
  docblock: string,
  modifiers: string[],
  params: ComponentMethodParamType[],
  returns: null, // TODO(ciro): make it real
};

export type ComponentType = {
  description?: string,
  displayName: string,
  composes: string[],
  methods: ComponentMethodType[],
  readme: string,
  status?: string,
  ignore?: boolean,
  date?: string,
  version?: string,
  tags?: string,
  category?: string,
};

export type ComponentFailedType = {
  path: string,
  error: string,
};

export type ExtractSCSSResponseType = {
  variables: VariableType[],
};

export type ExtractJSResponseType = {
  parsed: ComponentType[],
  failed: ComponentFailedType[],
};

export type ExtractMetadataResponseType = {
  js: ExtractJSResponseType,
  scss: ExtractSCSSResponseType,
  version: string,
  date: string,
  repository: {
    type: string,
    url: string,
  },
};

export type ComponentSampleType = {
  code: React.Node,
  label: string,
  codeSnippet?: React.Node,
};

export type ComponentFixtureType = {|
  title: string,
  samples: Array<ComponentSampleType>,
  $open?: boolean, // TODO: remove this hacky way of opening fixtures in the UI
|};

export type ComponentDocsType = {
  name: string,
  importSnippet: string,
  description?: React.Node,
  fixtures: Array<ComponentFixtureType>,
  type: 'column' | 'row' | 'full',
};

export type BuildConfigType = {
  componentsPath: string,
  scssPath: string,
  assetsDir: string,
  internalTypesPath: string,
  webpackConfigFileDev: string,
  webpackConfigFileProd: string,
  webpackConfig?: WebpackOptions,
};

export const languages = {
  none: '',
  jsx: 'jsx',
  markdown: 'markdown',
  css: 'css',
  scss: 'scss',
  shell: 'shell',
};

export type LanguageType = $Values<typeof languages>;

export type NavItem = {|
  label: string,
  url: string,
|};

export type NavItemWithCategory = {|
  ...NavItem,
  category: string,
|};

export type NavItemWithImage = {|
  ...NavItem,
  image: string,
|};
