// @flow strict
/* eslint react/prefer-stateless-function: off */
/* eslint jsx-a11y/iframe-has-title: off */

import * as React from 'react';

class PageTest extends React.Component<{}> {
  render() {
    return <iframe src="#/tests/PageTestSource?clean=true" width="100%" height="700px" />;
  }
}

export default PageTest;
