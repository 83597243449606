// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const LinkIcon = (): React.Element<*> => (
  <>
    <path d="M11.387 3.645l-2.099 2.099 0.707 0.707 2.099-2.099c0.453-0.459 1.083-0.743 1.778-0.743 1.381 0 2.5 1.119 2.5 2.5 0 0.696-0.284 1.325-0.743 1.778l-2.828 2.828c-0.452 0.452-1.077 0.732-1.768 0.732s-1.315-0.28-1.768-0.732l-0.006-0.006-0.707 0.707 0.006 0.006c1.367 1.367 3.583 1.367 4.95 0l2.828-2.828c1.367-1.367 1.367-3.583 0-4.95v0c-1.367-1.367-3.583-1.367-4.949-0z"></path>
    <path d="M9.995 13.522l-2.125 2.125c-0.453 0.459-1.083 0.743-1.779 0.743-1.381 0-2.5-1.119-2.5-2.5 0-0.696 0.284-1.325 0.742-1.778l2.828-2.828c0.452-0.452 1.077-0.732 1.768-0.732 0.687 0 1.31 0.277 1.762 0.726l-0-0 0.707-0.707c-1.367-1.361-3.579-1.359-4.944 0.006l-2.828 2.828c-1.367 1.367-1.367 3.583 0 4.95v0c1.367 1.367 3.583 1.367 4.95 0l2.125-2.125z"></path>
  </>
);
