// @flow strict

import * as React from 'react';

import type { ColumnType } from '../Tree2.types';
import { Column } from './Column';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

type Props = {|
  columns: Array<ColumnType>,
|};

export const Columns = ({ columns }: Props): React.Element<'div'> => (
  <div className={styles.columnsHeaderWrapperStyles}>
    {columns.map((column: ColumnType) => (
      <Column column={column} key={`colum-header-${column.field}`} />
    ))}
  </div>
);
