// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useUncontrolled } from 'uncontrollable';
import debounce from 'lodash.debounce';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './style.scss';
import type { testIdPropType } from '../../ace-internal/types/general';
import { Item } from './Internal/Item';
import { BackNext, Variants as BNVariants } from './Internal/BackNext';
import { Ellipsis } from './Internal/Ellipsis';
import { range } from '../../ace-internal/util/array';
import { addEventListener } from '../../ace-internal/util/dom';

export type PageType = {|
  url?: string,
  onClick?: (e: SyntheticMouseEvent<>) => void,
|};

type BaseProps = {|
  ...testIdPropType,
  className?: string,
  /** Total amount of pages */
  total: number,
  /** Used for screen reader to read out a label for component */
  screenReaderLabel: string,
|};

type ControlledSelected<T> = {|
  selected: T,
  onSelectedChange: (selected: T) => void,
|};
type UncontrolledSelected<T> = {|
  defaultSelected: T,
  onSelectedChange?: (selected: T) => void,
|};
type Selected<T> = ControlledSelected<T> | UncontrolledSelected<T>;

type ControlledProps = {|
  ...ControlledSelected<number>,
|};

type PaginationControlledPropType = {|
  ...BaseProps,
  ...ControlledProps,
|};

export type PaginationPropType = {|
  ...BaseProps,
  ...Selected<number>,
|};

const truncateItems = (
  pages: number[],
  centerItems: number,
  selectedPage: number,
  offset: number,
): number[] => {
  const centerItemsOffset = centerItems - offset;
  const selectedIndex: number = pages.indexOf(selectedPage);
  const halfCenterItems: number = Math.ceil(centerItemsOffset / 2);
  const headCutoff: number = halfCenterItems;
  const tailCutoff: number = pages.length - halfCenterItems - 1;

  // Here we generate the arrays of page numbers required,
  // using -1 as placeholders for ellipses.
  if (selectedIndex <= headCutoff) {
    return pages.slice(1, centerItemsOffset).concat(-1);
  }
  if (selectedIndex >= tailCutoff) {
    return [-1, ...pages.slice(-centerItemsOffset, -1)];
  }
  const remaining = pages.slice(
    selectedIndex - halfCenterItems + offset,
    selectedIndex + halfCenterItems - 1,
  );
  return [-1, ...remaining.concat(-1)];
};

const getAllowTotalPagesDisplayed = (wrapperElement: HTMLElement, maxDisplayed: number) => {
  const itemSize = 42;

  if (wrapperElement.clientWidth > itemSize * maxDisplayed + 2) {
    return maxDisplayed;
  }

  if (wrapperElement.clientWidth > itemSize * 7 + 2) {
    return 7;
  }

  return 5;
};

const centerItems = (total: number, selected: number, wrapperElement: ?HTMLElement): number[] => {
  if (total === 1) {
    return [1];
  }
  const totalPagesDisplayed = wrapperElement ? getAllowTotalPagesDisplayed(wrapperElement, 11) : 11;
  const centeredPages =
    total <= totalPagesDisplayed
      ? range(2, total)
      : truncateItems(range(1, total + 1), totalPagesDisplayed, selected, 2);
  return [1, ...centeredPages, total];
};

const previous = (n: number): number => (n === 1 ? 1 : n - 1);
const next = (n: number, total: number): number => (n === total ? total : n + 1);

const getClientWidth = () => {
  return (document && document.body && document.body.clientWidth) || 0;
};

const ControlledPagination = ({
  'data-test-id': testId,
  selected,
  onSelectedChange,
  total,
  className,
  screenReaderLabel,
}: PaginationControlledPropType) => {
  const [key, setKey] = React.useState<number>(getClientWidth());
  const wrapperNode = React.useRef();

  const debouncedSetKey = debounce(() => setKey(getClientWidth()));

  React.useEffect(() => {
    const cleanup = addEventListener(window, 'resize', debouncedSetKey);

    return () => {
      debouncedSetKey.cancel();
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (total <= 1) {
    return null;
  }

  return (
    <div
      key={key}
      ref={wrapperNode}
      className={classnames(className, styles.wrapper)}
      data-test-id={testId}
    >
      <ul role="navigation" aria-label={screenReaderLabel} className={styles.pagination}>
        <BackNext
          variant={BNVariants.Back}
          disabled={selected === 1}
          onClick={() => {
            onSelectedChange(previous(selected));
          }}
        />
        {total && (
          <>
            {centerItems(total, selected, wrapperNode.current).map((index: number):
              | React.Element<typeof Ellipsis>
              | React.Element<typeof Item> => {
              if (index === -1) {
                return <Ellipsis key={`${uuid()}`} />;
              }

              return (
                <Item
                  key={`${uuid()}`}
                  displayId={`${index}`}
                  selected={selected === index}
                  onClick={() => onSelectedChange(index)}
                />
              );
            })}
          </>
        )}
        <BackNext
          variant={BNVariants.Next}
          disabled={selected === total}
          onClick={() => {
            onSelectedChange(next(selected, total));
          }}
        />
      </ul>
    </div>
  );
};

/**
 * Pagination allows a long list of items to be broken up over a set of pages.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Pagination, Table
 * @category Data
 */
const Pagination = (props: PaginationPropType): React$Element<typeof ControlledPagination> => {
  const allProps = useUncontrolled(props, {
    selected: 'onSelectedChange',
  });

  return <ControlledPagination {...allProps} />;
};

Pagination.defaultProps = {
  screenReaderLabel: 'Pagination',
};

export { Pagination };
