// @flow strict

import * as React from 'react';
import type { State, DisplayUpdateType } from '../types';

type Context = {
  ...State,
  ...DisplayUpdateType,
};

const DatepickerStateContext = React.createContext<Context | void>(undefined);

type ProviderProps = {|
  value: Context,
  children: React.Node,
|};

/** @ignore */
function DatepickerProvider({ children, value }: ProviderProps) {
  const state = {
    selectedDate: value.selectedDate || undefined,
    calendarVisible: value.calendarVisible || false,
    minDate: value.minDate || undefined,
    maxDate: value.maxDate || undefined,
    onSelectDate: value.onSelectDate,
    onCalendarVisible: value.onCalendarVisible,
  };

  return (
    <DatepickerStateContext.Provider value={state}>{children}</DatepickerStateContext.Provider>
  );
}

function useDisplay() {
  const context = React.useContext(DatepickerStateContext);
  if (context === undefined) {
    throw new Error('useDatepickerState must be used within a DatepickerProvider');
  }
  return context;
}

export { DatepickerProvider, useDisplay };
