// @flow strict

import React, { useState } from 'react';
import * as clipboard from 'clipboard-polyfill/text';

import { Icon, Toaster, Toast, Button, Card } from '..';

type PropType = {|
  target: string,
  trigger: React$Element<typeof Button> | React$Element<typeof Card>,
  successMsg: string,
  failMsg: string,
|};

const success = {
  text: 'Link copied to clipboard',
  icon: 'StatusYes',
};

const error = {
  text: 'Unable to copy the link',
  icon: 'StatusNo',
};

type State = 'none' | 'success' | 'error';
/**
 * The `CopyToClipboard` component allows users to copy url text to their clipboard in one click.
 *
 * On click, a toast is triggered providing confirmation the interaction was successful.
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Toast, Button, Link
 * @category Buttons
 */
export const CopyToClipboard = ({ target, trigger, successMsg, failMsg }: PropType) => {
  const [showMessage, setMessage] = useState<State>('none');
  const shouldShowMessage = showMessage !== 'none';
  const hasError = showMessage === 'error';
  const glyph = shouldShowMessage && hasError ? error.icon : success.icon;
  const message = hasError ? failMsg : successMsg;

  const onClick = () => {
    clipboard.writeText(target).then(
      () => setMessage('success'),
      () => setMessage('error'),
    );
  };

  return (
    <>
      {React.cloneElement(trigger, { onClick, 'data-test-id': 'trigger' })}
      {shouldShowMessage && (
        <Toaster data-test-id="toaster">
          <Toast
            variant={hasError ? 'Error' : 'Success'}
            text={message}
            timeoutDuration={3e3}
            onTimeout={() => setMessage('none')}
            icon={<Icon glyph={glyph} />}
          ></Toast>
        </Toaster>
      )}
    </>
  );
};

CopyToClipboard.defaultProps = {
  successMsg: success.text,
  failMsg: error.text,
};
