// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Menu = (): React.Element<*> => (
  <>
    <path d="M16.246 13.992h-12.5c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h12.5c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
    <path d="M16.246 4.492h-12.5c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h12.5c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
    <path d="M16.246 9.242h-12.5c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h12.5c0.414 0 0.75-0.336 0.75-0.75s-0.336-0.75-0.75-0.75v0z"></path>
  </>
);
