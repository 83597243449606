// @flow strict
import * as React from 'react';
import styles from './Placeholder.scss';

type Props = {|
  /** Placeholder width */
  width?: number,
  /** Placeholder height */
  height: number,
|};

/**
 * The `Placeholder` component...
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Button, Page
 * @category Layout
 */
const Placeholder = ({ width, height }: Props) => {
  return (
    <div style={{ width, height }} className={styles.placeholder}>
      <img alt="Ansarada" src="https://static.ansarada.com/logos/0.3.0/favicon-16x16.png" />
    </div>
  );
};

export { Placeholder };
