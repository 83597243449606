// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileDocument = (): React.Element<*> => (
  <>
    <path d="M18.856 7.628l-6.5-6.5c-0.090-0.090-0.215-0.146-0.354-0.146-0 0-0 0-0 0h-8.5c-1.378 0-2.5 1.121-2.5 2.5v13c0 1.378 1.122 2.5 2.5 2.5h13c1.379 0 2.5-1.122 2.5-2.5v-8.5c0-0 0-0 0-0 0-0.138-0.056-0.263-0.146-0.354v0zM17.795 7.981h-4.293c-0.827-0.003-1.497-0.673-1.5-1.5v-4.293zM18.002 16.481c-0.003 0.827-0.673 1.497-1.5 1.5h-13c-0.827-0.003-1.497-0.673-1.5-1.5v-13c0.003-0.827 0.673-1.497 1.5-1.5h7.5v4.5c0 1.378 1.122 2.5 2.5 2.5h4.5z"></path>
  </>
);
