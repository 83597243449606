// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const UserSellside = (): React.Element<*> => (
  <>
    <path d="M15.545 13.662c-0.624-1.446-1.754-2.576-3.162-3.185l-0.039-0.015q-0.072-0.030-0.145-0.059c0.369-0.212 0.687-0.457 0.969-0.739l0-0c0.814-0.814 1.318-1.939 1.318-3.182 0-2.485-2.015-4.5-4.5-4.5s-4.5 2.015-4.5 4.5c0 1.243 0.504 2.368 1.318 3.182v0c0.285 0.285 0.608 0.533 0.96 0.734l0.022 0.012c-0.042 0.017-0.084 0.034-0.126 0.052-2.177 0.936-3.674 3.062-3.674 5.537 0 0.001 0 0.003 0 0.004v-0 1.495c0 0.276 0.224 0.5 0.5 0.5v0h11.031c0.276 0 0.5-0.224 0.5-0.5v0-1.495c0-0.004 0-0.008 0-0.012 0-0.841-0.174-1.642-0.488-2.368l0.015 0.039zM6.486 6.482c0-0 0-0 0-0 0-1.933 1.567-3.5 3.5-3.5s3.5 1.567 3.5 3.5c0 1.933-1.567 3.5-3.5 3.5-0.966 0-1.841-0.392-2.475-1.025v0c-0.633-0.63-1.025-1.502-1.025-2.465 0-0.003 0-0.007 0-0.010v0.001zM15.018 16.998h-10.031v-0.995c0-2.77 2.246-5.016 5.016-5.016s5.016 2.246 5.016 5.016v0z"></path>
  </>
);
