// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Copy = (): React.Element<*> => (
  <>
    <path d="M16.854 7.27l-4.142-4.142c-0.090-0.090-0.215-0.146-0.354-0.146-0 0-0 0-0 0h-5.097c-0.972 0.001-1.76 0.789-1.761 1.761v0.003l0.004 0.723h-0.728c-0.972 0.001-1.76 0.789-1.761 1.761v0.003l0.043 7.977c0.002 0.971 0.79 1.758 1.761 1.76h7.935c0.972-0.001 1.76-0.789 1.761-1.761v-0.725h0.724c0.972-0.001 1.76-0.789 1.761-1.761v-5.097c0-0 0-0 0-0 0-0.138-0.056-0.263-0.146-0.354v0zM15.65 7.481h-2.488c-0.42-0-0.761-0.341-0.761-0.761v-2.489zM13.514 15.206c-0.001 0.42-0.341 0.761-0.761 0.761h-7.935c-0.42-0.001-0.761-0.341-0.761-0.761v-0.003l-0.043-7.976c0.001-0.42 0.341-0.76 0.761-0.76h0.734l0.034 6.254c0.002 0.971 0.789 1.758 1.76 1.76h6.21zM16 12.72c-0 0.42-0.341 0.761-0.761 0.761h-7.934c-0.42-0-0.761-0.341-0.761-0.761v-0.003l-0.043-7.976c0.001-0.42 0.341-0.76 0.761-0.76h4.14v2.739c0.001 0.972 0.789 1.76 1.761 1.761h2.838z"></path>
  </>
);
