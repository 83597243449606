// @flow strict

import * as React from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

import styles from './HowToUseTable.scss';

type Sample = {|
  code: React$Node,
  label: string,
|};

type Props = {
  samples: $ReadOnlyArray<Sample>,
  type: 'column' | 'row' | 'full',
};

export const HowToUseTable = ({ samples, type }: Props) => {
  const isColumn = type === 'column';
  const isFull = type === 'full';

  return (
    <div
      className={classnames(styles.table, {
        [styles.column]: isColumn,
        [styles.full]: isFull,
      })}
    >
      {samples.map(sample => (
        <div className={styles.container} key={`${sample.label}-${uuid()}`}>
          <div className={styles.tableCell}>{sample.code}</div>
          <div className={styles.tableCellLabel}>
            <span className={styles.label}>{sample.label}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
