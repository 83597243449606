// @flow strict
import React from 'react';
import { ExpanderPanel } from '@ansarada/ace-react';

import { ComponentHeader, RouterLink } from '../../components';
import { groupByCategory, getComponentsNavItems } from '../../util/components';
import styles from './Components.scss';

const component = {
  displayName: 'Components',
  description: `Our component library has the necessary building blocks to build complete applications.`,
};

const listItem = item => (
  <li>
    <RouterLink to={item.url}>{item.label}</RouterLink>
  </li>
);

export const Components = () => {
  const components = groupByCategory(getComponentsNavItems(window.ORBIT.js.parsed));

  return (
    <div>
      <ComponentHeader component={component} />

      <div className={styles.wrapper}>
        <div className={styles.container}>
          {Object.keys(components).map(group => (
            <div>
              <ExpanderPanel defaultExpanded={false} title={group}>
                <ul>{components[group].map(listItem)}</ul>
              </ExpanderPanel>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
