// @flow strict
import * as React from 'react';
import cx from 'classnames';
import type { testIdPropType } from '../../ace-internal/types/general';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './Section.scss';

type Props = {|
  ...testIdPropType,
  /** Adjust spacing based on Shoelace spaces */
  spacing: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800',
  /** Content */
  children: React.Node,
|};

/**
 * The `Section` component creates a spaced container
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Columns, Inline, Stack
 * @category Layout
 */
const Section = ({ 'data-test-id': testId, spacing, children }: Props) => {
  return (
    <div data-test-id={testId} className={cx([styles.container, styles[`is${spacing}`]])}>
      {children}
    </div>
  );
};

Section.defaultProps = {
  spacing: '400',
};

export { Section };
