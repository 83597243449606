// @flow strict
/* eslint-disable no-console */
import React from 'react';
import { Header } from '../components';

const landmarkGroups = [
  {
    isKey: true,
    items: [
      {
        name: 'Scorecards',
        link: '#scorecards',
        onClick() {
          console.log('Scorecards');
        },
      },
      {
        name: 'Templates',
        onClick() {
          console.log('Templates');
        },
      },
      {
        name: 'Settings',
        link: 'https://www.ansarada.com/',
      },
    ],
  },
  {
    items: [
      {
        name: 'Account settings',
        onClick() {
          console.log('Account settings');
        },
      },
      {
        name: 'Help and support',
        link: 'https://www.ansarada.com/',
        target: '_blank',
      },
    ],
  },
  {
    items: [
      {
        name: 'Log out',
        onClick() {
          console.log('Log out');
        },
      },
    ],
  },
];

const HeaderTest = () => (
  <Header
    userName="Ciro Nunes"
    productName="Rooms"
    products={[
      {
        name: 'Home',
        link: 'https://www.ansarada.com/',
        onClick: () => {
          // eslint-disable-next-line no-alert
          alert('Welcome home');
        },
      },
      {
        name: 'Rooms',
        link: 'https://dataroom.ansarada.com/',
      },
      {
        name: 'Scorecards',
        link: 'https://mip.ansarada.com/',
      },
    ]}
    navigationGroups={landmarkGroups}
  />
);

export default HeaderTest;
