// @flow strict
export const HOUR = 'hour';
export const MINUTE = 'minute';
export const MERIDIEM = 'meridiem';

export const AM = 'am';
export const PM = 'pm';

export const SELECTION_PARTS = {
  hour: [0, 2],
  minute: [3, 5],
  meridiem: [5, 7],
};
