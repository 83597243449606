// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FormatUnderline = (): React.Element<*> => (
  <>
    <path d="M13.006 15.981h-6c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h6c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M9.942 14.981h0.143q1.111 0 1.902-0.783t0.791-1.909v-6.766q0-0.256-0.164-0.399c-0.1-0.088-0.232-0.142-0.377-0.142s-0.277 0.054-0.378 0.143l0.001-0.001q-0.164 0.142-0.164 0.399v6.766q0 0.67-0.47 1.147t-1.14 0.477h-0.142q-0.67 0-1.147-0.477t-0.477-1.147v-6.766q0-0.256-0.164-0.399c-0.1-0.088-0.232-0.143-0.377-0.143s-0.277 0.054-0.378 0.143l0.001-0.001q-0.164 0.143-0.164 0.399v6.766q0 1.125 0.791 1.909t1.916 0.783z"></path>
  </>
);
