// @flow strict
import * as React from 'react';
import { v4 as uuid } from 'uuid';
import useEventListener from '@use-it/event-listener';
import {
  search,
  valueExist,
  reducer,
  handleBodyEscape,
  handleBodyUpDown,
  handleInputKeydown,
  Trigger,
  NativeSelect,
  Options,
  flatValueExist,
} from './Select2.util';
import type { Props, Option } from './Select2.types';

import { Dropdown } from '../Dropdown';
import { DropdownGroup } from '../Dropdown/DropdownGroup';
import { DropdownAction } from '../Dropdown/DropdownAction';

/** @ignore */
const ControlledSelect2 = ({
  id,
  name,
  placeholder,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  options,
  value = '',
  size,
  status,
  onChange,
  disable = false,
}: Props): React.Element<'div'> => {
  const internalId = uuid();
  const initialState = {
    open: false,
    filter: '',
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const open = () => dispatch({ type: 'TOGGLE', payload: { open: true, filter: '' } });
  const close = () => dispatch({ type: 'TOGGLE', payload: { open: false, filter: '' } });
  const cleanFilter = () => dispatch({ type: 'CLEAR_FILTER' });

  const triggerRef = React.useRef(null);
  const containerRef = React.useRef(null);

  useEventListener('keydown', e => handleBodyEscape(e, state, close, cleanFilter), document.body);
  useEventListener('keydown', e => handleBodyUpDown(e, state, containerRef), document.body);
  useEventListener(
    'keydown',
    e => handleInputKeydown(e, state, open, containerRef),
    triggerRef.current,
  );

  // $FlowFixMe
  const optionsData = options.flatMap(group => group.items);
  const filteredOptions = search(optionsData, state.filter);

  const trigger = () => (
    <Trigger
      placeholder={placeholder}
      ariaControls={[internalId]}
      size={size}
      status={status}
      state={state}
      triggerRef={triggerRef}
      optionsData={optionsData}
      options={options}
      dispatch={dispatch}
      open={state.open}
      value={value}
      disable={disable}
    />
  );

  return (
    <div data-test-id={testId}>
      {!disable && (
        <Dropdown
          data-ansarada-ccd={ansaradaCCD}
          fullWidthTrigger
          id={internalId}
          open={state.open}
          onToggle={isOpen => {
            if (isOpen) {
              return open();
            }
            return close();
          }}
          trigger={trigger()}
        >
          {typeof state.filter !== 'undefined' &&
          state.filter.trim() !== '' &&
          filteredOptions.length === 0 &&
          !valueExist(flatValueExist(options), state.filter) ? (
            <DropdownGroup>
              <DropdownAction onClick={() => {}}>Nothing found</DropdownAction>
            </DropdownGroup>
          ) : (
            <Options
              onChange={onChange}
              state={state}
              options={options}
              filteredOptions={filteredOptions}
              value={value}
              dispatch={dispatch}
            />
          )}
        </Dropdown>
      )}

      {disable && trigger()}

      <NativeSelect
        id={id}
        name={name}
        size={size}
        status={status}
        state={state}
        optionsData={optionsData}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disable={disable}
      />
    </div>
  );
};

/**
 * The `Select2` component...
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Button, Page
 * @category Layout
 */

const Select2 = (props: Props): React$Element<typeof ControlledSelect2> => {
  const [value, setValue] = React.useState(props.defaultValue || '');

  const onChange = (newValue: Option) => {
    if (typeof props.onChange !== 'undefined') {
      props.onChange(newValue);
    }

    if (typeof props.defaultValue !== 'undefined') {
      setValue(newValue.value);
    }
  };

  const allProps = {
    ...props,
    onChange,
    value: props.value || value,
  };

  return <ControlledSelect2 {...allProps} />;
};

Select2.defaultProps = {
  status: 'Good',
};

export { Select2 };
