// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import * as table from '../../legacy-components/Table';
import { TreeHeaderGroup } from './TreeHeaderGroup';
import { TreeItem } from './TreeItem';
import { TreeGroup } from './TreeGroup';

const { TableLegacy, TableHeadLegacy, TableBodyLegacy } = table;

const TreeHeader = TableHeadLegacy;
const TreeBody = TableBodyLegacy;

export type TreePropType = {
  variant?: 'Normal' | 'Document',
  truncated?: boolean,
  children?: React.ChildrenArray<
    React.Element<typeof TreeHeader> | React.Element<typeof TreeBody> | void | false,
  >,
  className?: string,
};

/**
 * The tree represents a nested view of other elements with any number of controls.
 *
 *
 * The tree element provides a way of grouping together nested content. Everything inside a tree is made up of other ACE elements.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Table
 * @category Data
 */
const Tree = (props: TreePropType) => {
  const { variant = 'Normal', truncated = false, children, className, ...rest } = props;

  const classes = classnames(
    'ace-tree',
    { 'ace-tree-truncated': truncated },
    { 'ace-tree-document': variant === 'Document' },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <div className={classes} {...rest}>
      <TableLegacy variant="WithControls">{children}</TableLegacy>
    </div>
  );
};

export { Tree, TreeGroup, TreeHeader, TreeHeaderGroup, TreeBody, TreeItem };
