// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { isActive } from '../../ace-internal/util/active';
import styles from './styles.scss';
import type { MessageType } from './Internal/TabMessage';
import { UpdateActiveTab, Message, transition } from './Internal/TabMessage';
import Tab from './TabContent';

export type TabsPropType = {|
  className?: string,
  /** The active tab */
  active: string | number,
  /** Used to control the component state */
  onUpdate: (transition: string | number, msg: MessageType, e: SyntheticEvent<HTMLElement>) => void,
  /** The actual tabs */
  children: React.ChildrenArray<React.Element<typeof Tab> | void | false>,
|};

/**
 * Tabs are a simple way to divide content into separate views, allowing the user to navigate between them without having to leave the page.
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Button
 * @category Layout
 */
const Tabs = (props: TabsPropType) => {
  const { active, onUpdate } = props;

  /**
   * renderTabButton Function
   * This will generate the onClick update message with the
   * respective tab Object and render the HTML for the <li>
   */
  const renderTabButton = (tab, index) => {
    if (!tab) {
      return tab;
    }

    const { name, header, id } = tab.props;
    const isActiveTab = isActive(active, index, name);

    const className = classnames(styles.tabItem, {
      [styles.activeTabItem]: isActiveTab,
    });

    return (
      <li role="presentation" className={className}>
        <a
          role="tab"
          id={`button_${id}`}
          aria-controls={id}
          aria-selected={isActiveTab}
          className={styles.tabLink}
          href={`#${id}`}
          onClick={event => {
            const msg = name !== undefined ? Message(name) : Message(index);
            event.preventDefault();
            onUpdate(transition(active, msg), msg, event);
          }}
        >
          {header}
        </a>
      </li>
    );
  };

  const renderActiveTab = children => {
    const activeTab = React.Children.toArray(children).find(
      (item, index) => item && isActive(active, index, item.props.name),
    );

    return activeTab ? <Tab {...activeTab.props} /> : null;
  };

  return (
    <div className={props.className}>
      <ul className={styles.tabs} role="tablist">
        {React.Children.map(props.children, renderTabButton)}
      </ul>

      <div className={styles.tabPanels}>{renderActiveTab(props.children)}</div>
    </div>
  );
};

export { Tabs, Tab, UpdateActiveTab };
