// @flow strict

import * as React from 'react';
import classnames from 'classnames';
import type { testIdPropType } from '../../ace-internal/types/general';

export type LabelPropType = {
  ...testIdPropType,
  forEl: string,
  assistive?: boolean,
  required?: boolean,
  requiredText?: string,
  prefixed?: boolean,
  className?: string,
  text?: string,
  children?: React.Node,
  size?: 'Small' | 'Medium' | 'Large',
};

/** @ignore */
const Label = (props: LabelPropType) => {
  const {
    size,
    className,
    assistive = false,
    required = false,
    requiredText = 'Required',
    prefixed = false,
    forEl,
    text,
    children,
    'data-test-id': testId,
    ...rest
  } = props;

  const classes = classnames(
    'ace-form-label',
    { 'ace-form-label-required': !!required },
    { 'ace-assistive': !!assistive },
    { 'ace-form-label-prefixedurl': !!prefixed },
    { [`ace-form-label-${String(size).toLowerCase()}`]: !!size },
    className,
  );

  return (
    <label
      className={classes}
      htmlFor={forEl}
      data-test-id={testId}
      // $FlowFixMe 0.112.0 - inexact rest of object pattern
      {...rest}
    >
      <span className="ace-text">
        {text}
        {children}
      </span>
      {required && (
        <abbr title={requiredText} className="ace-form-label-required">
          *
        </abbr>
      )}
    </label>
  );
};

export { Label };
