// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const PrintCross = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M13.5 7.992c-3.037 0-5.5 2.462-5.5 5.5s2.463 5.5 5.5 5.5 5.5-2.462 5.5-5.5-2.462-5.5-5.5-5.5zM16.682 16.674c-0.811 0.809-1.93 1.309-3.166 1.309-2.477 0-4.484-2.008-4.484-4.484s2.008-4.484 4.484-4.484c2.477 0 4.484 2.008 4.484 4.484 0 0.63-0.13 1.23-0.364 1.774l0.011-0.029c-0.239 0.556-0.564 1.031-0.965 1.431l-0 0z"
    ></path>
    <path
      fill="#fb0069"
      d="M15.854 11.138c-0.090-0.091-0.216-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-1.646 1.647-1.646-1.647c-0.090-0.090-0.216-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l1.647 1.646-1.647 1.646c-0.090 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146l1.646-1.646 1.646 1.646c0.090 0.090 0.216 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354l-1.647-1.646 1.647-1.646c0.091-0.090 0.146-0.216 0.146-0.354s-0.056-0.263-0.146-0.354v0z"
    ></path>
    <path d="M8.604 16h-3.12c-0.268 0-0.484-0.217-0.484-0.484v0-5.516h4.25c0.314-0.379 0.667-0.709 1.058-0.989l0.017-0.011h-5.783c-0 0-0 0-0 0-0.299 0-0.542 0.243-0.542 0.542 0 0 0 0 0 0v0 4.958h-0.516c-0.268 0-0.484-0.217-0.484-0.484v0-7.031c0-0.268 0.217-0.484 0.484-0.484v0h13.031c0.268 0 0.484 0.217 0.484 0.484v0 2.266c0.379 0.314 0.709 0.669 0.989 1.061l0.011 0.017v-3.344c0-0.82-0.665-1.484-1.484-1.484v0h-1.516c0-0.009 0-0.017 0-0.026v-1.448c0-0.567-0.459-1.026-1.026-1.026h-7.948c-0.567 0-1.026 0.459-1.026 1.026v1.448c0 0.009 0 0.017 0 0.026h-1.516c-0.82 0-1.484 0.665-1.484 1.484v0 7.031c0 0.82 0.665 1.484 1.484 1.484v0h0.516v0.016c0 0.82 0.665 1.484 1.484 1.484v0h3.78c-0.244-0.292-0.463-0.62-0.646-0.97l-0.014-0.030zM6 4.026c0-0.014 0.012-0.026 0.026-0.026v0h7.948c0.014 0 0.026 0.012 0.026 0.026v0 1.448c0 0.014-0.012 0.026-0.026 0.026v0h-7.948c-0.014 0-0.026-0.012-0.026-0.026v0z"></path>
  </>
);
