// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import type { testIdPropType } from '../../ace-internal/types/general.js';
import type { Variants } from './Table.shared';
import { TableBodyLegacy } from './TableBodyLegacy';
import { TableHeaderLegacy } from './TableHeaderLegacy';
import { TableHeadLegacy } from './TableHeadLegacy';
import { TableFootLegacy } from './TableFootLegacy';
import { TableDataLegacy } from './TableDataLegacy';
import { TableRowLegacy } from './TableRowLegacy';
import styles from './styles.scss';

export type TablePropType = {|
  ...testIdPropType,
  ...Variants,
  scrollable?: boolean,
  caption?: string,
  className?: string,
  // TODO: ACEREACT-498 There all react element allow due to an issue with TreeHeaderGroup cause tree and table to not load
  children?: React.ChildrenArray<
    | React.Element<any>
    | React.Element<typeof TableBodyLegacy>
    | React.Element<typeof TableHeadLegacy>
    | React.Element<typeof TableFootLegacy>
    | void
    | false,
  >,
|};

/**
 * Tables allow you to present static data in a tabular format.
 * Tables can be set to a specific width or take up the full width of the container, with values in the table cells left aligned by default.
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Button
 * @category Data
 */
const TableLegacy = (props: TablePropType) => {
  const { variant, scrollable = true, caption, children, className, ...rest } = props;

  const classes = classnames(
    'ace-table',
    { 'ace-table-with-controls': variant === 'WithControls' },
    className,
    styles.table,
  );

  const contents = (
    <div className="ace-table-container">
      <table className={classes} {...rest}>
        {caption && <caption>{caption}</caption>}
        {children}
      </table>
    </div>
  );

  return scrollable ? <div className="ace-table-scroll-container">{contents}</div> : contents;
};

TableLegacy.defaultProps = {
  variant: 'Normal',
};

export {
  TableLegacy,
  TableHeadLegacy,
  TableBodyLegacy,
  TableFootLegacy,
  TableRowLegacy,
  TableHeaderLegacy,
  TableDataLegacy,
};
export type { Variants };
