// @flow strict

import * as React from 'react';

/**
 * @ignore
 */

export const Workflow = (): React.Element<*> => (
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1152">
    <g id="All_icons" data-name="All icons">
      <g>
        <path d="M864,320h-32V97.7c0-17.67-14.33-32-32-32h-292.27l-.49-.84c-.72-2.08-1.5-4.15-2.36-6.19-4.84-11.44-11.77-21.72-20.59-30.54-8.82-8.82-19.09-15.75-30.54-20.59-11.85-5.01-24.43-7.55-37.4-7.55s-25.55,2.54-37.4,7.55c-11.44,4.84-21.71,11.77-30.54,20.59-8.82,8.82-15.75,19.09-20.59,30.54-.86,2.04-1.65,4.1-2.36,6.19l-.5.84H32C14.33,65.7,0,80.02,0,97.7v896c0,17.67,14.33,32,32,32h163.58c15.52,72.1,79.75,126.3,156.42,126.3h512c88.22,0,160-71.78,160-160V480c0-88.22-71.78-160-160-160ZM64,129.7h128v31.76c0,17.8,14.43,32.24,32.24,32.24h383.52c17.8,0,32.24-14.43,32.24-32.24v-31.76h128v190.3h-416c-88.22,0-160,71.78-160,160v481.7H64V129.7ZM960,992c0,25.5-10.03,49.57-28.23,67.77s-42.27,28.23-67.77,28.23H352c-25.5,0-49.57-10.02-67.77-28.23s-28.23-42.27-28.23-67.77V480c0-25.5,10.03-49.57,28.23-67.77s42.27-28.23,67.77-28.23h512c25.5,0,49.57,10.02,67.77,28.23s28.23,42.27,28.23,67.77v512Z" />
        <path d="M800,512h-256c-17.67,0-32,14.33-32,32s14.33,32,32,32h256c17.67,0,32-14.33,32-32s-14.33-32-32-32Z" />
        <path d="M800,704h-256c-17.67,0-32,14.33-32,32s14.33,32,32,32h256c17.67,0,32-14.33,32-32s-14.33-32-32-32Z" />
        <rect x="384" y="512" width="64" height="64" />
        <rect x="384" y="704" width="64" height="64" />
        <path d="M800,896h-256c-17.67,0-32,14.33-32,32s14.33,32,32,32h256c17.67,0,32-14.33,32-32s-14.33-32-32-32Z" />
        <rect x="384" y="896" width="64" height="64" />
      </g>
    </g>
  </svg>
);
