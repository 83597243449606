// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const CopyTick = (): React.Element<*> => (
  <>
    <path
      fill="#93b703"
      d="M13.5 7.981c-3.037 0-5.5 2.463-5.5 5.5s2.463 5.5 5.5 5.5 5.5-2.463 5.5-5.5-2.462-5.5-5.5-5.5zM16.682 16.663c-0.811 0.809-1.93 1.309-3.166 1.309-2.477 0-4.484-2.008-4.484-4.484s2.008-4.484 4.484-4.484c2.477 0 4.484 2.008 4.484 4.484 0 0.63-0.13 1.23-0.364 1.774l0.011-0.029c-0.238 0.556-0.564 1.031-0.965 1.431l-0 0z"
    ></path>
    <path
      fill="#93b703"
      d="M15.364 10.674c-0.058-0.025-0.126-0.040-0.197-0.040-0.205 0-0.381 0.123-0.458 0.3l-0.001 0.003-1.637 3.818-0.821-1.232c-0.091-0.135-0.243-0.223-0.416-0.223-0.276 0-0.5 0.224-0.5 0.5 0 0.103 0.031 0.2 0.085 0.279l-0.001-0.002 1.333 2c0.091 0.135 0.243 0.223 0.416 0.223v0c0.016 0 0.031 0 0.046-0.002 0.186-0.018 0.342-0.135 0.413-0.298l0.001-0.003 2-4.667c0.026-0.058 0.041-0.126 0.041-0.198 0-0.205-0.123-0.381-0.3-0.458l-0.003-0.001z"
    ></path>
    <path d="M8.596 15.975h-3.783c-0.42-0-0.761-0.341-0.761-0.761v-0.003l-0.043-7.976c0.001-0.42 0.341-0.76 0.761-0.76h0.734l0.034 6.254c0.002 0.971 0.789 1.758 1.76 1.76h0.793c-0.058-0.301-0.092-0.646-0.092-1v-0h-0.701c-0.42-0-0.761-0.341-0.761-0.761v-0.003l-0.043-7.976c0.001-0.42 0.341-0.76 0.761-0.76h4.141v2.739c0 0.585 0.286 1.104 0.725 1.424l0.005 0.003c0.411-0.11 0.882-0.173 1.369-0.173 1.333 0 2.555 0.473 3.508 1.26l-0.009-0.007v-1.604c0-0 0-0 0-0 0-0.138-0.056-0.263-0.146-0.354l-4.142-4.142c-0.090-0.090-0.215-0.146-0.354-0.146-0 0-0 0-0 0h-5.097c-0.972 0.001-1.76 0.789-1.761 1.762v0.003l0.004 0.723h-0.728c-0.972 0.001-1.76 0.789-1.761 1.76v0.003l0.043 7.977c0.002 0.972 0.79 1.758 1.761 1.76h4.438c-0.242-0.292-0.459-0.62-0.64-0.969l-0.014-0.031zM12.396 4.238l3.25 3.25h-2.489c-0.42-0-0.761-0.341-0.761-0.761v-0z"></path>
  </>
);
