// @flow strict

import React from 'react';

import {
  Dropdown,
  DropdownGroup,
  DropdownAction,
  Button,
  Dialog,
  TableLegacy,
  TableHeadLegacy,
  TableBodyLegacy,
  TableRowLegacy,
  TableHeaderLegacy,
  TableDataLegacy,
  icon,
  Icon,
  FormattedContentBlock,
} from '../components';

type StateType = {
  [string]: boolean,
  dialogTest: boolean,
};

class DropdownTestComplex extends React.Component<{}, StateType> {
  _triggerRef: HTMLElement;

  boundElement: HTMLElement;

  constructor() {
    super();
    this.state = {
      dropdown21: false,
      dropdown22: false,
      dropdown23: false,
      dropdown24: false,
      dropdown25: false,
      dropdown26: false,
      dropdown29: false,
      dialogTest: false,
    };
  }

  update(address: string, value: boolean) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    const {
      dropdown21,
      dropdown22,
      dropdown23,
      dropdown24,
      dropdown25,
      dropdown26,
      dropdown29,
      dialogTest,
    } = this.state;
    return (
      <div>
        <FormattedContentBlock>
          <h2>Dropdown Default</h2>
        </FormattedContentBlock>
        <Dropdown
          open={dropdown21}
          onToggle={value => this.update('dropdown21', value)}
          trigger={<Button>Click to open dropdown</Button>}
        >
          <DropdownGroup header="This a header">
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction disabled href="#">
              item disabled
            </DropdownAction>
            <DropdownAction href="#" icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />}>
              item link with icon
            </DropdownAction>
          </DropdownGroup>
          <DropdownGroup>
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
          </DropdownGroup>
        </Dropdown>

        <FormattedContentBlock>
          <h2>Dialog</h2>
        </FormattedContentBlock>

        <Button onClick={() => this.update('dialogTest', true)}>Open dialog</Button>

        {dialogTest && (
          <Dialog
            size="Medium"
            title="dialog Test"
            footerPrimaryActions={[
              <Button onClick={() => this.update('dialogTest', false)}>Close</Button>,
            ]}
            onClose={v => this.update('dialogTest', v)}
            data-test-id="dialogTest"
          >
            <Dropdown
              open={dropdown22}
              onToggle={value => this.update('dropdown22', value)}
              trigger={<Button>Click to open dropdown</Button>}
            >
              <DropdownGroup header="This a header">
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item link</DropdownAction>
                <DropdownAction disabled href="#">
                  item disabled
                </DropdownAction>
                <DropdownAction
                  href="#"
                  icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />}
                >
                  item link with icon
                </DropdownAction>
              </DropdownGroup>
            </Dropdown>

            <FormattedContentBlock>
              <p>Hello friendly ACE users.</p>
              <p>Please don&apos;t look. I&apos;m shy.</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
              <p>Hai</p>
            </FormattedContentBlock>

            <Dropdown
              open={dropdown23}
              onToggle={value => this.update('dropdown23', value)}
              trigger={<Button>Click to open dropdown</Button>}
            >
              <DropdownGroup header="This a header">
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item link</DropdownAction>
                <DropdownAction disabled href="#">
                  item disabled
                </DropdownAction>
                <DropdownAction
                  href="#"
                  icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />}
                >
                  item link with icon
                </DropdownAction>
              </DropdownGroup>
            </Dropdown>
          </Dialog>
        )}

        <FormattedContentBlock>
          <h2>A dropdown too far to the right</h2>
        </FormattedContentBlock>

        <div
          style={{
            display: 'flex',
            height: '40px',
            background: '#BABCBE',
            flexDirection: 'row-reverse',
          }}
        >
          <div style={{ marginRight: '-20px', marginTop: '5px' }}>
            <Dropdown
              trigger={<Button>Click to open dropdown</Button>}
              open={dropdown24}
              onToggle={value => this.update('dropdown24', value)}
            >
              <DropdownGroup header="This a header">
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item default</DropdownAction>
                <DropdownAction href="#">item link</DropdownAction>
                <DropdownAction disabled href="#">
                  item disabled
                </DropdownAction>
                <DropdownAction
                  href="#"
                  icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />}
                >
                  item link with icon
                </DropdownAction>
              </DropdownGroup>
            </Dropdown>
          </div>
        </div>

        <FormattedContentBlock>
          <h2>Table test with transform</h2>
        </FormattedContentBlock>

        <div
          style={{
            overflow: 'scroll',
            height: '600px',
            width: '100%',
            willChange: 'transform',
          }}
        >
          <TableLegacy>
            <TableHeadLegacy>
              <TableRowLegacy>
                <TableHeaderLegacy>Name</TableHeaderLegacy>
                <TableHeaderLegacy>Controls</TableHeaderLegacy>
              </TableRowLegacy>
            </TableHeadLegacy>
            <TableBodyLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy>
                  <Dropdown
                    data-test-id="dropdownWrapper5"
                    trigger={<Button>Click to open dropdown</Button>}
                    open={dropdown25}
                    onToggle={value => this.update('dropdown25', value)}
                  >
                    <DropdownGroup header="This a header">
                      <DropdownAction href="#">item default</DropdownAction>
                      <DropdownAction href="#">item default</DropdownAction>
                      <DropdownAction href="#">item default</DropdownAction>
                      <DropdownAction href="#">item default</DropdownAction>
                      <DropdownAction href="#">item default</DropdownAction>
                      <DropdownAction href="#">item default</DropdownAction>
                      <DropdownAction href="#">item link</DropdownAction>
                      <DropdownAction disabled href="#">
                        item disabled
                      </DropdownAction>
                      <DropdownAction
                        href="#"
                        icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />}
                      >
                        item link with icon
                      </DropdownAction>
                    </DropdownGroup>
                  </Dropdown>
                </TableDataLegacy>
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
              <TableRowLegacy>
                <TableDataLegacy>
                  <Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />
                  Test Item
                </TableDataLegacy>
                <TableDataLegacy />
              </TableRowLegacy>
            </TableBodyLegacy>
          </TableLegacy>
        </div>

        <FormattedContentBlock>
          <h2>Oversized</h2>
        </FormattedContentBlock>

        <Dropdown
          data-test-id="dropdownWrapper6"
          trigger={<Button>Click to open oversize dropdown</Button>}
          open={dropdown26}
          onToggle={value => this.update('dropdown26', value)}
        >
          <DropdownGroup header="This a header">
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">A</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction href="#">Z</DropdownAction>
          </DropdownGroup>
        </Dropdown>

        <FormattedContentBlock>
          <h2>Dropdown horizontal align</h2>
        </FormattedContentBlock>

        <Dropdown
          open={dropdown29}
          onToggle={value => this.update('dropdown29', value)}
          horizontalAlign="Right"
          trigger={<Button>Click to open dropdown</Button>}
        >
          <DropdownGroup header="This a header">
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
            <DropdownAction disabled href="#">
              item disabled
            </DropdownAction>
            <DropdownAction href="#" icon={<Icon glyph={icon.Glyphs.ControlMenu} text="Menu" />}>
              item link with icon
            </DropdownAction>
          </DropdownGroup>
          <DropdownGroup>
            <DropdownAction href="#">item default</DropdownAction>
            <DropdownAction href="#">item link</DropdownAction>
          </DropdownGroup>
        </Dropdown>
      </div>
    );
  }
}

export default DropdownTestComplex;
