// @flow strict
import * as React from 'react';
import { TableRowLegacy } from './TableRowLegacy';

export type TableFooterPropType = {
  children?: React.ChildrenArray<React.Element<typeof TableRowLegacy> | void | false>,
};

/** @ignore */
export const TableFootLegacy = (props: TableFooterPropType) => {
  const { children, ...rest } = props;
  return <tfoot {...rest}>{children}</tfoot>;
};
