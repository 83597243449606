// @flow strict
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';

import { getHeight, toggleHeight } from './Sidebar.shared';
import styles from './SidebarCategory.scss';

type Props = {|
  title: string,
  children: React$Node,
  isSearching: boolean,
|};

const up = 'up';
const down = 'down';
type Direction = 'up' | 'down';

const Arrow = ({ direction }: { direction: Direction }) => (
  <svg
    className={cx(styles.arrow, direction === up && styles.up)}
    width="14px"
    height="9px"
    viewBox="0 0 14 9"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-181.000000, -195.000000)" fill="#939396">
        <g transform="translate(0.000000, 89.000000)">
          <polygon
            transform="translate(187.890000, 110.360000) rotate(-270.000000) translate(-187.890000, -110.360000) "
            points="191.78 110.36 185.42 116.72 184 115.31 188.95 110.36 184 105.41 185.42 104"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);

export const SidebarCategory = ({ title, children, isSearching }: Props) => {
  const [height, setHeight] = useState(getHeight(isSearching));
  useEffect(() => {
    setHeight(getHeight(isSearching));
  }, [isSearching]);
  return (
    <div className={styles.container}>
      <button tabIndex="0" onClick={() => setHeight(toggleHeight(height))} className={styles.title}>
        {title}
        <Arrow direction={height === 0 ? up : down} />
      </button>
      <AnimateHeight height={height}>{children}</AnimateHeight>
    </div>
  );
};
