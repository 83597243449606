// @flow strict
import * as React from 'react';

import classnames from 'classnames/bind';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';
// eslint-disable-next-line css-modules/no-unused-class
import hacks from './hacks.scss';

import { FormGridCell } from './FormGridCell';
import { FormGridContentCell } from './FormGridContentCell';

import type { testIdPropType } from '../../ace-internal/types/general';

const columnSizes = Object.freeze({
  '100': '100',
  '50': '50',
});

export type FormGridRowPropsType = {|
  children: React.ChildrenArray<
    | React$Element<typeof FormGridCell>
    | React$Element<typeof FormGridContentCell>
    | React$Element<'div'>
    | React$Element<'span'>
    | void
    | false,
  >,
  /** Sets the size of each column in the grid row */
  columnSize?: $Values<typeof columnSizes>,
  /** Sets test id used for e2e */
  ...testIdPropType,
  className?: string,
|};

/** @ignore */
const FormGridRow = ({
  children,
  columnSize,
  'data-test-id': testId,
  className,
}: FormGridRowPropsType) => (
  <div
    data-test-id={testId}
    className={classnames(styles.formGridRow, className, {
      [styles.cols100]: columnSize === columnSizes['100'],
      [styles.cols50]: columnSize === columnSizes['50'],
      [hacks.cols50]: columnSize === columnSizes['50'],
    })}
  >
    {children}
  </div>
);

export { FormGridRow, columnSizes };
