// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { useUncontrolled } from 'uncontrollable';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import {
  ContentState,
  convertFromHTML as HTMLToState,
  Editor as DraftEditor,
  EditorState,
  Modifier,
  RichUtils,
  CompositeDecorator,
} from 'draft-js';
import { Toolbar } from './Toolbar';
import styles from './styles.scss';

import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';

type EditorType = 'Editor';

type BaseProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  status: 'Good' | 'Bad',
  className?: string,
  maxLength?: number,
  readOnly?: boolean,
  keepEmptyLines?: boolean,
  resizable?: boolean,
  showHeadingControls?: boolean,
|};

type ControlledHtml<T> = {|
  html?: T,
  onHtmlChange: (html: T, plainText: T) => void,
|};
type UncontrolledHtml<T> = {|
  defaultHtml: T,
  onHtmlChange?: (html: T, plainText: T) => void,
|};
type Html<T> = ControlledHtml<T> | UncontrolledHtml<T>;

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledHtml<string>,
|};

type EditorPropType = {|
  ...BaseProps,
  ...Html<string>,
|};

const Anchor = (props: { children: React.Node, contentState: ContentState, entityKey: string }) => {
  const entity = props.contentState.getEntity(props.entityKey);
  const url = entity ? entity.getData().url : '#';
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

// Decorator strategy that allows us to render "Link" entities as <a> tags in the editor
function linkEntityStrategy(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

// "Decorators" allow us to add custom rich styling to the editor
// See https://draftjs.org/docs/advanced-topics-decorators.html#compositedecorator
const decorator = new CompositeDecorator([
  {
    strategy: linkEntityStrategy,
    component: Anchor,
  },
]);

const getContentRawFromHtml = (contentHtml: string, keepEmptyLines?: boolean): EditorState => {
  const blocksFromHTML = HTMLToState(contentHtml);
  let contentState = convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'a') {
        return createEntity('LINK', 'MUTABLE', { url: node.href });
      }
      return undefined;
    },
  })(contentHtml);

  if (!keepEmptyLines) {
    contentState = blocksFromHTML.contentBlocks
      ? ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
      : null;
  }

  return contentState && contentState.getBlockMap().first()
    ? EditorState.createWithContent(contentState, decorator)
    : EditorState.createEmpty(decorator);
};

const getHtmlFromContentRaw = (contentRaw: EditorState, keepEmptyLines?: boolean): string => {
  const currentContent = contentRaw.getCurrentContent();
  const htmlContent = keepEmptyLines ? convertToHTML(currentContent) : stateToHTML(currentContent);
  return htmlContent;
};

const renderCharacterCounter = (maxLength: number, currentLength: number): React$Element<'div'> => {
  const charCounter = maxLength - currentLength;
  return (
    <div
      className={classnames(styles.charCounter, {
        [styles.invalid]: charCounter < 0,
      })}
    >
      {charCounter} character{Math.abs(charCounter) !== 1 ? 's' : ''} remaining
    </div>
  );
};

const getPlainTextFromContent = (contentRaw: EditorState): string =>
  contentRaw.getCurrentContent().getPlainText();

const ControlledEditor = ({
  'data-ansarada-ccd': ansaradaCCD,
  'data-test-id': testId,
  className,
  html = '',
  keepEmptyLines,
  maxLength,
  onHtmlChange,
  readOnly,
  resizable,
  showHeadingControls,
  status,
}: ControlledPropType) => {
  const editorNode = React.useRef();
  const [hasFocus, setHasFocus] = React.useState(false);
  const [raw, setRaw] = React.useState();

  const onChange = (contentRaw: EditorState): void => {
    if (readOnly) {
      return;
    }
    if (onHtmlChange) {
      const contentHTML = getHtmlFromContentRaw(contentRaw, keepEmptyLines);
      const contentPlainText = getPlainTextFromContent(contentRaw);
      setRaw(contentRaw);
      onHtmlChange(contentPlainText ? contentHTML : '', contentPlainText);
    }
  };

  React.useEffect(() => {
    setRaw(getContentRawFromHtml(html, keepEmptyLines));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorContentClasses = classnames(styles.editorContent, {
    [styles.editing]: !readOnly,
  });

  const editorContainerClasses = classnames(styles.editor, {
    [styles.editingMode]: !readOnly,
    [styles.isFocus]: hasFocus && !readOnly,
    [styles.isBad]: status === 'Bad',
    [styles.isResizable]: resizable,
  });

  if (!raw && !readOnly) {
    return null;
  }

  const renderCharCounter =
    !readOnly &&
    maxLength &&
    renderCharacterCounter(maxLength, getPlainTextFromContent(raw).length);

  const editorState = readOnly && html ? getContentRawFromHtml(html, keepEmptyLines) : raw;

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(raw, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onBlur = () => {
    setHasFocus(false);
  };

  const onFocus = () => {
    setHasFocus(true);
  };

  const focusEditor = () => {
    if (editorNode && editorNode.current && !hasFocus) {
      editorNode.current.focus();
    }
  };

  return (
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div className={className}>
      <div
        className={editorContainerClasses}
        onClick={focusEditor}
        onFocus={onFocus}
        onBlur={onBlur}
        aria-invalid={status === 'Bad'}
        data-ansarada-ccd={ansaradaCCD || undefined}
        data-test-id={testId}
      >
        {!readOnly && (
          <Toolbar contentRaw={raw} showHeadingControls={showHeadingControls} onChange={onChange} />
        )}
        <div className={editorContentClasses}>
          <DraftEditor
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onChange={onChange}
            handlePastedText={(text, plainHtml, state) => {
              const newContent = Modifier.replaceText(
                state.getCurrentContent(),
                state.getSelection(),
                text,
              );
              const newState = EditorState.push(state, newContent);
              onChange(newState);
              return 'handled';
            }}
            spellCheck
            ref={editorNode}
            readOnly={readOnly}
          />
        </div>
      </div>
      {renderCharCounter}
    </div>
  );
};

/**
 * Editor is a multiline input field used primarily in forms. It enables the user to enter multiple lines of content in a structured format and apply basic text formatting.
 *
 *
 * Editor should be used with a label.
 *
 * @status released
 * @version 10.0.0
 * @date 26/09/2016
 * @tags Editor, Button
 * @category Form
 */
const Editor = (props: EditorPropType): React$Element<typeof ControlledEditor> => {
  const allProps = useUncontrolled(props, {
    html: 'onHtmlChange',
  });
  return <ControlledEditor {...allProps} />;
};

Editor.defaultProps = {
  status: 'Good',
};

export type { EditorType };
export { Editor };
