// @flow strict
import * as React from 'react';

import { DesignTokenStage, DesignTokenExample } from '..';

type Props = {
  groupName: string,
  valueNameSpace: string,
  removeNameSpace?: string,
  scss: any,
};

export const DesignTokenStageAuto = ({
  groupName,
  valueNameSpace,
  removeNameSpace = '',
  scss,
}: Props) => (
  <DesignTokenStage>
    {scss.variables
      .filter(item => item.name === groupName)
      .map(item =>
        item.mapValue
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }

            if (a.name > b.name) {
              return 1;
            }

            return 0;
          })

          .map(example => (
            <DesignTokenExample
              label={`${valueNameSpace}-${example.name.replace(removeNameSpace, '')}`}
            >
              {example.compiledValue}
            </DesignTokenExample>
          )),
      )}
  </DesignTokenStage>
);
