// @flow strict
/* eslint react/no-unescaped-entities: off */

import * as React from 'react';

import {
  TableLegacy,
  TableHeadLegacy,
  TableBodyLegacy,
  TableFootLegacy,
  TableRowLegacy,
  TableHeaderLegacy,
  TableDataLegacy,
  FormattedContentBlock,
} from '../components';

type MessageType = {
  message: string,
  data: Object,
};

type StateType = {
  messageOpen: boolean,
};

export const tableWithCaption = (
  <TableLegacy caption="Table of Red Dwarf crew members.">
    <TableHeadLegacy>
      <TableRowLegacy>
        <TableHeaderLegacy>Name</TableHeaderLegacy>
        <TableHeaderLegacy>Rank</TableHeaderLegacy>
      </TableRowLegacy>
    </TableHeadLegacy>
    <TableBodyLegacy>
      <TableRowLegacy>
        <TableDataLegacy>Dave Lister</TableDataLegacy>
        <TableDataLegacy>Technician (Third Class)</TableDataLegacy>
      </TableRowLegacy>
      <TableRowLegacy>
        <TableDataLegacy>Arnold Rimmer</TableDataLegacy>
        <TableDataLegacy>Technician (Second Class)</TableDataLegacy>
      </TableRowLegacy>
      <TableRowLegacy>
        <TableDataLegacy>Kryten</TableDataLegacy>
        <TableDataLegacy>Mechanoid</TableDataLegacy>
      </TableRowLegacy>
      <TableRowLegacy>
        <TableDataLegacy>Cat</TableDataLegacy>
        <TableDataLegacy>No official rank</TableDataLegacy>
      </TableRowLegacy>
      <TableRowLegacy>
        <TableDataLegacy>Holly</TableDataLegacy>
        <TableDataLegacy>Ship's computer AI</TableDataLegacy>
      </TableRowLegacy>
    </TableBodyLegacy>
    <TableFootLegacy>
      <TableRowLegacy>
        <TableHeaderLegacy>Comprises</TableHeaderLegacy>
        <TableDataLegacy>The whole crew</TableDataLegacy>
      </TableRowLegacy>
    </TableFootLegacy>
  </TableLegacy>
);

class MessageTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = { messageOpen: true };
  }

  update(address: string, func: Function) {
    return (msg: MessageType) => {
      const { state } = this;
      this.setState({ ...state, [address]: func(state[address], msg) });
    };
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <p>Table with caption, header and footer:</p>
          {tableWithCaption}

          <p>
            Table with sortable affordances.{' '}
            <strong>NOTE table sorting solution needs to be resolved</strong>.
          </p>
        </FormattedContentBlock>
        <TableLegacy>
          <TableHeadLegacy>
            <TableRowLegacy>
              <TableHeaderLegacy sortDirection="Ascending">Ascending</TableHeaderLegacy>
              <TableHeaderLegacy sortDirection="Descending">Descending</TableHeaderLegacy>
            </TableRowLegacy>
          </TableHeadLegacy>
          <TableBodyLegacy>
            <TableRowLegacy>
              <TableDataLegacy>A</TableDataLegacy>
              <TableDataLegacy>2</TableDataLegacy>
            </TableRowLegacy>
            <TableRowLegacy>
              <TableDataLegacy>B</TableDataLegacy>
              <TableDataLegacy>1</TableDataLegacy>
            </TableRowLegacy>
          </TableBodyLegacy>
        </TableLegacy>
        <FormattedContentBlock>
          <p>Normal, disabled and highlighted rows:</p>
        </FormattedContentBlock>
        <TableLegacy>
          <TableHeadLegacy>
            <TableRowLegacy>
              <TableHeaderLegacy>Stuff</TableHeaderLegacy>
              <TableHeaderLegacy>Things</TableHeaderLegacy>
              <TableHeaderLegacy>Stuff</TableHeaderLegacy>
              <TableHeaderLegacy>Things</TableHeaderLegacy>
            </TableRowLegacy>
          </TableHeadLegacy>
          <TableBodyLegacy>
            <TableRowLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
            </TableRowLegacy>
            <TableRowLegacy disabled>
              <TableDataLegacy>Disabled row</TableDataLegacy>
              <TableDataLegacy>Disabled row</TableDataLegacy>
              <TableDataLegacy>Disabled row</TableDataLegacy>
              <TableDataLegacy>Disabled row</TableDataLegacy>
            </TableRowLegacy>
            <TableRowLegacy highlighted>
              <TableDataLegacy>Highlighted row</TableDataLegacy>
              <TableDataLegacy>Highlighted row</TableDataLegacy>
              <TableDataLegacy>Highlighted row</TableDataLegacy>
              <TableDataLegacy>Highlighted row</TableDataLegacy>
            </TableRowLegacy>
            <TableRowLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
              <TableDataLegacy>Normal row</TableDataLegacy>
            </TableRowLegacy>
          </TableBodyLegacy>
        </TableLegacy>

        <FormattedContentBlock>
          <p>Cell types - classes are applied:</p>
        </FormattedContentBlock>
        <TableLegacy>
          <TableHeadLegacy>
            <TableRowLegacy>
              <TableHeaderLegacy variant="Icon">Icon</TableHeaderLegacy>
              <TableHeaderLegacy variant="Number">Number</TableHeaderLegacy>
              <TableHeaderLegacy variant="Date">Date</TableHeaderLegacy>
              <TableHeaderLegacy variant="Form">Form</TableHeaderLegacy>
              <TableHeaderLegacy variant="Control">Control</TableHeaderLegacy>
              <TableHeaderLegacy variant="Checkbox">Checkbox</TableHeaderLegacy>
              <TableHeaderLegacy variant="Email">Email</TableHeaderLegacy>
              <TableHeaderLegacy variant="User">User</TableHeaderLegacy>
              <TableHeaderLegacy variant="UserGroup">UserGroup</TableHeaderLegacy>
            </TableRowLegacy>
          </TableHeadLegacy>
          <TableBodyLegacy>
            <TableRowLegacy>
              <TableDataLegacy variant="Icon">Icon</TableDataLegacy>
              <TableDataLegacy variant="Number">Number</TableDataLegacy>
              <TableDataLegacy variant="Date">Date</TableDataLegacy>
              <TableDataLegacy variant="Form">Form</TableDataLegacy>
              <TableDataLegacy variant="Control">Control</TableDataLegacy>
              <TableDataLegacy variant="Checkbox">Checkbox</TableDataLegacy>
              <TableDataLegacy variant="Email">Email</TableDataLegacy>
              <TableDataLegacy variant="User">User</TableDataLegacy>
              <TableDataLegacy variant="UserGroup">UserGroup</TableDataLegacy>
            </TableRowLegacy>
          </TableBodyLegacy>
        </TableLegacy>
      </div>
    );
  }
}

export default MessageTest;
