// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { useUncontrolled } from 'uncontrollable';

import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import styles from './styles.scss';

type TextAreaType = 'TextArea';

export type BaseProps = {|
  /** Sets the data-test-id for e2e testing */
  ...testIdPropType,
  /** Sets the data-ansarada-ccd attrib */
  ...ansaradaCCDPropType,
  /** An optional id for connecting labels to textarea element */
  id?: string,
  disabled?: boolean,
  /** Adds extra classes in the TextArea element */
  className?: string,
  readOnly?: boolean,
  ariaDescribedBy?: string,
  status?: 'Good' | 'Bad',
  onMouseOver?: (e: SyntheticMouseEvent<*>) => void,
  onMouseOut?: (e: SyntheticMouseEvent<*>) => void,
  onFocus?: (e: SyntheticEvent<*>) => void,
  onBlur?: (e: SyntheticEvent<*>) => void,
  onClick?: (e: SyntheticMouseEvent<*>) => void,
  /** Sets placeholder text */
  placeholder?: string,
  required?: boolean,
  name?: string,
  autoComplete?: string,
  maxLength?: number,
  minLength?: number,
|};

type ControlledValue<T> = {|
  value: T,
  onChangeValue: (value: T) => void,
|};
type UncontrolledValue<T> = {|
  defaultValue: T,
  onChangeValue?: (value: T) => void,
|};
type Value<T> = ControlledValue<T> | UncontrolledValue<T>;

type ControlledProps = {|
  ...BaseProps,
  ...ControlledValue<string>,
|};

type Props = {|
  ...BaseProps,
  ...Value<string>,
|};

const ControlledTextArea = (props: ControlledProps) => {
  const {
    id,
    value,
    className,
    onChangeValue,
    disabled = false,
    status = 'Good',
    readOnly = false,
    'data-ansarada-ccd': ansaradaCCD = false,
    'data-test-id': testId,
    ariaDescribedBy,
    onMouseOver,
    onMouseOut,
    onFocus,
    onBlur,
    onClick,
    placeholder,
    required,
    name,
    autoComplete,
    maxLength,
    minLength,
  } = props;

  const classes = classnames(styles.textArea, { [styles.isBad]: status === 'Bad' }, className);

  return (
    <textarea
      id={id}
      autoComplete={autoComplete}
      name={name}
      value={value}
      className={classes}
      disabled={disabled}
      readOnly={!onChangeValue || readOnly}
      onChange={event => {
        if (onChangeValue) onChangeValue(event.target.value);
      }}
      data-ansarada-ccd={ansaradaCCD || undefined}
      data-test-id={testId}
      aria-describedby={ariaDescribedBy}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      placeholder={placeholder}
      required={required}
      maxLength={maxLength}
      minLength={minLength}
      aria-invalid={status === 'Bad'}
      aria-required={required}
    />
  );
};

/**
 * Text area is a multiline input field used primarily in forms. It enables the user to enter multiple lines of content in a structured format.
 *
 * Text area should be used with a label.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  TextInput
 * @category Form
 */
const TextArea = (props: Props) => {
  const allProps = useUncontrolled(props, {
    value: 'onChangeValue',
  });
  return <ControlledTextArea {...allProps} />;
};

export { TextArea };
export type { TextAreaType };
