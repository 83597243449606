// @flow strict

import React from 'react';
import cx from 'classnames';
import styles from '../styles.scss';

import { Icon, Glyphs as IconGlyphs } from '../../Icon';
import { PositionPopover, positions } from '../../../ace-internal/shared-component/PositionPopover';
import { DatepickerInput } from './DatepickerInput';
import { DatepickerCalendar } from './DatepickerCalendar';
import { useDisplay } from './DatepickerContext';
import type { BaseProps } from '..';

const ignoreFocus = (event: SyntheticMouseEvent<>) => {
  event.preventDefault();
};

export const DatepickerContainer = ({
  className,
  format,
  id,
  placeholder,
  size,
  'data-ansarada-ccd': ansaradaCCD,
}: BaseProps) => {
  const [inputNode, setInputNode] = React.useState();
  const context = useDisplay();

  const openDatepicker = () => {
    if (inputNode) {
      inputNode.focus();
    }
  };

  return (
    <div className={cx(styles.datepicker, className)}>
      <DatepickerInput
        id={id}
        size={size}
        format={format}
        placeholder={placeholder}
        inputRef={el => setInputNode(el)}
        data-ansarada-ccd={ansaradaCCD || undefined}
      />

      <Icon
        glyph={IconGlyphs.ObjectsDate}
        size="Medium"
        className={styles.datepickerButton}
        onClick={() => {
          if (!context.calendarVisible) {
            openDatepicker();
            context.onCalendarVisible(true);
          }
        }}
        onMouseDown={e => {
          if (context.calendarVisible) {
            ignoreFocus(e);
          }
        }}
      />

      {context.calendarVisible && (
        <PositionPopover
          verticalOffset={10}
          triggerRef={inputNode}
          idealPosition={positions.BottomLeft}
        >
          <DatepickerCalendar data-ansarada-ccd={ansaradaCCD || undefined} />
        </PositionPopover>
      )}
    </div>
  );
};
