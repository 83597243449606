// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Cloud = (): React.Element<*> => (
  <>
    <path d="M17.94 9.276c-0.582-0.63-1.359-1.070-2.236-1.225l-0.024-0.003c-0.831-2.383-3.059-4.062-5.678-4.062-3.314 0-6 2.686-6 6 0 0.002 0 0.003 0 0.005v-0c-1.654 0-3 1.346-3 3s1.346 3 3 3h11c2.209-0 4-1.791 4-4 0-1.049-0.404-2.003-1.064-2.717l0.002 0.003zM15.002 14.99h-11c-1.104-0.001-1.998-0.896-1.998-2s0.895-2 2-2c0.149 0 0.295 0.016 0.434 0.047l-0.013-0.002c0.032 0.007 0.068 0.011 0.105 0.011 0.276 0 0.5-0.224 0.5-0.5 0-0.018-0.001-0.035-0.003-0.052l0 0.002c-0.017-0.167-0.025-0.338-0.025-0.506 0-0 0-0 0-0 0-2.761 2.239-5 5-5 1.152 0 2.212 0.389 3.057 1.043l-0.011-0.009c0.839 0.651 1.46 1.546 1.76 2.577l0.009 0.036c0.057 0.199 0.229 0.345 0.438 0.363l0.002 0c1.544 0.135 2.745 1.422 2.745 2.989v0c0 1.654-1.346 3-3 3z"></path>
  </>
);
