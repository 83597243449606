// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import type {
  Node,
  ExpandedNodeIdsType,
  OnChangeExpanded,
  OnChangeNodes,
  OnCollapsedType,
  OnExpandType,
  ColumnType,
  Size,
  SelectedNodeIdsType,
  OnSelectType,
  OnChangeSelected,
} from '../Tree2.types';
import { KeyEvent } from '../../../ace-internal/types/keys';
import { hasActiveElementInEventPath, getEventDomPath } from '../../../ace-internal/util/dom';
import {
  getChildenNodes,
  triggerExpandNode,
  triggerCollapseNode,
  getPaddingLevel,
  getSelectedNodes,
  updateStatusNodebyId,
} from './Shared';
import { Row } from './Row';
import { StatusIcon } from './StatusIcon';
import { CheckboxSelect } from './CheckboxSelect';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

type Props = {|
  nodes?: Array<Node>,
  expandedNodeIds: ExpandedNodeIdsType,
  renderNode?: (treeNode: Node) => React.Node,
  onChangeExpanded?: OnChangeExpanded,
  onChangeNodes?: OnChangeNodes,
  onExpand?: OnExpandType,
  onCollapsed?: OnCollapsedType,
  node: Node,
  level?: number,
  size?: Size,
  columns?: Array<ColumnType>,
  checkable?: boolean,
  selectedNodeIds?: SelectedNodeIdsType,
  onChangeSelected?: OnChangeSelected,
  onSelect?: OnSelectType,
|};

/** @ignore */
const TreeNode = ({
  nodes = [],
  expandedNodeIds = [],
  onChangeNodes,
  renderNode,
  onChangeExpanded,
  onExpand,
  onCollapsed,
  node,
  level = 0,
  size,
  columns = [],
  checkable = false,
  selectedNodeIds = [],
  onChangeSelected,
  onSelect,
}: Props): React.Element<'li'> => {
  const { id, type, data } = node;
  const expanded = expandedNodeIds.includes(id);
  const actionNode = React.useRef<null | HTMLDivElement>(null);
  const disabled = node.disabled ? node.disabled : false;
  const childrenStatus = node.childrenStatus ? node.childrenStatus : 'Loaded';
  const selectedState = getSelectedNodes(selectedNodeIds, nodes, node);

  const toggleExpandedStatus = () => {
    if (
      typeof node.onLoad === 'function' &&
      childrenStatus === 'NotLoaded' &&
      onChangeNodes &&
      onChangeExpanded
    ) {
      onChangeNodes(updateStatusNodebyId(node, nodes, 'Loading'));
      if (typeof node.onLoad === 'function') {
        node
          .onLoad(node)
          .then((newNodes?: Array<Node> = []) => {
            const newNodesList = [...updateStatusNodebyId(node, nodes, 'Loaded'), ...newNodes];
            onChangeNodes(newNodesList);
          })
          .then(() => {
            triggerExpandNode(id, onChangeExpanded, expandedNodeIds, node, onExpand);
          })
          .catch(() => onChangeNodes(updateStatusNodebyId(node, nodes, 'NotLoaded')));
      }
    }

    if (childrenStatus === 'Loaded' && childrenStatus !== 'None' && expanded) {
      triggerCollapseNode(id, onChangeExpanded, expandedNodeIds, node, onCollapsed);
    }

    if (childrenStatus === 'Loaded' && childrenStatus !== 'None' && !expanded) {
      triggerExpandNode(id, onChangeExpanded, expandedNodeIds, node, onExpand);
    }

    if (childrenStatus === 'NotLoaded' && typeof node.onLoad !== 'function') {
      triggerExpandNode(id, onChangeExpanded, expandedNodeIds, node, onExpand);
    }
  };

  const onKeyDownEvent = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (actionNode && actionNode.current && actionNode.current !== e.target) {
      return;
    }

    e.stopPropagation();
    switch (type) {
      case 'Branch':
        if (KeyEvent.isEnter(e) || KeyEvent.isSpace(e)) {
          e.preventDefault();
          toggleExpandedStatus();
        }
        break;
      case 'Leaf':
        if (node.onKeydown) {
          node.onKeydown(node, e);
        }
        break;
      default:
        break;
    }
  };

  const onClickEvent = (e: SyntheticMouseEvent<HTMLElement>) => {
    if (actionNode.current) {
      const paths = getEventDomPath(e, actionNode.current);
      if (hasActiveElementInEventPath(paths)) {
        return;
      }
    }

    if (
      // $FlowFixMe
      e._dispatchInstances &&
      Array.isArray(e._dispatchInstances) &&
      e._dispatchInstances.length &&
      e._dispatchInstances.length > 1
    ) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    switch (type) {
      case 'Branch':
        toggleExpandedStatus();
        break;
      case 'Leaf':
        if (node.onClick) {
          node.onClick(node, e);
        }
        break;
      default:
    }
  };

  const statusIcon = <StatusIcon status={childrenStatus} type={type} expanded={expanded} />;
  const checkBox = checkable ? (
    <CheckboxSelect
      nodes={nodes}
      node={node}
      checked={selectedState === 'true'}
      indeterminate={selectedState === 'indeterminate'}
      onSelect={onSelect}
      onChangeSelected={onChangeSelected}
      selectedNodeIds={selectedNodeIds}
    />
  ) : (
    undefined
  );

  const isTable =
    !renderNode && columns.length > 0 && typeof data === 'object' && !React.isValidElement(data);

  return (
    <li role="none">
      {(renderNode || data) && (
        <div
          className={classnames(
            styles.wrapperStyles,
            {
              [styles.activeStyles]:
                !disabled && childrenStatus !== 'Loading' && childrenStatus !== 'None',
            },
            {
              [styles.notActiveStyles]:
                disabled || childrenStatus === 'Loading' || childrenStatus === 'None',
            },
            {
              [styles.compactStyles]: size === 'Compact',
            },
            {
              [styles.standardStyles]: size === 'Standard',
            },
          )}
          role="treeitem"
          aria-expanded={type === 'Branch' && childrenStatus !== 'None' ? expanded : undefined}
          aria-busy={type === 'Branch' && childrenStatus === 'Loading'}
          aria-label={type === 'Branch' && childrenStatus === 'Loading' ? 'Loading' : undefined}
          onClick={onClickEvent}
          onKeyDown={onKeyDownEvent}
          tabIndex="0"
          ref={actionNode}
        >
          {(typeof renderNode === 'function' ||
            typeof data !== 'object' ||
            React.isValidElement(data)) && (
            <div
              className={styles.contentWrapperStyles}
              style={{ paddingLeft: `${getPaddingLevel(level, type)}px` }}
            >
              {checkBox}
              {statusIcon}
              {renderNode ? renderNode(node) : data}
            </div>
          )}

          {isTable && (
            <Row
              id={id}
              columns={columns}
              data={data}
              statusElement={statusIcon}
              level={level}
              type={type}
              checkBox={checkBox}
            />
          )}
        </div>
      )}
      {expanded && type === 'Branch' && (
        <ul className={classnames(styles.ulStyles, styles.expandStyles)}>
          {getChildenNodes(
            id,
            nodes,
            level + 1,
            expandedNodeIds,
            onChangeExpanded,
            onChangeNodes,
            size,
            renderNode,
            columns,
            onExpand,
            onCollapsed,
            checkable,
            selectedNodeIds,
            onChangeSelected,
            onSelect,
          )}
        </ul>
      )}
    </li>
  );
};

export { TreeNode };
