// @flow strict
import * as React from 'react';
import { render } from 'react-dom';
import App from './App';

const root = document.querySelector('#orbit-root');

if (root !== null) {
  if (module.hot) {
    module.hot.accept(err => {
      // eslint-disable-next-line no-console
      if (err) console.log(err);
    });
  }
  render(<App />, root);
}
