// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { SidebarMenuItem } from '..';
import styles from './SidebarMenuGroup.styles.scss';

export type SidebarMenuGroupPropTypes = {
  open?: boolean,
  header?: ?string,
  children?: React.ChildrenArray<
    React.Element<typeof SidebarMenuItem> | React.Element<'li'> | void | false,
  >,
  isNewSidebarEnabled?: boolean,
};

/** @ignore */
export const SidebarMenuGroup = (props: SidebarMenuGroupPropTypes) => {
  const { header, children, open, isNewSidebarEnabled } = props;

  return (
    <React.Fragment>
      {header !== undefined && header !== null && open && (
        <h3 className={isNewSidebarEnabled ? classnames(styles.title) : 'ace-h3'}>{header}</h3>
      )}
      <ul className={styles.section}>{children}</ul>
    </React.Fragment>
  );
};
