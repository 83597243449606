// @flow strict
import * as React from 'react';
import marked from 'marked';
import { Tabs, Tab } from '@ansarada/ace-react';
import {
  Readme,
  Showcase,
  // TableOfContents,
  PropsTable,
} from '..';

import styles from './HowToUse.scss';
import type { ComponentType, ComponentDocsType } from '../../../types/orbit';

type Props = {|
  componentConfig: ComponentType,
  componentDocs: ComponentDocsType,
|};

export const HowToUse = ({ componentConfig, componentDocs }: Props) => {
  const [active, setActive] = React.useState(0);
  const { importSnippet, fixtures, type, description } = componentDocs;
  const readme = { __html: marked(componentConfig.readme) };
  const { reportBugUrl } = window.ORBIT.config;
  const editTheseDocsUrl = `${window.ORBIT.repository.url}src/master/packages/ace/components/${componentConfig.displayName}/README.md?mode=edit&spa=0&at=master&fileviewer=file-view-default`;

  return (
    <div className={styles.container}>
      {/* <div className={styles.tableOfContentsContainer}>
        <TableOfContents selector="h2, h3" />
      </div> */}

      <Tabs active={active} onUpdate={tab => setActive(tab)}>
        <Tab header="How to use" id="how-to-use">
          <Showcase
            componentDocs={componentDocs}
            importSnippet={importSnippet}
            description={description}
            fixtures={fixtures}
            type={type}
          />
        </Tab>
        <Tab header="Props" id="props">
          <PropsTable component={componentConfig} />
        </Tab>
        <Tab header="README" id="readme">
          <Readme editUrl={editTheseDocsUrl} reportBugUrl={reportBugUrl} readme={readme} />
        </Tab>
      </Tabs>
    </div>
  );
};
