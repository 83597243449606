// @flow strict
import * as React from 'react';
import cx from 'classnames';

import styles from './style.scss';

type LoadingIndicatorBaseType = {
  /** Dark mode */
  dark?: boolean,
  className?: string,
  size?: number,
};

/**
 * Loading Indicators are used to indicate a loading state.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Button, Page
 * @category Feedback
 */
const LoadingIndicator = ({ dark = false, className, size }: $Exact<LoadingIndicatorBaseType>) => {
  const classes = cx(styles.loadingIndicator, className, {
    [styles.isDark]: dark,
  });

  return <span className={classes} style={size ? { height: size, width: size } : undefined} />;
};

export default LoadingIndicator;
