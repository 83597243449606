// @flow strict
import React from 'react';
import { HelpLink, Icon, icon } from '../components';

const HelpLinkTest = () => (
  <div>
    <HelpLink
      href="http://example.com/"
      icon={<Icon glyph={icon.Glyphs.StatusHelp} text="Menu" title="Help" />}
    />
    <HelpLink
      href="http://example.com/"
      text="Help"
      icon={<Icon glyph={icon.Glyphs.StatusHelp} text="Menu" />}
    />
  </div>
);

export default HelpLinkTest;
