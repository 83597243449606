// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { getCellVariantCSSClass } from './Table.shared';
import type { CellVariants, SortDirections } from './Table.shared.js';

export type TableHeaderPropType = {
  variant?: CellVariants,
  sortDirection?: SortDirections,
  className?: string,
  children?: React.ChildrenArray<React.Node>,
};

/** @ignore */
export const TableHeaderLegacy = (props: TableHeaderPropType) => {
  const { variant = 'Generic', sortDirection = 'Ascending', className, children, ...rest } = props;

  const classes = classnames(
    { [`ace-table-cell-${getCellVariantCSSClass(variant)}`]: !!variant },
    className,
  );

  const attrs = {
    ...rest,
    'data-ace-sorted-direction': sortDirection.toLowerCase(),
    className: classes,
  };

  return <th {...attrs}>{children}</th>;
};
