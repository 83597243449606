// @flow strict
import * as React from 'react';
import classnames from 'classnames';

// eslint-disable-next-line css-modules/no-unused-class
import hacks from './hacks.scss';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

import type { testIdPropType } from '../../ace-internal/types/general';
import { FormGridRow } from './FormGridRow';

export type FormGridPropsType = {|
  children: React.ChildrenArray<React$Element<typeof FormGridRow>>,
  /** Sets test id used for e2e */
  ...testIdPropType,
  className?: string,
|};

/**
 * FormGrid allows a variety of form components to be laid out within a grid system.
 *
 * The grid system is made up of containers, rows and columns, which align content and is flexible allowing fluid resizing of columns and rows.
 * Label, hint and errors are apart of FormGridCell component.
 *
 * For example if text width sits full width, column width would resize to fit.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags Select, TextInput, Radio
 * @category Form
 */

const FormGrid = ({ children, 'data-test-id': testId, className }: FormGridPropsType) => (
  <div className={classnames(styles.formGrid, hacks.formGrid, className)} data-test-id={testId}>
    {children}
  </div>
);

export { FormGrid };
