// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { Chevron } from './Chevron';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../style.scss';

const Variants = Object.freeze({
  Back: 'Back',
  Next: 'Next',
});

type BackNextProp = {|
  onClick: (e: SyntheticMouseEvent<>) => void,
  disabled?: boolean,
  variant?: $Keys<typeof Variants>,
|};

/** @ignore */
const BackNext = ({ variant = Variants.Next, onClick, disabled = false }: BackNextProp) => (
  <li>
    <button
      title={variant}
      onClick={onClick}
      // eslint-disable-next-line css-modules/no-undef-class
      className={classnames(styles.item, styles.backNext)}
      type="button"
      disabled={disabled || undefined}
    >
      <Chevron
        direction={variant === Variants.Next ? 'Left' : 'Right'}
        disabled={disabled || undefined}
      />
    </button>
  </li>
);

export { BackNext, Variants };
