// @flow strict

import * as React from 'react';
import classnames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';
import type { testIdPropType, ansaradaCCDPropType } from '../../../ace-internal/types/general';

const Sizes = Object.freeze({
  Small: 'Small',
  Medium: 'Medium',
});

export type ItemPropType = {|
  text: string,
  size?: $Values<typeof Sizes>,
  href?: string,
  target?: string,
  onClick?: () => void,
  ...testIdPropType,
  ...ansaradaCCDPropType,
|};

const minCharacter = 8;

const isCharacterLengthLess = (text: string, length: number) => text.length <= length;

/**
 * @ignore
 */
const Item = (props: ItemPropType) => {
  const classes = classnames(styles.breadcrumb, {
    [styles.isLast]: !props.onClick && !props.href,
    [styles.isNotLast]: props.onClick || props.href,
    [styles.isLessThenMin]: isCharacterLengthLess(props.text, minCharacter),
    [styles.isSmall]: props.size === Sizes.Small,
  });
  return (
    <li
      className={classes}
      data-test-id={props['data-test-id']}
      data-ansarada-ccd={props['data-ansarada-ccd']}
    >
      {props.href && (
        <a
          href={props.href}
          className={styles.isActive}
          target={props.target}
          rel={props.target ? 'noopener noreferrer' : undefined}
        >
          {props.text}
        </a>
      )}
      {!props.href && props.onClick && (
        <button onClick={props.onClick} className={styles.isActive}>
          {props.text}
        </button>
      )}
      {!props.href && !props.onClick && <span className={styles.isText}>{props.text}</span>}
    </li>
  );
};

export { Item };
