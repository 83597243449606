// @flow strict
import * as React from 'react';
import type {
  Id,
  Node,
  ExpandedNodeIdsType,
  NodesType,
  ColumnType,
  Size,
  SelectedNodeIdsType,
  OnSelectType,
  OnChangeSelected,
} from './Tree2.types';
import type { testIdPropType } from '../../ace-internal/types/general';
import { getChildenNodes, getChildrenIds } from './internal/Shared';
import { removeDuplicates } from '../../ace-internal/util/array';
import { Columns } from './internal/Columns';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

type Props = {|
  selectedNodeIds?: SelectedNodeIdsType,
  // eslint-disable-next-line react/no-unused-prop-types
  defaultSelectedNodeIds?: SelectedNodeIdsType,
  onChangeSelected?: OnChangeSelected,
  expandedNodeIds?: ExpandedNodeIdsType,
  // eslint-disable-next-line react/no-unused-prop-types
  defaultExpandedNodeIds?: ExpandedNodeIdsType,
  checkable?: boolean,
  onSelect?: OnSelectType,
  onExpand?: (treeNode: Node) => void,
  onCollapsed?: (treeNode: Node) => void,
  onChangeExpanded?: (expandedNodeIds: ExpandedNodeIdsType) => void,
  columns?: Array<ColumnType>,
  nodes?: NodesType,
  // eslint-disable-next-line react/no-unused-prop-types
  defaultNodes?: NodesType,
  onChangeNodes?: (nodes: NodesType) => void,
  renderNode?: (treeNode: Node) => React.Node,
  ...testIdPropType,
  size?: Size,
  showColumnHeadings?: boolean,
|};

const ControlledTree2 = ({
  'data-test-id': testId,
  nodes = [],
  expandedNodeIds = [],
  renderNode,
  onChangeExpanded,
  onChangeNodes,
  onExpand,
  onCollapsed,
  size = 'Standard',
  columns = [],
  checkable,
  selectedNodeIds = [],
  onChangeSelected,
  onSelect,
  showColumnHeadings = true,
}: Props): React.Element<'div'> => {
  React.useEffect(() => {
    if (checkable) {
      const nodeIds = nodes
        .filter(node => node.type === 'Branch' && selectedNodeIds.includes(node.id))
        .map(node => node.id);

      const newSelection = nodeIds.reduce((accumulator: SelectedNodeIdsType, id: Id): Array<Id> => {
        return [
          ...accumulator,
          ...getChildrenIds(id, nodes).filter(
            nodeId => !accumulator.includes(nodeId) && !selectedNodeIds.includes(nodeId),
          ),
        ];
      }, []);

      if (onChangeSelected && newSelection.length > 0) {
        onChangeSelected(removeDuplicates([...selectedNodeIds, ...newSelection]));
      }
    }
  }, [checkable, nodes, onChangeSelected, selectedNodeIds]);
  return (
    <div data-test-id={testId} className={styles.baseStyles}>
      {columns.length > 0 && showColumnHeadings && <Columns columns={columns} />}
      <ul role="tree" className={styles.ulStyles}>
        {getChildenNodes(
          'Root',
          nodes,
          0,
          expandedNodeIds,
          onChangeExpanded,
          onChangeNodes,
          size,
          renderNode,
          columns,
          onExpand,
          onCollapsed,
          checkable,
          selectedNodeIds,
          onChangeSelected,
          onSelect,
        )}
      </ul>
    </div>
  );
};

/**
 * The `Tree2` component...
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Button, Page
 * @category Layout
 */
const Tree2 = (props: Props): React$Element<typeof ControlledTree2> => {
  const [nodes, setNodes] = React.useState(props.defaultNodes || []);
  const [selectedNodeIds, setSelectedNodeIds] = React.useState(props.defaultSelectedNodeIds || []);
  const [expandedNodeIds, setExpandedNodeIds] = React.useState(props.defaultExpandedNodeIds || []);
  const onChangeExpanded = (newExpandedNodeIds: ExpandedNodeIdsType) => {
    if (props.onChangeExpanded) {
      props.onChangeExpanded(newExpandedNodeIds);
    }

    if (props.defaultExpandedNodeIds) {
      setExpandedNodeIds(newExpandedNodeIds);
    }
  };

  const onChangeSelected = (newExpandedNodeIds: ExpandedNodeIdsType) => {
    if (props.onChangeSelected) {
      props.onChangeSelected(newExpandedNodeIds);
    }

    if (props.defaultSelectedNodeIds) {
      setSelectedNodeIds(newExpandedNodeIds);
    }
  };

  const onChangeNodes = (newNodes: NodesType) => {
    if (props.onChangeNodes) {
      props.onChangeNodes(newNodes);
    }

    if (props.defaultExpandedNodeIds) {
      setNodes(newNodes);
    }
  };

  const allProps = {
    ...props,
    nodes: props.nodes || nodes,
    selectedNodeIds: props.selectedNodeIds || selectedNodeIds,
    expandedNodeIds: props.expandedNodeIds || expandedNodeIds,
    onChangeExpanded,
    onChangeSelected,
    onChangeNodes,
  };

  return <ControlledTree2 {...allProps} />;
};

export { Tree2 };
