// @flow strict
/* eslint no-console: off */

import React from 'react';

import {
  form,
  Form,
  formSingleGroup,
  FormSingleGroup,
  formMultiGroup,
  FormMultiGroup,
  formContentGroup,
  FormContentGroup,
  FormSection,
  FormSubItem,
  Label,
  TextInput,
  Button,
  SubmitButton,
  Message,
  ExpanderPanel,
  FormattedContentBlock,
} from '../components';
import * as treeTest from './TreeTest';
import * as tableTest from './TableTest';

type StateType = {
  [string]: string,
};

class FormLayoutTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      textInput1: '',
      textInput2: '',
      textInput3: '',
      textInput4: '',
      textInput5: '',
      textInput6: '',
      textInput7: '',
      textInput8: '',
      textInput9: '',
      textInput10: '',
    };
  }

  update(address: string, value: string) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Short form with two simple inputs</h2>
        </FormattedContentBlock>
        <Form
          onSubmit={() => {
            console.log('Submit form');
          }}
          variant={form.Variants.SideLabel}
          method={form.Method.Get}
        >
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input1" text="Label for text1" />}
          >
            <TextInput
              id="text-input1"
              value={this.state.textInput1}
              onChangeValue={v => this.update('textInput1', v)}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input2" text="Label for text2" />}
          >
            <TextInput
              id="text-input2"
              value={this.state.textInput2}
              onChangeValue={v => this.update('textInput2', v)}
            />
          </FormSingleGroup>
          <FormContentGroup contentType={formSingleGroup.ContentType.Buttons}>
            <SubmitButton variant="Primary">Button 1</SubmitButton>
          </FormContentGroup>
        </Form>
        <FormattedContentBlock>
          <h2>Inline single group</h2>
        </FormattedContentBlock>
        <Form variant={form.Variants.Filter}>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Input}
            inline
            label={<Label text="Inline" forEl="text-input-inline-form" />}
          >
            <TextInput
              id="text-input-inline-form"
              value={this.state.textInput3}
              onChangeValue={() => {}}
            />
          </FormSingleGroup>
        </Form>
        <FormattedContentBlock>
          <h2>Long form with two sections</h2>
        </FormattedContentBlock>
        <Form variant={form.Variants.SideLabel} method={form.Method.Get}>
          <FormSection header="Section 1">
            <FormSingleGroup
              contentType={formSingleGroup.ContentType.Text}
              label={<Label forEl="text-input3" text="Label for text3" />}
            >
              <TextInput
                id="text-input3"
                value={this.state.textInput3}
                onChangeValue={v => this.update('textInput3', v)}
              />
            </FormSingleGroup>
            <FormMultiGroup contentType={formSingleGroup.ContentType.Text} legend="Multi Group 1">
              <FormSubItem>
                <Label forEl="text-input5" text="Input 5" assistive />
                <TextInput
                  id="text-input5"
                  value={this.state.textInput5}
                  onChangeValue={v => this.update('textInput5', v)}
                />
              </FormSubItem>
              <FormSubItem>
                <Label forEl="text-input6" text="Input 6" assistive />
                <TextInput
                  id="text-input6"
                  value={this.state.textInput6}
                  onChangeValue={v => this.update('textInput6', v)}
                />
              </FormSubItem>
            </FormMultiGroup>
          </FormSection>
          <FormSection header="Section 2">
            <FormSingleGroup
              contentType={formSingleGroup.ContentType.Text}
              label={<Label forEl="text-input4" text="Label for text4" />}
            >
              <TextInput
                id="text-input4"
                value={this.state.textInput4}
                onChangeValue={v => this.update('textInput4', v)}
              />
            </FormSingleGroup>
            <FormMultiGroup contentType={formMultiGroup.ContentType.Text} legend="Multi Group 2">
              <FormSubItem>
                <Label forEl="text-input7" text="Input 7" />
                <TextInput
                  id="text-input7"
                  value={this.state.textInput7}
                  onChangeValue={v => this.update('textInput7', v)}
                />
              </FormSubItem>
              <FormSubItem>
                <Label forEl="text-input8" text="Input 8" assistive />
                <TextInput
                  id="text-input8"
                  value={this.state.textInput8}
                  onChangeValue={v => this.update('textInput8', v)}
                />
              </FormSubItem>
            </FormMultiGroup>
            <FormContentGroup contentType={formContentGroup.ContentType.Buttons}>
              <Button>Button 2</Button>
              <Button>Button 3</Button>
            </FormContentGroup>
          </FormSection>
          <FormSection header="Section 3 (assistive header)" assistiveHeader>
            <FormContentGroup contentType={formContentGroup.ContentType.Buttons}>
              <Button>Button 4 (label should not be rendered)</Button>
            </FormContentGroup>
            <FormContentGroup contentType={formContentGroup.ContentType.Message}>
              <Message id="message-1">
                <FormattedContentBlock>
                  <p>I am a message</p>
                </FormattedContentBlock>
              </Message>
            </FormContentGroup>
          </FormSection>
        </Form>
        <h2>Assistive header form</h2>
        <Form variant={form.Variants.Filter}>
          <FormMultiGroup
            contentType={formMultiGroup.ContentType.Mixed}
            legend="Description of the overall form"
            assistiveHeader
            inline
          >
            <Label forEl="text-input9" text="Input 9" />
            <TextInput
              id="text-input9"
              value={this.state.textInput9}
              onChangeValue={v => this.update('textInput9', v)}
            />

            <Label forEl="text-input10" text="Input 10" />
            <TextInput
              id="text-input10"
              value={this.state.textInput10}
              onChangeValue={v => this.update('textInput10', v)}
            />
          </FormMultiGroup>
        </Form>
        <h2>Forms with different content types</h2>
        <Form variant={form.Variants.SideLabel}>
          <FormSection header="Tree">
            <FormContentGroup contentType={formContentGroup.ContentType.Tree}>
              {treeTest.tree}
            </FormContentGroup>
          </FormSection>
          <FormSection header="Table">
            <FormContentGroup contentType={formContentGroup.ContentType.Table}>
              {tableTest.tableWithCaption}
            </FormContentGroup>
          </FormSection>
          <FormSection header="ExpanderPanel">
            <FormContentGroup contentType={formContentGroup.ContentType.Expander}>
              <ExpanderPanel title="Initially Expanded ExpanderPanel" defaultExpanded={false}>
                <p>ExpanderPanel content.</p>
              </ExpanderPanel>
            </FormContentGroup>
          </FormSection>
        </Form>
      </div>
    );
  }
}

export default FormLayoutTest;
