// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Cross = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M13.859 6.136c-0.090-0.090-0.215-0.146-0.354-0.146s-0.263 0.056-0.354 0.146l-3.146 3.146-3.147-3.146c-0.090-0.087-0.213-0.141-0.348-0.141-0.276 0-0.5 0.224-0.5 0.5 0 0.136 0.054 0.259 0.142 0.349l3.146 3.146-3.146 3.146c-0.088 0.090-0.141 0.213-0.141 0.349 0 0.276 0.224 0.5 0.5 0.5 0.135 0 0.258-0.054 0.348-0.141l3.147-3.146 3.146 3.147c0.091 0.093 0.218 0.151 0.359 0.151 0.276 0 0.5-0.224 0.5-0.5 0-0.141-0.058-0.268-0.152-0.359l-3.146-3.147 3.146-3.146c0.091-0.090 0.147-0.216 0.147-0.354s-0.056-0.263-0.147-0.354v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M10.006 1.99c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM10.006 16.99c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"
    ></path>
  </>
);
