// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { getCellVariantCSSClass } from './Table.shared';
import type { CellVariants } from './Table.shared';

export type TableDataPropType = {
  variant?: CellVariants,
  className?: string,
  children?: React.Node,
};

/** @ignore */
export const TableDataLegacy = (props: TableDataPropType) => {
  const { variant = 'Generic', className, children, ...rest } = props;

  const classes = classnames(
    {
      [`ace-table-cell-${getCellVariantCSSClass(variant)}`]: variant !== 'Generic',
    },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <td className={classes} {...rest}>
      {children}
    </td>
  );
};
