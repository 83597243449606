// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { TableRowLegacy } from '../../legacy-components/Table';
import { TreeItem } from './TreeItem';

type TreeGroupPropType = {
  level?: number,
  expanded?: boolean,
  className?: string,
  children?: React.ChildrenArray<React.Element<typeof TreeItem> | void | false>,
};

/** @ignore */
export const TreeGroup = (props: TreeGroupPropType) => {
  // disabled passes through with ...rest
  const { level = 0, expanded = false, className, children, ...rest } = props;

  const classes = classnames(
    'ace-tree-group',
    `ace-tree-level-${level}`,
    { 'ace-tree-branch-expanded': expanded !== undefined && expanded },
    { 'ace-tree-branch-collapsed': expanded !== undefined && !expanded },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <TableRowLegacy className={classes} {...rest}>
      {children}
    </TableRowLegacy>
  );
};
