// @flow strict
import * as React from 'react';

import { ClassNames } from '@emotion/react';
import classnames from 'classnames';
import type { Id, ColumnType, RenderFunc, NodeTypes } from '../Tree2.types';

import { getPaddingLevel } from './Shared';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

import { CheckboxSelect } from './CheckboxSelect';

type Props = {|
  id: Id,
  columns: Array<ColumnType>,
  data: Object,
  statusElement: React.Element<*>,
  level: number,
  type: NodeTypes,
  checkBox?: React.Element<typeof CheckboxSelect>,
|};

export const getChildenContent = (
  data: Object,
  field: string,
  render?: RenderFunc,
): React.Node | void => {
  if (Object.prototype.hasOwnProperty.call(data, field)) {
    if (typeof render === 'function') {
      return render(data[field]);
    }
    return data[field];
  }
  return undefined;
};

export const Row = ({
  id,
  columns,
  data,
  statusElement,
  level,
  type,
  checkBox,
}: Props): React.Element<'div'> => (
  <div key={`row-${id}`} className={styles.rowWrapperStyles}>
    {columns.map((columnItem: ColumnType, index: number) => {
      const {
        field,
        render,
        className,
        width = '100%',
        minWidth,
        maxWidth,
        mobileWidth = width,
        mobileMinWidth = minWidth,
        mobileMaxWidth = maxWidth,
        displayOnMobile = true,
      } = columnItem;

      return (
        <ClassNames key={`cell-${id}-${field}`}>
          {({ css }) => (
            <div
              className={classnames(
                styles.cellStyles,
                css({
                  width,
                  minWidth,
                  maxWidth,
                  '@media (max-width: 767px)': {
                    width: mobileWidth,
                    minWidth: mobileMinWidth,
                    maxWidth: mobileMaxWidth,
                  },
                }),
              )}
              style={{ paddingLeft: index === 0 && `${getPaddingLevel(level, type)}px` }}
            >
              {index === 0 && checkBox}
              {index === 0 && statusElement}
              <div
                className={classnames(
                  styles.cellContentStyles,
                  { [styles.columHideMobileStyles]: !displayOnMobile },
                  className,
                )}
              >
                {getChildenContent(data, field, render)}
              </div>
            </div>
          )}
        </ClassNames>
      );
    })}
  </div>
);
