// @flow strict
import * as React from 'react';
import styles from './toast.scss';

import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { configInternalTestId } from '../../ace-internal/util/util';
import { BaseToast } from './BaseToast';
import { Button, Icon } from '..';

type ToastProps = {|
  variant?: 'Neutral' | 'Success' | 'Caution' | 'Error' | 'Info',
  /** Sets the icon of the toast */
  icon?: React.Element<typeof Icon>,
  /** The text to appear when the toast opens */
  text: string | React.Element<'span'>,
  /** Sets the action text to appear as a link button */
  actionText?: string,
  /** Callback for when the action is taken */
  onAction?: () => void,
  /** Callback for when the toast times out */
  onTimeout?: Function,
  /** The id which makes the toast accessible  */
  ariaId?: string,
  /** Sets timeout duration in milliseconds. If this is `undefined`, the default duration is 3000ms. If set to `0`, toast will not timeout. */
  timeoutDuration?: number,
  ...testIdPropType,
  ...ansaradaCCDPropType,
|};

const createInternalTestId = configInternalTestId({ componentName: 'Toast' });

/**
 * The Toast component is used to display alerts on the top or bottom of the screen.
 *
 *
 * The Toasts will close when the close button is clicked or after a timeout – default set to timeout in 3 seconds.
 *
 *
 * Use the Toast component when you want to provide feedback to the user about an action taken.
 *
 * @status released
 * @date 26/09/2018
 * @version 11.0.0
 * @tags Message, Button
 * @category Feedback
 */
export const Toast = (props: ToastProps) => {
  const {
    variant = 'Neutral',
    icon,
    text,
    actionText,
    onAction,
    onTimeout,
    timeoutDuration,
    ariaId,
    'data-test-id': testId,
    'data-ansarada-ccd': ansaradaCCD,
  } = props;
  const action = !!actionText && !!onAction && (
    <Button
      className={styles.actionButton}
      variant="Link"
      onClick={onAction}
      data-test-id={createInternalTestId('close')}
      aria-labelledby={ariaId}
    >
      {actionText}
    </Button>
  );

  return (
    <BaseToast
      onTimeout={onTimeout}
      timeoutDuration={timeoutDuration}
      data-test-id={testId}
      variant={variant}
    >
      <div className={styles.toastContent}>
        {icon && <span className={styles.icon}>{icon}</span>}
        <span
          data-test-id={createInternalTestId('text')}
          className={styles.text}
          data-ansarada-ccd={ansaradaCCD}
          id={ariaId}
        >
          {text}
        </span>
        {action && <span className={styles.action}>{action}</span>}
      </div>
    </BaseToast>
  );
};
