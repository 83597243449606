// @flow strict
import * as React from 'react';
import classnames from 'classnames/bind';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

import { Errors } from './Errors';
import { Hint } from './Hint';
import { Content } from './Content';

import type { ErrorContentType } from './Errors';
import type { HintContentType } from './Hint';

export type LabelPropsType = {|
  children: string,
  useLabel?: boolean,
  labelId: string,
  hide?: true,
  errors?: ErrorContentType,
  hint?: HintContentType,
|};

const getContent = (errors?: ErrorContentType, hint?: HintContentType, children: string) => (
  <React.Fragment>
    <Content>{children}</Content>
    {hint && <Hint>{hint}</Hint>}
    {errors && <Errors>{errors}</Errors>}
  </React.Fragment>
);

/**
 * @ignore
 */

const Label = ({ children, useLabel, labelId, hide, errors, hint }: LabelPropsType) => {
  const classes = classnames(styles.label, { [styles.hideLabel]: hide });
  return useLabel ? (
    <label htmlFor={labelId} className={classes}>
      {getContent(errors, hint, children)}
    </label>
  ) : (
    <legend id={labelId} className={classes}>
      {getContent(errors, hint, children)}
    </legend>
  );
};

export { Label };
