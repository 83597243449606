// @flow strict
import * as React from 'react';

import type { NavigationItem as NavigationItemProps } from '../types';
import { Button } from '../../Button';
import { Link } from '../../Link';

import styles from './NavigationItem.scss';

/** @ignore */
export const NavigationItem = (props: { item: NavigationItemProps }) => {
  const { item } = props;
  if (item.link) {
    return (
      <Link
        className={styles.clickable}
        href={item.link}
        target={item.target}
        onClick={item.onClick}
        data-test-id={item.name}
      >
        {item.name}
      </Link>
    );
  }
  if (item.onClick) {
    return (
      <Button
        className={styles.clickable}
        variant={item.variant || 'SubtleLink'}
        onClick={item.onClick}
        data-test-id={item.name}
        size="Compact"
      >
        {item.name}
      </Button>
    );
  }
  return <span>{item.name}</span>;
};
