// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { useUncontrolled } from 'uncontrollable';
import { Radio } from '../Radio';
import { getLayoutClasses } from '../../ace-internal/shared-component/CheckboxRadioGroupHelpers';
import type { ElementGroup } from '../../ace-internal/shared-component/CheckboxRadioGroupHelpers';
import styles from './styles.scss';

/**
 * Warning: This component is duplicated here and in CheckboxGroup and they should be updated together(they're only split because of react-docgen)
 *
 * Creates a group of elements with extra props. The benefit is that it wraps
 * its child elements with a styled div and adds extra props to them. Mainly used
 * for RadioGroup and CheckboxGroup since both components follow the same pattern.
 */
function CheckboxRadioGroupHOC(
  props: ElementGroup,
  addChildrenExtraProps: (React.Element<any>) => Object,
): React.Element<'div'> {
  return (
    <div className={classnames(getLayoutClasses(props.layout), props.className)}>
      {React.Children.map(props.children, element =>
        element ? React.cloneElement(element, addChildrenExtraProps(element)) : element,
      )}
    </div>
  );
}

export type RadioGroupType = 'RadioGroup';

type BaseProps = {|
  /** Name to be used in all Radios */
  name: string,
  groupLabel?: string,
  showGroupLabel?: boolean,
  children: React.ChildrenArray<React.Element<typeof Radio> | void | false>,
  /** Change the direction of the children Radios */
  layout?: 'Inline' | 'Block',
  className?: string,
  error?: boolean,
  disabled?: boolean,
  'aria-describedby'?: string,
|};

type ControlledValue<T> = {|
  value?: T,
  onChangeValue: (value: T) => void,
|};
type UncontrolledValue<T> = {|
  defaultValue: T,
  onChangeValue?: (value: T) => void,
|};
type Value<T> = ControlledValue<T> | UncontrolledValue<T>;

type ControlledProps = {|
  ...ControlledValue<string>,
|};

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledProps,
|};

type RadioGroupPropType = {|
  ...BaseProps,
  ...Value<string>,
|};

function onRadioChecked(value: string) {
  const lastValue = this.value;
  if (value !== lastValue) {
    this.onChangeValue(value);
  }
}

const ControlledRadioGroup = (props: ControlledPropType): React.Element<'div'> =>
  CheckboxRadioGroupHOC(props, (radio: React.Element<typeof Radio>): Object => ({
    name: props.name,
    checked: props.value === radio.props.value,
    onChecked: onRadioChecked.bind(props),
    'aria-describedby': props['aria-describedby'],
    error: props.error,
    disabled: props.disabled,
  }));

/**
 * Radio Group allows the user to make a single selection within a group of options.
 *
 *
 * A button is required to apply the user’s selection
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Radio, Checkbox, CheckboxGroup
 * @category Form
 */
const RadioGroup = (props: RadioGroupPropType): React$Element<'div'> => {
  const allProps = useUncontrolled(props, {
    value: 'onChangeValue',
  });
  return (
    <div>
      {props.showGroupLabel && <span className={styles.groupLabel}>{props.groupLabel}</span>}
      <ControlledRadioGroup {...allProps} />
    </div>
  );
};

export { RadioGroup };
