// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import type { testIdPropType } from '../../ace-internal/types/general';

import { Icon } from '../Icon';

import { AceText } from '../../legacy-components/AceText';

type HelpLinkPropType = {
  ...testIdPropType,
  text?: string,
  href: string,
  icon?: React.Element<typeof Icon>,
  className?: string,
};

/** @ignore */
const HelpLink = (props: HelpLinkPropType) => {
  const { text, href, icon, className, 'data-test-id': testId, ...rest } = props;

  const classes = classnames('ace-helplink', className);

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <a href={href} className={classes} {...rest} data-test-id={testId}>
      {icon}
      {text && <AceText text={text} />}
    </a>
  );
};

export { HelpLink };
