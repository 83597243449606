// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const History = (): React.Element<*> => (
  <>
    <path d="M10.002 2c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM10.002 17c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"></path>
    <path d="M10.502 9.793v-5.293c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0 5.5c0 0 0 0 0 0 0 0.138 0.056 0.263 0.146 0.354l2.5 2.5c0.090 0.090 0.216 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354v0z"></path>
  </>
);
