// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnAViewer = (): React.Element<*> => (
  <>
    <path d="M15.498 1h-11c-1.379 0-2.5 1.122-2.5 2.5v11c0 1.378 1.121 2.5 2.5 2.5h4.018l1.054 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.428-0.241l0.001-0.002 1.054-1.757h4.017c1.378 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.122-2.5-2.5-2.5zM16.998 14.5c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.428 0.241l-0.001 0.002-0.771 1.285-0.771-1.285c-0.089-0.146-0.248-0.243-0.429-0.243h-4.3c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M10.002 7.099c-1.249 0-2.265 1.016-2.265 2.265s1.016 2.265 2.265 2.265 2.264-1.016 2.264-2.265-1.016-2.265-2.264-2.265zM10.002 10.628c-0 0-0 0-0.001 0-0.698 0-1.265-0.566-1.265-1.265s0.566-1.265 1.265-1.265c0.698 0 1.265 0.566 1.265 1.265v0c-0.001 0.698-0.566 1.264-1.264 1.265h-0z"></path>
    <path d="M14.19 7.261c-0.509-0.555-1.078-0.999-1.691-1.319-0.773-0.403-1.615-0.608-2.501-0.608s-1.728 0.205-2.502 0.608c-0.613 0.32-1.182 0.763-1.691 1.319-0.858 0.936-1.253 1.871-1.269 1.91-0.024 0.057-0.038 0.123-0.038 0.192s0.014 0.136 0.040 0.196l-0.001-0.003c0.016 0.039 0.411 0.974 1.269 1.91 0.509 0.555 1.078 0.999 1.691 1.319 0.773 0.404 1.615 0.608 2.502 0.608s1.728-0.205 2.502-0.608c0.613-0.32 1.182-0.763 1.691-1.319 0.858-0.936 1.253-1.871 1.27-1.91 0.024-0.057 0.038-0.123 0.038-0.192s-0.014-0.136-0.040-0.196l0.001 0.003c-0.017-0.039-0.412-0.974-1.27-1.91zM13.431 10.813c-0.429 0.463-0.904 0.831-1.411 1.094-0.625 0.323-1.305 0.486-2.023 0.486s-1.398-0.164-2.023-0.487c-0.507-0.262-0.982-0.63-1.411-1.094-0.388-0.421-0.725-0.897-0.996-1.414l-0.017-0.036c0.288-0.553 0.625-1.030 1.017-1.455l-0.004 0.005c0.429-0.463 0.904-0.831 1.411-1.094 0.625-0.323 1.305-0.486 2.023-0.486s1.398 0.164 2.023 0.486c0.507 0.262 0.982 0.63 1.411 1.094 0.388 0.42 0.725 0.897 0.996 1.414l0.017 0.036c-0.288 0.553-0.625 1.030-1.017 1.456l0.004-0.005z"></path>
  </>
);
