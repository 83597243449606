// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const ImportFolderStructure = (): React.Element<*> => (
  <>
    <svg viewBox="0 0 1280 1280">
      <g>
        <path
          fill="#777778"
          d="M991.8,383.5h-64v64h64c52.9,0.2,95.8,43.1,96,96v64.1c-27.7-20.9-61.4-32.1-96-32.1h-128V172.7l105.4,105.4
		c12.5,12.5,32.8,12.5,45.3,0c12.5-12.5,12.5-32.8,0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3,0c0,0,0,0,0,0l-160,160
		c-12.5,12.5-12.5,32.8,0,45.3c12.5,12.5,32.8,12.5,45.3,0l105.4-105.4v402.7h-512c-34.6,0-68.3,11.2-96,32.1V415.5
		c0.2-52.9,43.1-95.8,96-96h192c52.9,0.2,95.8,43.1,96,96c0,17.7,14.3,32,32,32h128v-64h-99.2c-14.9-72.9-79.5-128-156.8-128h-192
		c-88.2,0-160,71.8-160,160v576c0,88.2,71.8,160,160,160h704c88.2,0,160-71.8,160-160v-448C1151.8,455.2,1080,383.5,991.8,383.5z
		 M1087.8,991.5c-0.2,52.9-43.1,95.8-96,96h-704c-52.9-0.2-95.8-43.1-96-96v-256c0.2-52.9,43.1-95.8,96-96h704
		c52.9,0.2,95.8,43.1,96,96V991.5z"
        />
      </g>
    </svg>
  </>
);
