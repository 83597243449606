// @flow strict

import React from 'react';

import { TextEditable } from '../components';

type State = {
  value: string,
};

class TextEditableTest extends React.Component<{}, State> {
  constructor() {
    super();
    this.state = {
      value: 'very long input value',
    };
  }

  render() {
    const { value } = this.state;
    const placeholder = 'Placeholder text';
    return (
      <div>
        <TextEditable
          value={value}
          placeholder={placeholder}
          onChangeValue={val => {
            this.setState({ value: val });
          }}
        />
      </div>
    );
  }
}

export default TextEditableTest;
