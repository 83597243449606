// @flow strict
/* eslint-disable no-unused-vars */

import * as React from 'react';

import classnames from 'classnames';

import { Actions } from './Internal/Actions';
import { Archive } from './Internal/Archive';
import { ArrowDown } from './Internal/ArrowDown';
import { ArrowIndent } from './Internal/ArrowIndent';
import { ArrowLeft } from './Internal/ArrowLeft';
import { ArrowRestore } from './Internal/ArrowRestore';
import { ArrowRight } from './Internal/ArrowRight';
import { ArrowSortDown } from './Internal/ArrowSortDown';
import { ArrowSortUp } from './Internal/ArrowSortUp';
import { ArrowUndo } from './Internal/ArrowUndo';
import { ArrowUp } from './Internal/ArrowUp';
import { Attachment } from './Internal/Attachment';
import { AwaitingAction } from './Internal/AwaitingAction';
import { Broken } from './Internal/Broken';
import { Caution } from './Internal/Caution';
import { ChevronAllCollapse } from './Internal/ChevronAllCollapse';
import { ChevronAllExpand } from './Internal/ChevronAllExpand';
import { ChevronCollapsed } from './Internal/ChevronCollapsed';
import { ChevronExpanded } from './Internal/ChevronExpanded';
import { CircleFill } from './Internal/CircleFill';
import { CircleOutline } from './Internal/CircleOutline';
import { Cloud } from './Internal/Cloud';
import { Colour } from './Internal/Colour';
import { Contact } from './Internal/Contact';
import { CopyCross } from './Internal/CopyCross';
import { CopyTick } from './Internal/CopyTick';
import { Copy } from './Internal/Copy';
import { CrossLarge } from './Internal/CrossLarge';
import { CrossSmall } from './Internal/CrossSmall';
import { Cross } from './Internal/Cross';
import { Date } from './Internal/Date';
import { Deny } from './Internal/Deny';
import { Design } from './Internal/Design';
import { Disable } from './Internal/Disable';
import { Download } from './Internal/Download';
import { EditCross } from './Internal/EditCross';
import { EditTick } from './Internal/EditTick';
import { Edit } from './Internal/Edit';
import { EmailBulk } from './Internal/EmailBulk';
import { EmailDeny } from './Internal/EmailDeny';
import { EmailSend } from './Internal/EmailSend';
import { EmailTick } from './Internal/EmailTick';
import { Email } from './Internal/Email';
import { Enable } from './Internal/Enable';
import { Evaluation } from './Internal/Evaluation';
import { ExternalLink } from './Internal/ExternalLink';
import { EyeHide } from './Internal/EyeHide';
import { EyeView } from './Internal/EyeView';
import { FileAcrobat } from './Internal/FileAcrobat';
import { FileAudio } from './Internal/FileAudio';
import { FileDocument } from './Internal/FileDocument';
import { FileExcel } from './Internal/FileExcel';
import { FileHtml } from './Internal/FileHtml';
import { FileImage } from './Internal/FileImage';
import { FilePowerpoint } from './Internal/FilePowerpoint';
import { FileText } from './Internal/FileText';
import { FileVideo } from './Internal/FileVideo';
import { FileVisio } from './Internal/FileVisio';
import { FileWord } from './Internal/FileWord';
import { Filter } from './Internal/Filter';
import { FolderEmpty } from './Internal/FolderEmpty';
import { FolderRoot } from './Internal/FolderRoot';
import { Folder } from './Internal/Folder';
import { FormatBoldActive } from './Internal/FormatBoldActive';
import { FormatBold } from './Internal/FormatBold';
import { FormatItalicActive } from './Internal/FormatItalicActive';
import { FormatItalic } from './Internal/FormatItalic';
import { FormatListActive } from './Internal/FormatListActive';
import { FormatList } from './Internal/FormatList';
import { FormatUnderlineActive } from './Internal/FormatUnderlineActive';
import { FormatUnderline } from './Internal/FormatUnderline';
import { Help } from './Internal/Help';
import { HeartOutline } from './Internal/HeartOutline';
import { HeartFill } from './Internal/HeartFill';
import { History } from './Internal/History';
import { Information } from './Internal/Information';
import { ImportFolderStructure } from './Internal/ImportFolderStructure';
import { Language } from './Internal/Language';
import { LinkIcon } from './Internal/LinkIcon';
import { LockClosed } from './Internal/LockClosed';
import { LockOpen } from './Internal/LockOpen';
import { LockSecurityPolicy } from './Internal/LockSecurityPolicy';
import { LogomarkAnsarada } from './Internal/LogomarkAnsarada';
import { LogomarkKira } from './Internal/LogomarkKira';
import { MenuContextual } from './Internal/MenuContextual';
import { Menu } from './Internal/Menu';
import { Move } from './Internal/Move';
import { Notification } from './Internal/Notification';
import { Number } from './Internal/Number';
import { Plus } from './Internal/Plus';
import { PrintCross } from './Internal/PrintCross';
import { PrintTick } from './Internal/PrintTick';
import { Print } from './Internal/Print';
import { PriorityHigh } from './Internal/PriorityHigh';
import { PriorityLow } from './Internal/PriorityLow';
import { PriorityMedium } from './Internal/PriorityMedium';
import { QnAActions } from './Internal/QnAActions';
import { QnAAdministrator } from './Internal/QnAAdministrator';
import { QnAAnswer } from './Internal/QnAAnswer';
import { QnAComment } from './Internal/QnAComment';
import { QnAForward } from './Internal/QnAForward';
import { QnAManage } from './Internal/QnAManage';
import { QnAQuestion } from './Internal/QnAQuestion';
import { QnASubjectGroup } from './Internal/QnASubjectGroup';
import { QnASubject } from './Internal/QnASubject';
import { QnATick } from './Internal/QnATick';
import { QnAViewer } from './Internal/QnAViewer';
import { RedactArea } from './Internal/RedactArea';
import { RedactPage } from './Internal/RedactPage';
import { RedactPattern } from './Internal/RedactPattern';
import { RedactText } from './Internal/RedactText';
import { Redact } from './Internal/Redact';
import { Report } from './Internal/Report';
import { SaveCross } from './Internal/SaveCross';
import { SaveTick } from './Internal/SaveTick';
import { Save } from './Internal/Save';
import { Search } from './Internal/Search';
import { Settings } from './Internal/Settings';
import { Submissions } from './Internal/Submissions';
import { Tick } from './Internal/Tick';
import { TriangleDown } from './Internal/TriangleDown';
import { TriangleRight } from './Internal/TriangleRight';
import { TriangleUp } from './Internal/TriangleUp';
import { Upload } from './Internal/Upload';
import { UserAdministrator } from './Internal/UserAdministrator';
import { UserBuysideGroup } from './Internal/UserBuysideGroup';
import { UserBuyside } from './Internal/UserBuyside';
import { UserSellsideGroup } from './Internal/UserSellsideGroup';
import { UserSellside } from './Internal/UserSellside';
import { Watermark } from './Internal/Watermark';
import { Workflow } from './Internal/Workflow';
import { ZipFile } from './Internal/ZipFile';

// import Sprite from './Assets/sprite.svg';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

type Size = 'Small' | 'Medium' | 'Large' | 'XLarge' | 'XXLarge';

type Color = string;
type FileColor = '#43a2d3';
type ReportColor = '#f67a50';
type QnAColor = '#1f9c73';
type SettingColor = '#774dca';
type HeartColor = '#1e8c0d';

export type Glyph =
  | 'Actions'
  | 'Archive'
  | 'Arrow-down'
  | 'Arrow-indent'
  | 'Arrow-left'
  | ['Arrow-left', SettingColor]
  | 'Arrow-restore'
  | 'Arrow-right'
  | 'Arrow-sort-down'
  | 'Arrow-sort-up'
  | 'Arrow-undo'
  | 'Arrow-up'
  | 'Attachment'
  | 'Awaiting-action'
  | 'Broken'
  | 'Caution'
  | 'Chevron-all-collapse'
  | 'Chevron-all-expand'
  | 'Chevron-collapsed'
  | 'Chevron-expanded'
  | 'Circle-fill'
  | ['Circle-fill', Color]
  | 'Circle-outline'
  | 'Cloud'
  | 'Colour'
  | 'Contact'
  | 'Copy-cross'
  | 'Copy-tick'
  | 'Copy'
  | 'Cross-large'
  | 'Cross-small'
  | 'Cross'
  | 'Date'
  | 'Deny'
  | 'Design'
  | 'Disable'
  | 'Download'
  | 'Edit-cross'
  | 'Edit-tick'
  | 'Edit'
  | 'Email-bulk'
  | 'Email-deny'
  | 'Email-send'
  | 'Email-tick'
  | 'Email'
  | ['Email', FileColor]
  | 'Enable'
  | 'Evaluation'
  | 'External-link'
  | 'Eye-view'
  | 'Eye-hide'
  | 'File-acrobat'
  | 'File-audio'
  | 'File-document'
  | 'File-excel'
  | 'File-html'
  | 'File-image'
  | 'File-powerpoint'
  | 'File-text'
  | ['File-text', FileColor]
  | 'File-video'
  | 'File-visio'
  | 'File-word'
  | 'Filter'
  | 'Folder-empty'
  | 'Folder-root'
  | 'Folder'
  | 'Format-bold-active'
  | 'Format-bold'
  | 'Format-italic-active'
  | 'Format-italic'
  | 'Format-list-active'
  | 'Format-List'
  | 'Format-underline-active'
  | 'Format-underline'
  | 'Heart-outline'
  | ['Heart-outline', HeartColor]
  | 'Heart-fill'
  | ['Heart-fill', HeartColor]
  | 'Help'
  | 'History'
  | 'Information'
  | ['Information', FileColor]
  | 'ImportFolderStructure'
  | 'Language'
  | 'Link'
  | 'Lock-closed'
  | 'Lock-open'
  | 'Lock-security-policy'
  | 'Logomark-ansarada'
  | 'Logomark-kira'
  | 'Menu-contextual'
  | 'Menu'
  | 'Move'
  | 'Notification'
  | 'Number'
  | 'Plus'
  | 'Print-cross'
  | 'Print-tick'
  | 'Print'
  | 'Priority-high'
  | 'Priority-low'
  | 'Priority-medium'
  | 'QnA-actions'
  | 'QnA-administrator'
  | 'QnA-answer'
  | 'QnA-comment'
  | 'QnA-forward'
  | 'QnA-manage'
  | 'QnA-question'
  | ['QnA-question', QnAColor]
  | 'QnA-subject-group'
  | 'QnA-subject'
  | 'QnA-tick'
  | 'QnA-viewer'
  | 'Redact-area'
  | 'Redact-page'
  | 'Redact-pattern'
  | 'Redact-text'
  | 'Redact'
  | 'Report'
  | ['Report', ReportColor]
  | 'Save-cross'
  | 'Save-tick'
  | 'Save'
  | 'Search'
  | 'Settings'
  | ['Settings', SettingColor]
  | 'Submissions'
  | 'Tick'
  | 'Triangle-down'
  | 'Triangle-right'
  | 'Triangle-up'
  | 'Upload'
  | 'User-administrator'
  | 'User-buyside-group'
  | ['User-buyside-group', ReportColor]
  | 'User-buyside'
  | 'User-sellside-group'
  | ['User-sellside-group', ReportColor]
  | 'User-sellside'
  | 'Watermark'
  | 'Workflow'
  | 'Zip-file';

export type IconPropType = {
  /** Sets size of the icon */
  size?: Size,
  /** Sets icon glyph */
  glyph: Glyph,
  /** Sets screen reader text */
  label?: string,
  className?: string,
};

const getGlyphName = (glyph: Glyph): string => (Array.isArray(glyph) ? glyph[0] : glyph);
const getGlyphColor = (glyph: Glyph): ?string => {
  if (Array.isArray(glyph)) {
    return glyph[1];
  }

  return '#777778';
};

const getIcon = (glyph: Glyph): React.Element<*> | void => {
  switch (getGlyphName(glyph)) {
    case 'Actions':
      return <Actions />;
    case 'Archive':
      return <Archive />;
    case 'Arrow-down':
      return <ArrowDown />;
    case 'Arrow-indent':
      return <ArrowIndent />;
    case 'Arrow-left':
      return <ArrowLeft />;
    case 'Arrow-restore':
      return <ArrowRestore />;
    case 'Arrow-right':
      return <ArrowRight />;
    case 'Arrow-sort-down':
      return <ArrowSortDown />;
    case 'Arrow-sort-up':
      return <ArrowSortUp />;
    case 'Arrow-undo':
      return <ArrowUndo />;
    case 'Arrow-up':
      return <ArrowUp />;
    case 'Attachment':
      return <Attachment />;
    case 'Awaiting-action':
      return <AwaitingAction />;
    case 'Broken':
      return <Broken />;
    case 'Caution':
      return <Caution />;
    case 'Chevron-all-collapse':
      return <ChevronAllCollapse />;
    case 'Chevron-all-expand':
      return <ChevronAllExpand />;
    case 'Chevron-collapsed':
      return <ChevronCollapsed />;
    case 'Chevron-expanded':
      return <ChevronExpanded />;
    case 'Circle-fill':
      return <CircleFill />;
    case 'Circle-outline':
      return <CircleOutline />;
    case 'Cloud':
      return <Cloud />;
    case 'Colour':
      return <Colour />;
    case 'Contact':
      return <Contact />;
    case 'Copy-cross':
      return <CopyCross />;
    case 'Copy-tick':
      return <CopyTick />;
    case 'Copy':
      return <Copy />;
    case 'Cross-large':
      return <CrossLarge />;
    case 'Cross-small':
      return <CrossSmall />;
    case 'Cross':
      return <Cross />;
    case 'Date':
      return <Date />;
    case 'Deny':
      return <Deny />;
    case 'Design':
      return <Design />;
    case 'Disable':
      return <Disable />;
    case 'Download':
      return <Download />;
    case 'Edit-cross':
      return <EditCross />;
    case 'Edit-tick':
      return <EditTick />;
    case 'Edit':
      return <Edit />;
    case 'Email-bulk':
      return <EmailBulk />;
    case 'Email-deny':
      return <EmailDeny />;
    case 'Email-send':
      return <EmailSend />;
    case 'Email-tick':
      return <EmailTick />;
    case 'Email':
      return <Email />;
    case 'Enable':
      return <Enable />;
    case 'Evaluation':
      return <Evaluation />;
    case 'External-link':
      return <ExternalLink />;
    case 'Eye-hide':
      return <EyeHide />;
    case 'Eye-view':
      return <EyeView />;
    case 'File-acrobat':
      return <FileAcrobat />;
    case 'File-audio':
      return <FileAudio />;
    case 'File-document':
      return <FileDocument />;
    case 'File-excel':
      return <FileExcel />;
    case 'File-html':
      return <FileHtml />;
    case 'File-image':
      return <FileImage />;
    case 'File-powerpoint':
      return <FilePowerpoint />;
    case 'File-text':
      return <FileText />;
    case 'File-video':
      return <FileVideo />;
    case 'File-visio':
      return <FileVisio />;
    case 'File-word':
      return <FileWord />;
    case 'Filter':
      return <Filter />;
    case 'Folder-empty':
      return <FolderEmpty />;
    case 'Folder-root':
      return <FolderRoot />;
    case 'Folder':
      return <Folder />;
    case 'Format-bold-active':
      return <FormatBoldActive />;
    case 'Format-bold':
      return <FormatBold />;
    case 'Format-italic-active':
      return <FormatItalicActive />;
    case 'Format-italic':
      return <FormatItalic />;
    case 'Format-list-active':
      return <FormatListActive />;
    case 'Format-List':
      return <FormatList />;
    case 'Format-underline-active':
      return <FormatUnderlineActive />;
    case 'Format-underline':
      return <FormatUnderline />;
    case 'Heart-outline':
      return <HeartOutline />;
    case 'Heart-fill':
      return <HeartFill />;
    case 'Help':
      return <Help />;
    case 'History':
      return <History />;
    case 'Information':
      return <Information />;
    case 'ImportFolderStructure':
      return <ImportFolderStructure />;
    case 'Language':
      return <Language />;
    case 'Link':
      return <LinkIcon />;
    case 'Lock-closed':
      return <LockClosed />;
    case 'Lock-open':
      return <LockOpen />;
    case 'Lock-security-policy':
      return <LockSecurityPolicy />;
    case 'Logomark-ansarada':
      return <LogomarkAnsarada />;
    case 'Logomark-kira':
      return <LogomarkKira />;
    case 'Menu-contextual':
      return <MenuContextual />;
    case 'Menu':
      return <Menu />;
    case 'Move':
      return <Move />;
    case 'Notification':
      return <Notification />;
    case 'Number':
      return <Number />;
    case 'Plus':
      return <Plus />;
    case 'Print-cross':
      return <PrintCross />;
    case 'Print-tick':
      return <PrintTick />;
    case 'Print':
      return <Print />;
    case 'Priority-high':
      return <PriorityHigh />;
    case 'Priority-low':
      return <PriorityLow />;
    case 'Priority-medium':
      return <PriorityMedium />;
    case 'QnA-actions':
      return <QnAActions />;
    case 'QnA-administrator':
      return <QnAAdministrator />;
    case 'QnA-answer':
      return <QnAAnswer />;
    case 'QnA-comment':
      return <QnAComment />;
    case 'QnA-forward':
      return <QnAForward />;
    case 'QnA-manage':
      return <QnAManage />;
    case 'QnA-question':
      return <QnAQuestion />;
    case 'QnA-subject-group':
      return <QnASubjectGroup />;
    case 'QnA-subject':
      return <QnASubject />;
    case 'QnA-tick':
      return <QnATick />;
    case 'QnA-viewer':
      return <QnAViewer />;
    case 'Redact-area':
      return <RedactArea />;
    case 'Redact-page':
      return <RedactPage />;
    case 'Redact-pattern':
      return <RedactPattern />;
    case 'Redact-text':
      return <RedactText />;
    case 'Redact':
      return <Redact />;
    case 'Report':
      return <Report />;
    case 'Save-cross':
      return <SaveCross />;
    case 'Save-tick':
      return <SaveTick />;
    case 'Save':
      return <Save />;
    case 'Search':
      return <Search />;
    case 'Settings':
      return <Settings />;
    case 'Submissions':
      return <Submissions />;
    case 'Tick':
      return <Tick />;
    case 'Triangle-down':
      return <TriangleDown />;
    case 'Triangle-right':
      return <TriangleRight />;
    case 'Triangle-up':
      return <TriangleUp />;
    case 'Upload':
      return <Upload />;
    case 'User-administrator':
      return <UserAdministrator />;
    case 'User-buyside-group':
      return <UserBuysideGroup />;
    case 'User-buyside':
      return <UserBuyside />;
    case 'User-sellside-group':
      return <UserSellsideGroup />;
    case 'User-sellside':
      return <UserSellside />;
    case 'Watermark':
      return <Watermark />;
    case 'Workflow':
      return <Workflow />;
    case 'Zip-file':
      return <ZipFile />;
    default:
      return undefined;
  }
};

/**
 * Icon Beta are an important element in our product. Their purpose is to visually convey functions and concepts in a small space, while aiding people in recognising these functions and concepts, while also speeding up comprehension and interaction.
 * @status Beta
 * @date  06/03/2019
 * @version  12.0.0
 * @tags  Button, Icon
 * @category Buttons
 */
const IconBeta = ({ size = 'Medium', glyph, label, className }: IconPropType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role={label ? undefined : 'presentation'}
    aria-label={label}
    viewBox="0 0 20 20"
    fill={getGlyphColor(glyph)}
    focusable="false"
    className={classnames(
      styles.icon,
      { [styles.isSmall]: size === 'Small' },
      { [styles.isMedium]: size === 'Medium' },
      { [styles.isLarge]: size === 'Large' },
      { [styles.isXLarge]: size === 'XLarge' },
      { [styles.isXXLarge]: size === 'XXLarge' },
      className,
    )}
  >
    {getIcon(glyph)}
  </svg>
);

export { IconBeta };
