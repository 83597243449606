// @flow strict
import * as React from 'react';
import { Changelog as ChangelogPage } from '../../components';
import styles from './Changelog.scss';

export const Changelog = () => (
  <div className={styles.container}>
    <ChangelogPage />
  </div>
);
