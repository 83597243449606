// @flow strict
import * as React from 'react';
import classnames from 'classnames/bind';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

import type { testIdPropType, ansaradaCCDPropType } from '../../../ace-internal/types/general';

export type BaseGridCellType = {|
  ...ansaradaCCDPropType,
  ...testIdPropType,
  children: React.ChildrenArray<React$Element<*> | void>,
  autoFit?: boolean,
|};

/**
 * @ignore
 */

const BaseGridCell = ({
  children,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  autoFit,
}: BaseGridCellType) => (
  <div
    data-test-id={testId}
    data-ansarada-ccd={ansaradaCCD}
    className={classnames({
      [styles.isAutoFit]: autoFit,
      [styles.isDefautFit]: !autoFit,
    })}
  >
    {children}
  </div>
);

export { BaseGridCell };
