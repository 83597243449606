// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import { Icon } from '../Icon';

const Variants = {
  Normal: 'Normal',
  Primary: 'Primary',
  Secondary: 'Secondary',
};

const Layouts = {
  Icon: 'Icon',
  IconSpace: 'IconSpace',
};

type PanelPropType = {
  variant?: $Keys<typeof Variants>,
  layout?: $Keys<typeof Layouts>,
  layoutIcon?: React.Element<typeof Icon>,
  children?: React.Node,
  className?: string,
};

// eslint-disable-next-line consistent-return
const getVariantCSSClass = (variant?: $Keys<typeof Variants>, baseCSSClass: string) => {
  if (typeof variant !== 'undefined' && variant !== Variants.Normal) {
    return `${baseCSSClass}-${variant.toLowerCase()}`;
  }
};

// eslint-disable-next-line consistent-return
const getLayoutCSS = (layout?: $Keys<typeof Layouts>, baseCSSClass: string) => {
  if (typeof layout !== 'undefined' && layout === Layouts.IconSpace) {
    return `${baseCSSClass}-icon-space`;
  }

  if (typeof layout !== 'undefined') {
    return `${baseCSSClass}-${layout.toLowerCase()}`;
  }
};

/**
 * Panel is used to customise how a piece of content is shown.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Card
 * @category Layout
 */
const Panel = (props: PanelPropType) => {
  const { variant = Variants.Normal, layout, layoutIcon, children, className, ...rest } = props;

  const baseCSSClass = 'ace-panel';

  const classes = classnames(
    baseCSSClass,
    getVariantCSSClass(variant, baseCSSClass),
    getLayoutCSS(layout, baseCSSClass),
    className,
  );

  return (
    <div {...rest} className={classes}>
      {layoutIcon}
      {children}
    </div>
  );
};

export { Panel, Layouts, Variants };
