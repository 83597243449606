// @flow strict
import React from 'react';

import { AssetPickerFolderItem } from '../components';

const AssetPickerFolderItemTest = () => (
  <div>
    <div>
      <AssetPickerFolderItem
        alt="Tasty folder"
        name="Tasty"
        openControlAlt="open Tasty folder"
        id="1"
      />
      <AssetPickerFolderItem
        alt="Lorem ipsum dolor sit amet folder"
        name="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ut metus nec risus tristique varius eget at nisl. Duis a laoreet libero. Mauris eget pulvinar sem"
        openControlAlt="open Lorem ipsum dolor sit amet folder"
        id="1"
      />
      <AssetPickerFolderItem
        alt="Tasty folder"
        name="Tasty active folder"
        openControlAlt="open Tasty folder"
        id="4"
        onSelect={() => {}}
      />
      <AssetPickerFolderItem
        alt="Tasty folder"
        name="Tasty"
        openControlAlt="open Tasty folder"
        id="2"
        selected
      />

      <AssetPickerFolderItem
        alt="Tasty folder"
        name="Tasty Active"
        openControlAlt="open Tasty folder"
        onSelect={() => {}}
        id="10"
        selected
      />
    </div>
  </div>
);

export default AssetPickerFolderItemTest;
