// @flow strict

import React from 'react';

import {
  FormGrid,
  FormGridRow,
  formGridRow,
  FormGridCell,
  TextInput,
  RadioGroup,
  Radio,
  Card,
  Select,
  Option,
  TextArea,
  Checkbox,
  CheckboxGroup,
  Autocomplete,
  Toggle,
  Datepicker,
  Editor,
  Link,
  Button,
  FormattedContentBlock,
  FormGridContentCell,
} from '../components';

const FormGridTest = () => (
  <div>
    <div style={{ maxWidth: '512px', margin: '0 auto' }}>
      <Card>
        <FormGrid>
          <FormGridRow columnSize={formGridRow.columnSizes[50]}>
            <FormGridCell labelText="First Name">
              <TextInput id="firstName" defaultValue="" className="person-name" />
            </FormGridCell>
            <FormGridCell labelText="Last Name">
              <TextInput defaultValue="" className="person-name" />
            </FormGridCell>
          </FormGridRow>
        </FormGrid>
      </Card>
      <Card>
        <FormGrid>
          <FormGridRow columnSize={formGridRow.columnSizes[100]}>
            <FormGridCell
              labelText="Address"
              hint={
                <span>
                  Full address with street{' '}
                  <Link href="http://ace-react.ansarada.com">Check out valid url pattens here</Link>
                </span>
              }
              errors={
                <span>
                  Enter a valid address,{' '}
                  <Link href="http://ace-react.ansarada.com">Check out valid url pattens here</Link>
                  .
                </span>
              }
            >
              <TextInput defaultValue="" status="Bad" />
            </FormGridCell>
          </FormGridRow>
          <FormGridRow columnSize={formGridRow.columnSizes[50]}>
            <FormGridCell labelText="City">
              <TextInput defaultValue="" className="person-name" />
            </FormGridCell>
            <FormGridCell labelText="Postcode">
              <TextInput defaultValue="" className="person-name" />
            </FormGridCell>
          </FormGridRow>
        </FormGrid>
      </Card>
      <div className="buttons-block">
        <Button>Cancel</Button>
        <Button variant="Primary">Save</Button>
      </div>
      <FormattedContentBlock>
        <h2>Extra form grid tests</h2>
      </FormattedContentBlock>
      <FormGrid>
        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="Long text input">
            <TextInput defaultValue="" />
          </FormGridCell>
          <FormGridContentCell>
            <p style={{ maxWidth: '200px' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non malesuada nunc, nec
              vestibulum ante. Donec et tortor non risus laoreet tincidunt in a ante. Donec at
              fermentum ante. Pellentesque non metus lacus. Phasellus ac massa elit. Nulla imperdiet
              malesuada ipsum id eleifend. Donec dolor ipsum, aliquam a dui sit amet, ultricies
              tincidunt justo. Pellentesque tincidunt, ex at feugiat vestibulum, arcu tortor
              pulvinar metus, quis convallis nisi nisl id diam. Vivamus a ipsum ut enim pretium
              tincidunt vel non orci. Maecenas vehicula, libero at luctus sagittis, dolor nulla
              rutrum lectus, sed aliquet tortor odio eget lectus. Praesent eu ultrices felis. Aenean
              nisl massa, porta et gravida at, sodales sed lacus. Phasellus pellentesque ante elit,
              nec congue leo varius et. Donec hendrerit, mi et sagittis tincidunt, odio felis
              egestas tortor, facilisis pharetra dui neque a massa. Morbi cursus at augue nec
              fermentum.
            </p>
          </FormGridContentCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Long text input with hint" hint="This is really long">
            <TextInput defaultValue="" />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Long text area">
            <TextArea defaultValue="" />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="small area">
            <TextArea defaultValue="" />
          </FormGridCell>
          <FormGridCell labelText="small area">
            <TextArea defaultValue="" />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="small area">
            <TextArea defaultValue="" />
          </FormGridCell>
          <FormGridCell labelText="small area">
            <TextInput defaultValue="" />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Select">
            <Select value="" onChangeValue={() => {}}>
              <Option value="" />
              <Option text="Adidas" value="10" />
              <Option text="KKR" value="11" />
              <Option text="Nike" value="12" />
              <Option text="Reebok" value="13" />
              <Option text="TPG Capital" value="14" />
            </Select>
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="Select">
            <Select value="" onChangeValue={() => {}}>
              <Option value="" />
              <Option text="Adidas" value="10" />
              <Option text="KKR" value="11" />
              <Option text="Nike" value="12" />
              <Option text="Reebok" value="13" />
              <Option text="TPG Capital" value="14" />
            </Select>
          </FormGridCell>
          <FormGridCell labelText="Select">
            <Select value="" onChangeValue={() => {}}>
              <Option value="" />
              <Option text="Adidas" value="10" />
              <Option text="KKR" value="11" />
              <Option text="Nike" value="12" />
              <Option text="Reebok" value="13" />
              <Option text="TPG Capital" value="14" />
            </Select>
          </FormGridCell>
        </FormGridRow>

        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="Radio group">
            <RadioGroup name="radio-option-1" defaultValue="">
              <Radio id="radio-nolabel-yes-1" value="yes" label="Yes" defaultChecked={false} />
              <Radio id="radio-nolabel-no-1" value="no" label="no" defaultChecked={false} />
            </RadioGroup>
          </FormGridCell>
          <FormGridCell labelText="Radio group inline">
            <RadioGroup name="radio-option-2" layout="Inline" defaultValue="no">
              <Radio id="radio-nolabel-yes-2" value="yes" label="Yes" defaultChecked={false} />
              <Radio id="radio-nolabel-no-2" value="no" label="no" defaultChecked={false} />
            </RadioGroup>
          </FormGridCell>
        </FormGridRow>

        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="Radio single">
            <Radio id="radio-single2-yes" value="yes" label="Yes" defaultChecked={false} />
            <Radio id="radio-single2-no" value="no" label="no" defaultChecked={false} />
          </FormGridCell>
          <FormGridCell labelText="Radio single">
            <Radio id="radio-single-yes2" value="yes" label="Yes" defaultChecked={false} />
            <Radio id="radio-single-no" value="no" label="no" defaultChecked={false} />
          </FormGridCell>
        </FormGridRow>

        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="CheckBox group">
            <CheckboxGroup defaultValue={[]}>
              <Checkbox id="check-nolabel-yes" value="yes" label="Yes" defaultChecked={false} />
              <Checkbox id="check-nolabel-no" value="no" label="no" defaultChecked={false} />
            </CheckboxGroup>
          </FormGridCell>
          <FormGridCell labelText="Checkbox group inline">
            <CheckboxGroup layout="Inline" defaultValue={[]}>
              <Checkbox id="check2-0yes" value="yes" label="Yes" defaultChecked={false} />
              <Checkbox id="check2-no" value="no" label="no" defaultChecked={false} />
            </CheckboxGroup>
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="checkbox singles">
            <Checkbox id="check-nolabel-yes2" value="yes" label="Yes" defaultChecked={false} />
            <Checkbox id="check-nolabel-no2" value="no" label="no" defaultChecked={false} />
          </FormGridCell>
          <FormGridCell labelText="Checkbox singles">
            <Checkbox id="check3-0yes" value="yes" label="Yes" defaultChecked={false} />
            <Checkbox id="check3-no" value="no" label="no" defaultChecked={false} />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Terms and Conditions" hideLabel>
            <Checkbox id="tc" value="yes" label="I agree to terms" defaultChecked={false} />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Autocomplete">
            <Autocomplete
              placeholder="Enter your location"
              items={[]}
              open={false}
              selected={[]}
              onToggle={() => {}}
              onChangeValue={() => {}}
              onSelected={() => {}}
              onChangeItems={() => {}}
            />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Toggle">
            <Toggle id="toggle" checked={false} onChecked={() => {}} />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Date picker">
            <Datepicker
              id="datepicker"
              format="D/M/Y"
              className="datepicker-test-className"
              defaultSelectedDate={new Date()}
              defaultCalendarVisible={false}
            />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[100]}>
          <FormGridCell labelText="Editor">
            <Editor defaultHtml="" maxLength={100} />
          </FormGridCell>
        </FormGridRow>
        <FormGridRow columnSize={formGridRow.columnSizes[50]}>
          <FormGridCell labelText="Editor">
            <Editor defaultHtml="" maxLength={100} />
          </FormGridCell>
          <FormGridCell labelText="Date picker" errors={['no headers', 'no text', 'no RTF']}>
            <Datepicker
              format="D/M/Y"
              className="datepicker-test-className"
              defaultSelectedDate={new Date()}
              defaultCalendarVisible={false}
            />
          </FormGridCell>
        </FormGridRow>
      </FormGrid>
    </div>
  </div>
);

export default FormGridTest;
