// @flow strict
/* eslint-disable no-console */

import React from 'react';
import { Breadcrumb, FormattedContentBlock } from '../components';

const BreadcrumbTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>Small</h2>
    </FormattedContentBlock>

    <Breadcrumb
      size="Small"
      items={[
        { href: '#', text: 'Finance' },
        { href: '#', text: 'Corporate' },
        { href: '#', text: 'Financial Documents' },
      ]}
    />
    <FormattedContentBlock>
      <h2>Small with a lot of items</h2>
    </FormattedContentBlock>

    <Breadcrumb
      size="Small"
      items={[
        { href: '#', text: 'Finance' },
        { href: '#', text: 'Corporate' },
        { href: '#', text: 'Financial Documents' },
        { href: '#', text: 'Finance' },
        { href: '#', text: 'Corporate' },
        { href: '#', text: 'Financial Documents' },
        { href: '#', text: 'Finance' },
        { href: '#', text: 'Corporate' },
        { href: '#', text: 'Financial Documents' },
        { href: '#', text: 'Finance' },
        { href: '#', text: 'Corporate' },
        { href: '#', text: 'Financial Documents' },
        { href: '#', text: 'Finance' },
        { href: '#', text: 'Corporate' },
        { href: '#', text: 'Financial Documents' },
      ]}
    />
    <FormattedContentBlock>
      <h2>Normal usecase</h2>
    </FormattedContentBlock>

    <Breadcrumb
      items={[
        { href: '#', text: 'Root' },
        { onClick: e => console.log(e), text: '1 Corporate' },
        { href: '#', text: '2 Finance' },
        { href: '#', text: '3 Corporate' },
        { href: '#', text: '4 Finance' },
        { href: '#', text: '5 Corporate' },
        { href: '#', text: '6 Finance' },
        { href: '#', text: '7 Corporate' },
        { href: '#', text: 'Financial Documents 8' },
      ]}
    />

    <FormattedContentBlock>
      <h2>Max</h2>
    </FormattedContentBlock>

    <Breadcrumb
      items={[
        { href: '#', text: 'Root' },
        {
          href: '#',
          text:
            '1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
        {
          href: '#',
          text:
            '2 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
        {
          href: '#',
          text:
            '3 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
        {
          href: '#',
          text:
            '4 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
        {
          href: '#',
          text:
            '5 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
        {
          href: '#',
          text:
            '6 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
        {
          href: '#',
          text:
            '7 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
        {
          href: '#',
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi in metus maximus, tincidunt orci quis, egestas neque. Etiam in consequat tellus, nec consequat tellus. Praesent malesuada faucibus lacus et euismod. Curabitur blandit velit sed lacus volutpat.',
        },
      ]}
    />
    <FormattedContentBlock>
      <h2>Single</h2>
    </FormattedContentBlock>

    <Breadcrumb
      items={[
        {
          href: '#',
          text: 'Financial Documents 8',
        },
      ]}
    />
  </div>
);

export default BreadcrumbTest;
