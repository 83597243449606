// @flow strict
import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';
import Footer from './footer';

type Layout = 'Fluid' | 'Fixed' | 'Hybrid' | 'Popup';
type BackgroundColor = 'Dark' | 'Light';

const layouts: Array<Layout> = ['Fluid', 'Fixed', 'Hybrid', 'Popup'];

type PagePropType = {|
  header?: React.Node,
  children?: React.Node,
  footer?: React.Node,
  sideBar?: React.Node,
  className?: string,
  backgroundColor?: BackgroundColor,
  layout?: Layout,
|};

/**
 * Page is the surface that defines the interactive space and contains the global elements that are: the layout, the header, the footer, and the content. They may also include a Sidebar Menu or Vertical Navigation on the left of the main content area.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Card
 * @category Layout
 */
class Page extends React.Component<PagePropType> {
  // eslint-disable-next-line class-methods-use-this
  setBodyClass(pageType: string) {
    const classPrefix = 'ace-page-';
    const { body } = window.document;

    if (body.classList.contains(`${classPrefix}${pageType.toLowerCase()}`)) {
      return;
    }

    layouts.forEach(layout => {
      body.classList.remove(`${classPrefix}${layout.toLowerCase()}`);
    });

    body.classList.add(`${classPrefix}${pageType.toLowerCase()}`);
  }

  componentWillUpdate(nextProps: Object) {
    if (nextProps.layout) {
      this.setBodyClass(nextProps.layout.toLowerCase());
    }
  }

  componentWillMount() {
    const { body } = window.document;
    const { className, layout = 'Fluid' } = this.props;

    const classes = classnames('ace-page', className);

    body.classList.add(...classes.split(' '));
    this.setBodyClass(layout);
  }

  render() {
    const { header, children, footer, sideBar, backgroundColor = 'Light' } = this.props;

    const pageContentContent = classnames('ace-item', 'ace-page-content-content', {
      [styles.isDark]: backgroundColor === 'Dark',
    });

    return (
      <div id="page">
        {header}
        <main id="content" role="main">
          <div className="ace-group ace-page-content">
            {sideBar}
            <div className={pageContentContent}>{children}</div>
          </div>
        </main>
        {footer || <Footer />}
      </div>
    );
  }
}

export { Page };
