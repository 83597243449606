// @flow strict
import * as React from 'react';
import { Button, Icon, icon } from '@ansarada/ace-react';

type Props = {
  children: string,
  target: string,
};

const CopyClipboard = ({ children, target }: Props) => {
  const [text, setText] = React.useState<string>(children);
  const [currentIcon, setCurrentIcon] = React.useState<$Values<typeof icon.Glyphs>>(
    icon.Glyphs.ActionCopy,
  );

  const handleClick = (targetClick: string) => {
    navigator.clipboard.writeText(targetClick).then(() => {
      setText('Copied');
      setCurrentIcon(icon.Glyphs.StatusYes);

      setTimeout(() => {
        setText(children);
        setCurrentIcon(icon.Glyphs.ActionCopy);
      }, 2000);
    });
  };

  return (
    <Button icon={<Icon glyph={currentIcon} />} variant="Link" onClick={() => handleClick(target)}>
      {text}
    </Button>
  );
};

export { CopyClipboard };
