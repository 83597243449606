// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Design = (): React.Element<*> => (
  <>
    <path d="M16.506 19h-13c-1.379 0-2.5-1.122-2.5-2.5v-13c0-1.378 1.121-2.5 2.5-2.5h13c1.378 0 2.5 1.122 2.5 2.5v13c0 1.378-1.122 2.5-2.5 2.5zM3.506 2c-0.827 0.003-1.497 0.673-1.5 1.5v13c0.003 0.827 0.673 1.497 1.5 1.5h13c0.827-0.003 1.497-0.673 1.5-1.5v-13c-0.003-0.827-0.673-1.497-1.5-1.5h-0z"></path>
    <path d="M16.254 5.744c0 0 0 0 0-0 0-1.105-0.895-2-2-2-0.552 0-1.052 0.224-1.414 0.586l-6.729 6.729c-0.006 0.006-0.013 0.013-0.019 0.020l-0.003 0.003c-0.005 0.006-0.010 0.012-0.016 0.018l-0.004 0.005q-0.007 0.009-0.014 0.019l-0.003 0.004c-0.005 0.007-0.010 0.015-0.014 0.022l-0.001 0.002c-0.010 0.016-0.020 0.034-0.028 0.053l-0.001 0.003-1.766 3.886c-0.028 0.061-0.045 0.132-0.045 0.207 0 0.276 0.224 0.5 0.5 0.5 0.075 0 0.146-0.017 0.21-0.046l-0.003 0.001 3.892-1.769c0.007-0.003 0.014-0.007 0.021-0.010l0.004-0.002c0.008-0.004 0.016-0.009 0.024-0.014l0.008-0.005c0.005-0.003 0.010-0.007 0.016-0.010s0.006-0.004 0.009-0.006 0.010-0.007 0.014-0.010l0.008-0.006c0.005-0.004 0.010-0.008 0.016-0.013l0.006-0.005c0.006-0.006 0.013-0.012 0.019-0.018l0.001-0.001 6.726-6.727c0.362-0.36 0.586-0.858 0.586-1.409 0-0.002 0-0.004 0-0.006v0zM8.586 12.826l-1.415-1.414 5.315-5.314 1.414 1.414zM6.622 12.276l1.101 1.101-2.018 0.917zM14.961 6.451l-0.353 0.354-1.414-1.414 0.353-0.354c0.181-0.181 0.431-0.293 0.707-0.293 0.552 0 1 0.448 1 1 0 0.276-0.112 0.526-0.293 0.707v0z"></path>
  </>
);
