// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { TableHeaderLegacy, TableRowLegacy } from '../../legacy-components/Table';
import { TreeItem } from './TreeItem';

type TreeHeaderGroupPropType = {
  className?: string,
  children: React.ChildrenArray<React.Element<typeof TreeItem> | void | false>,
};

/** @ignore */
export const TreeHeaderGroup = (props: TreeHeaderGroupPropType) => {
  const { className, children, ...rest } = props;

  const classes = classnames('ace-tree-group', 'ace-tree-level-0', className);

  // We allow user to use TreeItem inside TreeHeader and TreeBody.
  // By default it outputs td, but here we rewrite it to output th
  const headerItem = (item, index) => {
    if (!item) {
      return item;
    }

    const itemClasses = classnames('ace-tree-item', item.props.className);

    return (
      // $FlowFixMe 0.112.0 - inexact rest of object pattern
      <TableHeaderLegacy key={index} className={itemClasses} {...rest}>
        {item.props.children}
      </TableHeaderLegacy>
    );
  };

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <TableRowLegacy className={classes} {...rest}>
      {React.Children.map(children, headerItem)}
    </TableRowLegacy>
  );
};
