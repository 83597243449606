// @flow strict
/* eslint no-bitwise: off */

/*
  IE11 does not support Number.isInteger so we need to manually support it.
*/
export const isInteger = (value: any) => (value ^ 0) === +value;

/*
  Is item active.
*/
export const isActive = (active?: string | number, index?: number, name?: string) => {
  if (active !== undefined && isInteger(active)) {
    return index === active;
  }

  if (active !== undefined && !isInteger(active)) {
    return name === active;
  }

  return false;
};
