// @flow strict
import * as React from 'react';

import type { ChildrenStatus, NodeTypes } from '../Tree2.types';

import { Icon } from '../../Icon';
import LoadingIndicator from '../../LoadingIndicator';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

type Props = {|
  status?: ChildrenStatus,
  expanded: boolean,
  type: NodeTypes,
|};

export const StatusIcon = ({
  status = 'Loaded',
  expanded = false,
  type,
}: Props): React.Element<'div'> => (
  <div className={styles.statusIconStyles}>
    {type === 'Branch' && status !== 'Loading' && status !== 'None' && (
      <Icon glyph={status === 'Loaded' && expanded ? 'ControlExpanded' : 'ControlCollapsed'} />
    )}
    {type === 'Branch' && status === 'None' && <Icon glyph="ObjectsEmptyFolder" size="Large" />}
    {type === 'Branch' && status === 'Loading' && <LoadingIndicator size={16} dark />}
  </div>
);
