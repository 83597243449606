// @flow strict
/* eslint react/no-unescaped-entities: off */

import * as React from 'react';

import { Highlight, FormattedContentBlock } from '../components';

const HighlightTest = () => (
  <div>
    <FormattedContentBlock>
      <h2>Highlight</h2>
      <p>Search: "quick" (tests default Highlight call)</p>
      <p>
        The <Highlight>quick</Highlight> brown fox.
      </p>
      <p>Search: "brown" (tests explictly-set Normal variant)</p>
      <p>
        The quick <Highlight variant="Normal">brown</Highlight> fox.
      </p>
      <p>Search: "eet" (tests explicitly-set Highlight variant)</p>
      <p>
        The fl<Highlight variant="Substring">eet</Highlight> f
        <Highlight variant="Substring">eet</Highlight> on the str
        <Highlight variant="Substring">eet</Highlight>.
      </p>
    </FormattedContentBlock>
  </div>
);

export default HighlightTest;
