// @flow strict

export type Variants = {|
  variant: 'Normal' | 'WithControls',
|};

export type SortDirections = 'Ascending' | 'Descending';

export type CellVariants =
  | 'Icon'
  | 'Number'
  | 'Date'
  | 'Form'
  | 'Control'
  | 'Checkbox'
  | 'Email'
  | 'Generic'
  | 'User'
  | 'UserGroup';

export const getCellVariantCSSClass = (variant: CellVariants) => {
  if (variant === 'UserGroup') {
    return 'user-group';
  }

  return variant.toLowerCase();
};
