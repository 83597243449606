// @flow strict

import React, { useState, useEffect } from 'react';

import { KeyEvent } from '../../../ace-internal/types/keys';
import { TextInput } from '../../TextInput';
import { timeObjToString } from './TimeFunctions';
import { useDisplay } from './TimepickerContext';
import { HOUR, MINUTE, MERIDIEM, AM, PM, SELECTION_PARTS } from './constants';
import styles from './TimepickerInput.scss';

const changeCurrentSelection = (currentSelection: string, step: -1 | 1) => {
  switch (currentSelection) {
    case MERIDIEM:
      return step === 1 ? MERIDIEM : MINUTE;
    case MINUTE:
      return step === 1 ? MERIDIEM : HOUR;
    default:
      return step === 1 ? MINUTE : HOUR;
  }
};

const leftPad = (x: number) => (x.toString().length < 2 ? `0${x}` : x);

export type TimepickerInputPropType = {
  id?: string,
  inputRef: (?HTMLInputElement) => void,
  ansaradaCCD?: true,
  testId: string,
  size?: 'Small' | 'Medium' | 'Large',
  placeholder: string,
};

// Modulus function that doesn't return negative numbers
function mod(n, m) {
  return ((n % m) + m) % m;
}

const cycleTime = (value: number, step: number, maxSize: number, allowZero?: boolean): number => {
  const newValue = mod(value + step, maxSize);
  if (newValue === 0 && !allowZero) {
    // add an extra step to skip 0
    return mod(newValue + step, maxSize);
  }
  return newValue;
};

const inputTimeValue = (
  input: string,
  currentInput?: number,
  inputLeft: number,
  maxInputLeft: number,
  maxInput: number,
  minInput: number,
) => {
  let newInput;
  let newInputLeft;
  let nextSelection = false;

  if (inputLeft > 0) {
    newInput = parseInt(`${currentInput || 0}${input}`, 10);
    newInputLeft = inputLeft - 1;
  } else {
    newInput = parseInt(input, 10);
    newInputLeft = maxInputLeft;
  }

  if (newInput > maxInput) {
    newInput = maxInput;
  } else if (newInput < minInput && newInputLeft === 0) {
    newInput = minInput;
  }

  if (newInputLeft === 0) {
    nextSelection = true;
  }
  return { newInput, newInputLeft, nextSelection };
};

// $FlowFixMe
const reducer = (state, action) => ({ ...state, ...action.payload });

const TimepickerInput = ({
  id,
  inputRef,
  placeholder,
  size,
  ansaradaCCD,
  testId,
}: TimepickerInputPropType) => {
  // avoid useState 2 times
  const [selectionState, setSelectionState] = useState({
    selection: HOUR,
    inputLeft: 0,
  });
  const [componentNodeInput, setComponentNodeInput] = React.useState();

  const state = useDisplay();
  const { minTimeObject, maxTimeObject, availableMeridiems, isValid, selectedTime } = state;
  const [display, updateDisplay] = React.useReducer(reducer, {
    inputHour: 0,
    inputMinute: 0,
    inputMeridiem: AM,
  });

  React.useEffect(() => {
    if (selectedTime) {
      const time = selectedTime && selectedTime.match(/(\d\d):(\d\d)(\w\w)/);
      updateDisplay({
        payload: {
          inputHour: parseInt(time && time[1], 10) || 0,
          inputMinute: parseInt(time && time[2], 10) || 0,
          inputMeridiem: (time && time[3]) || AM,
        },
      });
    }
  }, [selectedTime]);

  const setSelection = () => {
    window.requestAnimationFrame(() => {
      if (componentNodeInput) {
        componentNodeInput.setSelectionRange(...SELECTION_PARTS[selectionState.selection]);
      }
    });
  };

  useEffect(setSelection);

  const changeSelection = () => {
    const newSelection = componentNodeInput ? componentNodeInput.selectionStart : 0;
    let selectionSet = false;

    Object.entries(SELECTION_PARTS).forEach(([part, slice]) => {
      // $FlowFixMe
      if (newSelection > slice[0] && newSelection < slice[1]) {
        setSelectionState({
          selection: part,
          inputLeft: 0,
        });
        selectionSet = true;
      }
    });
    if (!selectionSet) {
      setSelection();
    }
  };

  const update = ({ inputHour, inputMinute, inputMeridiem }) => {
    updateDisplay({ payload: { inputHour, inputMinute, inputMeridiem } });
    if (inputHour.toString() && inputMinute.toString() && inputMeridiem) {
      state.onSelectTime(`${leftPad(inputHour)}:${leftPad(inputMinute)}${inputMeridiem}`);
    }
  };

  const stepTime = (step: number) => {
    const newTime = {
      inputHour: display.inputHour,
      inputMinute: display.inputMinute,
      inputMeridiem: display.inputMeridiem,
      minTimeObject,
      maxTimeObject,
    };

    switch (selectionState.selection) {
      case MERIDIEM: {
        const newMeridiem = display.inputMeridiem !== AM ? AM : PM;
        newTime.inputMeridiem = newMeridiem;
        break;
      }
      case MINUTE: {
        const newMinute = display.inputMinute !== undefined ? display.inputMinute : 0;
        newTime.inputMinute = cycleTime(newMinute, step, 60, true);
        break;
      }
      default: {
        const newHour = display.inputHour !== undefined ? display.inputHour : 0;
        newTime.inputHour = cycleTime(newHour, step, 12, true);
        break;
      }
    }

    return newTime;
  };

  const onKeyDown = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
    if (KeyEvent.isArrowLeft(e)) {
      e.preventDefault();
      setSelectionState({
        selection: changeCurrentSelection(selectionState.selection, -1),
        inputLeft: 0,
      });
    } else if (KeyEvent.isArrowRight(e) || e.key === '/') {
      e.preventDefault();
      setSelectionState({
        selection: changeCurrentSelection(selectionState.selection, 1),
        inputLeft: 0,
      });
    } else if (KeyEvent.isArrowUp(e)) {
      e.preventDefault();
      update(stepTime(1));
    } else if (KeyEvent.isArrowDown(e)) {
      e.preventDefault();
      update(stepTime(-1));
    } else if (KeyEvent.isNumber(e)) {
      e.preventDefault();
      let newInput;
      let newInputLeft;
      let nextSelection;
      const newTime = {
        inputHour: display.inputHour,
        inputMinute: display.inputMinute,
        inputMeridiem: display.inputMeridiem,
        minTimeObject,
        maxTimeObject,
        availableMeridiems,
      };

      switch (selectionState.selection) {
        case MINUTE:
          ({ newInput, newInputLeft, nextSelection } = inputTimeValue(
            e.key,
            display.inputMinute,
            selectionState.inputLeft || 0,
            1,
            59,
            1,
          ));
          newTime.inputMinute = newInput;
          break;
        case HOUR:
          ({ newInput, newInputLeft, nextSelection } = inputTimeValue(
            e.key,
            display.inputHour,
            selectionState.inputLeft || 0,
            1,
            12,
            1,
          ));
          newTime.inputHour = newInput;
          break;

        default:
          break;
      }

      nextSelection = nextSelection
        ? changeCurrentSelection(selectionState.selection, 1)
        : selectionState.selection;
      setSelectionState({
        selection: nextSelection,
        inputLeft: newInputLeft,
      });

      update(newTime);
    } else if (KeyEvent.isEscape(e)) {
      // close timepicker & blur input
      state.onOpen(false);
      e.currentTarget.blur();
    } else if (KeyEvent.isChar(e) || KeyEvent.isBackspace(e) || KeyEvent.isDelete(e)) {
      // prevents the selection from changing when users insert character keys
      e.preventDefault();
    }
  };

  return (
    <TextInput
      id={id}
      className={styles.timepickerInput}
      size={size}
      placeholder={placeholder}
      value={timeObjToString({
        hour: display.inputHour,
        minute: display.inputMinute,
        meridiem: display.inputMeridiem,
      })}
      inputRef={el => {
        setComponentNodeInput(el);
        inputRef(el);
      }}
      onFocus={() => state.onOpen(true)}
      onBlur={() => state.onOpen(false)}
      onClick={changeSelection}
      onChangeValue={() => {}}
      onPaste={() => {}}
      onKeyDown={onKeyDown}
      status={isValid ? 'Good' : 'Bad'}
      data-ansarada-ccd={ansaradaCCD || undefined}
      data-test-id={testId}
    />
  );
};

export { TimepickerInput };
