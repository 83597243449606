// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const UserAdministrator = (): React.Element<*> => (
  <>
    <path d="M18.969 10.592c0.034-0.183 0.053-0.394 0.053-0.61s-0.019-0.427-0.056-0.631l0.003 0.021-0.861-0.083c-0.067-0.245-0.158-0.46-0.274-0.658l0.007 0.012 0.551-0.667c-0.239-0.338-0.525-0.624-0.852-0.855l-0.011-0.007-0.668 0.551c-0.186-0.11-0.4-0.201-0.627-0.263l-0.019-0.004-0.083-0.861c-0.183-0.034-0.394-0.053-0.61-0.053s-0.427 0.019-0.631 0.056l0.022-0.003-0.083 0.861c-0.245 0.067-0.46 0.158-0.658 0.275l0.012-0.007-0.668-0.551c-0.338 0.239-0.624 0.525-0.855 0.852l-0.007 0.011 0.55 0.667c-0.11 0.186-0.201 0.4-0.263 0.627l-0.004 0.019-0.861 0.083c-0.034 0.183-0.053 0.394-0.053 0.61s0.019 0.427 0.056 0.631l-0.003-0.021 0.861 0.083c0.067 0.245 0.158 0.46 0.274 0.658l-0.007-0.012-0.55 0.668c0.239 0.338 0.525 0.624 0.852 0.855l0.011 0.007 0.668-0.551c0.186 0.11 0.4 0.201 0.627 0.263l0.019 0.004 0.083 0.861c0.183 0.034 0.394 0.053 0.61 0.053s0.427-0.019 0.631-0.056l-0.022 0.003 0.083-0.861c0.245-0.067 0.46-0.158 0.658-0.275l-0.012 0.007 0.668 0.551c0.338-0.239 0.624-0.525 0.856-0.852l0.007-0.011-0.55-0.668c0.11-0.186 0.201-0.4 0.263-0.627l0.004-0.019zM15.522 11.436c-0.803-0-1.454-0.651-1.454-1.454s0.651-1.454 1.454-1.454 1.454 0.651 1.454 1.454v0c0 0 0 0 0 0 0 0.803-0.651 1.454-1.454 1.454-0 0-0 0-0 0h0z"></path>
    <path d="M11.26 11.75c-0.534-0.535-1.169-0.97-1.873-1.274l-0.039-0.015q-0.072-0.030-0.145-0.059c0.369-0.212 0.687-0.457 0.969-0.739l0-0c0.814-0.814 1.318-1.939 1.318-3.182 0-2.485-2.015-4.5-4.5-4.5s-4.5 2.015-4.5 4.5c0 1.243 0.504 2.368 1.318 3.182v0c0.285 0.285 0.608 0.533 0.96 0.734l0.022 0.012c-0.042 0.017-0.084 0.034-0.126 0.052-2.177 0.936-3.674 3.062-3.674 5.537 0 0.002 0 0.003 0 0.005v-0 1.495c0 0.276 0.224 0.5 0.5 0.5v0h11.031c0.276 0 0.5-0.224 0.5-0.5v0-1.495c0-0.002 0-0.005 0-0.008 0-1.659-0.674-3.16-1.762-4.245l-0-0zM4.516 8.957c-0.633-0.633-1.025-1.508-1.025-2.475 0-1.933 1.567-3.5 3.5-3.5s3.5 1.567 3.5 3.5c0 1.933-1.567 3.5-3.5 3.5-0 0-0 0-0 0v0c-0.003 0-0.006 0-0.009 0-0.964 0-1.836-0.392-2.465-1.025l-0-0zM12.022 16.998h-10.031v-0.995c0-2.77 2.246-5.015 5.016-5.015 1.385 0 2.639 0.562 3.547 1.469v0c0.907 0.902 1.469 2.151 1.469 3.532 0 0.005 0 0.010-0 0.015v-0.001z"></path>
  </>
);
