// @flow strict
import * as React from 'react';
import cx from 'classnames';
import { useUncontrolled } from 'uncontrollable';
import debounce from 'lodash.debounce';

import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { configInternalTestId } from '../../ace-internal/util/util';

import { DropdownContent } from './Internal/DropdownContent';
import { DropdownGroup } from './DropdownGroup';
import { Options, Trigger } from '../Select2/Select2.util';
import { Button } from '../Button';
import { PositionPopover, positions } from '../../ace-internal/shared-component/PositionPopover';

import { dropdownTriggerProps } from './Internal/DropdownTrigger';
import { addEventListener, getRealDomNode, isActiveElement } from '../../ace-internal/util/dom';

import styles from './styles.scss';

// eslint-disable-next-line css-modules/no-unused-class
import cssStyles from './Internal/DropdownContent.scss';

type BaseProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  /** Used to pass the trigger component used to open and close the dropdown */
  trigger:
    | React.Element<'button'>
    | React.Element<'input'>
    | React.Element<'div'>
    | React.Element<typeof Button>
    | React.Element<typeof Trigger>,
  /** Set the start point for horizontal alignment */
  horizontalAlign?: 'Left' | 'Right',
  /** Used for dropdown groups */
  children?: React.ChildrenArray<
    React.Element<typeof DropdownGroup> | React.Element<typeof Options> | void | false,
  >,
  className?: string,
  id?: string,
  fullWidthTrigger?: boolean,
  /** Keep the dropdown open on scroll events */
  keepOpenOnScroll?: boolean,
|};

type ControlledOpen<T> = {|
  open: T,
  onToggle: (open: boolean) => void,
|};
type UncontrolledOpen<T> = {|
  defaultOpen: T,
  onToggle?: (open: boolean) => void,
|};
type Open<T> = ControlledOpen<T> | UncontrolledOpen<T>;

type ControlledProps = {|
  ...BaseProps,
  ...ControlledOpen<boolean>,
|};

export type DropdownPropType = {|
  ...BaseProps,
  ...Open<boolean>,
|};

const createInternalTestId = configInternalTestId({ componentName: 'Dropdown' });

// Block scrolling on parent element when hit the bottom of scroll area of current element
const _onContentNodeWheel = (e: SyntheticWheelEvent<*>) => {
  const isFirefox = navigator.userAgent.indexOf('Firefox') > 0;
  // Every other browser uses 120 but firefox that uses 3
  // Chrome uses wheelDeltaY not DeltaY
  const realDeltaY =
    // $FlowFixMe
    e.wheelDeltaY && typeof e.wheelDeltaY === 'number' ? e.wheelDeltaY : e.deltaY;
  const deltaY = isFirefox ? realDeltaY * -30 || 0 : realDeltaY / 3 || 0;

  e.currentTarget.scrollTop -= deltaY;
  if (e.preventDefault) e.preventDefault();
  if (e.stopPropagation) e.stopPropagation();
  if (e.nativeEvent && e.nativeEvent.stopImmediatePropagation) {
    e.nativeEvent.stopImmediatePropagation();
  }
  return false;
};

const ControlledDropdown = ({
  id,
  'data-test-id': testId,
  trigger,
  open,
  horizontalAlign = 'Left',
  children,
  className,
  onToggle,
  fullWidthTrigger,
  keepOpenOnScroll,
  'data-ansarada-ccd': ansaradaCCD,
}: ControlledProps) => {
  const [focusItem, setFocusItem] = React.useState();
  const wrapperClicked = React.useRef(null);
  const triggerNode = React.useRef(null);
  const contentNode = React.useRef(null);

  const _onWindowScroll = (e: SyntheticMouseEvent<HTMLElement>, contentEl: HTMLElement) => {
    const isContent =
      contentEl &&
      e.target &&
      // $FlowFixMe - I really wanna use target here since currentTarget might be `Window`
      e.target.classList &&
      // $FlowFixMe - 0.125.0
      e.target.classList.contains(cssStyles.dropdownContent);
    // Workaround for Cypress bug https://github.com/cypress-io/cypress/issues/871
    const isCypress = window && window.Cypress;
    const isDropdownKeep =
      // $FlowFixMe - 0.125.0
      e.target && e.target.getAttribute && e.target.getAttribute('data-dropdown-keep') === 'true';

    if (open && !isContent && !isCypress && !isDropdownKeep && !isActiveElement(e.target)) {
      onToggle(false);
    }
  };

  const _onWindowClick = (e: SyntheticMouseEvent<HTMLElement>, contentEl: HTMLElement) => {
    const isContent =
      // $FlowFixMe - I really wanna use target here since currentTarget might be `Window`
      contentEl && contentEl.querySelector(`.${e.target.classList.item(0)}`);

    if (
      !open ||
      !(e.target instanceof Element) ||
      isContent ||
      (triggerNode && triggerNode.current && triggerNode.current.contains(e.target))
    ) {
      return;
    }

    onToggle(false);
  };

  const _onWindowResize = () => {
    if (open) {
      onToggle(!open);
    }
  };

  const _onWrapperNodeClick = () => {
    if (!open) {
      return;
    }

    wrapperClicked.current = setTimeout(() => {
      onToggle(!open);
    }, 0);
  };

  const setup = (contentEl: HTMLElement) => {
    const scrollEventListeners = keepOpenOnScroll
      ? []
      : [
          addEventListener(
            window,
            'scroll',
            debounce(e => _onWindowScroll(e, contentEl), 10),
            true,
          ),
        ];

    return scrollEventListeners.concat([
      addEventListener(window, 'click', e => _onWindowClick(e, contentEl), true),
      addEventListener(window, 'resize', debounce(_onWindowResize, 10)),
      contentEl ? addEventListener(contentEl, 'click', _onWrapperNodeClick) : () => {},
      contentEl ? addEventListener(contentEl, 'wheel', _onContentNodeWheel) : () => {},
    ]);
  };

  React.useLayoutEffect(() => {
    let contentEl = getRealDomNode(contentNode.current);
    let eventsToUnbind = [];

    if (open && contentEl && eventsToUnbind.length === 0) {
      eventsToUnbind = setup(contentEl);
    }

    return () => {
      contentEl = null;
      eventsToUnbind.forEach(fn => fn());
      if (wrapperClicked.current) {
        clearTimeout(wrapperClicked.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const triggerProps = dropdownTriggerProps(open, onToggle, setFocusItem, {
    onClick: trigger.props.onClick,
    onKeyDown: trigger.props.onKeyDown,
  });

  return (
    <React.Fragment>
      <span
        ref={triggerNode}
        className={cx([styles.triggerWrapper, fullWidthTrigger && styles.fullWidth])}
        data-test-id={testId}
      >
        {React.cloneElement(trigger, triggerProps)}
      </span>
      {open && (
        <PositionPopover
          verticalOffset={10}
          triggerRef={triggerNode.current}
          idealPosition={horizontalAlign === 'Left' ? positions.BottomLeft : positions.BottomRight}
        >
          <DropdownContent
            id={id}
            className={className}
            data-test-id={createInternalTestId('wrapper')}
            onUpdate={onToggle}
            focusItem={focusItem}
            triggerNode={triggerNode.current}
            ref={contentNode}
            data-ansarada-ccd={ansaradaCCD}
          >
            {children}
          </DropdownContent>
        </PositionPopover>
      )}
    </React.Fragment>
  );
};

/**
 * Dropdowns are used to declutter the interface, by holding contextually relevant but lesser-used actions or content.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Button
 * @category Navigation
 */
const Dropdown = (props: DropdownPropType): React$Element<typeof ControlledDropdown> => {
  const allProps = useUncontrolled(props, {
    open: 'onToggle',
  });

  return <ControlledDropdown {...allProps} />;
};

export { Dropdown };
