// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const FileAudio = (): React.Element<*> => (
  <>
    <path d="M16.505 18.981h-13c-1.379 0-2.5-1.122-2.5-2.5v-13c0-1.379 1.121-2.5 2.5-2.5h13c1.378 0 2.5 1.121 2.5 2.5v13c0 1.378-1.122 2.5-2.5 2.5zM3.505 1.981c-0.827 0.003-1.497 0.673-1.5 1.5v13c0.003 0.827 0.673 1.497 1.5 1.5h13c0.827-0.003 1.497-0.673 1.5-1.5v-13c-0.003-0.827-0.673-1.497-1.5-1.5h-0z"></path>
    <path d="M10.715 4.028c-0.062-0.029-0.134-0.046-0.21-0.046-0.124 0-0.237 0.045-0.324 0.119l0.001-0.001-3.397 2.881h-2.28c-0.276 0-0.5 0.224-0.5 0.5v0 5c0 0.276 0.224 0.5 0.5 0.5v0h2.28l3.397 2.881c0.087 0.074 0.2 0.119 0.323 0.119 0.276 0 0.5-0.224 0.5-0.5v0-11c0-0.2-0.117-0.372-0.287-0.452l-0.003-0.001zM5.005 7.981h1.5v4h-1.5zM10.005 14.401l-2.5-2.12v-4.6l2.5-2.121z"></path>
    <path d="M15.566 7.724c-0.318-0.773-0.764-1.433-1.318-1.984l-0-0c-0.090-0.090-0.216-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354v0c0.905 0.905 1.464 2.155 1.464 3.535s-0.56 2.631-1.464 3.535v0c-0.090 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146v0c1.086-1.087 1.757-2.588 1.757-4.245 0-0.813-0.161-1.588-0.454-2.295l0.015 0.040z"></path>
    <path d="M12.859 7.128c-0.090-0.090-0.216-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354v0c1.184 1.183 1.184 3.109 0 4.293-0.090 0.090-0.146 0.216-0.146 0.354 0 0.276 0.224 0.5 0.5 0.5 0.138 0 0.263-0.056 0.354-0.146v0c0.73-0.73 1.182-1.739 1.182-2.854s-0.452-2.123-1.182-2.854v0z"></path>
  </>
);
