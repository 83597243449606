// @flow strict
/* Bug in jsx-a11y/label-has-associated-control not picking up value correctly */
/* eslint jsx-a11y/label-has-associated-control: off */
import * as React from 'react';
import { useUncontrolled } from 'uncontrollable';
import classnames from 'classnames';
import { Swipeable } from 'react-swipeable';

import type { testIdPropType } from '../../ace-internal/types/general';
import { KeyEvent } from '../../ace-internal/types/keys';
import { configInternalTestId } from '../../ace-internal/util/util';
import styles from './styles.scss';

type BaseProps = {|
  ...testIdPropType,
  id: string,
  /** Sets the disabled state, if set the user can see the checked state, but not change it */
  disabled: boolean,
  /** Sets the appearance of the toggle */
  variant: 'Caution' | 'Normal',
  className?: string,
  'aria-describedby'?: string,
|};

type ControlledChecked<T> = {|
  checked?: T,
  onChecked: (checked: T) => void,
|};
type UncontrolledChecked<T> = {|
  defaultChecked: T,
  onChecked?: (checked: T) => void,
|};
type Checked<T> = ControlledChecked<T> | UncontrolledChecked<T>;

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledChecked<boolean>,
|};

type TogglePropType = {|
  ...BaseProps,
  ...Checked<boolean>,
|};

const DirectionLeft = 'Left';
const DirectionRight = 'Right';

const keyDown = (
  onUpdate: (checked: boolean) => void,
  checked: boolean,
  e: SyntheticKeyboardEvent<HTMLElement>,
): void => {
  if (!(e.currentTarget instanceof Element)) {
    return;
  }

  if (KeyEvent.isArrowLeft(e)) {
    e.preventDefault();
    if (checked) {
      onUpdate(false);
    }
  }

  if (KeyEvent.isArrowRight(e)) {
    e.preventDefault();
    if (!checked) {
      onUpdate(true);
    }
  }
};

const swipe = (
  direction: string,
  checked: boolean,
  onUpdate: (checked: boolean) => void,
  e: SyntheticEvent<HTMLElement>,
): void => {
  if (!(e.currentTarget instanceof Element)) {
    return;
  }
  e.preventDefault();
  if (direction === DirectionRight && !checked) {
    onUpdate(true);
  }

  if (direction === DirectionLeft && checked) {
    onUpdate(false);
  }
};

const createInternalTestId = configInternalTestId({ componentName: 'Toggle' });

const ControlledToggle = React.memo<ControlledPropType>(
  ({
    'aria-describedby': describedBy,
    'data-test-id': testId,
    checked = false,
    className,
    disabled = false,
    id,
    variant,
    onChecked,
  }: ControlledPropType) => {
    const [hoverable, setHoverable] = React.useState(true);

    // when we want to call onUpdate set hoverable to false, this allows the handle to animate all
    // the way to the left or right before the user's mouse cursor has left the label
    const callOnChecked = (data: boolean) => {
      setHoverable(false);
      if (onChecked) onChecked(data);
    };

    const swipeableAttributes = disabled
      ? {}
      : {
          onSwipingRight: e => swipe(DirectionRight, checked, callOnChecked, e),
          onSwipingLeft: e => swipe(DirectionLeft, checked, callOnChecked, e),
        };
    const classes = classnames(className, styles.toggle);

    const labelClasses = classnames(styles.toggleLabel, {
      [styles.isChecked]: checked,
      [styles.isDisabled]: disabled,
      [styles.isCaution]: variant === 'Caution',
      [styles.isHoverable]: hoverable,
    });

    return (
      <Swipeable {...swipeableAttributes}>
        <div className={classes} data-test-id={testId}>
          <input
            data-test-id={createInternalTestId('input')}
            aria-disabled={!disabled && true}
            aria-describedby={describedBy}
            onKeyDown={e => keyDown(callOnChecked, checked, e)}
            checked={checked}
            disabled={disabled}
            className={styles.toggleInput}
            name={id}
            id={id}
            type="checkbox"
            onChange={() => callOnChecked(!checked)}
          />
          <label
            data-test-id={createInternalTestId('label')}
            htmlFor={id}
            className={labelClasses}
            // enable hoverable on mouse leave
            onMouseLeave={() => setHoverable(true)}
          />
        </div>
      </Swipeable>
    );
  },
);

// Define the component name
ControlledToggle.name = 'Toggle';
export type ToggleType = 'Toggle';

/**
 * Use toggles to allow users to quickly view and switch between enabled or disabled states – when the intent is to turn something on or off instantly (ex. Enable notifications).
 *
 *
 * Toggles should never require users to press a button to apply the settings. When you require users to press a submit button, consider using checkboxes instead.
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Radio, Button, Checkbox
 * @category Buttons
 */
const Toggle = (props: TogglePropType): React$Element<typeof ControlledToggle> => {
  const controlledProps = useUncontrolled(props, {
    checked: 'onChecked',
  });

  return <ControlledToggle {...controlledProps} />;
};

Toggle.defaultProps = {
  disabled: false,
  variant: 'Normal',
};

export { Toggle };
