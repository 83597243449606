// @flow strict
import * as React from 'react';
import debounce from 'lodash.debounce';
import { removeStyleOnElement, setStyleOnElement, addEventListener } from '../../util/dom';
import styles from './styles.scss';
import { PageHidden } from '../../../components/PageHidden';
import { positions, getPosition } from './calculatePosition';

export type Props = {|
  triggerRef: ?HTMLElement,
  children: React.Element<any>,
  idealPosition: $Values<typeof positions>,
  verticalOffset: number,
  horizontalOffset: number,
|};

const PositionPopover = ({
  children,
  idealPosition,
  verticalOffset,
  horizontalOffset,
  triggerRef,
}: Props) => {
  const contentNode = React.useRef(null);
  const updatePosition = () => {
    let attribs;
    if (!triggerRef) return;

    const { top, left, right, bottom } = triggerRef.getBoundingClientRect();

    if (contentNode && contentNode.current) {
      const { width, height } = contentNode.current.getBoundingClientRect();
      attribs = getPosition({
        idealPosition,
        left,
        right,
        top,
        bottom,
        horizontalOffset,
        verticalOffset,
        width,
        height,
      });

      ['bottom', 'top', 'left', 'right', 'maxHeight', 'maxWidth'].forEach(style => {
        if (style in attribs) {
          // $FlowFixMe
          setStyleOnElement(contentNode.current, style, attribs[style]);
        } else {
          removeStyleOnElement(contentNode.current, style);
        }
      });
    }

    if (contentNode && contentNode.current && attribs) {
      contentNode.current.className = `${styles.container} ${attribs.position}`;
    }
  };
  const debouncedUpdatePosition = debounce(updatePosition, 10);

  React.useEffect(() => {
    const eventsToUnbind = [
      addEventListener(window, 'scroll', debouncedUpdatePosition),
      addEventListener(window, 'resize', debouncedUpdatePosition),
    ];

    return () => {
      eventsToUnbind.forEach(fn => fn());
      debouncedUpdatePosition.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    window.requestAnimationFrame(() => {
      debouncedUpdatePosition();
    });
  });

  return (
    <PageHidden>
      {/* $FlowFixMe - 0.125.1 */}
      <div className={`${styles.container} ${styles.hidden}`} ref={contentNode}>
        {children}
      </div>
    </PageHidden>
  );
};

PositionPopover.defaultProps = {
  idealPosition: 'BottomLeft',
  verticalOffset: 0,
  horizontalOffset: 0,
};

export { PositionPopover, positions };
