// @flow strict
import * as React from 'react';
import { Lozenge } from '../Lozenge';
import styles from './styles.scss';

export type BadgePropType = {|
  variant?: 'Notification' | 'New' | 'Beta',
|};

export const Badge = ({ variant = 'Notification' }: BadgePropType) => {
  switch (variant) {
    case 'New':
      return <Lozenge className={styles.badge} text="New" textColor="#07070C" />;
    case 'Beta':
      return <Lozenge className={styles.badge} text="Beta" textColor="#07070C" />;
    default:
      return <i className={styles.notification} />;
  }
};
