// @flow strict
import * as React from 'react';
import classnames from 'classnames';

type HighlightPropType = {
  variant?: 'Normal' | 'Substring',
  className?: string,
  children?: React.Node,
};

/** @ignore */
const Highlight = (props: HighlightPropType) => {
  const { variant = 'Normal', className, children, ...rest } = props;

  const classes = classnames(
    'ace-highlight',
    { 'ace-highlight-substring': variant === 'Substring' },
    className,
  );

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <span className={classes} {...rest}>
      {children}
    </span>
  );
};

export { Highlight };
