// @flow strict
import * as React from 'react';

import { Icon, Glyphs as IconGlyphs } from '../../components/Icon';

const Messages = { Close: 'CLOSE' };

const transition = (old: boolean, msg: Object) => {
  const { message } = msg;

  switch (message) {
    case Messages.Close:
      return false;

    default:
      throw new Error(`Unknown message type: ${message}`);
  }
};

export type ClosePropType = {
  text?: string,
  onUpdate: Function,
};

const Close = (props: ClosePropType) => {
  const { text = 'close', onUpdate, ...rest } = props;

  return (
    <Icon
      {...rest}
      onClick={evt => {
        onUpdate(false, Messages.Close, evt);
      }}
      glyph={IconGlyphs.ControlCloseSmall}
      text={text}
    />
  );
};

export { Close, transition, Messages };
