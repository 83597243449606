// @flow strict
import * as React from 'react';
import elementToString from 'react-element-to-jsx-string';
import {
  ExpanderPanel,
  FormGrid,
  FormGridRow,
  FormGridCell,
  TextInput,
  Icon,
} from '@ansarada/ace-react';
import { v4 as uuid } from 'uuid';
import { CodeSnippet, Stage } from '..';
import { HowToUseTable } from '../HowToUse/HowToUseTable';

import styles from './Showcase.scss';

const defaultConfig = Object.freeze({
  viewAll: 'View all',
  code: 'Code',
  language: 'jsx',
  expanderColour: '#EBEBED',
});

const createSnippet = sample =>
  elementToString(sample.codeSnippet ? sample.codeSnippet : sample.code, {
    filterProps: [],
    showDefaultProps: true,
    showFunctions: false,
    functionValue: () => '...',
    tabStop: 2,
    useBooleanShorthandSyntax: false,
    useFragmentShortSyntax: true,
    sortProps: true,
  });
type Props = {
  importSnippet: string,
  type: 'column' | 'row' | 'full',
  description?: React.Node,
  fixtures: any,
};

export const Showcase = ({ importSnippet, type, fixtures, description }: Props) => {
  const [keyword, setKeyword] = React.useState('');

  // Because show case does not get unloaded keyword state stays so we need to clear it when we load a new component page
  React.useEffect(() => {
    setKeyword('');
  }, [importSnippet]);

  return (
    <>
      <h2 id="import-snippet" className={styles.h2}>
        Import snippet
      </h2>
      <CodeSnippet
        className={styles.importSnippet}
        language={defaultConfig.language}
        snippet={importSnippet}
      />
      {description}

      <FormGrid className={styles.seachForm}>
        <FormGridRow>
          <FormGridCell labelText="Filter variables" hideLabel>
            <TextInput
              value={keyword}
              onChangeValue={setKeyword}
              icon={<Icon glyph="ActionSearch" />}
              placeholder="Filter variables"
              clearable
            />
          </FormGridCell>
        </FormGridRow>
      </FormGrid>

      {fixtures.map(fixture => {
        const samples = fixture.samples.filter(item => {
          const localKeyword = keyword.toLocaleLowerCase().trim();

          if (localKeyword === '') {
            return true;
          }

          if (item && item.metadata) {
            return item.metadata.toLowerCase().includes(localKeyword);
          }

          if (item && item.label) {
            return item.label.toLowerCase().includes(localKeyword);
          }

          return true;
        });

        if (samples.length === 0) {
          return null;
        }

        return (
          <div key={uuid()}>
            <h3 id={fixture.title.toLowerCase().replace(/\s/g, '-')} className={styles.title}>
              {fixture.title}
            </h3>
            <Stage>
              <HowToUseTable samples={samples} type={type} />
            </Stage>
            <ExpanderPanel
              colour={defaultConfig.expanderColour}
              title={defaultConfig.code}
              defaultExpanded={false}
            >
              <div className={styles.codeSnippetContainer}>
                {samples.map(sample => (
                  <CodeSnippet
                    key={uuid()}
                    language={defaultConfig.language}
                    snippet={createSnippet(sample)}
                  />
                ))}
              </div>
            </ExpanderPanel>
          </div>
        );
      })}
    </>
  );
};
