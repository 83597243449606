// @flow strict

import { groupBy } from 'ramda';
import type { ComponentType, NavItemWithCategory } from '../../types/orbit';

const categoryMap = {
  Data: 'Data display',
  Form: 'Form controls',
  Buttons: 'Buttons & Indicators',
  Layout: 'Layout',
  Feedback: 'Feedback',
  Utils: 'Utils',
  Navigation: 'Navigation',
};

export const mapCategories = (category: string): string => categoryMap[category];

export const getComponentsNavItems = (
  components: Array<ComponentType>,
): Array<NavItemWithCategory> =>
  components
    .filter(c => !c.ignore)
    .map(c => ({
      label: c.displayName,
      url: `/components/${c.displayName}`,
      category: c.category ? mapCategories(c.category) : '',
    }));

export const groupByCategory = (
  items: Array<NavItemWithCategory>,
): { [string]: Array<NavItemWithCategory> } => groupBy(c => c.category, items);
