// @flow strict
import * as React from 'react';
import { useUncontrolled } from 'uncontrollable';

import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { FormattedContentBlock } from '../FormattedContentBlock';
import styles from './styles.scss';
import { PositionPopover } from '../../ace-internal/shared-component/PositionPopover';
import { addEventListener } from '../../ace-internal/util/dom';

export type BaseProps = {|
  /** Optional attribute, sets a test id. */
  ...testIdPropType,
  /** See [ansaradaCCD](docs/architecture/ansarada-ccd.md) */
  ...ansaradaCCDPropType,
  /** The ideal position for the tooltip */
  position: 'Top' | 'Bottom' | 'Left' | 'Right',
  /** The trigger for the tooltip */
  children: React.Element<*>,
  /** An id (required for accessibility) */
  id: string,
  /** The text generated by the tooltip */
  text: string | React.Element<typeof FormattedContentBlock>,
|};

type ControlledOpen<T> = {|
  open?: T,
  onTrigger: (open: T) => void,
|};
type UncontrolledOpen<T> = {|
  defaultOpen: T,
  onTrigger?: (open: T) => void,
|};
type Open<T> = ControlledOpen<T> | UncontrolledOpen<T>;

type ControlledPropType = {|
  ...BaseProps,
  ...ControlledOpen<boolean>,
|};

export type TooltipPropType = {|
  ...BaseProps,
  ...Open<boolean>,
|};

const ControlledTooltip = ({
  children,
  id,
  onTrigger,
  open,
  text,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
  position,
}: ControlledPropType) => {
  const [triggerNode, setTriggerNode] = React.useState();
  const [isOpen, setIsOpen] = React.useState(open);

  const _open = () => {
    setIsOpen(true);
    onTrigger(true);
  };
  const _close = () => {
    setIsOpen(false);
    onTrigger(false);
  };

  React.useEffect(() => {
    const events = [
      addEventListener(window, 'scroll', _close),
      addEventListener(window, 'resize', _close),
    ];
    return () => {
      events.forEach(removeListener => removeListener());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // Note onMouseEnter + onMouseLeave need to be on a wrapping span to avoid an issue
    // where chrome + firefox trigger onMouseEnter on disabled buttons/inputs but
    // don't trigger onMouseLeave
    // see https://github.com/facebook/react/issues/4251
    <>
      <span
        className={styles.triggerWrapper}
        ref={el => setTriggerNode(el)}
        onMouseEnter={_open}
        onMouseLeave={_close}
      >
        {React.cloneElement(children, {
          onFocus: _open,
          onBlur: _close,
          'aria-describedby': id,
        })}
      </span>
      {isOpen && (
        <PositionPopover
          verticalOffset={10}
          horizontalOffset={10}
          triggerRef={triggerNode}
          idealPosition={position}
        >
          <div
            id={id}
            className={styles.tooltip}
            role="tooltip"
            data-test-id={testId}
            data-ansarada-ccd={ansaradaCCD || undefined}
          >
            {text}
          </div>
        </PositionPopover>
      )}
    </>
  );
};

/**
 *
 * Tooltips provide additional context for simple interface elements. A tooltip appears when a pointing device hovers over an element that has a tooltip on it.
 *
 *
 * Preferred position for the Tooltip is below the element. However can also be used above, to the left or right of an element.
 *
 * @status released
 * @date 26/09/2018
 * @version 12.0.0
 * @tags Card, Button
 * @category Feedback
 */
const Tooltip = (props: TooltipPropType): React$Element<typeof ControlledTooltip> => {
  const allProps = useUncontrolled(props, {
    open: 'onTrigger',
  });
  return <ControlledTooltip {...allProps} />;
};

Tooltip.defaultProps = {
  position: 'Bottom',
};

export { Tooltip };
