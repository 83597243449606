// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const SaveCross = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M13.5 7.5c-3.037 0-5.5 2.463-5.5 5.5s2.463 5.5 5.5 5.5 5.5-2.462 5.5-5.5-2.462-5.5-5.5-5.5zM16.683 16.183c-1.753 1.748-4.594 1.745-6.342-0.009s-1.745-4.594 0.009-6.342 4.594-1.745 6.342 0.009c1.287 1.291 1.664 3.231 0.956 4.911-0.228 0.534-0.555 1.020-0.966 1.431z"
    ></path>
    <path
      fill="#fb0069"
      d="M15.853 10.647c-0.195-0.195-0.512-0.195-0.708 0 0 0 0 0 0 0l-1.645 1.647-1.647-1.647c-0.195-0.195-0.513-0.195-0.708 0s-0.195 0.513 0 0.708l1.648 1.645-1.647 1.647c-0.195 0.195-0.195 0.512 0 0.708s0.513 0.195 0.708 0l1.645-1.648 1.647 1.647c0.195 0.195 0.513 0.195 0.708 0s0.195-0.512 0-0.708l-1.648-1.645 1.647-1.647c0.195-0.195 0.195-0.511 0-0.706 0 0 0 0 0 0z"
    ></path>
    <path d="M15.333 7.805v-4.778c0-0.009 0-0.017 0-0.027h1.667v5.748c0.38 0.314 0.716 0.677 1 1.080v-6.916c0-0.503-0.408-0.913-0.913-0.913h-14.175c-0.503 0-0.913 0.408-0.913 0.913v12.422l2.667 2.666h6.556c-0.548-0.25-1.052-0.588-1.489-1h-2.708c-0.014 0-0.027-0.011-0.027-0.027v0-4.447c0-0.014 0.011-0.027 0.027-0.027h0.995c0.030-0.339 0.091-0.673 0.183-1h-1.178c-0.567 0-1.027 0.459-1.027 1.027v4.448c0 0.009 0 0.017 0 0.027h-0.919l-2.081-2.083v-11.919h1.667c0 0.009 0 0.017 0 0.027v5.948c0 0.566 0.459 1.025 1.025 1.027h3.192c0.238-0.366 0.517-0.7 0.833-1h-4.025c-0.014 0-0.027-0.011-0.027-0.027 0 0 0 0 0 0v-5.948c0-0.014 0.011-0.027 0.025-0.027 0 0 0 0 0 0h8.614c0.014 0 0.027 0.011 0.027 0.027 0 0 0 0 0 0v4.528c0.342 0.052 0.677 0.136 1.002 0.25z"></path>
  </>
);
