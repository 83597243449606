// @flow strict

import type { ColumnType, SortDirectionType } from '../Table.shared';

import * as icon from '../../Icon';

const applySortDirection = (result: number, sortDirection: SortDirectionType) => {
  if (sortDirection === 'DESC') {
    return 0 - result;
  }

  return result;
};

const sortText = (a: string, b: string) => {
  const _a = a.toUpperCase();
  const _b = b.toUpperCase();

  if (_a < _b) {
    return -1;
  }

  if (_a > _b) {
    return 1;
  }

  return 0;
};

const sortNumber = (a: number, b: number) => a - b;

const sortDate = (a: Date, b: Date) => a - b;

const sortCurrency = (a: string, b: string) => {
  const _a = Number(a.replace(/[^\d.]/g, ''));
  const _b = Number(b.replace(/[^\d.]/g, ''));
  return _a - _b;
};

const getColumnField = (item?: Object, columnItem?: ColumnType) =>
  typeof item !== 'undefined' &&
  typeof columnItem !== 'undefined' &&
  Object.prototype.hasOwnProperty.call(item, columnItem.field)
    ? item[columnItem.field]
    : undefined;

const getSortDirection = (sortDirection?: string, defaultSort: string) => {
  if (typeof sortDirection === 'undefined') {
    return defaultSort;
  }
  return sortDirection;
};

const getSortDirectionDisplayTitle = (sortDirection: string) => {
  if (sortDirection === 'ASC') {
    return 'ascending';
  }
  return 'descending';
};

const getSortIcon = (sortDirection: string) => {
  if (sortDirection === 'ASC') {
    return icon.Glyphs.ControlSortDown;
  }
  return icon.Glyphs.ControlSortUp;
};

const sortData = (
  data: Array<Object>,
  columnItem: ColumnType,
  sortDirection: SortDirectionType,
) => {
  const sortDataBy = (a: Object, b: Object) => {
    const _a = getColumnField(a, columnItem);
    const _b = getColumnField(b, columnItem);

    // Can't sort if a or b is undefined so we return 0 so we don't move the values
    if (typeof _a === 'undefined' || typeof _b === 'undefined') {
      return 0;
    }

    if (typeof columnItem.customSort !== 'undefined') {
      return applySortDirection(columnItem.customSort(_a, _b), sortDirection);
    }

    switch (columnItem.type) {
      case 'Number':
        return applySortDirection(sortNumber(Number(_a), Number(_b)), sortDirection);
      case 'Currency':
        return applySortDirection(sortCurrency(_a, _b), sortDirection);
      case 'DateTime':
        return applySortDirection(sortDate(new Date(_a), new Date(_b)), sortDirection);
      default:
        return applySortDirection(sortText(_a, _b), sortDirection);
    }
  };

  if (Array.isArray(data) && data.sort) {
    return data.sort(sortDataBy);
  }

  return data;
};

export {
  applySortDirection,
  sortText,
  sortNumber,
  sortCurrency,
  sortData,
  sortDate,
  getSortDirection,
  getSortDirectionDisplayTitle,
  getSortIcon,
};
