// @flow strict
import * as React from 'react';
import { FormattedContentBlock, Card } from '@ansarada/ace-react';
import { NavLink } from 'react-router-dom';

import styles from './Shoelace.scss';
import { mapNavItems } from '../../util/scss';
import { ComponentHeader, CodeSnippet } from '../../components';
import type { NavItem } from '../../../types/sass-export';

const component = {
  displayName: 'Shoelace',
  description: `
  ShoelACE is a system of design tokens. It provides scss variables and mixins that align with our design systems and allows for rapid developement and provides an abstraction over design choices that allow easy changing of designs without large overheads.
  `,
  status: 'Released',
  importSnippet: `@import '@ansarada/shoelace';`,
};

const listItem = (token: NavItem) => (
  <li key={token.label} className={styles.token}>
    <NavLink to={token.url}>
      <Card className={styles.card}>
        <img className={styles.tokenImage} src={token.image} alt={token.label} />
        <h3 className={styles.tokenLabel}>{token.label}</h3>
      </Card>
    </NavLink>
  </li>
);

export const Shoelace = () => {
  const designTokens = window.ORBIT.scss.variables;
  const designTokensNavItems: NavItem[] = [
    ...mapNavItems(designTokens),
    {
      label: 'Utility mixins',
      image: 'utility-mixins.png',
      url: '/shoelace/utility-mixins',
    },
  ];

  return (
    <div>
      <ComponentHeader component={component} />
      <div className={styles.wrapper}>
        <FormattedContentBlock className={styles.addSpace}>
          <h2>Getting started</h2>
          <p>Shoelace requires that you use scss. To use it you will need to:</p>
          <ol>
            <li>
              <code>npm i -s @ansarada/ace-react</code>
            </li>
            <li>Use import code below</li>
          </ol>
        </FormattedContentBlock>

        <CodeSnippet language="jsx" snippet={component.importSnippet} className={styles.addSpace} />

        <FormattedContentBlock className={styles.addSpace}>
          <p>Once you have imported shoelace you can freely use any of the design tokens below.</p>
          <h2 className={styles.addSpace}>Design tokens</h2>
        </FormattedContentBlock>

        <ul className={styles.tokens}>{designTokensNavItems.map(listItem)}</ul>
      </div>
    </div>
  );
};
