// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const EditTick = (): React.Element<*> => (
  <>
    <path
      fill="#93b703"
      d="M13.506 8c-3.038 0-5.5 2.463-5.5 5.5s2.462 5.5 5.5 5.5 5.5-2.462 5.5-5.5-2.463-5.5-5.5-5.5zM16.688 16.681c-0.811 0.808-1.929 1.308-3.165 1.308-2.476 0-4.483-2.007-4.483-4.483s2.007-4.483 4.483-4.483c2.476 0 4.483 2.007 4.483 4.483 0 0.63-0.13 1.229-0.364 1.773l0.011-0.029c-0.238 0.556-0.564 1.031-0.965 1.431l-0 0z"
    ></path>
    <path
      fill="#93b703"
      d="M15.37 10.692c-0.058-0.025-0.126-0.040-0.197-0.040-0.205 0-0.381 0.123-0.458 0.3l-0.001 0.003-1.637 3.818-0.821-1.232c-0.091-0.137-0.244-0.225-0.418-0.225-0.276 0-0.5 0.224-0.5 0.5 0 0.105 0.032 0.202 0.087 0.282l-0.001-0.002 1.333 2c0.091 0.135 0.243 0.223 0.416 0.223v0c0.016 0 0.031-0.001 0.046-0.002 0.186-0.018 0.341-0.135 0.413-0.297l0.001-0.003 2-4.667c0.026-0.058 0.041-0.126 0.041-0.197 0-0.205-0.123-0.381-0.3-0.458l-0.003-0.001z"
    ></path>
    <path d="M8.031 14.086l-0.27 0.27-2.121-2.121 7.643-7.638 2.119 2.118-1.309 1.309c0.431 0.047 0.824 0.136 1.199 0.265l-0.038-0.012 1.953-1.953c0.036-0.032 0.071-0.065 0.103-0.097 0.975-0.975 0.975-2.561 0-3.535-0.958-0.958-2.506-0.975-3.484-0.050-0.020 0.015-0.038 0.030-0.054 0.047l-9.191 9.192c-0.054 0.054-0.096 0.121-0.121 0.196l-0.001 0.004-2.449 5.248c-0.030 0.062-0.047 0.135-0.047 0.212 0 0.276 0.224 0.5 0.5 0.5 0.077 0 0.15-0.017 0.215-0.048l-0.003 0.001 5.303-2.474c0.074-0.035 0.136-0.085 0.183-0.146l0.001-0.001 0.125-0.125c-0.118-0.336-0.207-0.729-0.252-1.136l-0.002-0.025zM14.434 3.441c0.018-0.014 0.034-0.028 0.048-0.043l0-0c0.271-0.271 0.646-0.439 1.061-0.439 0.828 0 1.5 0.672 1.5 1.5 0 0.414-0.168 0.789-0.439 1.061v0c-0.022 0.022-0.045 0.043-0.070 0.065-0.008 0.007-0.016 0.014-0.023 0.021l-0.401 0.401-2.121-2.121zM3.497 16.506l1.591-3.41 1.818 1.819z"></path>
  </>
);
