// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnAAdministrator = (): React.Element<*> => (
  <>
    <path d="M15.498 0.99h-11c-1.378 0-2.5 1.122-2.5 2.5v11c0 1.378 1.122 2.5 2.5 2.5h4.018l1.054 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.428-0.241l0.001-0.002 1.054-1.757h4.016c1.379 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.121-2.5-2.5-2.5zM16.998 14.49c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.428 0.241l-0.001 0.002-0.771 1.285-0.771-1.285c-0.089-0.146-0.248-0.243-0.429-0.243h-4.3c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M15.408 8.795c-0.043-0.158-0.101-0.296-0.176-0.423l0.004 0.008 0.354-0.429c-0.154-0.217-0.337-0.401-0.547-0.55l-0.007-0.005-0.429 0.354c-0.119-0.070-0.257-0.129-0.403-0.169l-0.012-0.003-0.053-0.554c-0.118-0.022-0.254-0.034-0.392-0.034s-0.274 0.012-0.406 0.036l0.014-0.002-0.053 0.554c-0.158 0.043-0.296 0.101-0.423 0.176l0.008-0.004-0.429-0.354c-0.217 0.154-0.401 0.338-0.55 0.548l-0.005 0.007 0.354 0.429c-0.070 0.119-0.129 0.257-0.169 0.403l-0.003 0.012-0.554 0.053c-0.022 0.118-0.034 0.254-0.034 0.392s0.012 0.274 0.036 0.406l-0.002-0.014 0.554 0.053c0.043 0.158 0.101 0.296 0.176 0.423l-0.004-0.008-0.354 0.429c0.154 0.217 0.337 0.401 0.547 0.55l0.007 0.005 0.429-0.354c0.119 0.070 0.257 0.129 0.403 0.169l0.012 0.003 0.053 0.554c0.118 0.022 0.254 0.034 0.392 0.034s0.274-0.012 0.406-0.036l-0.014 0.002 0.053-0.554c0.158-0.043 0.296-0.101 0.423-0.176l-0.008 0.004 0.429 0.354c0.217-0.154 0.401-0.337 0.55-0.547l0.005-0.007-0.354-0.429c0.070-0.119 0.129-0.257 0.169-0.403l0.003-0.012 0.554-0.053c0.022-0.118 0.034-0.254 0.034-0.392s-0.012-0.274-0.036-0.406l0.002 0.014zM13.746 10.175c-0.516 0-0.935-0.418-0.935-0.935s0.418-0.935 0.935-0.935c0.516 0 0.935 0.418 0.935 0.935v0c0 0.516-0.418 0.935-0.935 0.935v0z"></path>
    <path d="M9.122 9.256c0.863-0.491 1.436-1.405 1.436-2.453 0-1.553-1.259-2.813-2.813-2.813s-2.813 1.259-2.813 2.813c0 1.046 0.571 1.958 1.417 2.443l0.014 0.007c-1.387 0.55-2.369 1.903-2.369 3.487v0.95c0 0.166 0.134 0.3 0.3 0.3v0h6.925c0.152 0 0.275-0.123 0.275-0.275v0-0.95c0-1.582-0.986-2.952-2.374-3.509zM5.934 6.803c0-0 0-0 0-0.001 0-1.001 0.811-1.813 1.813-1.813s1.813 0.811 1.813 1.813c0 1.001-0.811 1.813-1.813 1.813-0.5 0-0.953-0.203-1.281-0.53v0c-0.328-0.326-0.531-0.778-0.531-1.277 0-0.001 0-0.003 0-0.004v0zM10.496 12.99h-5.5v-0.25c0-0 0-0 0-0 0-1.512 1.221-2.74 2.731-2.75h0.018c0.729 0 1.418 0.29 1.94 0.816 0.246 0.248 0.447 0.541 0.587 0.867l0.007 0.018c0.137 0.316 0.217 0.683 0.217 1.069 0 0.001 0 0.003 0 0.004v-0z"></path>
  </>
);
