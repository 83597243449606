// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { Button, Icon, icon } from '@ansarada/ace-react';
import styles from './FullScreen.scss';

type Props = {
  name: string,
  children: React.Node,
  isVisible: boolean,
  onClickBack: () => void,
};

type SetBodyOverflowY = string => void;
const setBodyOverflowY: SetBodyOverflowY = value => {
  if (document.body) {
    document.body.style.overflowY = value;
  }
};

export const FullScreen = ({ name, children, isVisible, onClickBack }: Props) => {
  React.useEffect(() => {
    setBodyOverflowY('hidden');
    return () => setBodyOverflowY('');
  }, []);

  return (
    <div className={classnames(styles.container, { [styles.visible]: isVisible })}>
      <header className={styles.header}>
        <span>
          You are viewing a whole-viewport demo for the <strong>{name}</strong> component.
        </span>
        <Button icon={<Icon glyph={icon.Glyphs.ControlArrowLeft} />} onClick={onClickBack}>
          Back to docs
        </Button>
      </header>
      <div>{children}</div>
    </div>
  );
};
