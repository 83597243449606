// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Broken = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M18.002 9.99v-0.001c0-0.004 0-0.007 0-0.011-0.007-4.413-3.586-7.988-8-7.988s-7.992 3.574-8 7.985v0.006c0 0.003 0 0.006 0 0.008v0.001c0 0.004 0 0.007 0 0.011 0.007 4.413 3.586 7.988 8 7.988s7.992-3.574 8-7.984v-0.015zM10.002 2.99c3.746 0 6.805 2.943 6.991 6.643l-6.491 2.164v-4.307c0-0 0-0 0-0 0-0.276-0.224-0.5-0.5-0.5-0.057 0-0.111 0.009-0.162 0.027l0.004-0.001-6.806 2.269c0.353-3.535 3.338-6.295 6.965-6.295zM10.002 16.99c-3.746 0-6.805-2.943-6.991-6.643l6.491-2.164v4.306c0 0 0 0 0 0 0 0.276 0.224 0.5 0.5 0.5 0.057 0 0.111-0.009 0.162-0.027l-0.004 0.001 6.807-2.269c-0.354 3.535-3.337 6.295-6.965 6.295z"
    ></path>
  </>
);
