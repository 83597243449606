// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Colour = (): React.Element<*> => (
  <>
    <path d="M10.005 2v0c-4.418 0-8 3.582-8 8s3.582 8 8 8v0c4.418 0 8-3.582 8-8s-3.582-8-8-8zM16.303 13.059l-5.298-3.059 5.298-3.059c0.443 0.894 0.702 1.946 0.702 3.059s-0.259 2.165-0.72 3.1l0.018-0.041zM15.802 6.076l-5.297 3.058v-6.116c2.204 0.156 4.126 1.331 5.297 3.058zM9.505 3.018v6.116l-5.297-3.058c1.17-1.727 3.093-2.902 5.297-3.058zM3.707 6.941l5.298 3.059-5.298 3.059c-0.443-0.894-0.702-1.946-0.702-3.059s0.259-2.165 0.72-3.1l-0.018 0.041zM4.207 13.924l5.297-3.058v6.116c-2.203-0.156-4.125-1.331-5.296-3.058zM10.504 16.982v-6.116l5.297 3.058c-1.17 1.727-3.092 2.902-5.296 3.058z"></path>
  </>
);
