// @flow strict
/* eslint jsx-a11y/anchor-is-valid: off */

import React from 'react';

import {
  form,
  Form,
  formSingleGroup,
  FormSingleGroup,
  formMultiGroup,
  FormMultiGroup,
  Label,
  Select,
  Option,
  Radio,
  Checkbox,
  CheckboxGroup,
  SubmitButton,
  FormattedContentBlock,
} from '../components';

type StateType = {
  checkbox8Checked: boolean,
  checkboxes: Array<string>,
  hyperlinkcheckbox: boolean,
  radioNolabel: boolean,
  radioLabel: boolean,
  radioDisabled: boolean,
  radioSubtle: boolean,
  selectDocumentValue: string,
  selectSecurityGroupValue: string,
};

class FormNonTextInputTest extends React.Component<{}, StateType> {
  constructor() {
    super();
    this.state = {
      checkbox8Checked: true,
      hyperlinkcheckbox: false,
      radioNolabel: false,
      radioLabel: false,
      radioDisabled: false,
      radioSubtle: false,
      selectDocumentValue: '',
      selectSecurityGroupValue: '',
      checkboxes: ['checkboxSelected', 'checkboxDisabledChecked'],
      checkboxDefault: false,
      checkboxSelected: true,
      checkboxDisabled: false,
      checkboxDisabledChecked: true,
      checkboxIndeterminate: true,
      checkboxIndeterminateDisabled: true,
    };
  }

  update(address: string, value: any) {
    this.setState({ ...this.state, [address]: value });
  }

  updateCheckboxState(address: string, value: any) {
    this.setState({ ...this.state, [address]: value.checked });
  }

  updateCheckboxes(value: Array<string>) {
    this.setState({ ...this.state, checkboxes: value });
  }

  toogleCheckbox() {
    this.setState({
      checkbox8Checked: !this.state.checkbox8Checked,
    });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h3>Form: checkboxes</h3>
        </FormattedContentBlock>
        <FormMultiGroup contentType={formMultiGroup.ContentType.Checkbox} legend="Checkboxes">
          <CheckboxGroup
            value={this.state.checkboxes}
            onChangeValue={data => {
              this.updateCheckboxes(data);
            }}
          >
            <Checkbox
              name="checkboxDefault"
              value="checkboxDefault"
              id="checkboxDefault"
              label="Default"
              defaultChecked={false}
            />
            <Checkbox
              name="checkboxSelected"
              value="checkboxSelected"
              id="checkboxSelected"
              label="Selected"
              defaultChecked={false}
            />
            <Checkbox
              name="checkboxDisabled"
              value="checkboxDisabled"
              id="checkboxDisabled"
              label="Disabled"
              disabled
              defaultChecked={false}
            />
            <Checkbox
              name="checkboxDisabledChecked"
              value="checkboxDisabledChecked"
              id="checkboxDisabledChecked"
              label="Disabled Checked"
              disabled
              defaultChecked={false}
            />
            <Checkbox
              name="checkboxIndeterminate"
              value="checkboxIndeterminate"
              id="checkboxIndeterminate"
              label="Partial or Indeterminate"
              indeterminate
              defaultChecked={false}
            />
            <Checkbox
              name="checkboxIndeterminateDisabled"
              value="checkboxIndeterminateDisabled"
              id="checkboxIndeterminateDisabled"
              label="Partial or Indeterminate disabled"
              disabled
              indeterminate
              defaultChecked={false}
            />
          </CheckboxGroup>
        </FormMultiGroup>

        <FormattedContentBlock>
          <h3>Toggle Checkbox by programming</h3>
        </FormattedContentBlock>

        <Form method={form.Method.Get} variant={form.Variants.Filter}>
          <FormSingleGroup contentType={formSingleGroup.ContentType.Button}>
            <SubmitButton onClick={() => this.toogleCheckbox()}>Toggle Checkbox</SubmitButton>
          </FormSingleGroup>
          <FormSingleGroup contentType={formSingleGroup.ContentType.Checkbox}>
            <Checkbox
              name="checkbox8"
              value="checkbox8"
              id="checkbox8"
              label={<span>With Programming</span>}
              checked={this.state.checkbox8Checked}
              onChecked={v => this.updateCheckboxState('checkbox8Checked', v)}
            />
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <p>Radio state: &quot;{this.state.radioNolabel}&quot;</p>
        </FormattedContentBlock>

        <Form method={form.Method.Get}>
          <FormMultiGroup legend="Label as string" legendId="legendId">
            <Radio
              id="radio-nolabel-yes"
              name="radio-option"
              value="yes"
              label="Yes"
              aria-describedby="legendId"
              checked={this.state.radioNolabel === 'yes'}
              onChecked={() => {
                this.update('radioNolabel', 'yes');
              }}
            />
            <Radio
              id="radio-nolabel-no"
              name="radio-option"
              value="no"
              label="No"
              aria-describedby="legendId"
              checked={this.state.radioNolabel === 'no'}
              onChecked={() => {
                this.update('radioNolabel', 'no');
              }}
            />
          </FormMultiGroup>
        </Form>

        <FormattedContentBlock>
          <p>Radio state: &quot;{this.state.radioLabel}&quot;</p>
        </FormattedContentBlock>

        <Form method={form.Method.Get}>
          <FormMultiGroup legend="Label as label component" legendId="legendId2">
            <Radio
              id="radio-label-yes"
              name="radio-label"
              value="yes"
              aria-describedby="legendId2"
              label="yes"
              checked={this.state.radioLabel === 'yes'}
              onChecked={() => this.update('radioLabel', 'yes')}
            />
            <Radio
              id="radio-label-no"
              name="radio-label"
              value="no"
              aria-describedby="legendId2"
              label="no"
              checked={this.state.radioLabel === 'no'}
              onChecked={() => this.update('radioLabel', 'no')}
            />
          </FormMultiGroup>
        </Form>

        <Form method={form.Method.Get}>
          <FormMultiGroup legend="Disabled" legendId="legendId3">
            <Radio
              id="radio-disabled-yes"
              name="radio-disabled"
              disabled
              value="yes"
              label="Yes"
              aria-describedby="legendId3"
              checked={this.state.radioDisabled}
              onChecked={v => this.update('radioDisabled', v)}
            />
            <Radio
              id="radio-disabled-no"
              name="radio-disabled"
              disabled
              value="no"
              label="No"
              aria-describedby="legendId3"
              checked={this.state.radioDisabled}
              onChecked={v => this.update('radioDisabled', v)}
            />
          </FormMultiGroup>
        </Form>

        <FormattedContentBlock>
          <h3>Form: select</h3>
        </FormattedContentBlock>

        <Form method={form.Method.Get} variant={form.Variants.Filter}>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Select}
            label={<Label text="Security Group" forEl="select-security-group" />}
          >
            <Select
              id="select-security-group"
              value={this.state.selectSecurityGroupValue}
              onChangeValue={v => this.update('selectSecurityGroupValue', v)}
            >
              <Option value="" />
              <Option text="Adidas" value="10" />
              <Option text="KKR" value="11" />
              <Option text="Nike" value="12" />
              <Option text="Reebok" value="13" />
              <Option text="TPG Capital" value="14" />
            </Select>
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Select}
            label={<Label text="Document" forEl="select-document" />}
          >
            <Select
              status="Bad"
              id="select-document"
              value={this.state.selectDocumentValue}
              onChangeValue={v => this.update('selectDocumentValue', v)}
            >
              <Option text="All Documents" value="" />
              <Option text="01 Corporate Information" value="10" />
              <Option text="01.01 Certificates of Registration" value="11" />
              <Option text="01.01.01 ABC Limited.docx" value="12" />
              <Option text="01.01.02 ABC Limited-Incorporation Doc..." value="13" />
            </Select>
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Select}
            label={<Label text="Disabled Filter" forEl="select-disabled" />}
          >
            <Select id="select-disabled" disabled value="" onChangeValue={() => {}}>
              <Option value="" />
            </Select>
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <h3>Labels with hyperlinks</h3>
        </FormattedContentBlock>

        <Form method={form.Method.Get} variant={form.Variants.Filter}>
          <FormSingleGroup contentType={formSingleGroup.ContentType.Checkbox}>
            <Checkbox
              name="hyperlinkcheckbox"
              value="hyperlinkcheckbox"
              id="hyperlinkcheckbox"
              label={
                <span>
                  I agree to the <a href="#">terms of use</a> and <a href="#">Privacy policy</a>
                </span>
              }
              defaultChecked={this.state.hyperlinkcheckbox}
            />
          </FormSingleGroup>
        </Form>
      </div>
    );
  }
}

export default FormNonTextInputTest;
