// @flow strict
/* eslint jsx-a11y/anchor-is-valid: off */
/* eslint-disable no-console */

import React from 'react';

import {
  Form,
  formSingleGroup,
  FormSingleGroup,
  InputInfo,
  InputState,
  Label,
  TextInput,
  TextArea,
  FormattedContentBlock,
} from '../components';

import {
  ControlledTextInput,
  ControlledTextArea,
} from '../ace-internal/shared-component/ControlledTextInput';

type StateType = {
  [string]: string,
};

class FormTextInputTest extends React.Component<*, StateType> {
  constructor() {
    super();
    this.state = {
      textInput: '',
      keypress: '',
      keydown: '',
    };
  }

  update(address: string, value: string) {
    this.setState({ ...this.state, [address]: value });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h3>Form: TextInput</h3>
        </FormattedContentBlock>

        <Form>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input" text="Text input" />}
          >
            <ControlledTextInput id="text-input" initValue="" aria-describedby="input-info" />
            <InputInfo id="input-info">Extra information about the input.</InputInfo>
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-placeholder" text="Text input with placeholder" />}
          >
            <ControlledTextInput
              id="text-input-placeholder"
              initValue=""
              aria-describedby="input-info"
              placeholder="Placeholder text"
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-required" text="Text input required" required />}
          >
            <ControlledTextInput
              id="text-input-required"
              initValue=""
              aria-describedby="input-info-required"
              required
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-error" text="Text input error state" />}
          >
            <ControlledTextInput
              id="text-input-error"
              status="Bad"
              initValue="Text input error state"
            />
            <InputState id="text-input-state-error" status="Bad">
              Extra information about the error.
            </InputState>
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-disabled" text="Disabled text input" />}
          >
            <TextInput
              id="text-input-disabled"
              onChangeValue={() => {}}
              value="Disabled text input"
              disabled
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-readonly" text="Readonly text input" />}
          >
            <TextInput
              id="text-input-readonly"
              onChangeValue={() => {}}
              value="Readonly text input"
              readOnly
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-noupdate" text="No onUpdate text input" />}
          >
            <TextInput id="text-input-noupdate" defaultValue="No onUpdate text input" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-small" text="Text input small" />}
          >
            <ControlledTextInput id="text-input-small" type="Text" initValue="" size="Small" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="text-input-large" text="Text input large" />}
          >
            <ControlledTextInput id="text-input-large" type="Text" initValue="" size="Large" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="email-input" text="Email input" />}
          >
            <ControlledTextInput id="email-input" type="Email" initValue="email input" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="number-input" text="Number input" />}
          >
            <ControlledTextInput id="number-input" type="Number" initValue="0123456789" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="phone-number-input" text="Phone number input" />}
          >
            <ControlledTextInput id="phone-number-input" type="Tel" initValue="+61 401 000 000" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="password-input" text="Password input" />}
          >
            <ControlledTextInput id="password-input" type="Password" initValue="password input" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Search}
            label={<Label forEl="search-input" text="Search input" />}
          >
            <ControlledTextInput id="search-input" type="Search" initValue="Search input" />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.URL}
            label={<Label forEl="url-input" text="URL input" />}
          >
            <ControlledTextInput id="url-input" type="URL" initValue="www.ansarada.com" />
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <h3>Form: Labels</h3>
        </FormattedContentBlock>

        <Form>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-default-input" text="label default" />}
          >
            <TextInput
              id="label-default-input"
              onChangeValue={() => {}}
              value={this.state.textInput}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-xsmall-input" text="label xsmall" size="Small" />}
          >
            <TextInput
              id="label-xsmall-input"
              onChangeValue={() => {}}
              value={this.state.textInput}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-small-input" text="label small" size="Small" />}
          >
            <TextInput
              id="label-small-input"
              onChangeValue={() => {}}
              value={this.state.textInput}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-medium-input" text="label medium" size="Medium" />}
          >
            <TextInput
              id="label-medium-input"
              onChangeValue={() => {}}
              value={this.state.textInput}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-large-input" text="label large" size="Large" />}
          >
            <TextInput
              id="label-large-input"
              onChangeValue={() => {}}
              value={this.state.textInput}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-xlarge-input" text="label xlarge" size="Large" />}
          >
            <TextInput
              id="label-xlarge-input"
              onChangeValue={() => {}}
              value={this.state.textInput}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-assistive-input" text="label assistive" assistive />}
          >
            <TextInput
              id="label-assistive-input"
              onChangeValue={() => {}}
              value={this.state.textInput}
            />
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <h3>Form: TextArea</h3>
        </FormattedContentBlock>

        <Form>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.TextArea}
            label={<Label forEl="text-area" text="Text area" />}
          >
            <ControlledTextArea id="text-area" initValue="" aria-describedby="text-area-info" />
            <InputInfo id="text-area-info">Extra information about the text area.</InputInfo>
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.TextArea}
            label={<Label forEl="text-area-placeholder" text="Text area with placeholder" />}
          >
            <ControlledTextArea
              id="text-area-placeholder"
              initValue=""
              placeholder="Text area with placeholder"
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.TextArea}
            label={<Label forEl="text-area-required" text="Text area required" required />}
          >
            <ControlledTextArea id="text-area-required" initValue="" required />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.TextArea}
            label={<Label forEl="text-area-error" text="Text area error" />}
          >
            <ControlledTextArea id="text-area-error" initValue="Text area error" status="Bad" />
            <InputState id="text-area-error-state" status="Bad">
              Extra information about the error.
            </InputState>
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.TextArea}
            label={<Label forEl="text-area-disabled" text="Disabled text area" />}
          >
            <TextArea id="text-area-disabled" defaultValue="Disabled text area" disabled />
          </FormSingleGroup>

          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-keypress" text="Keypress" />}
          >
            <TextInput
              id="label-keypress"
              onChangeValue={v => this.update('keypress', v)}
              value={this.state.keypress}
              onKeyPress={e => {
                console.log(e);
              }}
            />
          </FormSingleGroup>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Text}
            label={<Label forEl="label-keydown" text="Keydown" />}
          >
            <TextInput
              id="label-keydown"
              onChangeValue={v => this.update('keydown', v)}
              value={this.state.keydown}
              onKeyDown={e => {
                console.log(e);
              }}
            />
          </FormSingleGroup>
        </Form>
      </div>
    );
  }
}

export default FormTextInputTest;
