// @flow strict
/* eslint-disable arrow-body-style */

import * as React from 'react';
import { Pagination, FormattedContentBlock } from '../components';

class PaginationTest extends React.Component<
  {},
  {
    selected1: number,
    selected2: number,
  },
> {
  constructor() {
    super();

    this.state = {
      selected1: 1,
      selected2: 1,
    };
  }

  update(address: string, value: number) {
    this.setState({ [address]: value });
  }

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h2>Pagination</h2>
        </FormattedContentBlock>

        <Pagination
          total={30}
          onSelectedChange={v => this.update('selected1', v)}
          selected={this.state.selected1}
        />

        <Pagination
          total={0}
          onSelectedChange={v => this.update('selected2', v)}
          selected={this.state.selected2}
        />
      </div>
    );
  }
}

export { PaginationTest };
