// @flow strict
import * as React from 'react';
import classnames from 'classnames';

// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

import * as icon from '../../components/Icon';
import { TruncateText } from '../../components/TruncateText';

const { Icon } = icon;

const Variants = {
  Directory: 'Directory',
  User: 'User',
  Generic: 'Generic',
  File: 'File',
  Question: 'Question',
  Answer: 'Answer',
  Comment: 'Comment',
  UserGroup: 'UserGroup',
};

const getFileTypeCSS = (fileType: string) => `document-${fileType.toLowerCase()}`;

type FacetPropType = {
  kind: string,
  href?: string,
  children?: React.ChildrenArray<React.Element<any> | string>,
  target?: string,
  rel?: string,
};

const Facet = (props: FacetPropType) => {
  const { kind, href, target, rel, children, ...rest } = props;

  const classes = styles[kind];

  return href ? (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <a className={classes} href={href} target={target} rel={rel} {...rest}>
      {children}
    </a>
  ) : (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <span className={classes} {...rest}>
      {children}
    </span>
  );
};

const glyph = (variant: $Keys<typeof Variants>, fileType?: string) => {
  switch (variant) {
    case Variants.Directory:
      return icon.Glyphs.ObjectsFolder;

    case Variants.User:
      return icon.Glyphs.ObjectsUser;

    case Variants.Generic:
      return icon.Glyphs.DocumentGeneric;

    case Variants.File:
      return fileType ? getFileTypeCSS(fileType) : '';

    case Variants.Question:
      return icon.Glyphs.ObjectsQuestion;

    case Variants.Answer:
      return icon.Glyphs.ObjectsAnswer;

    case Variants.Comment:
      return icon.Glyphs.ObjectsSubject;

    case Variants.UserGroup:
      return icon.Glyphs.ObjectsUsergroup;

    default:
      throw new Error(`Unknown variant: ${variant}`);
  }
};

// eslint-disable-next-line consistent-return
const iconAttrs = (iconColour?: string) => {
  if (iconColour) {
    return {
      style: { color: iconColour },
    };
  }
};

type AssetPropType = {
  variant?: $Keys<typeof Variants>,
  name?: string | React.Element<typeof TruncateText>,
  author?: string,
  href?: string,
  number?: string,
  path?: string,
  size?: string,
  disabled?: boolean,
  className?: string,
  timestamp?: string,
  iconColour?: string,
  fileType?: string,
  target?: string,
  rel?: string,
};

const Asset = (props: AssetPropType) => {
  const {
    variant = Variants.Generic,
    name,
    author,
    href,
    number,
    path,
    size,
    disabled,
    timestamp,
    className,
    iconColour,
    fileType,
    target,
    rel,
    ...rest
  } = props;

  const classes = classnames(styles.asset, { [styles.isDisabled]: !!disabled }, className);

  return (
    // $FlowFixMe 0.112.0 - inexact rest of object pattern
    <span className={classes} {...rest}>
      <Icon glyph={glyph(variant, fileType)} text={fileType} {...iconAttrs(iconColour)} />
      {number && <Facet kind="number">{number}</Facet>}
      {path && <Facet kind="path">{path}</Facet>}
      <Facet href={href} target={target} rel={rel} kind="name">
        {name}
      </Facet>
      {size && <Facet kind="size">{size}</Facet>}
      {author && <Facet kind="author">{author}</Facet>}
      {timestamp && <Facet kind="timestamp">{timestamp}</Facet>}
    </span>
  );
};

export { Asset, Variants };
