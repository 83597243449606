// @flow strict
import React from 'react';
import { NavLink } from 'react-router-dom';
import type { Location } from 'react-router-dom';

import type { NavItem, NavItemWithCategory } from '../../../../types/orbit';
import styles from './SidebarGroup.scss';

type Item = NavItem | NavItemWithCategory;

type Props = {|
  title: string,
  items: $ReadOnlyArray<Item>,
  isActiveComponent: (match: { url: string }, location: Location) => boolean,
  isSearching: boolean,
|};

export const SidebarGroup = ({ title, items, isActiveComponent, isSearching }: Props) => {
  return (
    <div className={styles.container}>
      {!isSearching && <h2 className={styles.title}>{title}</h2>}

      <ul>
        {items.map(item => (
          <li key={item.label}>
            <NavLink
              className={styles.subItem}
              to={item.url}
              activeClassName={styles.activeSubItem}
              isActive={isActiveComponent}
            >
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
