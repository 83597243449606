// @flow strict

// The next line is required because we re-assign props to newProps
/* eslint-disable react/no-unused-prop-types */
// remove this disable rule once the prop's have been deprecated

import * as React from 'react';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { useUncontrolled } from 'uncontrollable';

import type { ansaradaCCDPropType, testIdPropType } from '../../ace-internal/types/general';
import styles from './styles.scss';

export type RadioType = 'Radio';

type FieldLabel = string | React.Element<'span'>;

type BaseProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  /** CSS Class name property for extra styling */
  className?: string,
  /** Sets the radio button disabled state */
  disabled?: boolean,
  /** Becomes the radio button label. Can be a string or a span with content. If a span is passed, the className prop will be overridden with radio button specific styling */
  label: FieldLabel,
  /** Radio button value attribute */
  value: string,
  /** Radio button ID. If empty, a uuid will be generated for it */
  id?: string,
  /** Radio button name */
  name?: string,
  /** Error prop */
  error?: boolean,
  /** Aria described by id. It should be the id of the element which described the radio button */
  'aria-describedby'?: string,
|};

type ControlledChecked<T> = {|
  checked?: T,
  onChecked: (checked: T) => void,
|};
type UncontrolledChecked<T> = {|
  defaultChecked: T,
  onChecked?: (checked: T) => void,
|};
type Checked<T> = ControlledChecked<T> | UncontrolledChecked<T>;

type RadioControlledProps = {|
  ...BaseProps,
  ...ControlledChecked<boolean>,
|};

type RadioPropType = {|
  ...BaseProps,
  ...Checked<boolean>,
|};

function getLabelContent(label: FieldLabel, disabled: boolean): React.Element<'span'> {
  if (typeof label === 'string') {
    return (
      <span className={styles.labelText} disabled={disabled}>
        {label}
      </span>
    );
  }
  return React.cloneElement(label, { className: styles.labelText });
}

const ControlledRadio = (props: RadioControlledProps): React.Element<'label'> => {
  const {
    id = uuid(),
    checked = false,
    disabled = false,
    name,
    value,
    onChecked,
    error,
    'data-ansarada-ccd': ansaradaCCD,
    'data-test-id': testId,
    label,
    className,
    'aria-describedby': describedBy,
  } = props;

  return (
    <label
      className={classnames(
        styles.radio,
        className,
        { [styles.isDisabled]: disabled },
        { [styles.error]: error },
      )}
      data-test-id={testId}
      disabled={disabled}
      data-ansarada-ccd={ansaradaCCD || undefined}
      htmlFor={id}
    >
      <input
        type="radio"
        id={id}
        className={classnames(styles.radioInput, { [styles.error]: error })}
        name={name}
        value={value}
        disabled={disabled}
        checked={checked}
        aria-describedby={describedBy}
        onChange={e => {
          if (onChecked) {
            onChecked(e.target.value);
          }
        }}
      />
      {getLabelContent(label, disabled)}
    </label>
  );
};

/**
 * Radio allows the user to make a single selection.
 *
 *
 * A button is required to apply the user’s selection
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  RadioGroup, Checkbox, CheckboxGroup
 * @category Form
 */
const Radio = (props: RadioPropType): React$Element<typeof ControlledRadio> => {
  const allProps = useUncontrolled(props, {
    checked: 'onChecked',
  });

  return <ControlledRadio {...allProps} />;
};

export { Radio };
