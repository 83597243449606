// @flow strict
import * as React from 'react';

import {
  Select,
  Option,
  Label,
  Form,
  formSingleGroup,
  FormSingleGroup,
  FormattedContentBlock,
} from '../components';

type StateType = {
  selectValue: string,
  longValue: string,
};

class FormSelectTest extends React.Component<{}, StateType> {
  constructor() {
    super();

    this.state = {
      selectValue: '',
      longValue: '',
    };
  }

  updateSelect = (value: string) => {
    this.setState({ selectValue: value });
  };

  updateLongValue = (longValue: string) => {
    this.setState({ longValue });
  };

  render() {
    return (
      <div>
        <FormattedContentBlock>
          <h3>Form: Select</h3>
          <p>Select Content: &quot;{this.state.selectValue}&quot;</p>
        </FormattedContentBlock>
        <Form>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Select}
            label={<Label forEl="select" text="Select" />}
          >
            <Select id="select" onChangeValue={this.updateSelect} value={this.state.selectValue}>
              <Option value="" text="Select a Fruit" disabled />
              <Option value="1" text="Apple" />
              <Option value="2" text="Mango" />
              <Option value="3" text="Cherry" />
            </Select>
          </FormSingleGroup>
        </Form>

        <FormattedContentBlock>
          <h3>Form: Select with long text</h3>
          <p>Select Content: &quot;{this.state.longValue}&quot;</p>
        </FormattedContentBlock>

        <Form>
          <FormSingleGroup
            contentType={formSingleGroup.ContentType.Select}
            label={<Label forEl="select-long-text" text="Select" />}
          >
            <Select
              id="select-long-text"
              onChangeValue={this.updateLongValue}
              value={this.state.longValue}
            >
              <Option value="" text="Select a text" disabled />
              <Option value="1" text={'long text'.repeat(100)} />
              <Option value="2" text="short text" />
            </Select>
          </FormSingleGroup>
        </Form>
      </div>
    );
  }
}

export default FormSelectTest;
