// @flow strict
const positions = Object.freeze({
  TopLeft: 'TopLeft',
  BottomLeft: 'BottomLeft',
  TopRight: 'TopRight',
  BottomRight: 'BottomRight',
  Bottom: 'Bottom',
  Top: 'Top',
  Left: 'Left',
  Right: 'Right',
});

type calculatePositionArgs = {
  idealPosition: $Values<typeof positions>,
  left: number,
  right: number,
  top: number,
  bottom: number,
  horizontalOffset: number,
  verticalOffset: number,
  width: number,
  height: number,
};

const verticalMap = {
  TopLeft: positions.Top,
  BottomLeft: positions.Bottom,
  TopRight: positions.Top,
  BottomRight: positions.Bottom,
  Bottom: positions.Bottom,
  Top: positions.Top,
  Left: positions.Left,
  Right: positions.Right,
};

const horizontalMap = {
  TopLeft: positions.Left,
  BottomLeft: positions.Left,
  TopRight: positions.Right,
  BottomRight: positions.Right,
  Left: positions.Left,
  Right: positions.Right,
  Bottom: positions.Bottom,
  Top: positions.Top,
};

// window.innerWidth and window.innerHeight include the scroll bar width which we want to ignore
// so we use document.documentElement.clientWidth/Height
// $FlowFixMe
const windowHeight = () => document.documentElement.clientHeight;
// $FlowFixMe
const windowWidth = () => document.documentElement.clientWidth;

const toPx = number => `${Math.round(number)}px`;

const GUTTER = 10;

const getBestCornerPosition = values => {
  let vert;
  let hor;
  const { idealPosition, width, height, topSpace, bottomSpace, leftSpace, rightSpace } = values;
  const verticalPos = verticalMap[idealPosition];
  const horizontalPos = horizontalMap[idealPosition];

  if (
    (verticalPos === positions.Top && (height < topSpace || topSpace >= bottomSpace)) ||
    (verticalPos === positions.Bottom && height > bottomSpace && topSpace >= bottomSpace)
  ) {
    vert = 'Top';
  } else {
    vert = 'Bottom';
  }

  if (
    (horizontalPos === positions.Left && (width < leftSpace || leftSpace >= rightSpace)) ||
    (horizontalPos === positions.Right && width > rightSpace && leftSpace >= rightSpace)
  ) {
    hor = 'Left';
  } else {
    hor = 'Right';
  }
  return positions[`${vert}${hor}`];
};

const getCornerCoords = (position, values) => {
  // prettier-ignore
  const { left, right, top, bottom, verticalOffset, leftSpace, rightSpace, topSpace, bottomSpace } = values;
  const leftPos = {
    left: toPx(left + window.pageXOffset),
    maxWidth: toPx(leftSpace),
  };

  const rightPos = {
    right: toPx(windowWidth() - right - window.pageXOffset),
    maxWidth: toPx(rightSpace),
  };

  const topPos = {
    bottom: toPx(windowHeight() - top + verticalOffset - window.pageYOffset),
    maxHeight: toPx(topSpace),
  };

  const bottomPos = {
    top: toPx(bottom + verticalOffset + window.pageYOffset),
    maxHeight: toPx(bottomSpace),
  };

  switch (position) {
    case positions.TopLeft:
      return { ...topPos, ...leftPos };
    case positions.TopRight:
      return { ...topPos, ...rightPos };
    case positions.BottomLeft:
      return { ...bottomPos, ...leftPos };
    case positions.BottomRight:
    default:
      return { ...bottomPos, ...rightPos };
  }
};

const getBestGridPosition = ({
  idealPosition,
  horMiddle,
  height,
  width,
  leftSpace,
  rightSpace,
  bottomSpace,
  topSpace,
}) => {
  if (idealPosition === positions.Left || idealPosition === positions.Right) {
    // if left or right will be cut off by the bottom of the screen, move to the top
    if (horMiddle + height / 2 > windowHeight() - GUTTER) {
      return positions.Top;
    }
    // if left or right will be cut off by the top of the screen, move to the bottom
    if (horMiddle - height / 2 < GUTTER) {
      return positions.Bottom;
    }

    // if it fits in leftSpace return Left or it doesn't fit in Right space, but does fit in Left
    if (
      (idealPosition === positions.Left && width <= leftSpace) ||
      (idealPosition === positions.Right && width <= leftSpace && width > rightSpace)
    ) {
      return positions.Left;
    }
    // otherwise if it fits in rightSpace return Right;
    if (width <= rightSpace) {
      return positions.Right;
    }
  }

  // if it fits in Bottom space or it doesn't fit in top space and bottom space is bigger
  if (
    (idealPosition === positions.Bottom && (height < bottomSpace || bottomSpace >= topSpace)) ||
    ((idealPosition === positions.Top ||
      idealPosition === positions.Left ||
      idealPosition === positions.Right) &&
      height > topSpace &&
      bottomSpace >= topSpace)
  ) {
    return positions.Bottom;
  }

  // otherwise default to topspace
  return positions.Top;
};

const getGridCoords = (position, values) => {
  // prettier-ignore
  const { horMiddle, vertMiddle, width, height, left, right, top, bottom, horizontalOffset, verticalOffset, leftSpace, bottomSpace, rightSpace, topSpace } = values;

  const leftRightYCoords = {
    top: toPx(Math.max(horMiddle - height / 2, GUTTER) + window.pageYOffset),
    maxHeight: toPx(windowHeight() - GUTTER * 2),
  };

  const topBotXCoords = {
    left: toPx(Math.max(vertMiddle - width / 2, GUTTER) + window.pageXOffset),
    maxWidth: toPx(windowWidth() - GUTTER * 2),
  };

  switch (position) {
    case positions.Left:
      return {
        right: toPx(windowWidth() - left + horizontalOffset - window.pageXOffset),
        maxWidth: toPx(leftSpace),
        ...leftRightYCoords,
      };
    case positions.Right:
      return {
        left: toPx(right + horizontalOffset + window.pageXOffset),
        maxWidth: toPx(rightSpace),
        ...leftRightYCoords,
      };
    case positions.Bottom:
      return {
        top: toPx(bottom + verticalOffset + window.pageYOffset),
        maxHeight: toPx(bottomSpace),
        ...topBotXCoords,
      };
    case positions.Top:
    default:
      return {
        bottom: toPx(windowHeight() - top + verticalOffset - window.pageYOffset),
        maxHeight: toPx(topSpace),
        ...topBotXCoords,
      };
  }
};

const getPosition = (
  values: calculatePositionArgs,
): {
  bottom?: string,
  top?: string,
  left?: string,
  right?: string,
  maxHeight: string,
  maxWidth: string,
  position: $Values<typeof positions>,
} => {
  const { top, bottom, left, right, verticalOffset, horizontalOffset } = values;

  const topSpace = top - (GUTTER + horizontalOffset);
  const bottomSpace = windowHeight() - (bottom + GUTTER + horizontalOffset);

  if (
    values.idealPosition === positions.Top ||
    values.idealPosition === positions.Bottom ||
    values.idealPosition === positions.Left ||
    values.idealPosition === positions.Right
  ) {
    const calculatedValues = {
      ...values,
      topSpace,
      bottomSpace,
      rightSpace: windowWidth() - right - GUTTER - verticalOffset,
      leftSpace: left - GUTTER - verticalOffset,
      vertMiddle: (left + right) / 2,
      horMiddle: (top + bottom) / 2,
    };

    const position = getBestGridPosition(calculatedValues);
    return { position, ...getGridCoords(position, calculatedValues) };
  }

  const calculatedValues = {
    ...values,
    topSpace,
    bottomSpace,
    leftSpace: windowWidth() - left - GUTTER,
    rightSpace: right - GUTTER,
  };

  const position = getBestCornerPosition(calculatedValues);
  return { position, ...getCornerCoords(position, calculatedValues) };
};

export { getPosition, positions };
