// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const QnAActions = (): React.Element<*> => (
  <>
    <path d="M15.506 0.99h-11c-1.379 0-2.5 1.122-2.5 2.5v11c0 1.378 1.121 2.5 2.5 2.5h4.017l1.055 1.757c0.089 0.146 0.248 0.243 0.429 0.243s0.34-0.096 0.428-0.241l0.001-0.002 1.054-1.757h4.016c1.378 0 2.5-1.122 2.5-2.5v-11c0-1.378-1.122-2.5-2.5-2.5zM17.006 14.49c-0.003 0.827-0.673 1.497-1.5 1.5h-4.3c-0.181 0-0.34 0.096-0.428 0.241l-0.001 0.002-0.771 1.285-0.772-1.285c-0.089-0.146-0.248-0.243-0.429-0.243h-4.299c-0.827-0.003-1.497-0.673-1.5-1.5v-11c0.003-0.827 0.673-1.497 1.5-1.5h11c0.827 0.003 1.497 0.673 1.5 1.5v0z"></path>
    <path d="M11.091 3.609c0-0.207 0-0.207-0.103 0l-3.615 6.61 2.169-0.31-0.62 3.925c0 0.207 0 0.207 0.103 0l3.615-6.61-2.17 0.31z"></path>
  </>
);
