// @flow strict
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import type { RouterHistory, Location } from 'react-router-dom';
import { head } from 'ramda';

import { FullScreen as FullScreenComponent } from '../../components';
import loadComponent from '../../util/loadComponent';

type FullScreenWrapperProps = {
  children: React.Node,
  name: string,
  history: RouterHistory,
  location: Location,
};

const FullScreenWrapperFactory = ({
  name,
  children,
  history,
  location,
}: FullScreenWrapperProps) => {
  const backToComponentPage = () => {
    const urlParts = location.pathname.split('/');
    urlParts.splice(-2, 2);
    history.push(`/components/${urlParts[urlParts.length - 1]}`);
  };

  return (
    <FullScreenComponent name={name} isVisible onClickBack={() => backToComponentPage()}>
      {children}
    </FullScreenComponent>
  );
};

type FullScreenProps = {|
  match: { params: { id: string, example: string } },
|};

const FullScreenWrapper = withRouter(FullScreenWrapperFactory);

const FullScreen = ({ match }: FullScreenProps) => {
  const [samples, setSamples] = React.useState();

  const getFileContent = async () => {
    const fileContents = await loadComponent(match.params.id, 'fixtures');
    const fixture = fileContents.default.fixtures.find(f => f.title === match.params.example);

    if (fixture) {
      setSamples(fixture.samples);
    }
  };

  getFileContent();

  if (!samples) return null;

  const sample = head(samples);
  const codeSnippet = sample.codeSnippet || sample.code;
  return <FullScreenWrapper name={match.params.id}>{codeSnippet}</FullScreenWrapper>;
};

export { FullScreen };
