// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const EyeHide = (): React.Element<*> => (
  <>
    <path d="M12.487 7.516c-0.123-0.123-0.254-0.236-0.394-0.34l-0.009-0.007-0.72 0.72c0.3 0.195 0.549 0.443 0.739 0.733l0.006 0.009 0.719-0.72c-0.109-0.146-0.22-0.275-0.341-0.395l-0-0z"></path>
    <path d="M7.91 11.342l-0.72 0.72c0.213 0.286 0.458 0.532 0.734 0.738l0.009 0.007 0.719-0.719c-0.299-0.197-0.547-0.446-0.736-0.737l-0.006-0.010z"></path>
    <path d="M7.532 10.306c-0.013-0.095-0.020-0.205-0.020-0.316v-0c0-1.378 1.122-2.5 2.5-2.5 0 0 0.001 0 0.001 0 0.111 0 0.22 0.007 0.328 0.021l-0.013-0.001 0.828-0.829c-0.34-0.121-0.732-0.191-1.14-0.191-1.935 0-3.504 1.569-3.504 3.504 0 0.408 0.070 0.801 0.198 1.165l-0.008-0.025z"></path>
    <path d="M12.491 9.663c0.014 0.098 0.021 0.212 0.021 0.327 0 0 0 0 0 0v0c0 1.378-1.121 2.5-2.5 2.5-0 0-0.001 0-0.001 0-0.115 0-0.228-0.008-0.338-0.023l0.013 0.001-0.828 0.828c0.343 0.123 0.739 0.195 1.151 0.195 1.935 0 3.504-1.569 3.504-3.504 0-0.412-0.071-0.808-0.202-1.176l0.008 0.024z"></path>
    <path d="M2.384 17.612c0.097 0.098 0.231 0.159 0.379 0.159h0c0.001 0 0.002 0 0.003 0 0.134 0 0.256-0.054 0.344-0.142l14.535-14.535c0.196-0.196 0.188-0.521-0.017-0.726-0.097-0.098-0.231-0.159-0.379-0.159h-0c-0.001 0-0.002-0-0.003-0-0.134 0-0.256 0.054-0.344 0.142l-14.535 14.535c-0.195 0.196-0.188 0.521 0.017 0.726z"></path>
    <path d="M4.594 14.658c0.255 0.195 0.546 0.392 0.847 0.573l0.043 0.024 0.733-0.733c-0.351-0.195-0.648-0.387-0.931-0.596l0.023 0.016z"></path>
    <path d="M14.528 4.724l-0.733 0.733c0.351 0.195 0.648 0.387 0.931 0.596l-0.023-0.016 0.715-0.715c-0.255-0.195-0.545-0.392-0.847-0.573l-0.043-0.024z"></path>
    <path d="M18.968 9.798c-0.027-0.065-0.675-1.599-2.094-3.145q-0.333-0.363-0.686-0.688l-0.708 0.708q0.326 0.297 0.635 0.631c1.028 1.114 1.627 2.245 1.84 2.686-0.213 0.441-0.813 1.572-1.84 2.686-0.758 0.822-1.599 1.475-2.498 1.942-1.115 0.578-2.33 0.872-3.611 0.872-0.986 0-1.932-0.174-2.824-0.518l-0.766 0.766c1.124 0.5 2.329 0.752 3.59 0.752 1.45 0 2.826-0.335 4.091-0.994 1.007-0.525 1.941-1.254 2.779-2.168 1.418-1.547 2.066-3.081 2.094-3.145 0.024-0.056 0.038-0.122 0.038-0.191s-0.014-0.137-0.040-0.197l0.001 0.003z"></path>
    <path d="M3.823 14.016l0.708-0.708q-0.326-0.297-0.634-0.631c-1.028-1.114-1.627-2.246-1.84-2.687 0.213-0.441 0.813-1.572 1.84-2.686 0.758-0.822 1.598-1.475 2.498-1.942 1.115-0.578 2.33-0.872 3.611-0.872 0.985 0 1.932 0.174 2.824 0.518l0.766-0.766c-1.124-0.5-2.328-0.752-3.589-0.752-1.45 0-2.826 0.335-4.091 0.994-1.005 0.525-1.94 1.254-2.778 2.168-1.418 1.547-2.066 3.081-2.093 3.145-0.024 0.057-0.038 0.123-0.038 0.192s0.014 0.136 0.040 0.196l-0.001-0.003c0.027 0.065 0.675 1.599 2.094 3.145q0.332 0.364 0.684 0.688z"></path>
  </>
);
