// @flow strict
/* eslint jsx-a11y/no-noninteractive-element-to-interactive-role: off */
import * as React from 'react';
import classNames from 'classnames';
import { TruncateText } from '../TruncateText';
import { Asset } from '../Asset';
import { KeyEvent } from '../../ace-internal/types/keys';

import styles from './AssetPickerFileItem.scss';

export type AssetPickerFileItemPropType = {
  alt: string,
  annotation?: string,
  className?: string,
  disabled?: boolean,
  fileType?: string,
  name: string,
  onSelect?: Function,
  selected?: boolean,
  id: string,
};

export type AssetPickerFileItemCallBack = {
  id: string,
  type: 'File',
};

const onSelectMouseCallBack = (
  e: SyntheticEvent<*>,
  value: AssetPickerFileItemCallBack,
  onSelect: Function,
) => onSelect(value);

const onSelectKeyBoardCallBack = (
  e: SyntheticKeyboardEvent<*>,
  value: AssetPickerFileItemCallBack,
  onSelect: Function,
) => {
  if (KeyEvent.isEnter(e) || KeyEvent.isSpace(e)) {
    e.preventDefault();
    onSelect(value);
  }
};

/** @ignore */
const AssetPickerFileItem = (props: AssetPickerFileItemPropType) => {
  const {
    alt,
    annotation,
    className,
    disabled = false,
    fileType,
    name,
    onSelect,
    selected = false,
    id,
    ...rest
  } = props;

  const classes = classNames(
    styles.assetPickerFileItem,
    { [styles.isDisabled]: disabled },
    { [styles.isActive]: !disabled && !selected },
    { [styles.isSelected]: selected },
    className,
  );

  return (
    // $FlowFixMe 0.125.0
    <li
      aria-label={alt}
      aria-pressed={selected}
      className={classes}
      onClick={
        onSelect
          ? (e: SyntheticEvent<*>) => {
              onSelectMouseCallBack(
                e,
                {
                  id,
                  type: 'File',
                },
                onSelect,
              );
            }
          : undefined
      }
      onKeyDown={
        onSelect
          ? (e: SyntheticKeyboardEvent<*>) => {
              onSelectKeyBoardCallBack(
                e,
                {
                  id,
                  type: 'File',
                },
                onSelect,
              );
            }
          : undefined
      }
      role="button"
      tabIndex={onSelect ? 0 : undefined}
      {...rest}
    >
      <div className={styles.assetColumn}>
        <Asset
          name={<TruncateText truncatePosition="Middle">{name}</TruncateText>}
          disabled={disabled}
          fileType={fileType}
        />
      </div>
      {annotation && (
        <div className={styles.endControlColumn}>
          <span className={styles.annotationText}>{annotation}</span>
        </div>
      )}
    </li>
  );
};

export { AssetPickerFileItem };
