// @flow strict

import * as React from 'react';
import { PageHidden } from '../components';

const PageHiddenTest = () => (
  <PageHidden>
    <div className="wireframe-green" style={{ height: '20px', width: '20px' }} />
  </PageHidden>
);

export default PageHiddenTest;
