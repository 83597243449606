// @flow strict

import * as React from 'react';
import classnames from 'classnames/bind';

import type { ColumnType, SortDirectionType } from '../Table.shared';

import { getSortDirection, getSortDirectionDisplayTitle, getSortIcon } from './SortingLogic';

import { Icon } from '../../Icon';

// eslint-disable-next-line css-modules/no-unused-class
import styles from '../styles.scss';

export type HeaderCellPropType = {|
  column: ColumnType,
  sticky?: boolean,
  onSort?: (columnItem: ColumnType, sortDirection: SortDirectionType) => void,
  sorted?: boolean,
  sortDirection?: SortDirectionType,
  className?: string,
|};

/** @ignore */
const HeaderCell = (props: HeaderCellPropType) => {
  const { column, sticky, onSort, sorted = false, sortDirection, className } = props;
  const { title, type = 'Text', sortable = false, defaultSort = 'ASC' } = column;
  const cx = classnames.bind(styles);
  const classes = cx('headerCell', `is${type}`, className, {
    isSticky: sticky,
    isSortable: sortable,
    isSorted: sorted,
    isColumnSticky: column.sticky,
  });

  const onSortEvent = () => {
    if (sortable && onSort) {
      onSort(
        column,
        typeof sortDirection === 'undefined'
          ? defaultSort
          : sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC',
      );
    }
  };

  const onClick = () => onSortEvent();
  const onkeyDown = (e: SyntheticKeyboardEvent<>) => (e.keyCode === 13 ? onSortEvent() : undefined);
  const _sortDirection = getSortDirection(sortDirection, defaultSort);

  return (
    <th
      className={classes}
      onClick={onClick}
      onKeyDown={onkeyDown}
      aria-sort={
        sortable && sorted
          ? getSortDirectionDisplayTitle(getSortDirection(sortDirection, defaultSort))
          : undefined
      }
      tabIndex={sortable ? 0 : undefined}
    >
      {title}
      {sortable && (
        <span style={{ visibility: sorted ? 'visible' : 'hidden' }}>
          <Icon glyph={getSortIcon(_sortDirection)} />
        </span>
      )}
    </th>
  );
};

export { HeaderCell };
