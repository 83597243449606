// @flow strict
import * as React from 'react';
import cx from 'classnames';
import { useUncontrolled } from 'uncontrollable';
import type { testIdPropType, ansaradaCCDPropType } from '../../ace-internal/types/general';
import { CloseButton } from '../../ace-internal/shared-component/CloseButton';
import { PasswordButton } from '../../ace-internal/shared-component/PasswordButton';
import { Icon } from '../Icon';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.scss';

type Type = 'Email' | 'Number' | 'Tel' | 'Password' | 'PasswordText' | 'Search' | 'Text' | 'URL';
type TextInputType = 'TextInput';

type BaseProps = {|
  ...testIdPropType,
  ...ansaradaCCDPropType,
  status?: 'Good' | 'Bad',
  /** An optional id for connecting labels to input element */
  id?: string,
  /** Adds extra classes in the input element */
  className?: string,
  /** Sets the input content type */
  type?: Type,
  disabled?: boolean,
  /** Sets the input size */
  size?: 'Small' | 'Medium' | 'Large',
  /** Function for handling changes to the input */
  onChangeValue?: (newValue: string) => void,
  readOnly?: boolean,
  /** Sets the input field's value. */
  value?: string,
  ariaDescribedBy?: string,
  onMouseOver?: (e: SyntheticMouseEvent<*>) => void,
  onMouseOut?: (e: SyntheticMouseEvent<*>) => void,
  onFocus?: (e: SyntheticEvent<*>) => void,
  onBlur?: (e: SyntheticEvent<*>) => void,
  onClick?: (e: SyntheticMouseEvent<*>) => void,
  onPaste?: (e: SyntheticClipboardEvent<*>) => void,
  onKeyPress?: (e: SyntheticKeyboardEvent<*>) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<*>) => void,
  /** Sets placeholder text */
  placeholder?: string,
  required?: boolean,
  name?: string,
  autoComplete?: string,
  maxLength?: number,
  minLength?: number,
  icon?: React$Element<typeof Icon>,
  clearable?: boolean,
  onClear?: (inputRef: ?React$Ref<'input'>) => void,
  inputRef?: React$Ref<'input'>,
|};

type ControlledTypeP<T> = {|
  /** Sets the input field's TypeP. */
  type?: T,
  /** Function for handling changes to the input */
  onChangeType?: (newTypeP: T) => void,
|};

type UncontrolledTypeP<T> = {|
  /** Sets the input field's default TypeP. */
  defaultType: T,
  /** Function for handling changes to the input */
  onChangeType?: (newTypeP: T) => void,
|};

type TypeP<T> = ControlledTypeP<T> | UncontrolledTypeP<T>;

type ControlledValue<T> = {|
  /** Sets the input field's value. */
  value?: T,
  /** Function for handling changes to the input */
  onChangeValue: (newValue: T) => void,
|};

type UncontrolledValue<T> = {|
  /** Sets the input field's default value. */
  defaultValue: T,
  /** Function for handling changes to the input */
  onChangeValue?: (newValue: T) => void,
|};

type Value<T> = ControlledValue<T> | UncontrolledValue<T>;

type TextInputControlledProps = {|
  ...BaseProps,
  ...ControlledValue<string>,
  ...ControlledTypeP<Type>,
|};

export type TextInputPropType = {|
  ...BaseProps,
  ...Value<string>,
  ...TypeP<Type>,
|};

const getType = t => t.replace('PasswordText', 'Text').toLowerCase();

const ControlledTextInput = (props: TextInputControlledProps) => {
  const {
    id,
    className,
    type = 'Text',
    disabled = false,
    size = 'Medium',
    status = 'Good',
    readOnly = false,
    value,
    onChangeValue,
    onChangeType,
    'data-ansarada-ccd': ansaradaCCD = false,
    'data-test-id': testId,
    ariaDescribedBy,
    onMouseOver,
    onMouseOut,
    onFocus,
    onBlur,
    onClick,
    onKeyPress,
    onKeyDown,
    onPaste,
    placeholder,
    required,
    name,
    autoComplete,
    maxLength,
    minLength,
    icon,
    // $FlowFixMe
    inputRef,
    clearable = false,
    onClear,
  } = props;

  const hasIcon = !!icon;
  const isPassword = type === 'Password' || type === 'PasswordText';
  const hasWrapper = hasIcon || clearable || isPassword;

  const classes = cx(
    styles.textInput,
    styles[`is${size}`],
    { [styles.isBad]: status === 'Bad' },
    className,
  );

  const input = (
    <input
      id={id}
      autoComplete={autoComplete}
      name={name}
      className={cx(!hasWrapper && classes, {
        [styles.inputWithIcon]: hasIcon,
        [styles.input]: hasWrapper,
        [styles.inputWithShowHide]: isPassword,
      })}
      type={getType(type)}
      disabled={disabled}
      readOnly={readOnly}
      value={value}
      onChange={event => {
        if (typeof onChangeValue === 'function') {
          onChangeValue(event.target.value);
        }
      }}
      data-ansarada-ccd={ansaradaCCD || isPassword || undefined}
      data-test-id={testId}
      aria-describedby={ariaDescribedBy}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      onPaste={onPaste}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      required={required}
      maxLength={maxLength}
      minLength={minLength}
      ref={inputRef}
      aria-invalid={status === 'Bad'}
      aria-required={required}
    />
  );

  if (!hasWrapper) return input;

  return (
    <span
      className={cx(classes, {
        [styles.containerWithIcon]: hasIcon,
        [styles.container]: clearable || isPassword,
        [styles.containerClearableWithIcon]: (clearable && hasIcon) || isPassword,
      })}
    >
      {hasIcon && icon}
      {input}
      {isPassword && (
        <PasswordButton
          show
          passwordShow={type !== 'Password'}
          onClick={() => {
            if (!onChangeType) return;
            if (type === 'Password') {
              onChangeType('PasswordText');
            } else {
              onChangeType('Password');
            }
          }}
        />
      )}
      {clearable && value !== '' && (
        <CloseButton
          onClick={() => {
            onChangeValue('');
            // $FlowFixMe
            if (inputRef && inputRef.current) {
              // $FlowFixMe
              inputRef.current.focus();
            }
            if (onClear) onClear(inputRef);
          }}
          className={styles.close}
        />
      )}
    </span>
  );
};

/**
 * Text inputs are input fields used primarily in forms. They allow the user to enter a single line of content in a structured format, e.g. text, URL, password, numbers or phone numbers.
 *
 * Text inputs should be used with a label.
 *
 * @status released
 * @date 26/09/2018
 * @version 12.0.0
 * @tags Page
 * @category Form
 */
const TextInput = (props: TextInputPropType): React$Element<typeof ControlledTextInput> => {
  const allProps = useUncontrolled(props, {
    value: 'onChangeValue',
    type: 'onChangeType',
  });

  return <ControlledTextInput {...allProps} />;
};

export { TextInput };
export type { TextInputType, Type };
