// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const AwaitingAction = (): React.Element<*> => (
  <path
    fill="#fb0069"
    d="M17.502 9.5h-6.293l4.45-4.45c0.084-0.089 0.136-0.21 0.136-0.343 0-0.276-0.224-0.5-0.5-0.5-0.133 0-0.254 0.052-0.343 0.136l0-0-4.45 4.449v-6.293c0-0.276-0.224-0.5-0.5-0.5s-0.5 0.224-0.5 0.5v0 6.293l-4.45-4.449c-0.090-0.090-0.215-0.146-0.354-0.146-0.276 0-0.5 0.224-0.5 0.5 0 0.138 0.056 0.263 0.146 0.354l4.45 4.449h-6.293c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h6.293l-4.45 4.45c-0.084 0.089-0.136 0.21-0.136 0.343 0 0.276 0.224 0.5 0.5 0.5 0.133 0 0.254-0.052 0.343-0.136l-0 0 4.45-4.449v6.293c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-6.293l4.45 4.449c0.090 0.090 0.215 0.146 0.354 0.146 0.276 0 0.5-0.224 0.5-0.5 0-0.138-0.056-0.263-0.146-0.354l-4.45-4.449h6.293c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"
  ></path>
);
