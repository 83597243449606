// @flow strict
import * as React from 'react';
import classnames from 'classnames';
// eslint-disable-next-line css-modules/no-unused-class
import styles from '../style.scss';

type ItemPropType = {|
  displayId: string,
  selected: boolean,
  onClick?: (e: SyntheticMouseEvent<>) => void,
|};

/** @ignore */
const Item = ({ displayId, selected = false, onClick }: ItemPropType): React$Element<'li'> => {
  const classes = classnames(styles.item, { [styles.isSelected]: selected });
  return (
    <li>
      <button disabled={selected ? true : undefined} onClick={onClick} className={classes}>
        {displayId}
      </button>
    </li>
  );
};

export { Item };
