// @flow strict
import * as React from 'react';
import { map } from 'ramda';

import { Table, FormattedContentBlock } from '@ansarada/ace-react';

import styles from './PropsTable.scss';
import { transformComponent, findRelatedComponents, convertProps } from '../../util/transformData';
import { FormattedContentBlockWrapper } from '../FormattedContentBlockWrapper';

type PropTypes = {
  component: {
    displayName: string,
  },
};

const getComposeTemplate = map(item => ({
  propName: item.name,
  propType: '',
  propRequired: '',
  propDefault: '',
  propDescription: '',
}));

const getPropsTemplate = map(item => ({
  propName: item.name,
  propType:
    (item.metadata.flowType && item.metadata.flowType.raw) ||
    (item.metadata.flowType && item.metadata.flowType.name),
  propRequired: item.metadata.required.toString(),
  propDefault: item.metadata.defaultValue ? item.metadata.defaultValue.value : '',
  propDescription: item.metadata.description && item.metadata.description,
}));

const getTemplate = component => {
  const data = component.composes ? [...getComposeTemplate(component.composes)] : [];
  Array.prototype.push.apply(data, getPropsTemplate(convertProps(component.props)));

  const renderCodeBlock = (text: string) =>
    text && text.length > 0 ? (
      <FormattedContentBlockWrapper>
        <code>{text}</code>
      </FormattedContentBlockWrapper>
    ) : (
      text
    );

  return (
    <React.Fragment key={component.displayName}>
      <h2 id={component.displayName} className={styles.title}>
        <FormattedContentBlock>
          <code>{`<${component.displayName}>`}</code>
        </FormattedContentBlock>
      </h2>
      <Table
        headerSticky
        columns={[
          {
            title: 'name',
            field: 'propName',
            sortable: true,
            render: renderCodeBlock,
          },
          {
            title: 'type',
            field: 'propType',
            sortable: true,
            render: renderCodeBlock,
          },
          {
            title: 'Required',
            field: 'propRequired',
            render: renderCodeBlock,
          },
          {
            title: 'Default',
            field: 'propDefault',
            render: renderCodeBlock,
          },
          {
            title: 'Description',
            field: 'propDescription',
            className: styles.description,
          },
        ]}
        data={data}
      />
    </React.Fragment>
  );
};

export const PropsTable = ({ component }: PropTypes) => {
  const data = transformComponent(component);
  const relatedComponents = findRelatedComponents(data)(window.ORBIT.js.parsed);

  return (
    <div>
      {getTemplate(data)}
      {relatedComponents.map(c => !c.path.toLowerCase().includes('internal') && getTemplate(c))}
    </div>
  );
};
