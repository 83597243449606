// @flow strict
import * as React from 'react';
import ReactDOM from 'react-dom';
import type { testIdPropType } from '../../ace-internal/types/general';

type PageHiddenPropsType = {
  children: React.Node,
  ...testIdPropType,
};

/**
 * PageHidden is an abstraction over React portals that portals it’s children to the bottom of the body element.
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Dialog, Toast, Tooltip
 * @category Utils
 */
class PageHidden extends React.Component<PageHiddenPropsType> {
  hiddenElement: HTMLElement | null;

  wrapperElement: HTMLDivElement;

  constructor(props: PageHiddenPropsType) {
    super(props);

    this.hiddenElement = document.querySelector('#ace-hidden');
    this.wrapperElement = document.createElement('div');

    if (!this.hiddenElement) {
      this.hiddenElement = document.createElement('div');
      this.hiddenElement.id = 'ace-hidden';

      if (document.body) {
        // TODO: Replace appendChild with append when IE11 is no longer not supported
        document.body.appendChild(this.hiddenElement);
      }
    }

    if (props['data-test-id']) {
      this.wrapperElement.dataset.testId = props['data-test-id'];
    }
    // TODO: Replace appendChild with append when IE11 is no longer not supported
    this.hiddenElement.appendChild(this.wrapperElement);
  }

  componentWillUnmount() {
    if (this.hiddenElement) {
      this.hiddenElement.removeChild(this.wrapperElement);
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.wrapperElement);
  }
}

export { PageHidden };
