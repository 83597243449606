// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Deny = (): React.Element<*> => (
  <>
    <path d="M13.502 9.49h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"></path>
    <path d="M10.002 1.99c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM10.002 16.99c-3.866 0-7-3.134-7-7s3.134-7 7-7 7 3.134 7 7-3.134 7-7 7z"></path>
  </>
);
