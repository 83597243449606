// @flow strict
import {
  compose,
  pick,
  zipObj,
  toPairs,
  map,
  filter,
  reject,
  equals,
  prop,
  split,
  assoc,
  reverse,
  nth,
} from 'ramda';

const pathComponents = split('/');
const getModule = compose(nth(1), reverse, pathComponents);
const select = pick(['props', 'composes', 'displayName', 'ignore', 'path', 'module']);
// $FlowFixMe - 0.111.3
const addModule = item => assoc('module', getModule(prop('path', item)), item);

// $FlowFixMe
export const convertProps = compose(map(zipObj(['name', 'metadata'])), toPairs);

// $FlowFixMe
export const transformComponent = compose(select, addModule);

// $FlowFixMe
export const findRelatedComponents = component =>
  // $FlowFixMe
  compose(
    map(select),
    reject(item => equals(prop('displayName', component), prop('displayName', item))),
    filter(item => equals(prop('module', component), prop('module', item))),
    map(addModule),
  );
