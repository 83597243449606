// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const Save = (): React.Element<*> => (
  <>
    <path d="M17.090 2h-14.176c-0.504 0-0.912 0.408-0.912 0.912v0 12.422l2.667 2.666h12.422c0.504 0 0.912-0.408 0.912-0.912v0-14.176c0-0.504-0.408-0.912-0.912-0.912-0 0-0 0-0.001 0h0zM14.309 3c0.014 0 0.026 0.012 0.026 0.026v0 5.948c0 0 0 0 0 0 0 0.014-0.011 0.026-0.025 0.026h-8.615c-0.014-0-0.026-0.012-0.026-0.026 0 0 0 0 0 0v0-5.948c0 0 0 0 0 0 0-0.014 0.012-0.026 0.026-0.026v0zM7.027 17c-0.014 0-0.026-0.012-0.026-0.026v0-4.448c0 0 0 0 0 0 0-0.014 0.012-0.026 0.026-0.026 0 0 0 0 0.001 0h7.281c0.014 0 0.026 0.012 0.026 0.026v0 4.448c0 0.014-0.012 0.026-0.026 0.026v0zM17.001 17h-1.667c0-0.009 0.001-0.017 0.001-0.026v-4.448c0-0.567-0.459-1.026-1.026-1.026v0h-7.281c-0.567 0-1.026 0.459-1.026 1.026v0 4.448c0 0.009 0 0.017 0 0.026h-0.919l-2.081-2.081v-11.919h1.667c0 0.009 0 0.017 0 0.026v5.948c0 0.566 0.459 1.026 1.025 1.026h8.615c0.567 0 1.026-0.459 1.026-1.026v0-5.948c0-0.009 0-0.017-0.001-0.026h1.667z"></path>
    <path d="M8.502 13.5c-0.276 0-0.5 0.224-0.5 0.5v0 1.5c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0-1.5c0-0.276-0.224-0.5-0.5-0.5v0z"></path>
  </>
);
