// @flow strict
import * as React from 'react';

import type { NavigationGroup } from '../types';
import { NavigationItem } from './NavigationItem';

import styles from './TertiaryNavigation.scss';

type Props = {|
  items: $ReadOnlyArray<NavigationGroup>,
  label: string,
  testId: string,
  navRef?: React$Ref<'nav'>,
|};

/** @ignore */
export const TertiaryNavigation = ({ items = [], testId, label, navRef }: Props) => {
  const groupedItems = items.reduce((acc, group) => acc.concat(group.items), []);

  return (
    <nav ref={navRef} className={styles.container} aria-label={label} data-test-id={testId}>
      {groupedItems.map(item => {
        return (
          <div key={item.name} className={styles.landmark}>
            <NavigationItem item={item} />
          </div>
        );
      })}
    </nav>
  );
};
