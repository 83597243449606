// @flow strict
import * as React from 'react';
import { Link, Icon, icon } from '@ansarada/ace-react';
import styles from './ShoelaceComponent.scss';
import { loadMdx } from '../../util/loadComponent';

type Props = {|
  match: { params: { id: string } },
|};

type State = {|
  component: React.ComponentType<any>,
|};

const getComponent = (name: string) => window.ORBIT.scss.variables.find(i => i.name.includes(name));
const mountComponent = async (id: string, set: any) => {
  const _component = getComponent(id);
  const toLoad = _component && _component.name.replace('$', '');
  const contents = await loadMdx(toLoad || id);
  set({ component: contents.default });
};

const EmptyComponent = () => null;

export const ShoelaceComponent = ({ match }: Props) => {
  const [cmp, setCmp] = React.useState<State>({ component: EmptyComponent });
  const editUrl = `${window.ORBIT.repository.url}src/master/packages/shoelace/docs/${match.params.id}.mdx?mode=edit&spa=0&at=master&fileviewer=file-view-default`;

  React.useEffect(() => {
    mountComponent(match.params.id, setCmp);
  }, [match.params.id]);

  React.useEffect(() => {
    mountComponent(match.params.id, setCmp);
  }, [match]);

  const Component = cmp.component;

  return (
    <div>
      <div className={styles.editButton}>
        <Link variant="Subtle" icon={<Icon glyph={icon.Glyphs.ActionEdit} />} href={editUrl}>
          Edit this page
        </Link>
      </div>
      <Component />
    </div>
  );
};
