// @flow strict
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';

import { Module, ModuleHeader, ModuleContent, FormattedContentBlockWrapper } from '..';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './ComponentHeader.scss';

type Props = {|
  component: {
    displayName: string,
    status?: string,
    description?: string,
  },
  version?: string,
  tags?: React.Node,
  children?: React.Node,
  isModule?: boolean,
|};

export const ComponentHeader = ({ component, version, tags, isModule, children }: Props) => (
  <header className={styles.header}>
    <div className={classnames(styles.wrapper, styles.headerWrapper)}>
      <div className={classnames(styles.headerContainer, !isModule && styles.fullWidth)}>
        <h1 className={styles.title}>{component.displayName}</h1>
        <FormattedContentBlockWrapper>
          {children && children}
          {component.description && <ReactMarkdown source={component.description} />}
        </FormattedContentBlockWrapper>
      </div>
      {isModule && (
        <Module>
          <ModuleHeader>
            <span className={styles.status}>{component.status}</span>
          </ModuleHeader>
          <ModuleContent>
            <dl className={styles.definitions}>
              {version && <dt>Version:</dt>}
              {version && <dd>{version}</dd>}
              {tags && <dt>Tags:</dt>}
              {tags && <dd>{tags}</dd>}
            </dl>
          </ModuleContent>
        </Module>
      )}
    </div>
  </header>
);
