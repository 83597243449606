// @flow strict
export default class AutocompleteItem {
  value: string;

  _label: string;

  constructor(value: string, label?: string) {
    this.value = value;
    if (label) {
      this._label = label;
    }
  }

  get label() {
    if (!this._label || !this._label.length) {
      return this.value;
    }
    return this._label;
  }
}
