// @flow strict
import * as React from 'react';

/**
 * @ignore
 */
export const LockOpen = (): React.Element<*> => (
  <>
    <path
      fill="#fb0069"
      d="M15.212 8.984h-9.221v-3.006c0-2.218 1.798-4.016 4.016-4.016s4.016 1.798 4.016 4.016v0c0 0.276 0.224 0.5 0.5 0.5s0.5-0.224 0.5-0.5v0c0-2.77-2.246-5.016-5.016-5.016s-5.016 2.246-5.016 5.016v0 3.006h-0.19c-0.99 0.002-1.793 0.804-1.794 1.794v6.412c0 0.989 0.805 1.794 1.792 1.794l10.413 0.031c0.99-0.002 1.793-0.804 1.794-1.794v-6.443c-0.002-0.99-0.804-1.793-1.794-1.794h-0zM16.006 17.222c-0.001 0.438-0.356 0.793-0.794 0.794h-0l-10.412-0.031c-0.438-0.001-0.794-0.356-0.794-0.794v-6.412c0-0.438 0.356-0.794 0.794-0.794h10.412c0.438 0 0.794 0.356 0.794 0.794v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M13.506 14.953h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"
    ></path>
    <path
      fill="#fb0069"
      d="M13.506 11.953h-7c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5v0h7c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5v0z"
    ></path>
  </>
);
