// @flow strict
import * as React from 'react';
import classNames from 'classnames';
import type { testIdPropType } from '../../ace-internal/types/general';
import styles from './style.scss';

type CardPropType = {
  ...testIdPropType,
  children?: React.Node,
  className?: string,
  onClick?: (e: SyntheticMouseEvent<HTMLElement>) => void,
  onKeyDown?: (e: SyntheticKeyboardEvent<HTMLElement>) => void,
  noSpacing?: boolean,
};

/**
 * The Card component is used as a container for other content and elements.
 *
 *
 * Cards can be stacked vertically or horizontally. When stacked vertically, the default width is set to fit container.
 *
 * @status released
 * @version 10.0.0
 * @date 26/09/2019
 * @tags ExpanderPanel
 * @category Layout
 */
const Card = (props: CardPropType) => {
  const { children, className, 'data-test-id': testId, onClick, onKeyDown, noSpacing } = props;

  const classes = classNames(className, styles.card, {
    [styles.isActive]: onClick,
    [styles.noSpacing]: noSpacing,
  });

  if (onClick) {
    return (
      <div
        className={classes}
        data-test-id={testId}
        onClick={onClick}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex="0"
      >
        {children}
      </div>
    );
  }

  return (
    <div className={classes} data-test-id={testId}>
      {children}
    </div>
  );
};

export { Card };
