// @flow strict
import * as React from 'react';
import classnames from 'classnames';
import { Card } from '@ansarada/ace-react';

import styles from './DesignTokenExample.scss';

type Props = {
  children: React.Node,
  title?: string,
  label?: string,
  className?: string,
  isCard?: boolean,
  isFullWidth?: boolean,
};

export const DesignTokenExample = ({
  children,
  title,
  label,
  className,
  isCard,
  isFullWidth = false,
}: Props) => (
  <div className={className}>
    <Card
      className={classnames(styles.card, {
        [styles.isCard]: isCard,
        [styles.isFullWidth]: isFullWidth,
      })}
    >
      <div className={styles.children}>{children}</div>
      {title && <h3 className={styles.title}>{title}</h3>}
    </Card>
    {label && (
      <div className={styles.labelContainer}>
        <span className={styles.label}>{label}</span>
      </div>
    )}
  </div>
);
