// @flow strict
/*!
 * Adapted from react modal
 *
 * https://github.com/reactjs/react-modal/
 */

import findTabbable from './tabbable';

const focusLaterElements = [];
let modalElement = null;
let needToFocus = false;

export function handleBlur() {
  needToFocus = true;
}

export function handleFocus() {
  if (needToFocus) {
    needToFocus = false;
    if (!modalElement && modalElement !== null) {
      return;
    }
    // need to see how jQuery shims document.on('focusin') so we don't need the
    // setTimeout, firefox doesn't support focusin, if it did, we could focus
    // the element outside of a setTimeout. Side-effect of this implementation
    // is that the document.body gets focus, and then we focus our element right
    // after, seems fine.
    setTimeout(() => {
      if (modalElement && !modalElement.contains(document.activeElement)) {
        const el = findTabbable(modalElement)[0] || modalElement;

        if (el !== undefined && el !== null) {
          if (el.focus !== undefined && el.focus !== null) {
            el.focus();
          }
        }
      }
    }, 0);
  }
}

export function markForFocusLater() {
  focusLaterElements.push(document.activeElement);
}

// eslint-disable-next-line consistent-return
export function returnFocus() {
  const toFocus = focusLaterElements.pop();

  if (toFocus !== undefined && toFocus !== null) {
    if (toFocus.focus !== undefined && toFocus.focus !== null) {
      return toFocus.focus();
    }
  }

  // eslint-disable-next-line no-console
  console.warn(
    ['You tried to return focus to', toFocus, 'but it is not in the DOM anymore'].join(' '),
  );
}

export function setupScopedFocus(element: HTMLElement) {
  modalElement = element;

  if (window.addEventListener) {
    window.addEventListener('blur', handleBlur, false);
    document.addEventListener('focus', handleFocus, true);
  } else {
    window.attachEvent('onBlur', handleBlur);
    if (document !== undefined && document.attachEvent !== undefined) {
      document.attachEvent('onFocus', handleFocus);
    }
  }
}

export function teardownScopedFocus() {
  modalElement = null;

  if (window.addEventListener) {
    window.removeEventListener('blur', handleBlur);
    document.removeEventListener('focus', handleFocus);
  } else {
    window.detachEvent('onBlur', handleBlur);
    if (document !== undefined && document.detachEvent !== undefined) {
      document.detachEvent('onFocus', handleFocus);
    }
  }
}
