// @flow strict

import { KeyEvent } from '../../../ace-internal/types/keys';

import type { DropdownMessageUpdateType } from './DropdownMessage';

const dropdownTriggerProps = (
  open: boolean,
  onToggle: DropdownMessageUpdateType,
  setFocusItem: Function,
  mergeEventHandlers?: {
    onClick?: (*) => *,
    onKeyDown?: (*) => *,
  },
) => ({
  'aria-expanded': open,
  onClick: (event: SyntheticMouseEvent<*>) => {
    if (mergeEventHandlers && mergeEventHandlers.onClick) {
      mergeEventHandlers.onClick(event);
    }

    if (!(event.currentTarget instanceof HTMLElement)) {
      return;
    }

    event.stopPropagation();

    setFocusItem(undefined);
    onToggle(!open);
  },

  onKeyDown: (event: SyntheticKeyboardEvent<*>) => {
    if (mergeEventHandlers && mergeEventHandlers.onKeyDown) {
      mergeEventHandlers.onKeyDown(event);
    }

    if (open || !(event.currentTarget instanceof Element)) {
      setFocusItem('First');
      setTimeout(() => {
        // send it to the next tick
        setFocusItem(undefined);
      });
      return;
    }

    if (KeyEvent.isArrowDown(event)) {
      event.preventDefault();
      setFocusItem('First');
      onToggle(true);
      return;
    }

    if (KeyEvent.isArrowUp(event)) {
      event.preventDefault();
      setFocusItem('Last');
      onToggle(true);
      return;
    }

    setFocusItem(undefined);
  },
});

export { dropdownTriggerProps };
