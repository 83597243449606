// @flow strict
import * as React from 'react';
import './libscustom/prism.js';

import { HashRouter as Router, Route, withRouter } from 'react-router-dom';
import type { Location, Match } from 'react-router-dom';
import { Page } from '@ansarada/ace-react';

import styles from './App.scss';
import { mapNavItems } from './util/scss';
import { getComponentsNavItems, groupByCategory } from './util/components';

import { Sidebar } from './components/Sidebar';
import {
  Home,
  Component,
  Components,
  Keyboard,
  Changelog,
  Tests,
  Test,
  FullScreen,
  Shoelace,
  ShoelaceComponent,
} from './pages';
import { Header, MarkdownPage } from './components';

// $FlowFixMe - import outside of project boundaries
import { version as Version } from '../../ace/package.json';

window.Prism.plugins.NormalizeWhitespace.setDefaults({
  'remove-trailing': false,
  'remove-indent': false,
  'break-lines': 80,
});

const mainNavItems = [
  { label: 'Changelog', url: '/changelog' },
  { label: 'Tests', url: '/tests' },
];

const codingNavItems = {
  Usage: [
    { label: 'Getting started', url: '/docs/getting-started.md' },
    { label: 'Setup', url: '/docs/usage/setup.md' },
    { label: 'Upgrade', url: '/docs/usage/upgrade.md' },
    { label: 'Globals & polyfills', url: '/docs/usage/globals-and-polyfills.md' },
  ],
  Architecture: [
    { label: 'Architecture', url: '/docs/architecture/accessibility.md' },
    { label: 'CCD', url: '/docs/architecture/ansarada-ccd.md' },
    { label: 'Component Events', url: '/docs/architecture/component-events.md' },
    { label: 'Component State', url: '/docs/architecture/component-state.md' },
    { label: 'Test ID', url: '/docs/architecture/test-id.md' },
    { label: 'Tests', url: '/docs/architecture/tests.md' },
  ],
  Coding: [
    { label: 'Accessibility', url: '/docs/coding/accessibility.md' },
    { label: 'Keyboard Handling', url: '/docs/coding/keyboard-handling.md' },
  ],
  Contribution: [
    { label: 'Contribution Guide', url: '/docs/contribution/dev.md' },
    { label: 'Ideation', url: '/docs/contribution/ideation.md' },
    { label: 'Dev setup', url: '/docs/contribution/dev-setup.md' },
    { label: 'Developing Components', url: '/docs/contribution/component-development.md' },
    { label: 'Definition of Done', url: '/docs/contribution/definition-of-done.md' },
    { label: 'Template: README', url: '/docs/contribution/component-documentation-template.md' },
    {
      label: 'Checklist: Interaction Design',
      url: '/docs/contribution/interaction-design-checklist.md',
    },
    { label: 'Releasing', url: '/docs/contribution/releasing.md' },
  ],
};

const getUri = (folder, md) => `#/${folder}/${md}`.toLowerCase();

const replaceAll = (str: string, oldValue: string, newValue: string) =>
  str.replace(new RegExp(`href="${oldValue}`, 'g'), `href="${newValue}`);

const mapContent = (path: string, files) => {
  const f = files.keys().map(files);
  return f.map((content, index) => {
    const uri = getUri(path, files.keys()[index])
      .replace('#', '')
      .replace('./', '');
    const contentHtml = replaceAll(content, './', '#/');

    return {
      uri,
      content: contentHtml,
    };
  });
};

// $FlowFixMe
const mdDocFiles = require.context('../../ace/docs', true, /\.md$/);
// $FlowFixMe
const mdScriptsFiles = require.context('../../../scripts', true, /\.md$/);
// $FlowFixMe
const mdComponentsFiles = require.context('../../ace/components', true, /\.md$/);
// $FlowFixMe
const mdOrbitFiles = require.context('../../orbit', false, /\.md$/);
// $FlowFixMe
const mdOrbitDocFiles = require.context('../../orbit/docs', true, /\.md$/);

const docs = mapContent('docs', mdDocFiles);
const scripts = mapContent('scripts', mdScriptsFiles);
const componentsDocs = mapContent('components', mdComponentsFiles);
const orbit = mapContent('orbit', mdOrbitFiles);
const orbitDocs = mapContent('orbitDocs', mdOrbitDocFiles);

type ScrollTopProps = {|
  children: React.Node,
  location: Location,
  match: Match,
|};

const ScrollToTop = ({ location, children }: ScrollTopProps) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const ScrollTop = withRouter(ScrollToTop);

const SidebarWithRouter = withRouter(Sidebar);

const App = () => {
  const components = groupByCategory(getComponentsNavItems(window.ORBIT.js.parsed));
  const designTokens = window.ORBIT.scss.variables;

  const designTokensNavItems = [
    ...mapNavItems(designTokens),
    {
      label: 'Utility mixins',
      url: '/shoelace/utility-mixins',
      image: '',
    },
  ];

  return (
    <Router>
      <Page
        layout="Fluid"
        sideBar={
          <div className={styles.sidebarContainer}>
            <Header version={Version} />
            <SidebarWithRouter
              mainNavItems={mainNavItems}
              codingNavItems={codingNavItems}
              componentsNavItems={components}
              designTokensNavItems={designTokensNavItems}
              className={styles.sidebar}
            />
          </div>
        }
      >
        <main>
          <ScrollTop>
            <Route exact path="/" component={Home} />
            <Route path="/changelog" component={Changelog} />
            <Route path="/keyboard-handling" component={Keyboard} />
            <Route exact path="/shoelace" component={Shoelace} />
            <Route exact path="/shoelace/:id" component={ShoelaceComponent} />
            <Route exact path="/components" component={Components} />
            <Route exact path="/components/:id" component={Component} />
            <Route exact path="/components/:id/fullscreen/:example" component={FullScreen} />
            <Route exact path="/tests" component={Tests} />
            <Route path="/tests/:id" component={Test} />
            {docs.map(route => (
              <Route
                path={route.uri}
                key={route.uri}
                render={() => <MarkdownPage uri={route.uri} contentHtml={route.content} />}
              />
            ))}
            {scripts.map(route => (
              <Route
                key={route.uri}
                path={route.uri}
                render={() => <MarkdownPage uri={route.uri} contentHtml={route.content} />}
              />
            ))}
            {componentsDocs.map(route => (
              <Route
                key={route.uri}
                path={route.uri}
                render={() => <MarkdownPage uri={route.uri} contentHtml={route.content} />}
              />
            ))}
            {orbit.map(route => (
              <Route
                key={route.uri}
                path={route.uri}
                render={() => <MarkdownPage uri={route.uri} contentHtml={route.content} />}
              />
            ))}
            {orbitDocs.map(route => (
              <Route
                key={route.uri}
                path={route.uri}
                render={() => <MarkdownPage uri={route.uri} contentHtml={route.content} />}
              />
            ))}
          </ScrollTop>
        </main>
      </Page>
    </Router>
  );
};

export default App;
