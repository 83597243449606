// @flow strict
import * as React from 'react';

import { BaseGridCell } from './Internal/BaseGridCell';
import type { BaseGridCellType } from './Internal/BaseGridCell';

export type FormGridCellPropsType = {|
  ...BaseGridCellType,
|};

/**
 * @ignore
 */
const FormGridContentCell = ({
  children,
  'data-test-id': testId,
  'data-ansarada-ccd': ansaradaCCD,
}: FormGridCellPropsType) => (
  <BaseGridCell data-test-id={testId} data-ansarada-ccd={ansaradaCCD}>
    {children}
  </BaseGridCell>
);

export { FormGridContentCell };
