// @flow strict
import * as React from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import { addEventListener } from '../../ace-internal/util/dom';

import { configInternalTestId } from '../../ace-internal/util/util';
import type { testIdPropType } from '../../ace-internal/types/general';

import type { uiTextType, NavigationItem, NavigationGroup } from './types';
import { ProductSwitcher } from './Internal/ProductSwitcher';
import { MainNavigation } from './Internal/MainNavigation';
import { SecondaryNavigation } from './Internal/SecondaryNavigation';

import styles from './styles.scss';
import { TertiaryNavigation } from './Internal/TertiaryNavigation';

type HeaderPropType = {|
  ...testIdPropType,
  className?: string,
  uiText?: uiTextType,
  /** The full name of the user */
  userName: string,
  /** The name of the currently active product */
  productName: string,
  /** Enable a dropdown on product name to switch product */
  products: $ReadOnlyArray<NavigationItem>,
  /** Main navigation groups */
  navigationGroups: $ReadOnlyArray<NavigationGroup>,
|};

const createInternalTestId = configInternalTestId({ componentName: 'header' });

/**
 * Header is the universal header for all products on the Ansarada platform.
 *
 * It should be the first component inside any given app, and should only be used once (do not stack).
 *
 * @status released
 * @date  26/09/2018
 * @version  12.0.0
 * @tags  Nav, SidebarMenu
 * @category Navigation
 */
const Header = ({
  userName,
  productName,
  products,
  uiText = {
    productName: 'Product Name',
    mainNavigation: 'Primary Navigation',
    secondaryNavigation: 'Profile Settings',
    secondaryNavigationTrigger: 'Open Profile Settings',
  },
  navigationGroups,
  'data-test-id': testId,
  className,
}: HeaderPropType) => {
  const mainNavigationGroups: NavigationGroup[] = navigationGroups.filter(l => l.isKey);
  const tertiaryNavigationGroups = navigationGroups.filter(l => l.isTertiary);
  const [isMainVisible, setMainVisible] = React.useState(true);
  const [isTertiaryVisible, setTertiaryVisible] = React.useState(true);

  const flexContainerRef = React.useRef();
  const detectMainRef = React.useRef<?React$ElementRef<'nav'>>(undefined);
  const detectTertiaryRef = React.useRef<?React$ElementRef<'nav'>>(undefined);

  const updateVisible = () => {
    if (flexContainerRef.current) {
      if (detectMainRef.current) {
        const node = detectMainRef.current;
        setMainVisible(node.offsetTop - flexContainerRef.current.offsetTop === 0);
      }
      if (detectTertiaryRef.current) {
        const node = detectTertiaryRef.current;
        setTertiaryVisible(node.offsetTop - flexContainerRef.current.offsetTop === 0);
      }
    }
  };

  const debouncedWindowResize = debounce(updateVisible, 100);

  React.useEffect(() => {
    updateVisible();
  }, [navigationGroups, userName, productName]);

  React.useEffect(() => {
    const unbindHandlers = [addEventListener(window, 'resize', debouncedWindowResize)];
    return () => {
      unbindHandlers.forEach(unbindFn => unbindFn());
    };
  }, [debouncedWindowResize]);

  const getSecondaryNavigationGroups = () => {
    return navigationGroups
      .map(l => {
        const secondaryItems = l.items.filter(
          item => (item.onClick || item.link) && item.isSecondary !== false,
        );
        if (!secondaryItems.length) return undefined;
        return {
          ...l,
          items: secondaryItems,
        };
      })
      .filter(Boolean)
      .filter(l => !(l.isKey && isMainVisible))
      .filter(l => !(l.isTertiary && isTertiaryVisible));
  };

  return (
    <header data-test-id={testId} className={classNames(className, styles.appNavigation)}>
      {/* The main area where the logo is located */}
      <ProductSwitcher
        testId={createInternalTestId('product-dropdown')}
        productName={productName}
        productNameLabel={uiText.productName}
        items={products}
      />

      <div className={styles.flexibleContainer} ref={flexContainerRef}>
        {/* The main navigation as a list of items */}
        <MainNavigation
          navRef={detectMainRef}
          items={mainNavigationGroups}
          label={uiText.mainNavigation}
          testId={createInternalTestId('nav-key-landmarks')}
        />

        {/* The tertiary navigation take the rest of space */}
        <TertiaryNavigation
          navRef={detectTertiaryRef}
          items={tertiaryNavigationGroups}
          label={uiText.mainNavigation}
          testId={createInternalTestId('nav-tertiary')}
        />

        {/* The empty is used to hide tertiary */}
        <div className={styles.empty} />
      </div>

      {/* The secondary navigation as a dropdown */}
      <SecondaryNavigation
        items={getSecondaryNavigationGroups()}
        testId={createInternalTestId('nav-key-landmarks')}
        heading={uiText.secondaryNavigation}
        triggerLabel={uiText.secondaryNavigationTrigger}
        userName={userName}
      />
    </header>
  );
};

export { Header };
