// @flow strict

import * as React from 'react';
import { RichUtils, EditorState } from 'draft-js';

import type { DraftInlineStyle } from 'draft-js/lib/DraftInlineStyle';
import type { DraftBlockType } from 'draft-js/lib/DraftBlockType';

import { HeadingControls, Controls } from './Controls';
import constants from './constants';
import styles from './Toolbar.scss';

type Props = {
  contentRaw: EditorState,
  showHeadingControls?: boolean,
  onChange: (contentRaw: EditorState) => void,
};

/** @ignore */
export const Toolbar = ({ contentRaw, showHeadingControls, onChange }: Props) => {
  const selection = contentRaw.getSelection();
  const blockType = contentRaw
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const inlineStyle = contentRaw.getCurrentInlineStyle();

  const inlineStyles = constants.InlineStyles;
  const listItemTypes = constants.ListItemTypesBasic;

  const toggleBlockType = (dbType: DraftBlockType) => {
    onChange(RichUtils.toggleBlockType(contentRaw, dbType));
  };

  const toggleInlineStyle = (iStyle: DraftInlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(contentRaw, iStyle));
  };

  return (
    <div className={styles.toolbar}>
      {showHeadingControls ? (
        <HeadingControls
          options={constants.HeadingStyles}
          currentStyles={new Set([blockType])}
          onUpdate={toggleBlockType}
        />
      ) : null}
      <Controls controls={inlineStyles} currentStyles={inlineStyle} onUpdate={toggleInlineStyle} />
      <Controls
        controls={listItemTypes}
        currentStyles={new Set([blockType])}
        onUpdate={toggleBlockType}
      />
    </div>
  );
};
