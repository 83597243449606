// @flow strict

import React from 'react';
import { Datepicker, Form, Label, FormSingleGroup, formSingleGroup } from '../components';

const DatepickerTest = () => {
  return (
    <div>
      <Form>
        <FormSingleGroup
          contentType={formSingleGroup.ContentType.Text}
          label={<Label forEl="datepicker-ace-datepicker-input" text="Basic Datepicker" />}
        >
          <Datepicker
            id="datepicker"
            format="D/M/Y"
            className="datepicker-test-className"
            defaultCalendarVisible={false}
            defaultSelectedDate={null}
          />
        </FormSingleGroup>
      </Form>

      <Form>
        <FormSingleGroup
          contentType={formSingleGroup.ContentType.Text}
          label={
            <Label forEl="datepicker-range-ace-datepicker-input" text="Restricted Date Range" />
          }
        >
          <Datepicker
            id="datepicker-range"
            format="D/M/Y"
            minDate={new Date(2017, 3, 10)}
            maxDate={new Date(2017, 3, 20)}
            defaultCalendarVisible={false}
            defaultSelectedDate={null}
          />
        </FormSingleGroup>
      </Form>

      <Form>
        <FormSingleGroup
          contentType={formSingleGroup.ContentType.Text}
          label={
            <Label forEl="datepicker-format-ace-datepicker-input" text="Datepicker with Format" />
          }
        >
          <Datepicker
            id="datepicker-update"
            format="D/M/Y"
            defaultCalendarVisible={false}
            defaultSelectedDate={null}
          />
        </FormSingleGroup>
      </Form>

      <Form>
        <FormSingleGroup
          contentType={formSingleGroup.ContentType.Text}
          label={<Label forEl="datepicker-update-ace-datepicker-input" text="Preselected date" />}
        >
          <Datepicker
            id="datepicker-update"
            format="D/M/Y"
            selectedDate={new Date()}
            // eslint-disable-next-line no-console
            onSelectDate={e => console.log(e)}
            // eslint-disable-next-line no-console
            onCalendarVisible={e => console.log(e)}
          />
        </FormSingleGroup>
      </Form>
    </div>
  );
};

export default DatepickerTest;
