// @flow strict
import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './Header.scss';

type Props = {|
  version: number,
|};

export const Header = ({ version }: Props) => (
  <header role="banner" className={styles.header}>
    <div className={styles.logoContainer}>
      <Link to="/">
        <img src="ace.svg" width="53" height="50" alt="Ace icon" />
      </Link>
      <span className={styles.version}>v{version}</span>
    </div>
  </header>
);
